// DocumentDownloader.js
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { downloadFileFromURL } from '../../lib/downloadFile';
import Notification from '../../components/Notification';
import { documentActions } from '../../store/actions/superBillActions';

// Configuration for different document types
const documentConfig = {
  superBill: {
    localStorageKey: 'superBillTasks',
    downloadSuccessMessage: 'Superbill downloaded successfully',
    downloadErrorMessage: 'Superbill was not downloaded',
    getFilename: (query) => {
      let fromDate = query?.fromdate?.split('-');
      let toDate = query?.todate?.split('-');

      if (!fromDate || !toDate) return `SuperBill-${new Date().toISOString().slice(0, 10)}.pdf`;

      fromDate = `${fromDate[2]}${fromDate[1]}${fromDate[0]}`;
      toDate = `${toDate[2]}${toDate[1]}${toDate[0]}`;
      return `SuperBill-(${fromDate}-${toDate}).pdf`;
    },
  },
  statement: {
    localStorageKey: 'statementTasks',
    downloadSuccessMessage: 'Statement downloaded successfully',
    downloadErrorMessage: 'Statement was not downloaded',
    getFilename: () => `Statement-${new Date().toISOString().slice(0, 10)}.html`,
  },
  // Add other document types as needed
};

const DocumentDownloader = ({ documentTypes = ['superBill', 'statement'] }) => {
  const dispatch = useDispatch();
  const documentState = useSelector((state) => state.superBill || {});

  // Process each document type
  useEffect(() => {
    documentTypes.forEach((documentType) => {
      const config = documentConfig[documentType];
      if (!config) return;

      // Retrieve tasks from local storage
      try {
        const storedTasks = JSON.parse(localStorage.getDecryptedData(config.localStorageKey)) || [];

        // Update Redux store with tasks for this document type
        storedTasks.forEach((task) => {
          if (task && task.taskId) {
            dispatch(documentActions.addDocumentTask({ ...task, documentType }));
          }
        });
      } catch (error) {
        console.error(`Error loading ${documentType} tasks:`, error);
      }
    });
  }, []);

  // Handle polling and downloads for each document type
  useEffect(() => {
    documentTypes.forEach((documentType) => {
      const config = documentConfig[documentType];
      if (!config) return;

      const tasks = documentState[documentType]?.tasks || [];

      // Start polling for tasks that are 'In Progress' and not currently being polled
      tasks.forEach(({ taskId, status, polling }) => {
        if ((status === 'In Progress') && !polling) {
          dispatch(documentActions.pollDocumentStatus(taskId, documentType));
        }
      });

      // Handle download and notification
      tasks.forEach(async ({
        status, downloadUrl, taskId, query, downloading,
      }) => {
        if (status === 'Completed' && downloadUrl && !downloading) {
          try {
            dispatch(documentActions.setDocumentDownloading(taskId, true, documentType));
            const filename = config.getFilename(query);
            await downloadFileFromURL(downloadUrl, filename);
            Notification({ message: config.downloadSuccessMessage, success: true });
            dispatch(documentActions.setDocumentStatus('Downloaded', taskId, documentType));
          } catch (error) {
            console.error('File download error:', error);
            dispatch(documentActions.setDocumentDownloading(taskId, false, documentType));
          }
        } else if (status === 'Error' && downloadUrl === null) {
          Notification({ message: config.downloadErrorMessage, success: false });
        }
      });
    });
  }, [documentState, dispatch]);

  return null;
};

export default DocumentDownloader;
