import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

import useCRUD from '../../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../../hooks/useRedirect';
import useReduxState from '../../../../../../../hooks/useReduxState';

import { apiUrls } from '../../../../../../../api/constants';
import { listIds } from '../../../../../../../lib/constants';
import Events from '../../../../../../../lib/events';
import successMessages from '../../../../../../../lib/successMessages';
import { formatAmount } from '../../../../../../../lib/util';

import Modal from '../../../../../../../components/Modal';
import Loader from '../../../../../../../components/Loader';
import Search from '../../../../../../../components/Search';
import Button from '../../../../../../../components/Button';
import Notification from '../../../../../../../components/Notification';

import ClaimList from '../../ClaimsTable';
import LineItemsTable from '../../LineItemsTable';
import PatientLineItemsTable from '../../../PatientPayment/PatientLineItemTable';
import { parsedLineItemData } from '../../../RemittanceCommonParser';

import PatientPaymentTable from '../../../PatientPayment/PatientPaymentTable';
import './EditWorklist.scss';

const InsurancePayment = ({
  selectedClaim, labels, handleOnBack, setLineItemData, patientId, isRemittancePayment,
  isEditTransaction, isEditWorkList, worklistId, searchText, setSelectedClaim, handleSearch,
  search, insurancePayerId, isApplyTransaction, remittancePaymentIdentifier, remittanceId,
  setPatientId, selectedPatientId, setBillingEncounterId, selectedBillingEncounterId,
  doNotFetchLineItemsData, editTransactionPatientId,
}) => (selectedClaim ? (
  <div className="pannel back-pannel mr-top-20">
    <div className="pannel-heading">
      <p className="back-arrow" id="edit-worklist_goBack" onClick={handleOnBack} role="presentation">
        {labels.get('buttons.goBack')}
      </p>
    </div>
    <div className="line-item-table-wrapper">
      <LineItemsTable
        claimId={selectedClaim}
        labels={labels}
        onDataChange={setLineItemData}
        patientId={patientId}
        isRemittancePayment={isRemittancePayment}
        remittanceId={remittanceId}
        isEditErrorTransaction={isEditTransaction || isApplyTransaction}
        isEditWorkList={isEditWorkList}
        worklistId={worklistId}
        isEditWorkListLineItems
        selectedPatientId={selectedPatientId}
        billingEncounterId={selectedBillingEncounterId}
        remittancePaymentIdentifierForWorklist={remittancePaymentIdentifier}
        patientIdForEditTransaction={editTransactionPatientId}
        doNotFetchLineItemsData={doNotFetchLineItemsData}
      />
    </div>
  </div>
)
  : (
    <>
      <Search
        placeholder="Search"
        value={searchText}
        onChange={handleSearch}
        className="claim-search-box"
        disabled={isEditTransaction}
      />
      <ClaimList
        labels={labels}
        onClick={setSelectedClaim}
        searchText={search}
        patientId={isEditTransaction && patientId}
        isEditTransaction={isEditTransaction}
        insurancePayerId={insurancePayerId}
        setPatientId={setPatientId}
        setBillingEncounterId={setBillingEncounterId}
      />
    </>
  )
);

const PatientPayment = ({
  selectedClaim, labels, handleOnBack, onPostPatientWorklist, setCurrentPath,
  paymentForm, formValidation, searchText, setSelectedClaim, handleSearch,
  patientTransactionTypeId, setLocation, worklistId, isEditWorkList, setPatientData,
  setPatientLocation, totalNoOfPages,
  setIsAllClaimPosted,
}) => (selectedClaim ? (
  <div className="pannel back-pannel mr-top-20">
    <div className="pannel-heading">
      <p className="back-arrow" id="edit-worklist_goBack" onClick={handleOnBack} role="presentation">
        {labels.get('buttons.goBack')}
      </p>
    </div>
    <div className="line-item-table-wrapper">
      <PatientLineItemsTable
        labels={labels}
        onPost={onPostPatientWorklist}
        setCurrentPath={setCurrentPath}
        paymentForm={paymentForm}
        formValidation={formValidation}
        totalNoOfPages={totalNoOfPages}
        transactionTypeId={patientTransactionTypeId}
        setLocation={setLocation}
        selectedPatientId={selectedClaim}
        isEditWorkList={isEditWorkList}
        worklistId={worklistId}
        setPatientData={setPatientData}
        setClaimPosted={setIsAllClaimPosted}
        setPatientLocation={setPatientLocation}
      />
    </div>
  </div>
)
  : (
    <>
      <Search
        placeholder="Search"
        value={searchText}
        onChange={handleSearch}
        className="claim-search-box"
      />
      <PatientPaymentTable
        labels={labels}
        searchText={searchText}
        setCurrentPath={setCurrentPath}
        onClick={setSelectedClaim}
      />
    </>
  ));

const EditWorklist = ({
  isVisible, toggleModal, labels, title, transactionTypeId, isApplyTransaction,
  isEditTransaction, isRemittancePayment, isEditWorkList, insurancePayerId, patientDetails = {},
  isPatientPaymentItems, setCurrentPath, onPostPatientWorklist, setNeedToScroll, isDataSaveInRoute,
  paymentForm, formValidation, patientTransactionTypeId, pageNo, totalNoOfPages, isResolvingError,
}) => {
  const {
    generatePath, params,
    path,
    replace,
  } = useRedirect();
  const {
    remittanceId, remittancePaymentId, patientPaymentPage, searchTextData, selectedClaimData,
    tabId, claimId: newClaimId, id: newPatientId, financialId, encounterId = 'encounterId', editTransactionPatientId,
    errorId,
  } = params;
  const [search, setSearch] = useState();
  const [searchText, setSearchText] = useState();
  const [selectedClaim, setSelectedClaim] = useState();
  const [lineItemData, setLineItemData] = useState([]);
  const [isAllClaimsPosted, setIsAllClaimPosted] = useState(false);
  const [patientData, setPatientData] = useState({});
  const [location, setLocation] = useState();
  const [selectedPatientId, setPatientId] = useState();
  const [selectedBillingEncounterId, setBillingEncounterId] = useState();

  const formId = path.indexOf('new') > -1 ? tabId : remittancePaymentId;
  const [, setNewLineItemData] = useReduxState(`insurance-payment-lineItemData-${newClaimId}-${newPatientId}-${financialId}-${formId}-${encounterId}-${tabId}`, []);

  const {
    patientId, amount, worklistId, claimId, balance,
    pageNumber, claimNo, remittancePaymentIdentifier,
  } = patientDetails;

  const [
    postLineItemResponse,,
    postLineItemLoading,
    postLineItem,
    clearPostLineItem,
  ] = useCRUD({
    id: listIds.EDIT_WORKLIST_TO_CLAIMS,
    url: apiUrls.EDIT_INSURANCE_PAYMENT,
    type: 'create',
  });

  const [
    editApplyTransactionResponse,,
    editApplyTransactionLoading,
    editApplyTransaction,
    clearEditApplyTransaction,
  ] = useCRUD({
    id: listIds.EDIT_APPLY_REMITTANCE_ERROR_TRANSACTION,
    url: apiUrls.EDIT_APPLY_REMITTANCE_ERROR_TRANSACTION,
    type: 'create',
  });

  const handleSearch = useCallback(({ target: { value } }) => {
    if (isDataSaveInRoute) {
      const url = path.substring(0, path.indexOf('remittancePaymentIdentifier'));
      let addedPath = 'remittancePaymentIdentifier/:searchTextData';
      if (path.includes('/:errorId')) {
        addedPath = 'remittancePaymentIdentifier/:searchTextData/:errorId';
      }
      replace(generatePath(`${url}${addedPath}`, {
        ...params,
        searchTextData: value || ' ',
      }));
    }
    setSearchText(value);
  }, [generatePath, isDataSaveInRoute, params, path, replace]);

  const handleSelectedClaim = useCallback((value, claimPatientId) => {
    if (isDataSaveInRoute) {
      const url = path.substring(0, path.indexOf('remittancePaymentIdentifier'));
      let addedPath = 'remittancePaymentIdentifier/:searchTextData/:selectedClaimData/:editTransactionPatientId';
      if (path.includes('/:errorId')) {
        addedPath = 'remittancePaymentIdentifier/:searchTextData/:errorId/:selectedClaimData/:editTransactionPatientId';
      }
      replace(generatePath(`${url}${addedPath}`, {
        ...params,
        selectedClaimData: value,
        editTransactionPatientId: claimPatientId || value,
      }));
    }
    setSelectedClaim(value);
  },
  [generatePath, isDataSaveInRoute, params, path, replace]);

  const handleOnBack = useCallback(() => {
    setNewLineItemData([]);
    setSelectedClaim();
  }, [setNewLineItemData]);

  const onPost = useCallback(async () => {
    const hasCreditPayment = isPatientPaymentItems
      ? patientData?.some((item) => item?.description === 'Credit Payment')
      : lineItemData?.some((item) => item?.description === 'Credit Payment');
    if (isPatientPaymentItems) {
      const isValidated = await formValidation(true, labels, totalNoOfPages);
      if (isValidated) {
        postLineItem({
          data: {
            lineItems: parsedLineItemData(patientData)?.map((item) => ({
              ...item,
              worklistId,
              pageNo: pageNumber || pageNo,
            })),
            isPatientPayment: true,
            remittanceBatchId: parseInt(remittanceId, 10),
            transactionTypeId: patientTransactionTypeId,
            paymentId: parseInt(remittancePaymentId, 10),
            locationId: location,
            worklistId,
            IsEditWorklist: true,
            pageNo: pageNumber || pageNo,
            remittancePaymentIdentifier,
          },
        });
      } else {
        Notification({ message: 'Please enter applied amount' });
      }
      setNeedToScroll(false);
    } else {
      const isDenialReasonNotSelected = lineItemData?.some((item) => (
        parseFloat((item?.approved), 10) === 0) && !item?.denialReasonId);
      if (isDenialReasonNotSelected) {
        Notification({ message: 'Please select denial reason' });
        return;
      }
      if (isEditWorkList) {
        postLineItem({
          data: {
            LineItems: parsedLineItemData(lineItemData)?.map((item) => ({
              ...item,
              worklistId,
              pageNo: pageNumber || pageNo,
            })),
            paymentId: remittancePaymentId,
            remittanceBatchId: remittanceId,
            transactionTypeId,
            IsEditWorklist: true,
            worklistId,
            pageNo: pageNumber || pageNo,
            existingClaimId: selectedClaim,
            remittancePaymentIdentifier,
            isCreditPayment: hasCreditPayment,
          },
        });
        setNeedToScroll(false);
      } else if (isEditTransaction || isApplyTransaction) {
        editApplyTransaction({
          data: {
            LineItems: parsedLineItemData(lineItemData, true)?.map((item) => ({
              ...item,
              worklistId,
              pageNo: pageNumber || pageNo,
            })),
            FinancePaymentId: remittancePaymentId,
            remittanceBatchId: remittanceId,
            transactionTypeId,
            PreviousClaimId: claimId,
            ChangedClaimId: selectedClaim,
            pageNo: pageNumber || pageNo,
            worklistId: worklistId && worklistId > 0 ? worklistId : null,
            isEditWorkList: !!(worklistId && worklistId > 0),
            remittancePaymentIdentifier,
            isResolvingError: isEditTransaction ? isResolvingError : undefined,

          },
        });
      }
      setNeedToScroll(false);
    }
  }, [isPatientPaymentItems, formValidation, labels, totalNoOfPages, location, setNeedToScroll,
    postLineItem, patientData, remittanceId, patientTransactionTypeId, remittancePaymentId,
    worklistId, pageNumber, pageNo, remittancePaymentIdentifier, lineItemData, isEditWorkList,
    isEditTransaction, isApplyTransaction, transactionTypeId, selectedClaim, editApplyTransaction,
    claimId, isResolvingError]);

  const handleBack = useCallback(() => {
    setNewLineItemData([]);
    toggleModal();
  }, [setNewLineItemData, toggleModal]);

  const modalFooter = useMemo(() => (selectedClaim ? [
    <div className="group-btns" key="footer">
      <Button
        className="btn min-wt-86"
        id="patients_financial_insurancePayment_cancel"
        onClick={handleBack}
      >
        {labels.get('cancel')}
      </Button>
      <Button
        className={classNames('btn min-wt-86 inline', {
          'btn-success': !isAllClaimsPosted,
          'cursor-not-allowed': isAllClaimsPosted,
        })}
        id="patients_financial_insurancePayment_save"
        onClick={onPost}
        disabled={isAllClaimsPosted}
      >
        {labels.get('post')}
      </Button>
    </div>,
  ] : [
    <div className="group-btns" key="footer">
      <Button
        className="btn min-wt-86"
        id="patients_financial_insurancePayment_cancel"
        onClick={handleBack}
      >
        {labels.get('cancel')}
      </Button>
    </div>,
  ]), [handleBack, isAllClaimsPosted, labels, onPost, selectedClaim]);

  const debouncedSetSearch = useMemo(() => debounce(setSearch, 1500), []);

  useEffect(() => {
    if (searchTextData && !searchText) {
      setSearchText(searchTextData);
    }

    if (selectedClaimData && !selectedClaim) {
      setSelectedClaim(selectedClaimData);
    }
  }, []);

  useEffect(() => {
    if (postLineItemResponse && !Array.isArray(postLineItemResponse)) {
      Notification({ message: postLineItemResponse?.messages, success: true });
      if (setNeedToScroll) {
        setNeedToScroll(false);
      }
      Events.trigger(`refetch-view-entered-item-details${remittancePaymentId}`);
      Events.trigger(`refetch-finance-view-entered-item-details${remittancePaymentId}`);
      Events.trigger('refetchBatchDetails');
      Events.trigger(`GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`);
      if (patientPaymentPage) {
        Events.trigger('GET_AMOUNT_AFTER_POSTING_BATCH_PATIENT_PAYMENT');
      } else {
        Events.trigger('GET_AMOUNT_AFTER_POSTING_BATCH');
      }
      clearPostLineItem();
      setNewLineItemData([]);
      toggleModal(!isVisible);
    }
  }, [clearPostLineItem, postLineItemResponse]);

  useEffect(() => {
    if (editApplyTransactionResponse && !Array.isArray(editApplyTransactionResponse)) {
      Notification({
        message: successMessages.REMITTANCE_ERROR_TRANSACTION_UPDATED_SUCCESSFULLY, success: true,
      });
      Events.trigger(`refetchPostedClaimsError${remittancePaymentId}`);
      Events.trigger(`GET_VIEW_ENTERED_ITEMS_${remittancePaymentId}`);
      Events.trigger(`refetch-remittance-error-list${remittanceId}`);
      Events.trigger('refetchBatchDetails');
      Events.trigger('GET_AMOUNT_AFTER_POSTING_BATCH');
      if (isApplyTransaction) {
        Events.trigger(`refetch-insurance-payment-details-${remittancePaymentId}`);
      }

      clearEditApplyTransaction();
      setNewLineItemData([]);
      toggleModal(!isVisible);
    }
  }, [editApplyTransactionResponse]);

  useEffect(() => {
    const newSearchString = searchText || '';
    if (newSearchString && `${newSearchString}`.length >= 3 && !(errorId === '2468')) {
      debouncedSetSearch(`${newSearchString}`);
    } else {
      debouncedSetSearch();
    }
  }, [debouncedSetSearch, searchText]);

  useEffect(() => {
    if (!isVisible) {
      setSelectedClaim();
      setLineItemData([]);
      setSearchText();
      setSearch();
    }
  }, [isVisible]);

  useEffect(() => {
    if (isEditTransaction && claimNo) {
      if (!(errorId === '2468')) {
        setSearch(claimNo);
      }
      setSearchText(claimNo);
    }
  }, [isEditTransaction, patientId, claimNo]);

  useEffect(() => {
    const isClaimsPosted = lineItemData?.every((item) => item?.balance <= 0);
    const isLineItemEdited = lineItemData?.some((item) => item?.isEdited);
    const hasCreditPayment = lineItemData?.some((item) => item?.description === 'Credit Payment');

    const isEditErrorTransacton = isEditTransaction || isApplyTransaction;

    if (hasCreditPayment) {
      setIsAllClaimPosted(false);
    } else {
      setIsAllClaimPosted((isClaimsPosted && !isEditErrorTransacton) || !isLineItemEdited);
    }
  }, [lineItemData, isEditTransaction, isApplyTransaction]);
  return (
    <Modal
      visible={isVisible}
      toggleModal={handleBack}
      width="1000px"
      footer={modalFooter}
      destroyOnClose
      afterClose={setLocation}
      maskClosable={false}
      className="edit-worklist-modal"
    >
      {(postLineItemLoading || editApplyTransactionLoading) && <Loader />}
      <div className="addition-header">
        <div className="lookup sprite-img-before">
          <p className="">{title ?? labels.get('titles.editWorklist')}</p>
          <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>|</p>
          <p>{`Amount: ${formatAmount(parseFloat(amount || 0, 10))}`}</p>
          {isEditWorkList && (
            <>
              <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>|</p>
              <p>{`Balance: ${formatAmount(parseFloat(balance || 0, 10))}`}</p>
            </>
          )}
        </div>
      </div>
      { !isPatientPaymentItems ? (
        <InsurancePayment
          selectedClaim={selectedClaim}
          labels={labels}
          handleOnBack={handleOnBack}
          setLineItemData={setLineItemData}
          patientId={patientId}
          isRemittancePayment={isRemittancePayment}
          remittanceId={remittanceId}
          isEditTransaction={isEditTransaction}
          isApplyTransaction={isApplyTransaction}
          isEditWorkList={isEditWorkList}
          worklistId={worklistId}
          searchText={searchText}
          setSelectedClaim={handleSelectedClaim}
          handleSearch={handleSearch}
          search={search}
          insurancePayerId={insurancePayerId}
          setPatientId={setPatientId}
          selectedPatientId={selectedPatientId}
          setBillingEncounterId={setBillingEncounterId}
          remittancePaymentIdentifier={remittancePaymentIdentifier}
          selectedBillingEncounterId={selectedBillingEncounterId}
          editTransactionPatientId={editTransactionPatientId}
          doNotFetchLineItemsData
        />
      ) : (
        <PatientPayment
          selectedClaim={selectedClaim}
          labels={labels}
          handleOnBack={handleOnBack}
          onPostPatientWorklist={onPostPatientWorklist}
          setCurrentPath={setCurrentPath}
          paymentForm={paymentForm}
          formValidation={formValidation}
          totalNoOfPages={totalNoOfPages}
          searchText={searchText}
          setSelectedClaim={handleSelectedClaim}
          handleSearch={handleSearch}
          patientTransactionTypeId={patientTransactionTypeId}
          setLocation={setLocation}
          worklistId={worklistId}
          isEditWorkList={isEditWorkList}
          setPatientData={setPatientData}
          setIsAllClaimPosted={setIsAllClaimPosted}
          setPatientLocation={setLocation}
        />
      )}
    </Modal>
  );
};

export default EditWorklist;
