import React, {
  useCallback, useMemo, useState,
} from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { apiUrls } from '../../../api/constants';
import downloadFile from '../../../lib/downloadFile';
import { labelPaths, responseType } from '../../../lib/constants';

import WithLabel from '../../../hoc/withLabel';
import useCrudWithEffects from '../../../hooks/useCrudWithEffects';

import Button from '../../../components/Button';
import Modals from '../../../components/Modal';
import Notification from '../../../components/Notification';
import usePatientDetail from '../../../hooks/usePatientDetail';

import AppointmentListModal from './appointmentListModal';
import DetailStatementModal from './detailStatementModal';

import './print.scss';
import '../mFax/mfax.scss';

import { superBillActions } from '../../../store/actions/superBillActions';

const PrintModal = ({
  labels, visible, toggleModal, printProps,
}) => {
  const dispatch = useDispatch();
  const timePeriod = useSelector((state) => state.superBill.superBill.timePeriod);
  const isPrintButtonDisabled = useSelector((state) => state
    .superBill.superBill
    .isPrintButtonDisabled);

  const [appointmentListModalVisibility, setAppointmentListModalVisibility] = useState(false);
  const [detailStatementModalVisibility, setDetailStatementModalVisibility] = useState(false);
  const handleFaceSheetPdf = useCallback(
    (faceSheetResponse) => {
      downloadFile(
        faceSheetResponse,
        `faceSheet-${moment().format('DD-MM-YY hh-mm-ss')}.pdf`,
        'application/pdf',
      );
      toggleModal();
    },
    [toggleModal],
  );

  const handleClinicalSummaryPdf = useCallback((clinicalSummaryResponse) => {
    window.open(clinicalSummaryResponse?.documentUrl, '_blank');
  }, []);

  const handleBatchDetailPdf = useCallback((batchDetailResponse) => {
    downloadFile(
      batchDetailResponse,
      `batchDetail-${moment().format('DD-MM-YY hh-mm-ss')}.pdf`,
      'application/pdf',
    );
  }, []);

  const [getClinicalSummaryResponse] = useCrudWithEffects({
    id: `clinicalSummary-data ${printProps?.patientId}-${printProps?.facesheet}`,
    url: apiUrls.SIGNED_PDF_DOCTOR,
    callBack: handleClinicalSummaryPdf,
    type: 'read',
  });

  const handlePatientLastSignedEncounter = useCallback(
    (response) => {
      getClinicalSummaryResponse({
        patientId: response?.patientId,
        encounterId: response?.encounterId,
      });
    },
    [getClinicalSummaryResponse],
  );

  const [getFaceSheetResponse] = useCrudWithEffects({
    id: `face-sheet-data ${printProps?.patientId}-${printProps?.facesheet}`,
    url: printProps?.url ? printProps.url : apiUrls.GET_FACESHEET_DATA,
    callBack: handleFaceSheetPdf,
    type: 'read',
  });

  const [getPatientLastSignedEncounter] = useCrudWithEffects({
    id: `GET_PATIENT_LAST_SIGNED_ENCOUNTER_DETAILS-${printProps?.patientId}`,
    url: `${apiUrls.GET_PATIENT_LAST_SIGNED_ENCOUNTER_DETAILS}/${printProps?.patientId}`,
    callBack: handlePatientLastSignedEncounter,
    type: 'read',
  });

  const [getBatchDetailPdfResponse] = useCrudWithEffects({
    id: 'GET_BATCH_DETAIL_PDF',
    url: apiUrls.GET_BATCH_DETAIL_PDF,
    callBack: handleBatchDetailPdf,
    type: 'read',
  });

  const memoizedDateRange = useMemo(() => {
    if (printProps?.viewType === 'weeks') {
      return [
        moment(printProps?.selectedDate).day('Monday'),
        moment(printProps?.selectedDate).day('Saturday'),
      ];
    }
    if (printProps?.viewType === 'months') {
      return [
        moment(printProps?.selectedDate).startOf('month'),
        moment(printProps?.selectedDate).endOf('month'),
      ];
    }
    return [
      moment(printProps?.selectedDate).startOf('day'),
      moment(printProps?.selectedDate).endOf('day'),
    ];
  }, [printProps, printProps?.selectedDate, printProps?.viewType]);

  const toggleAppointmentListModal = useCallback(() => {
    setAppointmentListModalVisibility(!appointmentListModalVisibility);
  }, [appointmentListModalVisibility]);

  const toggleDetailStatementModal = useCallback(() => {
    setDetailStatementModalVisibility(!detailStatementModalVisibility);
  }, [detailStatementModalVisibility]);

  const getFaceSheetData = useCallback(() => {
    const patientIds = [];
    if (printProps?.schedulerData && printProps?.url) {
      Object.values(printProps.schedulerData).forEach((value) => {
        value?.appointments?.forEach((data) => {
          patientIds.push(data?.patientId);
        });
      });
    }
    getFaceSheetResponse({
      patientId: printProps?.patientId,
      responseType: responseType.BLOB,
      PatientIds: !patientIds.length ? undefined : patientIds?.join(),
    });
    Notification({ message: 'Facesheet generation in progress', success: true });
    toggleModal();
  }, [getFaceSheetResponse, printProps, toggleModal]);

  const getClinicalSummary = useCallback(() => {
    getPatientLastSignedEncounter();
  }, [getPatientLastSignedEncounter]);

  const getBatchDetails = useCallback(() => {
    getBatchDetailPdfResponse({
      responseType: responseType.BLOB,
      RemittanceBatchId: printProps?.remittanceId,
    });
  }, [getBatchDetailPdfResponse, printProps]);

  const [currentPatient] = usePatientDetail({ patientId: printProps?.patientId });

  const getSuperBillData = useCallback(() => {
    dispatch(
      superBillActions.getSuperBillTask({
        fromdate: moment(memoizedDateRange?.[0])
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .format('YYYY-MM-DD'),
        todate: moment(memoizedDateRange?.[1])
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(0)
          .format('YYYY-MM-DD'),
        ProviderIds: isEmpty(printProps?.providerIds) ? undefined : printProps?.providerIds?.join(),
        patientName:
        isEmpty(currentPatient)
          ? undefined : `${currentPatient?.firstName} ${currentPatient?.lastName}`,
        patientId: printProps?.patientId,
        includeCancelled: false,
        IncludeLatestAppointment: printProps?.includeLatestAppointmentSuperBill,
        responseType: responseType.BLOB,
        AppointmentIds: printProps?.appointmentIds,
        providerNames: printProps.providerNames,
      }),
    );
    toggleModal();
  }, [dispatch, memoizedDateRange, printProps, toggleModal, currentPatient]);

  const isSuperbillButtonDisabled = () => {
    if (isPrintButtonDisabled) {
      return true;
    }

    if (printProps?.providerIds) {
      if (timePeriod !== 'days') {
        return true;
      }
      return false;
    }

    if (printProps.patientId) {
      return false;
    }
    return false;
  };

  return (
    <>
      <Modals
        visible={visible}
        width={760}
        className="print-modal-cls"
        toggleModal={toggleModal}
        destroyOnClose
        footer={null}
      >
        <div className="addition-header print-modal-header">
          <span className="print-modal-icon" />
          <p className="bold">{labels.get('titles.print')}</p>
        </div>
        <div className="modal-content-area">
          <div className="print-content-area">
            {printProps?.facesheet ? (
              <Button
                type="button"
                className="btn btn-success large-button"
                onClick={getFaceSheetData}
              >
                {labels.get('buttons.faceSheet')}
              </Button>
            ) : null}
            {printProps?.appointment ? (
              <Button
                type="button"
                className="btn btn-success large-button"
                onClick={toggleAppointmentListModal}
              >
                {labels.get('buttons.appointment')}
              </Button>
            ) : null}
          </div>
          <div className="print-content-area">
            {printProps?.superbill ? (
              <Button
                type="button"
                className="btn btn-success large-button"
                onClick={getSuperBillData}
                disabled={isSuperbillButtonDisabled()}
              >
                {labels.get('buttons.superbill')}
              </Button>
            ) : null}
            {printProps?.detailsStatement ? (
              <Button
                type="button"
                className="btn btn-success large-button"
                onClick={toggleDetailStatementModal}
              >
                {labels.get('buttons.detailStatement')}
              </Button>
            ) : null}
          </div>
          <div className="print-content-area">
            {printProps?.clinicalSummary ? (
              <Button
                type="button"
                className="btn btn-success large-button"
                onClick={getClinicalSummary}
              >
                {labels.get('buttons.clinicalSummary')}
              </Button>
            ) : null}
            {printProps?.batchDetail ? (
              <Button
                type="button"
                className="btn btn-success large-button"
                onClick={getBatchDetails}
              >
                {labels.get('buttons.batchDetail')}
              </Button>
            ) : null}
          </div>
        </div>
        <div className="print-modal-footer">
          <div className="footer-button">
            <Button type="button" className="btn min-wt-86" onClick={toggleModal}>
              {labels.get('buttons.cancel')}
            </Button>
          </div>
        </div>
      </Modals>
      <AppointmentListModal
        labels={labels}
        visible={appointmentListModalVisibility}
        toggleParentModal={toggleModal}
        toggleModal={toggleAppointmentListModal}
        patientId={printProps?.patientId}
        locationIds={printProps?.locationIds}
        providerIds={printProps?.providerIds}
        memoizedDateRange={memoizedDateRange}
      />
      <DetailStatementModal
        labels={labels}
        visible={detailStatementModalVisibility}
        toggleParentModal={toggleModal}
        toggleModal={toggleDetailStatementModal}
        patientId={printProps?.patientId}
      />
    </>
  );
};
export default WithLabel(PrintModal, labelPaths.PRINT_MODAL);
