import React, {
  useCallback, useState, useRef,
} from 'react';
import './opendownloadbox.scss';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import {
  Drawer, Tooltip, Card, Tag, Button, Space, Empty, Badge, Typography, Divider, Radio,
} from 'antd';
import {
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  FieldTimeOutlined,
  CalendarOutlined,
  TeamOutlined,
  UserOutlined,
  FileTextOutlined,
  FilterOutlined,
  FileExcelOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import dateFormatter from '../../lib/dateFormatter';
import { dateFormat } from '../../lib/constants';
import Notification from '../../components/Notification';

const { Text, Title } = Typography;

function DownloadBox({ booleanDownloadBox, openDownloadBox }) {
  const [filter, setFilter] = useState('all'); // 'all', 'statement', 'superBill'
  const [isPrinting, setIsPrinting] = useState(false);
  const viewPanel = useRef();

  const statementTasks = useSelector((state) => state.superBill?.statement?.tasks || []);
  const superBillTasks = useSelector((state) => state.superBill?.superBill?.tasks || []);

  // Set up the print handler using useReactToPrint
  const handleHtmlPrint = useReactToPrint({
    content: () => viewPanel.current,
    copyStyles: false,
    onAfterPrint: () => {
      setIsPrinting(false);
      viewPanel.current.innerHTML = '';
    },
  });

  const handleViewDocument = useCallback((task) => {
    if (task.downloadUrl) {
      window.open(task.downloadUrl, '_blank');
    } else {
      Notification({ message: 'Document URL not available', success: false });
    }
  }, []);

  // Handle printing a document using the signed URL
  const handlePrintDocument = useCallback(async (task) => {
    if (task.downloadUrl) {
      try {
        setIsPrinting(true);

        // Fetch the HTML content from the signed URL
        const response = await fetch(task.downloadUrl);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Get the HTML content
        const htmlContent = await response.text();

        // Set the HTML content to the viewPanel
        viewPanel.current.innerHTML = htmlContent || '';

        // Trigger the print
        setTimeout(() => {
          handleHtmlPrint();
        }, 500);

        Notification({ message: 'Preparing document for print...', success: true });
      } catch (error) {
        setIsPrinting(false);
        Notification({ message: 'Error preparing document for print', success: false });
      }
    } else {
      Notification({ message: 'Document URL not available', success: false });
    }
  }, [handleHtmlPrint]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const getAllDocumentTasks = () => {
    const allTasks = [];

    if (Array.isArray(statementTasks)) {
      statementTasks.forEach((task) => {
        allTasks.push({
          ...task,
          documentType: 'statement',
        });
      });
    }

    if (Array.isArray(superBillTasks)) {
      superBillTasks.forEach((task) => {
        const isDuplicate = allTasks.some((t) => t.taskId === task.taskId);
        if (!isDuplicate) {
          allTasks.push({
            ...task,
            documentType: 'superBill',
          });
        }
      });
    }

    const filteredTasks = filter === 'all'
      ? allTasks
      : allTasks.filter((task) => task.documentType === filter);

    const inProgressTasks = filteredTasks.filter((task) => task.status === 'In Progress');
    const completedTasks = filteredTasks.filter((task) => task.status === 'Completed' || task.status === 'Downloaded');
    const errorTasks = filteredTasks.filter((task) => task.status === 'Error');

    const sortByNewest = (a, b) => b.taskId - a.taskId;

    return {
      inProgress: inProgressTasks.sort(sortByNewest),
      completed: completedTasks.sort(sortByNewest),
      error: errorTasks.sort(sortByNewest),
      all: filteredTasks.sort(sortByNewest),
    };
  };

  const statusIcon = (status) => {
    if (status === 'In Progress') {
      return <SyncOutlined spin style={{ color: '#1890ff' }} />;
    }
    if (status === 'Completed') {
      return <CheckCircleOutlined style={{ color: '#52c41a' }} />;
    }
    if (status === 'Error') {
      return <CloseCircleOutlined style={{ color: '#f5222d' }} />;
    }
    if (status === 'Downloaded') {
      return <CheckCircleOutlined style={{ color: '#52c41a' }} />;
    }
    return null;
  };

  const STATUS_TEXT = {
    'In Progress': 'Generating...',
    Completed: 'Ready',
    Error: 'Failed',
    Downloaded: 'Complete',
  };

  const getDocumentTitle = (documentType) => {
    switch (documentType) {
      case 'superBill':
        return 'Superbill';
      case 'statement':
        return 'Statement';
      default:
        return documentType.charAt(0).toUpperCase() + documentType.slice(1);
    }
  };

  const getDocumentIcon = (documentType) => {
    switch (documentType) {
      case 'superBill':
        return <FileTextOutlined />;
      case 'statement':
        return <FileTextOutlined />;
      default:
        return <FileTextOutlined />;
    }
  };

  // Render buttons for completed tasks
  const renderActionButtons = (task) => {
    const { status, downloadUrl } = task;

    if (status !== 'Completed' && status !== 'Downloaded') {
      return null;
    }

    return downloadUrl && (
      <div className="action-buttons">
        <Button
          type="text"
          size="small"
          icon={<PrinterOutlined />}
          onClick={() => handlePrintDocument(task)}
          className="print-button"
          title="Print document"
          loading={isPrinting}
          disabled={isPrinting}
        />

        <Button
          type="primary"
          size="small"
          icon={<DownloadOutlined />}
          onClick={() => handleViewDocument(task)}
          className="download-action-button"
        >
          Download
        </Button>
      </div>
    );
  };

  const {
    inProgress, completed, error, all,
  } = getAllDocumentTasks();

  const isPrintAllTask = (task) => task.documentType === 'statement'
         && task.query?.selectAllStatements === true;

  const renderStats = () => (
    <div className="download-stats">
      <div className="stat-item">
        <Badge
          count={inProgress.length}
          showZero
          overflowCount={99}
          style={{
            backgroundColor: '#1890ff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="stat-badge"
        />
        <Text>In Progress</Text>
      </div>
      <div className="stat-item">
        <Badge
          count={completed.length}
          showZero
          overflowCount={99}
          style={{
            backgroundColor: '#52c41a',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="stat-badge"
        />
        <Text>Completed</Text>
      </div>
      <div className="stat-item">
        <Badge
          count={error.length}
          showZero
          overflowCount={99}
          style={{
            backgroundColor: '#f5222d',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="stat-badge"
        />
        <Text>Failed</Text>
      </div>
    </div>
  );

  return (
    <Drawer
      placement="left"
      width={500}
      className="app-open-downloadbox"
      closable={false}
      onClose={openDownloadBox}
      visible={booleanDownloadBox}
      zIndex={100}
      style={{ position: 'fixed' }}
    >
      {isPrinting && (
        <div className="print-overlay">
          <SyncOutlined spin style={{ fontSize: 24 }} />
          <Text>Preparing print dialog...</Text>
        </div>
      )}

      <div className="download-header">
        <Title level={4} className="title">Downloads</Title>
        <div className="close-back" onClick={openDownloadBox} role="presentation" data-testid="close-icon" />
      </div>

      <div className="document-filter">
        <Space>
          <FilterOutlined />
          <Text strong>Filter:</Text>
          <Radio.Group onChange={handleFilterChange} value={filter}>
            <Radio.Button value="all">All</Radio.Button>
            <Radio.Button value="statement">Statements</Radio.Button>
            <Radio.Button value="superBill">Superbills</Radio.Button>
          </Radio.Group>
        </Space>
      </div>

      {renderStats()}

      <Divider className="section-divider" />

      <div className="tasks-container">
        {inProgress.length > 0 && (
          <div className="task-section">
            <div className="section-header">
              <FieldTimeOutlined className="section-icon" />
              <Text strong>In Progress</Text>
            </div>
            {inProgress.map((task) => (
              <Card
                key={`${task.documentType}-${task.taskId}`}
                className="task-card task-card-in-progress"
                size="small"
              >
                <div className="card-content">
                  <div className="card-header">
                    <Tag icon={getDocumentIcon(task.documentType)} color={task.documentType === 'superBill' ? 'blue' : 'green'} className="document-type-tag">
                      {getDocumentTitle(task.documentType)}
                    </Tag>
                    <div className="status-indicator">
                      <Text type="secondary">{STATUS_TEXT[task.status]}</Text>
                      <Tooltip title={task.status}>
                        {statusIcon(task.status)}
                      </Tooltip>
                    </div>
                  </div>

                  <div className="card-details">
                    {task.documentType === 'superBill' && task.query?.providerNames && (
                      <div className="detail-item">
                        <TeamOutlined className="detail-icon" />
                        <Text>
                          {`${Array.isArray(task.query.providerNames)
                            ? task.query.providerNames.join(', ')
                            : task.query.providerNames}`}
                        </Text>
                      </div>
                    )}

                    {task.documentType === 'superBill' && task.query?.patientName && (
                      <div className="detail-item">
                        <UserOutlined className="detail-icon" />
                        <Text>{task.query.patientName}</Text>
                      </div>
                    )}

                    {task.documentType === 'superBill' && task.query?.fromdate && task.query?.todate && (
                      <div className="detail-item">
                        <CalendarOutlined className="detail-icon" />
                        <Text>
                          {dateFormatter(task.query.fromdate, dateFormat)}
                          {' - '}
                          {dateFormatter(task.query.todate, dateFormat)}
                        </Text>
                      </div>
                    )}

                    {task.documentType === 'statement' && !isPrintAllTask(task) && task.query?.statementIds && (
                    <div className="detail-item">
                      <FileTextOutlined className="detail-icon" />
                      <Text>{`${task.query.statementIds.length} statements`}</Text>
                    </div>
                    )}

                    {isPrintAllTask(task) && (
                    <div className="detail-item print-all-item">
                      <FileExcelOutlined className="detail-icon" />
                      <Text strong type="success">All Statements (Bulk Download)</Text>
                    </div>
                    )}
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}

        {completed.length > 0 && (
          <div className="task-section">
            <div className="section-header">
              <CheckCircleOutlined className="section-icon" />
              <Text strong>Completed</Text>
            </div>
            {completed.map((task) => (
              <Card
                key={`${task.documentType}-${task.taskId}`}
                className={`task-card task-card-${task.status.toLowerCase().replace(' ', '-')}`}
                size="small"
              >
                <div className="card-content">
                  <div className="card-header">
                    <Tag icon={getDocumentIcon(task.documentType)} color={task.documentType === 'superBill' ? 'blue' : 'green'} className="document-type-tag">
                      {getDocumentTitle(task.documentType)}
                    </Tag>
                    <div className="status-indicator">
                      <Text type="secondary">{STATUS_TEXT[task.status]}</Text>
                      <Tooltip title={task.status}>
                        {statusIcon(task.status)}
                      </Tooltip>
                    </div>
                  </div>

                  <div className="card-details">
                    {task.documentType === 'superBill' && task.query?.providerNames && (
                      <div className="detail-item">
                        <TeamOutlined className="detail-icon" />
                        <Text>
                          {`${Array.isArray(task.query.providerNames)
                            ? task.query.providerNames.join(', ')
                            : task.query.providerNames}`}
                        </Text>
                      </div>
                    )}

                    {task.documentType === 'superBill' && task.query?.patientName && (
                      <div className="detail-item">
                        <UserOutlined className="detail-icon" />
                        <Text>{task.query.patientName}</Text>
                      </div>
                    )}

                    {task.documentType === 'superBill' && task.query?.fromdate && task.query?.todate && (
                      <div className="detail-item">
                        <CalendarOutlined className="detail-icon" />
                        <Text>
                          {dateFormatter(task.query.fromdate, dateFormat)}
                          {' - '}
                          {dateFormatter(task.query.todate, dateFormat)}
                        </Text>
                      </div>
                    )}

                    {task.documentType === 'statement' && !isPrintAllTask(task) && task.query?.statementIds && (
                      <div className="detail-item">
                        <FileTextOutlined className="detail-icon" />
                        <Text>{`${task.query.statementIds.length} statements`}</Text>
                      </div>
                    )}
                    {isPrintAllTask(task) && (
                    <div className="detail-item print-all-item">
                      <FileExcelOutlined className="detail-icon" />
                      <Text strong type="success">All Statements (Bulk Download)</Text>
                    </div>
                    )}
                  </div>

                  <div className="card-actions">
                    {renderActionButtons(task)}
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}

        {error.length > 0 && (
          <div className="task-section">
            <div className="section-header">
              <CloseCircleOutlined className="section-icon" />
              <Text strong>Failed</Text>
            </div>
            {error.map((task) => (
              <Card
                key={`${task.documentType}-${task.taskId}`}
                className="task-card task-card-error"
                size="small"
              >
                <div className="card-content">
                  <div className="card-header">
                    <Tag icon={getDocumentIcon(task.documentType)} color={task.documentType === 'superBill' ? 'blue' : 'green'} className="document-type-tag">
                      {getDocumentTitle(task.documentType)}
                    </Tag>
                    <div className="status-indicator">
                      <Text type="danger">{STATUS_TEXT[task.status]}</Text>
                      <Tooltip title={task.status}>
                        {statusIcon(task.status)}
                      </Tooltip>
                    </div>
                  </div>

                  <div className="card-details">
                    {task.documentType === 'superBill' && task.query?.providerNames && (
                      <div className="detail-item">
                        <TeamOutlined className="detail-icon" />
                        <Text>
                          {`${Array.isArray(task.query.providerNames)
                            ? task.query.providerNames.join(', ')
                            : task.query.providerNames}`}
                        </Text>
                      </div>
                    )}

                    {task.documentType === 'superBill' && task.query?.patientName && (
                      <div className="detail-item">
                        <UserOutlined className="detail-icon" />
                        <Text>{task.query.patientName}</Text>
                      </div>
                    )}

                    {task.documentType === 'superBill' && task.query?.fromdate && task.query?.todate && (
                      <div className="detail-item">
                        <CalendarOutlined className="detail-icon" />
                        <Text>
                          {dateFormatter(task.query.fromdate, dateFormat)}
                          {' - '}
                          {dateFormatter(task.query.todate, dateFormat)}
                        </Text>
                      </div>
                    )}

                    {task.documentType === 'statement' && !isPrintAllTask(task) && task.query?.statementIds && (
                      <div className="detail-item">
                        <FileTextOutlined className="detail-icon" />
                        <Text>{`${task.query.statementIds.length} statements`}</Text>
                      </div>
                    )}
                    {isPrintAllTask(task) && (
                    <div className="detail-item print-all-item">
                      <FileExcelOutlined className="detail-icon" />
                      <Text strong type="success">All Statements (Bulk Download)</Text>
                    </div>
                    )}
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}

        {all.length === 0 && (
          <Empty
            description="No download tasks"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="empty-state"
          />
        )}
      </div>

      {/* Hidden div for print content */}
      <div className="display-none" ref={viewPanel} />
    </Drawer>
  );
}

export default DownloadBox;
