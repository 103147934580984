import React, {
  useCallback, useState, useEffect, useRef, useMemo,
} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useReactToPrint } from 'react-to-print';
import { Menu } from 'antd';

import useCRUD from '../../../../../hooks/useCRUD';

import Events from '../../../../../lib/events';
import { listId, listIds } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import { apiUrls } from '../../../../../api/constants';

import withQuery from '../../../../../hoc/withQuery/withQuery';

import Icon from '../../../../../components/Icon';
import Notification from '../../../../../components/Notification';
import WidgetLoader from '../../../../../components/WidgetLoader';
import ConfirmDialog from '../../../../../components/ConfirmDialog';

import FilterForm from '../Components/FilterForm';
import columns from './columns';
import useReduxState from '../../../../../hooks/useReduxState';
import { fileNameFromUrl, openUrlInNewTab, urltoFile } from '../../../../../lib/util';
import downloadFile from '../../../../../lib/downloadFile';
import WarningMessages from '../../../../../lib/warningMessages';
import iconNames from '../../../../../lib/iconNames';

const TableRefetchEvent = ({ reFetch, data, setTableData }) => {
  useEffect(() => {
    setTableData(data.sort((a, b) => {
      const dateA = moment.utc(a.effectiveDate).format('MM-DD-YYYY');
      const dateB = moment.utc(b.effectiveDate).format('MM-DD-YYYY');
      return moment(dateA, 'MM-DD-YYYY').diff(moment(dateB, 'MM-DD-YYYY'));
    }));
  }, [data, setTableData, reFetch]);
  useEffect(() => {
    Events.on('refetch-charge-table', 'refetch-charge-table', reFetch);
    return () => Events.remove('refetch-charge-table');
  }, []);
  return null;
};

const ChargeTable = ({
  labels, params, generatePath, setType, ...otherProps
}) => {
  const { id, tabId } = params;
  const [selectedRow, setSelectedRow] = useState();
  const [tableData, setTableData] = useState();
  const [batchDetails, setBatchDetails] = useState({ type: '', pageNumber: 1, actionType: null });
  const [filter, setFilter] = useReduxState(`patient-financial-charge-filter-${id}`, {
    createdDateFrom: moment().subtract(3, 'month').format('MM-DD-YYYY').toString(),
    createdDateTo: moment().format('MM-DD-YYYY').toString(),
  });

  const viewPanel = useRef();

  const [receiptHtml, , receiptLoading, getReceipt, clearReceiptResponse] = useCRUD({
    id: listIds.FINANCIAL_PATIENT_PAYMENT_RECEIPT, url: apiUrls.GET_PATIENT_PAYMENT_RECEIPT_HTML, type: 'read',
  });

  const [addToFaxQueueResponse, , addToFaxLoading, addToFax, clearFaxResponse] = useCRUD({
    id: listId.ADD_FAX_QUEUE_SHOPPING_CART,
    url: apiUrls.ADD_FAX_QUEUE_SHOPPING_CART,
    type: 'create',
  });

  const CustomTable = useMemo(() => withQuery({
    url: apiUrls.GET_FINANCIAL_LIST,
    listId: `${listIds.FINANCIAL_CHARGE_LIST}-${id}`,
  })(), [id]);

  const [
    ediToHtmlFile,,
    ediToHtmlFileLoading,
    getEdiToHtmlFile,
    clearEdiToHtmlFileRes,
  ] = useCRUD({
    id: listIds.REMITTANCE_BATCH_EDIFILETOHTML,
    url: apiUrls.REMITTANCE_BATCH_EDIFILETOHTML,
    type: 'read',
  });

  useEffect(() => {
    if (addToFaxQueueResponse?.isDuplicateExists) {
      ConfirmDialog({
        onOk: (close) => {
          addToFax({
            data: {
              FaxDocumentIds: [selectedRow],
              faxSource: 'billing',
              patientId: id,
              includeDuplicate: true,
            },
          });
          close();
        },
        onCancel: (close) => {
          addToFax({
            data: {
              FaxDocumentIds: [selectedRow],
              faxSource: 'billing',
              patientId: id,
              includeDuplicate: false,
            },
          });
          close();
        },
        okText: labels.get('buttons.ok'),
        title: labels.get('titles.warning'),
        content: labels.get('message.warningMessage'),
        icon: <Icon name="ExclamationCircleOutlined" />,
        cancelButtonProps: { className: 'ant-modal-continue-green-btn' },
        okButtonProps: { className: 'ant-modal-ok-white-btn' },
      })();
    } else if (addToFaxQueueResponse?.recordInserted > 0) {
      Notification({ message: SuccessMessages.DOCUMENT_ADDED_TO_FAX_SUCCESSFULLY, success: true });
      Events.trigger('get-patient-fax-document-count');
      clearFaxResponse(true);
    }
  }, [addToFaxQueueResponse]);

  const handleHtmlPrint = useReactToPrint({
    content: () => viewPanel.current,
    copyStyles: false,
  });

  const onShowReversal = useCallback(() => {
    setFilter({ showReversal: !filter?.showReversal });
  }, [filter, setFilter]);

  const handlePrintReceipt = useCallback((financeId) => () => {
    getReceipt({ PatientId: id, FinanceId: financeId });
  }, [getReceipt, id]);

  useEffect(() => {
    if (receiptHtml) {
      viewPanel.current.innerHTML = receiptHtml || '';
      handleHtmlPrint();
      clearReceiptResponse(true);
    }
  }, [receiptHtml]);

  const handleAddToFaxQueue = useCallback((financeId) => () => {
    setSelectedRow(financeId);
    addToFax({
      data: {
        FaxDocumentIds: [financeId],
        faxSource: 'billing',
        patientId: params?.id,
      },
    });
  }, [addToFax, params]);

  const handleEdiToHtml = useCallback((original) => () => {
    getEdiToHtmlFile({ batchId: original?.remittanceBatchId });
  }, [getEdiToHtmlFile]);

  const [
    unBatchQueueActionResponse,
    unBatchQueueActionError,
    unBatchQueueActionResLoading,
    unBatchQueueAction,
    clearunBatchQueueActionRes,
  ] = useCRUD({
    id: listIds.REMITTANCE_UNBATCH_QUEUE_ACTIONS,
    url: apiUrls.REMITTANCE_UNBATCH_QUEUE_ACTIONS,
    type: 'create',
    shouldClearError: false,
  });

  const tableRef = useRef();
  const [unBatchQueueActiveAction, setUnBatchQueueActiveAction] = useState();
  const handleEDIRightClickActions = useCallback((original, actionType, viewType) => () => {
    setUnBatchQueueActiveAction(actionType);
    setBatchDetails({ type: original?.paymentMethod, pageNumber: original?.financeDetails?.pageNo, actionType: viewType === 'view' ? 'view' : 'download' });
    unBatchQueueAction({
      data: {
        actionType: 'Download',
        remittanceBatchIds: original?.remittanceBatchId ? [original?.remittanceBatchId] : [],
      },
    });
  }, [unBatchQueueAction]);

  const rightClickMenu = useCallback(({ original }) => () => {
    if ((original?.source !== 'InsurancePayment' || original?.paymentMethod !== 'Electronic')
    && (original?.source !== 'InsurancePayment' || original?.paymentMethod !== 'Manual')) {
      return false;
    }
    if (original?.source === 'InsurancePayment' && original?.paymentMethod === 'Electronic') {
      return (
        <Menu className="profile-menu-container">
          <Menu.Item key="restore remittance" onClick={handleEDIRightClickActions(original, 'Download', 'download')}>
            <span>Download EDI</span>
          </Menu.Item>
          <Menu.Item key="restore remittance" onClick={handleEdiToHtml(original)}>
            <span>View Preview</span>
          </Menu.Item>
        </Menu>
      );
    }

    if (original?.source === 'InsurancePayment' && original?.paymentMethod === 'Manual') {
      return (
        <Menu className="profile-menu-container">
          <Menu.Item key="restore remittance" onClick={handleEDIRightClickActions(original, 'Download')}>
            <span>Download EOB</span>
          </Menu.Item>
          <Menu.Item key="restore remittance" onClick={handleEDIRightClickActions(original, 'Download', 'view')}>
            <span>View EOB Preview</span>
          </Menu.Item>
        </Menu>
      );
    }

    return <Menu />;
  }, [handleEDIRightClickActions, handleEdiToHtml]);

  const downloadEDIFile = useCallback(async (fileUrls = []) => {
    if (fileUrls.length === 0) {
      return Notification({ message: 'No EOB/EDI files present', success: false });
    }
    for (let i = 0; i < fileUrls.length; i += 1) {
      if (fileUrls[i] === null) {
        Notification({ message: 'No EOB/EDI files present', success: false });
      } else if (batchDetails?.type === 'Electronic') {
        const fileName = fileNameFromUrl(fileUrls[i]);
        // eslint-disable-next-line no-await-in-loop
        const file = await urltoFile(fileUrls[i], fileName);
        downloadFile(file, fileName);
        Notification({ message: 'Files has been downloaded successfully', success: true });
      } else if (batchDetails?.actionType === 'view') {
        openUrlInNewTab(fileUrls[i], batchDetails?.pageNumber);
      } else {
        openUrlInNewTab(fileUrls[i]);
      }
    }
    return null;
  }, [batchDetails]);

  useEffect(() => {
    if (unBatchQueueActionError) {
      if (unBatchQueueActionError?.includes('BatchIds with redundant check Number and Amount')) {
        ConfirmDialog({
          onOk: (close) => {
            unBatchQueueAction({
              data: {
                actionType: 'BatchRemittance',
                remittanceBatchIds: selectedRow?.map((item) => item?.original?.remittanceBatchId),
                SkipCheckDuplication: true,
              },
            });
            close();
          },
          onCancel: () => {
            clearunBatchQueueActionRes();
          },
          okText: 'Ok',
          title: 'Warning',
          content: WarningMessages.PAYMENT_WITH_SAME_CHECK_ALREADY_POSTED,
          icon: <Icon name={iconNames.exclamationCircleOutlined} />,
        })();
      } else {
        Notification({ message: unBatchQueueActionError });
        clearunBatchQueueActionRes();
      }
    }
  }, [clearunBatchQueueActionRes, labels, selectedRow,
    unBatchQueueAction, unBatchQueueActionError]);

  useEffect(() => {
    if (ediToHtmlFile && !Array.isArray(ediToHtmlFile)) {
      const w = window.open('');
      w?.document?.write(ediToHtmlFile);
      w?.document?.close();
      clearEdiToHtmlFileRes(true);
    }
  }, [clearEdiToHtmlFileRes, ediToHtmlFile]);

  useEffect(() => {
    if (unBatchQueueActionResponse) {
      if (!Array.isArray(unBatchQueueActionResponse) && unBatchQueueActiveAction !== 'Download') {
        Notification({ message: unBatchQueueActionResponse?.message, success: true });
      } else if (Array.isArray(unBatchQueueActionResponse) && unBatchQueueActiveAction === 'Download') {
        downloadEDIFile(unBatchQueueActionResponse);
      }
      tableRef?.current?.resetCheckBoxes?.(false);
      setUnBatchQueueActiveAction();
      clearunBatchQueueActionRes();
      Events.trigger('refetchRemittanceTable');
    }
  }, [
    clearunBatchQueueActionRes,
    unBatchQueueActionResponse,
    unBatchQueueActiveAction,
  ]);

  return (
    <>
      <div ref={viewPanel} className="display-none" />
      {(receiptLoading
        || addToFaxLoading
        || unBatchQueueActionResLoading
        || ediToHtmlFileLoading
      ) && <WidgetLoader />}
      <CustomTable
        filters={{ PatientId: id, showReversal: false, ...filter }}
        columns={columns(params, generatePath, labels, setType)}
        noDataText="Data not found"
        scrollId="charge-table-financial"
        handleAddToFaxQueue={handleAddToFaxQueue}
        handlePrintReceipt={handlePrintReceipt}
        customData={tableData}
        rightClickMenu={rightClickMenu}
        rowSelectionRef={tableRef}
        {...otherProps}
      >
        {({
          Component, reFetch, totalCount, data,
        }) => (
          <>
            <div className="filter-area flex align-center mr-top-12 mr-manage tab-filter-align mr-bt-8">
              <FilterForm
                labels={labels}
                filter={filter}
                setFilter={setFilter}
                patientId={id}
                tabId={tabId}
              />
              <div className="flex">
                <span
                  id="patients_financial_reversal"
                  data-testid="patients_financial_reversal"
                  className={classNames('cursor-pointer', 'mr-lt-4')}
                  onClick={onShowReversal}
                  style={{ paddingTop: '4px' }}
                  role="presentation"
                >
                  {filter?.showReversal ? labels.get('buttons.hideReversal') : labels.get('buttons.showReversal')}
                </span>
                <span className="table-count mr-lt-8" style={{ paddingTop: '4px' }}>
                  <span>{`${labels.get('buttons.totalCount')}: ${totalCount || 0}`}</span>
                </span>
              </div>
            </div>
            <TableRefetchEvent reFetch={reFetch} data={data} setTableData={setTableData} />
            {Component}
          </>
        )}
      </CustomTable>
    </>
  );
};
export default React.memo(ChargeTable);
