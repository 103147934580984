import {
  put, takeEvery, call, delay, fork, take, race, cancel, cancelled,
} from 'redux-saga/effects';
import { apiUrls } from '../../api/constants';
import api from '../../api/index';
import Notification from '../../components/Notification';
import { DOCUMENT_ACTIONS, documentActions } from '../actions/superBillActions';

// Configuration for different document types
const documentConfig = {
  superBill: {
    initiateUrl: apiUrls.GET_SUPERBILL_DATA,
    statusUrl: apiUrls.CHECK_SUPERBILL_STATUS,
    successMessage: 'Superbills are printing in the background. You can continue with your tasks and monitor the progress in the download box located in the left menu.',
    noDataMessage: 'No encounters are available for this timeframe or provider, Cannot generate Superbill.',
    errorMessage: 'Something went wrong',
    downloadSuccessMessage: 'Superbill downloaded successfully',
    downloadErrorMessage: 'Superbill was not downloaded',
    filenamePattern: (query) => {
      if (!query?.fromdate || !query?.todate) {
        return `SuperBill-${new Date().toISOString().slice(0, 10)}.pdf`;
      }

      try {
        let fromDate = query.fromdate.split('-');
        let toDate = query.todate.split('-');
        fromDate = `${fromDate[2]}${fromDate[1]}${fromDate[0]}`;
        toDate = `${toDate[2]}${toDate[1]}${toDate[0]}`;
        return `SuperBill-(${fromDate}-${toDate}).pdf`;
      } catch (e) {
        return `SuperBill-${new Date().toISOString().slice(0, 10)}.pdf`;
      }
    },
  },
  statement: {
    initiateUrl: apiUrls.GENERATE_STATEMENTS_HTML,
    statusUrl: apiUrls.CHECK_STATEMENT_STATUS,
    successMessage: 'Statements are generating in the background. You can continue with your tasks and monitor the progress in the download box located in the left menu.',
    noDataMessage: 'No statements are available for the selected criteria.',
    errorMessage: 'Something went wrong generating statements',
    downloadSuccessMessage: 'Statement downloaded successfully',
    downloadErrorMessage: 'Statement was not downloaded',
    filenamePattern: () => `Statement-${new Date().toISOString().slice(0, 10)}.pdf`,
    isPreview: true, // Statement-specific flag
  },
  // Add other document types as needed
};

function* pollDocumentStatus(taskId, documentType) {
  // If no taskId or documentType is provided, end the saga
  if (!taskId || !documentType) {
    return;
  }

  const config = documentConfig[documentType];
  if (!config) {
    return;
  }

  let delayDuration = 5000; // Start with shorter delay for better UX

  try {
    yield put(documentActions.setDocumentPolling(taskId, true, documentType));

    while (true) {
      try {
        // Fix potential URL issues - ensure statusUrl is properly formed
        const statusUrl = config.statusUrl.includes('http') || config.statusUrl.startsWith('/')
          ? config.statusUrl
          : `${process.env.REACT_APP_API_PATH}/${config.statusUrl}`;

        const response = yield call(api.get, {
          version: 2,
          token: localStorage.getDecryptedData('token'),
          url: `${statusUrl}/${taskId}`,
          params: { responseType: 'json' },
        });

        if (response.status === 200) {
          yield put(documentActions.setDocumentStatus('Completed', taskId, documentType));

          // Make sure we have a signedUrl
          const downloadUrl = response.data?.signedUrl;
          if (downloadUrl) {
            yield put(documentActions.setDocumentDownloadUrl(downloadUrl, taskId, documentType));
          } else {
            yield put(documentActions.setDocumentStatus('Error', taskId, documentType));
          }
          break;
        } else if (response.status === 202) {
          yield put(documentActions.setDocumentStatus('In Progress', taskId, documentType));
          yield delay(delayDuration);
          // Gradually increase polling interval with a cap
          delayDuration = Math.min(delayDuration * 1.5, 30000);
        } else {
          yield put(documentActions.setDocumentStatus('Error', taskId, documentType));
          break;
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          // Auth error, retry quickly
          yield delay(5000);
        } else {
          yield put(documentActions.setDocumentStatus('Error', taskId, documentType));
          break;
        }
      }
    }
  } finally {
    if (yield cancelled()) {
      yield put(documentActions.setDocumentPolling(taskId, false, documentType));
    }
  }
}

function* watchPollDocumentStatus(action) {
  const { taskId, documentType } = action.payload;

  if (!taskId || !documentType) {
    return;
  }

  // Fork the polling task so we can cancel it if needed
  const pollingTask = yield fork(pollDocumentStatus, taskId, documentType);

  // Wait for either completion or cancellation
  const { cancelAction } = yield race({
    completion: take((a) => (
      a.type === DOCUMENT_ACTIONS.SET_DOCUMENT_STATUS
        && a.payload?.taskId === taskId
        && a.payload?.documentType === documentType
        && ['Completed', 'Error'].includes(action.payload?.status)
    )),
    cancelAction: take((a) => a.type === DOCUMENT_ACTIONS.CANCEL_DOCUMENT_POLLING
        && a.payload?.taskId === taskId
        && a.payload?.documentType === documentType),
  });

  if (cancelAction) {
    yield cancel(pollingTask);
  }
}

function* fetchDocumentTask(action) {
  const { payload, documentType } = action;

  if (!documentType) {
    return;
  }

  const config = documentConfig[documentType];
  if (!config) {
    return;
  }

  // Create a copy of the payload to avoid modifying the original
  const actionPayload = { ...payload };

  // Special handling for different document types
  if (documentType === 'superBill') {
    const { providerNames, patientName } = actionPayload;
    delete actionPayload.providerNames;
    delete actionPayload.patientName;

    try {
      yield put(documentActions.disablePrintButton(documentType));
      const response = yield call(api.get, {
        version: 2,
        token: localStorage.getDecryptedData('token'),
        url: config.initiateUrl,
        params: actionPayload,
      });
      yield put(documentActions.enablePrintButton(documentType));

      if (response.status === 200) {
        Notification({
          message: config.successMessage,
          success: true,
        });

        // Add the provider names to the query before saving to Redux
        const queryWithExtras = {
          ...actionPayload,
          providerNames,
          patientName,
        };

        const taskId = response.data?.taskId;
        if (!taskId) {
          Notification({ message: 'Error starting document generation', success: false });
          return;
        }

        yield put(
          documentActions.addDocumentTask({
            taskId,
            status: response.data.message || 'In Progress',
            query: queryWithExtras,
            documentType,
          }),
        );

        // Start polling
        yield put(documentActions.pollDocumentStatus(taskId, documentType));
      } else if (response.status === 204) {
        Notification({
          message: config.noDataMessage,
          success: false,
        });
      } else {
        Notification({ message: config.errorMessage, success: false });
      }
    } catch (error) {
      Notification({ message: config.errorMessage, success: false });
      yield put(documentActions.enablePrintButton(documentType));
    }
  } else if (documentType === 'statement') {
    // Statement-specific logic
    try {
      yield put(documentActions.disablePrintButton(documentType));

      // Set isPreview from config if not provided in payload
      if (actionPayload.isPreview === undefined && config.isPreview !== undefined) {
        actionPayload.isPreview = config.isPreview;
      }

      const response = yield call(api.post, {
        version: 2,
        token: localStorage.getDecryptedData('token'),
        url: config.initiateUrl,
        data: actionPayload,
      });

      yield put(documentActions.enablePrintButton(documentType));

      if (response.status === 200 || response.status === 202) {
        Notification({
          message: config.successMessage,
          success: true,
        });

        const taskId = response.data?.taskId;
        if (!taskId) {
          Notification({ message: 'Error starting document generation', success: false });
          return;
        }

        yield put(
          documentActions.addDocumentTask({
            taskId,
            status: response.data.message || 'In Progress',
            query: actionPayload,
            documentType,
          }),
        );

        // Start polling
        yield put(documentActions.pollDocumentStatus(taskId, documentType));
      } else if (response.status === 204) {
        Notification({
          message: config.noDataMessage,
          success: false,
        });
      } else {
        Notification({ message: config.errorMessage, success: false });
      }
    } catch (error) {
      Notification({ message: config.errorMessage, success: false });
      yield put(documentActions.enablePrintButton(documentType));
    }
  }
  // Add other document type handling as needed
}

function* documentGenerationSaga() {
  yield takeEvery(DOCUMENT_ACTIONS.GET_DOCUMENT_TASK, fetchDocumentTask);
  yield takeEvery(DOCUMENT_ACTIONS.POLL_DOCUMENT_STATUS, watchPollDocumentStatus);
}

export default documentGenerationSaga;
