import React, { useState } from 'react';

import { enums } from '../../../../../../../lib/constants';

import Input from '../../../../../../../components/Form/Input';
import DatePicker from '../../../../../../../components/Form/DatePicker';
import Form from '../../../../../../../components/Form';

import EnumSelect from '../../../../../../../wiredComponents/Enum/Select';
import ProviderAutoComplete from '../../../../../../../wiredComponents/ProviderGridAutoComplete';
import LocationAutoComplete from '../../../../../../../wiredComponents/LocationGridAutoComplete';
import ErrorMessages from '../../../../../../../lib/errorMessages';

const CreditCardForm = ({ labels }) => (
  <Form.Section noOfColumns="2">
    <Form.Column>
      <EnumSelect
        label={labels.get('labels.cardType')}
        name="cardTypeId"
        enumName={enums.PATIENT_PAYMENT_CARD_TYPE}
      />
      <Input
        label={labels.get('labels.cardName')}
        name="nameOnCard"
        required
        maxValueLength={25}
      />
    </Form.Column>
    <Form.Column>
      <Input
        label={labels.get('labels.cardNumber')}
        name="cardNumber"
        maxValueLength={4}
        minValueLength={4}
        numberOnly
        required
      />
      <DatePicker
        label={labels.get('labels.expirationDate')}
        name="expirationDate"
        picker="month"
        dateFormat="MM/YYYY"
      />
    </Form.Column>
  </Form.Section>
);

const CheckForm = ({ labels, isCheckDateRequired = false }) => (
  <Form.Section noOfColumns="2">
    <Form.Column>
      <DatePicker
        label={labels.get('labels.checkDate')}
        name="checkDate"
        required={isCheckDateRequired}
      />
      <Input
        label={labels.get('labels.checkNumber')}
        name="checkNumber"
        required
      />
    </Form.Column>
    <Form.Column>
      <Input
        label={labels.get('labels.accountNumber')}
        name="accountNumber"
      />
      <Input
        label={labels.get('labels.checkAmount')}
        name="checkAmount"
        numberOnly
        decimalPlaces={3}
      />
    </Form.Column>
  </Form.Section>
);

const GiftCardForm = ({ labels }) => (
  <Form.Section noOfColumns="2">
    <Form.Column>
      <Input
        label={labels.get('labels.certificateName')}
        name="nameOnCertificate"
      />
    </Form.Column>
    <Form.Column>
      <Input
        label={labels.get('labels.certificateNumber')}
        name="certificateNumber"
      />
    </Form.Column>
  </Form.Section>
);

const ECheckForm = ({ labels }) => (
  <Form.Section noOfColumns="2">
    <Form.Column>
      <Input
        label={labels.get('labels.cardNumber')}
        name="cardNumber"
        maxValueLength={4}
        minValueLength={4}
        numberOnly
        required
      />
    </Form.Column>
    <Form.Column>
      <Input
        label={labels.get('labels.cardName')}
        name="nameOnCard"
        required
      />
    </Form.Column>
  </Form.Section>
);

const DebitCardForm = ({ labels }) => (
  <Form.Section noOfColumns="2">
    <Form.Column>
      <Input
        label={labels.get('labels.cardNumber')}
        name="cardNumber"
        maxValueLength={4}
        minValueLength={4}
        numberOnly
        required
      />
      <DatePicker
        label={labels.get('labels.expirationDate')}
        name="expirationDate"
        picker="month"
        dateFormat="MM/YYYY"
      />
    </Form.Column>
    <Form.Column>
      <Input
        label={labels.get('labels.cardName')}
        name="nameOnCard"
        required
        maxValueLength={25}
      />
    </Form.Column>
  </Form.Section>
);

const detailsFormComponent = (type, props) => {
  const forms = {
    947: CreditCardForm,
    949: CheckForm,
    954: GiftCardForm,
    955: ECheckForm, // Care Credit
    956: ECheckForm, // E-Check
    958: ECheckForm, // Money Order
    959: DebitCardForm,
  };

  if (forms[type]) {
    const DetailsForm = forms[type];
    return <DetailsForm {...props} />;
  }
  return null;
};

const PatientRefundForm = (props) => {
  const { labels } = props;
  const [transactionType, setTransactionType] = useState(0);
  return (
    <>
      <Form.Section noOfColumns="2">
        <Form.Column>
          <DatePicker
            label={labels.get('labels.effectiveDate')}
            name="effectiveDate"
            required
            id="19"
          />
          <Input
            label={labels.get('labels.paymentAmount')}
            name="patientBalance"
            id="1"
            disabled
          />
          <Input
            label={labels.get('labels.refundableBalance')}
            name="refundableBalance"
            id="19"
            disabled
          />
          <Input
            label={labels.get('labels.refundAmount')}
            name="amount"
            id="1"
            required
            decimalPlaces={2}
            rules={[({ getFieldValue }) => ({
              validator(rule, value) {
                if (parseFloat(getFieldValue('refundableBalance')) < parseFloat(value)) { return Promise.reject(ErrorMessages.REFUND_AMOUNT_NOT_GREATER_THAN_REFUNDABLE_BALANCE); }
                return Promise.resolve();
              },
            })]}
          />
        </Form.Column>
        <Form.Column>
          <EnumSelect
            label={labels.get('labels.transactionType')}
            name="paymentTypeId"
            enumName={enums.PATIENT_PAYMENT_TYPE}
            excludeOptionNames={['PPA']}
            onChange={setTransactionType}
            required
          />
          <ProviderAutoComplete
            id="provider"
            name="providerId"
            label={labels.get('labels.provider')}
            placeholder="Select Provider"
            selectProps={{ showSearch: true }}
            labelSpan={10}
            inputSpan={14}
          />
          <LocationAutoComplete
            id="location"
            name="locationId"
            label={labels.get('labels.location')}
            placeholder="Select Location"
            selectProps={{ showSearch: true }}
            // onSelect={onLocationSelect}
            labelSpan={10}
            inputSpan={14}
          />
          <Input
            label={labels.get('labels.comments')}
            name="comments"
            required
          />
        </Form.Column>
      </Form.Section>
      {detailsFormComponent(transactionType || 0, { labels, isCheckDateRequired: true })}
    </>
  );
};
export default PatientRefundForm;
