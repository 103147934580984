/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Form as AntdForm,
} from 'antd';

import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import clone from 'lodash/clone';
import intersectionBy from 'lodash/intersectionBy';
import unionBy from 'lodash/unionBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCrudDataWithOrWithoutTabId } from '../../store/selectors';

import CheckBox from '../../components/SmCheckbox';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Notification from '../../components/Notification';

import {
  UiRoutes, labelPaths, formId, listIds,
} from '../../lib/constants';
import { apiUrls } from '../../api/constants';
import SuccessMessages from '../../lib/successMessages';
import formFieldValueParser from '../../lib/formFieldValuesParser';

import EncounterTable from './Table';

import WithLabel from '../../hoc/withLabel';

import usePatientDetail from '../../hooks/usePatientDetail';
import useRedirect from '../../hooks/useRedirect';
import useTabLink from '../../hooks/useTabLink';
import useRights from '../../hooks/useRights';
import useCRUD from '../../hooks/useCRUD';
import useReduxStoreWithId from '../../hooks/useReduxStoreWithId';
import useReduxState from '../../hooks/useReduxState';

import NewEncounterForm from './Components/NewEncounterForm';
import EncounterTabs from './Tabs';

import Loader from '../../components/Loader';

import './newencounter.scss';
import rights from '../../lib/rights';
import ErrorMessages from '../../lib/errorMessages';
import {
  checkDisabledLineItem, getDateWithZeroTimeFormat, getDifferenceInMinute, getName,
} from '../../lib/util';
import ConfirmDialog from '../../components/ConfirmDialog';
import Icon from '../../components/Icon';
import RadioGroup from '../../components/RadioGroup';
import RulesResult from './Components/RulesEngine/RulesResult';
import { store } from '../../store';
import PostChargesDropdownBtn from './Components/RulesEngine/PostChargesDropdownBtn';
import RulesEngineEncounter from './Components/RulesEngine/RulesEngineEncounter';
import EncounterEvaluationStatus from './Components/RulesEngine/EncounterEvaluationStatus';

const modifierMapper = (modifiers) => modifiers.map((item) => {
  const clonedItem = item;
  clonedItem.description = item.modifierDescription || item.description;
  clonedItem.name = item.modifierCode;
  clonedItem.value = item.modifierId;
  return clonedItem;
});

const diagnosesCodeMapper = (diagnosesCodes) => diagnosesCodes.map((item) => {
  const clonedItem = item;
  clonedItem.description = item.icdCodeDescription || item.description;
  clonedItem.id = item.icD10CodeId || item.id;
  clonedItem.name = item.icdCode;
  clonedItem.value = item.icD10CodeId || item.id;
  return clonedItem;
});
const cptCodeMapper = (cptCode, cptCodeId, description) => ({
  cptCodeId,
  cptCode,
  description,
  id: cptCodeId,
  name: cptCode,
  value: cptCodeId,
  title: description,
});

const noOfColumns = 2;

const EncounterCheckBoxActions = ({ params, isCollectionCharges, holdAllHandler }) => {
  const renderCheckBox = useMemo(() => {
    let checkBoxDiv = <span />;
    if (params?.financialId) {
      checkBoxDiv = (
        <div className="checkbox check-box-min-width-160">
          <CheckBox label="" valuePropName="checked" name="sendAsCorrected" labelSpan="21" inputSpan="3">
            Send as corrected claim
          </CheckBox>
        </div>
      );
    } else if (!isCollectionCharges) {
      checkBoxDiv = (
        <div className="checkbox">
          <CheckBox label="" valuePropName="checked" onChange={holdAllHandler} name="isStatementHold" labelSpan="21" inputSpan="3">
            Hold all line items
          </CheckBox>
        </div>
      );
    }
    return checkBoxDiv;
  }, [holdAllHandler, isCollectionCharges, params]);

  return (
    { ...renderCheckBox }
  );
};

const getUpdatedClonedLineItem = (clonedLineItem, lineItem, filteredLineItems) => {
  clonedLineItem.modifiers = compact(
    lineItem?.modifiers?.map((item) => item.modifierId),
  ).toString();
  clonedLineItem.diagnosesCodes = compact(
    lineItem?.diagnosesCodes?.map((item) => item.id),
  ).toString();
  clonedLineItem.cptCode = clonedLineItem.cptCodeVal.cptCode;
  delete clonedLineItem.CPTCode;
  delete clonedLineItem.billingEncounterDiagnosisCodes;
  delete clonedLineItem.billingEncounterModifiers;
  delete clonedLineItem.cptCodeVal;
  delete clonedLineItem.disabled;
  delete clonedLineItem.diagnosisText;
  if (clonedLineItem.insuranceProfileId === 0 || clonedLineItem.insuranceProfileId === 1 || (clonedLineItem.insuranceProfileId === '0' || clonedLineItem.insuranceProfileId === '1')) {
    clonedLineItem.insuranceProfileId = clonedLineItem.insuranceProfileDetailId;
    clonedLineItem.insuranceProfileDetailId = '';
  }
  if (
    clonedLineItem.billingEncounterServiceLineId
  && typeof clonedLineItem.billingEncounterServiceLineId === 'string'
  && clonedLineItem.billingEncounterServiceLineId.indexOf('new') > -1) {
    delete clonedLineItem.billingEncounterServiceLineId;
  }
  if (clonedLineItem.billingEncounterServiceLineId !== 0) {
    clonedLineItem.serviceDate = getDateWithZeroTimeFormat(clonedLineItem.serviceDate);
    filteredLineItems.push(clonedLineItem);
  }
};

const NewEncounter = ({
  labels,
  goBackPath,
  getUrl,
  addUrl,
  updateUrl,
  patientIdProps,
  encounterIdProp,
  showCancel,
  closeTab,
  isCollectionCharges,
}) => {
  const [isPostedRedux,, setPosted] = useReduxStoreWithId({ id: 'isPosted-Encounter', defaultValue: true });
  const isPosted = isPostedRedux?.get('data') === undefined ? true : isPostedRedux?.get('data');
  const [isUpdateRedux,, setUpdate] = useReduxStoreWithId({ id: 'isUpdate-Encounter' });
  const isUpdate = isUpdateRedux?.get('data');
  const [isUpdateInsuranceDataRedux,, setIsUpdateInsuranceData] = useReduxStoreWithId({ id: 'isUpdateInsuranceData-Encounter' });
  const isUpdateInsuranceData = isUpdateInsuranceDataRedux?.get('data');
  const [authorizationOptionsRedux,, setAuthorizationOptions] = useReduxStoreWithId({ id: 'authorizationOptions-Encounter' });
  const authorizationOptions = authorizationOptionsRedux?.get('data');
  const [supressWarningRefRedux,, setSuppressWarning] = useReduxStoreWithId({ id: 'supressWarning-Encounter' });
  const supressWarningRef = supressWarningRefRedux?.get('data');
  const submitRef = useRef(false);
  const isSaveEncounterFromCollection = useRef(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [rulesEngineEvaluationStatus, setRulesEngineEvaluationStatus] = useState();
  const [selectedRule, setSelectedRule] = useState([]);
  const [showDropDownOfOverrideRulesEngine, setShowDropDownOfOverrideRulesEngine] = useState(false);

  const {
    push, generatePath, params: { tabId, claimFilter }, params, path,
  } = useRedirect();
  const [isEnterBillingChargesPermitted,
    isCreateClaimsPermitted, isCreateStatementPermitted, isRulesEngineOverRidePermitted] = useRights([
    rights.enter_charges,
    rights.create_claims,
    rights.create_statements,
    rights.access_to_override_rules_engine,
  ]);
  const [diagnosesCodesData,, setDiagnosesCodes, clearDiagnosesCodes] = useReduxStoreWithId({ id: 'DIAGNOSIS_CODES_ENCOUNTER_HISTORY' });
  const diagnosesCodes = diagnosesCodesData?.get('data');
  const [isDiagnosisCodesUpdated, setIsDiagnosisCodesUpdated] = useReduxState(`BILLING_ENCOUNTER_DIAGNOSIS_CODES_${tabId}`);
  const [anesthesiaData,, setAnesthesia, clearAnesthesia] = useReduxStoreWithId({ id: `BILLING_ENCOUNTER_ANESTHESIA-${tabId}` });
  const anesthesia = anesthesiaData?.get('data');
  const [savedEncounterData,, setSavedEncounterData, clear] = useReduxStoreWithId({ id: `BILLING_ENCOUNTER_LINE_ITEM_DATA-${tabId}` });
  const savedEncounter = savedEncounterData?.get('data');
  const icdCodesData = useSelector((state) => getCrudDataWithOrWithoutTabId(state, 'ICD-Codes-Data', tabId));
  const [form] = AntdForm.useForm();
  const [activeComponent, setActiveComponent] = useState('Encounter');
  const [showRcmHighlight, setShowRcmHighlight] = useState(false);
  const patientId = useMemo(() => patientIdProps || params?.id, [params, patientIdProps]);
  const encounterId = useMemo(() => (encounterIdProp || params?.encounterId),
    [params, encounterIdProp]);
  const [isEditLineItems, setIsEditLineItems] = useState(false);

  const { tabs } = store.getState();
  const activeTabName = tabs.get('tabModuleName');

  const [encounterDataResponse, , loading, getEncounter] = useCRUD({
    url: `${getUrl}`,
    id: `${getUrl}-billing-encounter-${encounterId}`,
    type: 'read',
  });

  const [icdCodeResponse, , icdLoading, getICDCodes] = useCRUD({
    url: apiUrls.CURRENT_ENCOUNTER_DIAGNOSIS,
    id: 'ICD-Codes-Data',
    type: 'read',
  });

  const [anesthesiaAutoSaveResponse, , anesthesiaAutoSaveLoading,
    anesthesiaAutoSave, clearAnesthesiaAutoSaveResponse] = useCRUD({
    url: `${updateUrl}/${encounterId}`,
    id: `${updateUrl}/${encounterId}`,
    type: 'update',
  });

  useEffect(() => {
    if (anesthesiaAutoSaveResponse) {
      Notification({ message: SuccessMessages.ENCOUNTER_UPDATED_SUCCESSFULLY, success: true });
      clearAnesthesiaAutoSaveResponse(true);
    }
  }, [anesthesiaAutoSaveResponse]);

  useEffect(() => {
    if (!savedEncounter) {
      if (encounterId) {
        getEncounter({ isCollectionCharges }, `/${encounterId}`);
      } else {
        setSavedEncounterData({
          serviceDate: moment(),
          lineItems: [{
            billingEncounterServiceLineId: 'new_1', serviceDate: new Date(),
          }],
        });
      }
    } else {
      const formData = [];
      Object.keys(savedEncounter).forEach((key) => {
        formData.push({ name: key, value: savedEncounter[key] });
      });
      form.setFields(formData);
    }
  }, [tabId, activeComponent]);

  const { navigate } = useTabLink({
    to: UiRoutes.viewEncounterWithId,
    id: UiRoutes.viewEncounterWithId,
    params: {
      tab: 'encounters',
      data: {
        name: '', ...params, patientId, encounterId,
      },
    },
  });

  const [patientDetail] = usePatientDetail({
    patientId, params,
  });

  const hasInsuranceProfileSelf = useMemo(() => {
    if (!savedEncounter || typeof savedEncounter !== 'object') {
      return false;
    }
    // Check if the data object or any of its line items have insuranceProfileId equal self (1 or 0)
    return (
      savedEncounter.insuranceProfileId === 1
      || (Array.isArray(savedEncounter.lineItems)
        && savedEncounter.lineItems.some((item) => item.insuranceProfileId === 0))
    );
  }, [savedEncounter]);

  // Rules Engine code below
  useEffect(() => {
    if ((encounterId || encounterIdProp) && savedEncounter) {
      setUpdate(true);
      if (
        isRulesEngineOverRidePermitted
        || savedEncounter.encounterOriginDescription === 'Manual'
        || !rulesEngineEvaluationStatus
        || isCollectionCharges
        || activeTabName === 'claims'
        || hasInsuranceProfileSelf
      ) {
        setSelectedOption('postCharges');
      } else {
        setSelectedOption('evalRules');
      }
    }
  }, [encounterId, encounterIdProp, savedEncounter, rulesEngineEvaluationStatus, isRulesEngineOverRidePermitted, isCollectionCharges, activeTabName, hasInsuranceProfileSelf]);

  const goBack = useCallback(() => {
    if (closeTab) {
      closeTab(params?.tabId, true);
    } else {
      push(generatePath(goBackPath, { tab: 'encounters', ...params }));
    }
  }, [closeTab, push, generatePath, goBackPath, params]);

  const onRequestComplete = useCallback(({ response }) => {
    const computedPatientName = getName(
      [patientDetail.firstName, patientDetail.middleName, patientDetail.lastName],
    );
    submitRef.current = false;
    if (response && response?.encounterId && !isUpdate) {
      Notification({ message: SuccessMessages.ENCOUNTER_ADDED_SUCCESSFULLY, success: true });
      if (closeTab) {
        clear();
        clearAnesthesia();
        closeTab(params?.tabId, true);
      }
      navigate({
        encounterId: response.encounterId,
        data: {
          name: `Encounter-${patientDetail?.firstName
            ? computedPatientName
            : ''}`,
        },
      });
      return;
    }
    if (response && isUpdate) {
      Notification({ message: SuccessMessages.ENCOUNTER_UPDATED_SUCCESSFULLY, success: true });
      if (closeTab) {
        closeTab(params?.tabId, true);
      } else if (encounterId) {
        getEncounter({ isCollectionCharges }, `/${encounterId}`);
        isSaveEncounterFromCollection.current = true;
      }
      return;
    }
    if (encounterId) {
      getEncounter({ isCollectionCharges }, `/${encounterId}`);
    }
    savedEncounter.refreshData = true;
    setSuppressWarning(false);
    setSavedEncounterData(savedEncounter);
  }, [patientDetail, isUpdate, encounterId, savedEncounter, setSuppressWarning,
    setSavedEncounterData, closeTab, navigate, clear, clearAnesthesia,
    params, getEncounter, isCollectionCharges]);

  const flattenedICDs = useMemo(() => {
    let icdCodes = encounterDataResponse?.serviceLineItems?.map(
      (item) => item?.billingEncounterDiagnosisCodes,
    );
    icdCodes = flatten(icdCodes);
    return icdCodes?.map((icd) => ({
      ...icd,
      id: icd?.icD10CodeId,
      value: icd?.icD10CodeId,
      name: icd?.icdCode,
      code: icd?.icdCode,
      desc: icd?.icdCodeDescription,
      description: icd?.icdCodeDescription,
    }));
  }, [encounterDataResponse]);

  useEffect(() => {
    if (!isEmpty(encounterDataResponse)
    && !encounterDataResponse?.encounterICDCodes
    && isEmpty(diagnosesCodes)) {
      if (encounterDataResponse?.encounterDetails?.encounterOriginDescription?.toLowerCase() === 'appointment') {
        if (!icdCodesData?.length) {
          getICDCodes({
            encounterId: encounterDataResponse?.encounterId,
          });
        }
      } else {
        const formattedICDCodes = {};
        // eslint-disable-next-line no-unused-expressions
        flattenedICDs?.forEach((item, index) => {
          formattedICDCodes[index + 1] = item;
        });
        setDiagnosesCodes(formattedICDCodes);
      }
    } else if (!isEmpty(encounterDataResponse)
    && isEmpty(diagnosesCodes) && !isDiagnosisCodesUpdated) {
      let flattenedIcdCodes = uniqBy(JSON.parse(encounterDataResponse?.encounterICDCodes), 'icdCode');
      flattenedIcdCodes = orderBy(flattenedIcdCodes, 'sortOrder', 'asc');
      const formattedICDCodes = {};
      // eslint-disable-next-line no-unused-expressions
      flattenedIcdCodes?.forEach((item, index) => {
        formattedICDCodes[index + 1] = { ...item, name: item?.icdCode, value: item?.id };
      });
      setDiagnosesCodes(formattedICDCodes);
      setIsDiagnosisCodesUpdated(true);
    }
    if (!isEmpty(encounterDataResponse) && encounterDataResponse?.anesthesiaDetails) {
      const anesthesiaObject = {};
      if (encounterDataResponse.anesthesiaDetails?.anesthesiaStartTime) {
        anesthesiaObject.anesthesiaStartTime = moment(
          `${encounterDataResponse.anesthesiaDetails.anesthesiaStartTime.includes('Z') ? encounterDataResponse.anesthesiaDetails.anesthesiaStartTime : `${encounterDataResponse.anesthesiaDetails.anesthesiaStartTime}Z`}`,
        );
      }
      if (encounterDataResponse.anesthesiaDetails?.anesthesiaEndTime) {
        anesthesiaObject.anesthesiaEndTime = moment(
          `${encounterDataResponse.anesthesiaDetails.anesthesiaEndTime.includes('Z') ? encounterDataResponse.anesthesiaDetails.anesthesiaEndTime : `${encounterDataResponse.anesthesiaDetails.anesthesiaEndTime}Z`}`,
        );
      }
      setAnesthesia(anesthesiaObject);
    }
    if (encounterDataResponse && isSaveEncounterFromCollection.current) {
      const billingEncounterLineItems = {};
      encounterDataResponse?.serviceLineItems?.map(({
        billingEncounterServiceLineId,
        cptCode,
        isActive,
        cap,
        status,
      }) => {
        billingEncounterLineItems[cptCode] = {
          billingEncounterServiceLineId, isActive, cap, status,
        };
      });
      const serviceLineItemData = savedEncounter?.lineItems;
      isSaveEncounterFromCollection.current = false;
      const filteredLineItems = [];
       serviceLineItemData?.forEach((lineItem, index) => {
         const {
           cptCode,
           CPTCode,
           billingEncounterServiceLineId,
         } = lineItem;
         const cptCodeValue = cptCode || CPTCode;
         if (typeof billingEncounterServiceLineId === 'string'
        && billingEncounterServiceLineId?.includes('new') && billingEncounterLineItems[cptCodeValue]?.billingEncounterServiceLineId) {
           lineItem.billingEncounterServiceLineId = billingEncounterLineItems[cptCodeValue].billingEncounterServiceLineId;
           lineItem.isActive = billingEncounterLineItems[cptCodeValue].isActive;
           lineItem.cap = billingEncounterLineItems[cptCodeValue].cap;
           lineItem.status = billingEncounterLineItems[cptCodeValue].status;
           form.setFields([
             { name: ['lineItems', index, 'billingEncounterServiceLineId'], value: billingEncounterLineItems[cptCodeValue].billingEncounterServiceLineId },
             { name: ['lineItems', index, 'isActive'], value: billingEncounterLineItems[cptCodeValue].isActive },
             { name: ['lineItems', index, 'cap'], value: billingEncounterLineItems[cptCodeValue].cap },
             { name: ['lineItems', index, 'status'], value: billingEncounterLineItems[cptCodeValue].status },
           ]);
         }
         if (!lineItem?.deleted) {
           filteredLineItems.push(lineItem);
         }
       });
       savedEncounter.lineItems = filteredLineItems;
       setSavedEncounterData({ ...savedEncounter });
       const formData = [];
       Object.keys({ ...savedEncounter }).forEach((key) => {
         formData.push({ name: key, value: savedEncounter[key] });
       });
       form.setFields(formData);
    } else if (encounterDataResponse) {
      const clonedData = {
        ...encounterDataResponse,
        ...encounterDataResponse?.encounterDetails,
        ...encounterDataResponse?.attendingProvider,
        ...encounterDataResponse?.authorization,
        ...encounterDataResponse?.insurance,
        ...encounterDataResponse?.insurancePayer,
        ...encounterDataResponse?.location,
        ...encounterDataResponse?.patient,
        ...encounterDataResponse?.primaryInsured,
        ...encounterDataResponse?.provider,
        ...encounterDataResponse?.referringPhysicianNPI,
      };
      clonedData.authorizationId = parseInt(
        encounterDataResponse?.authorization?.authorizationId, 10,
      ) || undefined;
      clonedData.serviceDate = clonedData?.serviceDateString?.dateString
      && moment(clonedData?.serviceDateString?.dateString);
      clonedData.referringPhysicianId = clonedData?.referringPhysicianNPI?.toString();
      clonedData.savedReferringPhysicianId = clonedData.referringPhysicianId;
      clonedData.savedToggleReferringPhysician = clonedData?.toggleReferringPhysician;
      if (clonedData?.insuranceProfileType?.toLowerCase()?.trim() === 'self') {
        clonedData.insuranceProfileDetailId = clonedData.insuranceProfileId;
        clonedData.insuranceProfileId = 0;
      }
      const serviceLineItemData = savedEncounter?.lineItems ? savedEncounter?.lineItems
        : clonedData?.serviceLineItems;

      const filteredLineItems = serviceLineItemData?.map((lineItem) => {
        let clonedLineItem = formFieldValueParser(lineItem, {
          date: [
            'serviceDate',
          ],
        });
        clonedLineItem = { ...clonedLineItem, ...clonedLineItem?.insurance };
        const {
          billingEncounterDiagnosisCodes: newDiagnosesCodes = [],
          billingEncounterModifiers: preModifiers = [],
          modifiers,
          cptCodeId,
          cptCode,
          CPTCode,
          cptCodeDescription,
          billingEncounterServiceLineId,
          isPosted: isPostd,
        } = clonedLineItem;
        const cptCodeData = {};
        cptCodeData[billingEncounterServiceLineId] = {
          code: cptCode || CPTCode,
        };
        const filteredLineItem = {
          ...clonedLineItem,
          // disabled: !holdItem,
          CPTCode: cptCode || CPTCode,
          cptCodeData,
          disabled: isPostd,
          modifiers: modifierMapper(modifiers?.length ? modifiers : preModifiers),
          diagnosesCodes: diagnosesCodeMapper(newDiagnosesCodes),
          cptCodeVal: cptCodeMapper(cptCode || CPTCode, cptCodeId, cptCodeDescription),
          unit: `${clonedLineItem?.unit || ''}`,
        };

        if (!clonedLineItem?.insuranceProfileDetailId && clonedLineItem?.insuranceProfileId) {
          filteredLineItem.insuranceProfileDetailId = filteredLineItem.insuranceProfileId;
          filteredLineItem.insuranceProfileId = 0;
        }
        if (clonedLineItem?.lineItemProviderDetails) {
          filteredLineItem.providerId = clonedLineItem.lineItemProviderDetails?.providerId;
          filteredLineItem.providerName = clonedLineItem.lineItemProviderDetails?.providerName;
        }
        if (clonedLineItem?.lineItemBusinessEntityDetails) {
          filteredLineItem.businessEntityId = clonedLineItem
            .lineItemBusinessEntityDetails?.businessEntityId;
          filteredLineItem.businessEntityName = clonedLineItem
            .lineItemBusinessEntityDetails?.businessEntityName;
        }
        return filteredLineItem;
      });
      clonedData.lineItems = filteredLineItems;
      setSavedEncounterData(clonedData);
      setIsUpdateInsuranceData(true);
      const formData = [];
      Object.keys(clonedData).forEach((key) => {
        formData.push({ name: key, value: clonedData[key] });
      });
      form.setFields(formData);
    }
  }, [encounterDataResponse]);

  useEffect(() => {
    if (icdCodesData || icdCodeResponse) {
      const icdData = icdCodesData || icdCodeResponse;
      const flattenedIcdCodes = icdData?.map((icd) => ({
        ...icd,
        id: icd.id,
        value: icd.id,
        name: icd.icdCode,
        code: icd.icdCode,
        desc: icd.description,
        description: icd.description,
      }));
      let parsedICDCodes = intersectionBy(flattenedIcdCodes, flattenedICDs, 'id');
      parsedICDCodes = unionBy(parsedICDCodes, flattenedICDs, 'id');

      const formattedICDCodes = {};
        // eslint-disable-next-line no-unused-expressions
        parsedICDCodes?.forEach((item, index) => {
          formattedICDCodes[index + 1] = item;
        });
        setDiagnosesCodes(formattedICDCodes);
    }
  }, [icdCodeResponse, icdCodesData]);

  const getExtraData = useCallback(() => {
    const data = {
      patientId,
      isPosted,
      method: encounterId ? 'put' : 'post',
      isCollectionCharges,
    };
    // rules Engine
    switch (selectedOption) {
      case 'postCharges':
        data.overrideRulesEngine = true;
        break;
      case 'evalRules':
        data.overrideRulesEngine = false;
        break;
      default: break;
    }

    if (isUpdate && selectedOption === 'postCharges' && (encounterDataResponse?.claimDetails?.waystarRejectionError) !== null) {
      data.IsWayStarRejected = true;
    }

    const selectedInsurance = form.getFieldInstance('insuranceProfileDetailId')?.props?.selectedInsurance;
    data.insuranceProfileDetailId = selectedInsurance?.value;
    if (selectedInsurance?.pId) {
      data.insuranceProfileId = selectedInsurance?.pId;
    }
    if (selectedInsurance?.item?.profileType?.toLowerCase() === 'self') {
      data.insuranceProfileId = selectedInsurance?.item?.insuranceProfileId;
      data.insuranceProfileDetailId = '';
    }
    if (savedEncounter?.referringPhysicianJson) {
      data.referringPhysicianJson = savedEncounter.referringPhysicianJson;
    }
    data.appointmentVisitType = savedEncounter?.encounterDetails?.appointmentVisitType;
    data.appointmentExamTypes = savedEncounter?.encounterDetails?.appointmentExamTypes;
    return data;
  }, [encounterId, form, isCollectionCharges, isPosted, patientId, savedEncounter, selectedOption]);

  const parser = useCallback((patientInfo) => {
    const parsedData = formFieldValueParser(patientInfo, {
      int: [
        'referringPhysicianId',
        'patientId',
      ],
    });
    parsedData.serviceDate = getDateWithZeroTimeFormat(parsedData.serviceDate);
    if (supressWarningRef) {
      parsedData.SupressWarning = supressWarningRef;
    }
    delete parsedData.insuranceProfileDetailId;
    const filteredLineItems = [];
    parsedData?.lineItems?.forEach((lineItem) => {
      const clonedLineItem = clone(lineItem);
      if (clonedLineItem?.cptCodeId) {
        if (!clonedLineItem?.deleted) {
          if (path?.includes('new') && !clonedLineItem.isActive) {
            clonedLineItem.isActive = true;
          }
          getUpdatedClonedLineItem(clonedLineItem, lineItem, filteredLineItems);
        } else if (clonedLineItem?.deleted && clonedLineItem?.billingEncounterServiceLineId && typeof clonedLineItem?.billingEncounterServiceLineId !== 'string') {
          clonedLineItem.isDeleted = true;
          clonedLineItem.isActive = false;
          getUpdatedClonedLineItem(clonedLineItem, lineItem, filteredLineItems);
        }
      }
    });
    parsedData.lineItems = filteredLineItems;
    parsedData.EncounterOrigin = 'EncounterOriginManual';
    if (diagnosesCodes) {
      const sortedDiagnosesCodes = Object.values(diagnosesCodes)?.map(
      (item, index) => ({ ...item, sortOrder: index }));
      parsedData.encounterICDCodes = sortedDiagnosesCodes
    && JSON.stringify(sortedDiagnosesCodes);
    }
    parsedData.anesthesiaStartTime = anesthesia?.anesthesiaStartTime;
    parsedData.anesthesiaEndTime = anesthesia?.anesthesiaEndTime;
    parsedData.referringPhysicianNpi = parsedData.referringPhysicianId;
    return parsedData;
  }, [anesthesia, diagnosesCodes, supressWarningRef]);

  const onSubmit = useCallback(({ target: { name } }) => {
    if (submitRef.current) { return; }
    submitRef.current = true;
    setPosted(name === 'postChanges');
    const lineItemData = parser(form.getFieldsValue());
    const isSubmit = !isEmpty(lineItemData?.lineItems)
    && lineItemData.lineItems.every(({ CPTCodeId, cptCodeId }) => CPTCodeId || cptCodeId);
    if (anesthesia?.anesthesiaStartTime && !anesthesia?.anesthesiaEndTime) {
      Notification({ message: ErrorMessages.ANESTHESIA_END_TIME_REQUIRED });
      submitRef.current = false;
      return;
    }
    if (anesthesia?.anesthesiaEndTime && !anesthesia?.anesthesiaStartTime) {
      Notification({ message: ErrorMessages.ANESTHESIA_START_TIME_REQUIRED });
      submitRef.current = false;
      return;
    }
    if (isSubmit) {
      form.submit();
    } else {
      Notification({ message: ErrorMessages.PLEASE_ENTER_LINEITEM });
      submitRef.current = false;
    }
  }, [anesthesia, form, parser, setPosted]);

  const handleAnesthesiaAutoSave = useCallback((anesthesiaTime) => {
    const data = parser(form.getFieldsValue()) || {};
    if (data?.lineItems) {
      delete data.lineItems;
    }
    const extraData = getExtraData();
    if (extraData?.method) {
      delete extraData.method;
    }
    anesthesiaAutoSave({
      ...data, ...extraData, ...anesthesiaTime, isPosted: false,
    });
  }, [anesthesiaAutoSave, form, getExtraData, parser]);

  const setLineItems = useCallback((data) => {
    savedEncounter.lineItems = data;
    setSavedEncounterData({ ...savedEncounter });
  }, [savedEncounter, setSavedEncounterData]);

  const setParentDataItems = useCallback((data) => {
    setSavedEncounterData({ ...savedEncounter, ...data });
    if (data.toggleReferringPhysician === 'self' || (data.toggleReferringPhysician !== 'self' && data.referringPhyscianName !== undefined)) {
      setIsEditLineItems(true);
    } else if (data.toggleReferringPhysician !== undefined && data.toggleReferringPhysician !== 'self' && data.referringPhyscianName === undefined) {
      setIsEditLineItems(false);
    }
  }, [savedEncounter, setSavedEncounterData]);

  const holdAllHandler = useCallback(({ target: { checked } }) => {
    const currentLineItems = savedEncounter?.lineItems || [];
    currentLineItems.forEach((item, currentIndex) => {
      if (!currentLineItems[currentIndex].isPosted
        && !checkDisabledLineItem(item?.stateMasterCode)) {
        currentLineItems[currentIndex].holdItem = checked;
        currentLineItems[currentIndex].isEdit = true;
        form.setFields([
          { name: ['lineItems', currentIndex, 'holdItem'], value: checked },
        ]);
      }
    });
    setLineItems(currentLineItems);
  }, [form, savedEncounter, setLineItems]);

  const handleParentInsuranceChange = useCallback(
    (insuranceProfileDetailId, insuranceProfileId) => {
      const currentLineItems = savedEncounter?.lineItems || [];
      currentLineItems.forEach((item, currentIndex) => {
        if (!currentLineItems[currentIndex].isPosted
          && !checkDisabledLineItem(item?.stateMasterCode)) {
          currentLineItems[currentIndex].insuranceProfileDetailId = insuranceProfileDetailId;
          currentLineItems[currentIndex].insuranceProfileId = insuranceProfileId === 0 ? '0' : insuranceProfileId;
          currentLineItems[currentIndex].isEdit = true;
          form.setFields([
            {
              name: ['lineItems', currentIndex, 'insuranceProfileId'],
              value: insuranceProfileId,
            },
            {
              name: ['lineItems', currentIndex, 'insuranceProfileDetailId'],
              value: insuranceProfileDetailId,
            },
          ]);
        }
      });
      setLineItems(currentLineItems);
      setIsUpdateInsuranceData(true);
    }, [form, savedEncounter, setIsUpdateInsuranceData, setLineItems],
  );

  const handleIsEdit = useCallback((value) => {
    setIsEditLineItems(value);
  }, []);

  const handleParentProviderChange = useCallback((providerId, providerName) => {
    const currentLineItems = savedEncounter?.lineItems || [];
    currentLineItems.forEach((item, currentIndex) => {
      if (!checkDisabledLineItem(item?.stateMasterCode)) {
        if (!currentLineItems[currentIndex].isPosted) {
          currentLineItems[currentIndex].providerId = providerId;
          currentLineItems[currentIndex].providerName = providerName;
        }
        form.setFields([
          {
            name: ['lineItems', currentIndex, 'providerId'],
            value: providerId,
          },
          {
            name: ['lineItems', currentIndex, 'providerName'],
            value: providerName,
          },
        ]);
      }
    });
    savedEncounter.lineItems = currentLineItems;
    setSavedEncounterData({ ...savedEncounter, providerId, providerFullName: providerName });
  }, [form, savedEncounter, setSavedEncounterData]);

  const handleParentAuthorizationChange = useCallback(
    (authorizationId) => {
      const currentLineItems = savedEncounter?.lineItems || [];
      const shoudldChangeAuthorization = authorizationOptions?.find(
        (ele) => ele?.authorizationId === authorizationId);
      currentLineItems.forEach((item, currentIndex) => {
        if (!checkDisabledLineItem(item?.stateMasterCode)) {
          if (currentLineItems[currentIndex]?.insuranceProfileDetailId
            === shoudldChangeAuthorization?.insuranceProfileId) {
            currentLineItems[currentIndex].authorizationId = authorizationId;
            form.setFields([
              {
                name: ['lineItems', currentIndex, 'authorizationId'],
                value: authorizationId,
              },
            ]);
          } else {
            currentLineItems[currentIndex].authorizationId = undefined;
          }
          currentLineItems[currentIndex].isEdit = true;
        }
      });
      savedEncounter.lineItems = currentLineItems;
      setSavedEncounterData({ ...savedEncounter, authorizationId });
    }, [savedEncounter, authorizationOptions, setSavedEncounterData, form],
  );

  const handleParentDiagonsisChange = useCallback(
    (diagnosisCode) => {
      const tabElement = document.getElementsByClassName('clip-boxes-wrap')?.[0];
      if (tabElement) {
        tabElement.style.pointerEvents = 'none';
      }
      const currentLineItems = savedEncounter?.lineItems || [];
      currentLineItems.forEach((item, currentIndex) => {
        let i = 0;
        while (i < 4) {
          if (form.getFieldValue(['lineItems', currentIndex, 'diagnosesCodes', i, 'id']) === diagnosisCode?.id
          && !checkDisabledLineItem(item?.stateMasterCode)) {
            item?.diagnosesCodes?.splice(i, 1);
            item?.billingEncounterDiagnosisCodes?.splice(i, 1);
          } else {
            i += 1;
          }
        }
      });
      savedEncounter.lineItems = currentLineItems;
      setSavedEncounterData({ ...savedEncounter });
      if (tabElement) {
        tabElement.style.pointerEvents = 'auto';
      }
    }, [savedEncounter, setSavedEncounterData, form],
  );

  const resetFormData = useCallback(() => {
    clear();
    clearAnesthesia();
    clearDiagnosesCodes();
    getEncounter({ isCollectionCharges }, `/${encounterId}`);
  }, [clear, clearAnesthesia, clearDiagnosesCodes, encounterId, getEncounter, isCollectionCharges]);

  const onRequestFail = useCallback((errorMessage) => {
    if (isPosted && (errorMessage?.[0] === ErrorMessages.PROVIDER_IS_NOT_CONTRACTED_FOR_LOCATION
      || errorMessage?.[0] === ErrorMessages.CONTRACT_EXPIRED_ERROR)) {
      ConfirmDialog({
        onOk: (close) => {
          setSuppressWarning(true);
          form.submit();
          close();
        },
        onCancel: (close) => {
          close();
          submitRef.current = false;
        },
        okText: 'Continue',
        title: 'Warning',
        content: `${errorMessage?.[0]}, Do you want to continue?`,
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    } else if (errorMessage?.length) {
      Notification({ message: errorMessage?.[0], success: false });
      submitRef.current = false;
    }
  }, [form, isPosted, setSuppressWarning]);

  const onFinishFail = useCallback((errorInfo) => {
    submitRef.current = false;
    const requiredError = errorInfo?.errorFields.find((err) => err.errors[0].indexOf('is required') > -1 || err.errors[0] === ' ');
    if (requiredError) {
      Notification({ message: ErrorMessages.PLEASE_FILL_ALL_REQUIRED_FIELDS });
    } else {
      Notification({ message: errorInfo?.errorFields[0].errors[0] });
    }
  }, []);

  const anesthesiaStartAndEndTimeDiffMin = useMemo(() => {
    if (anesthesia?.anesthesiaStartTime && anesthesia?.anesthesiaEndTime) {
      const minuteDiff = getDifferenceInMinute(anesthesia?.anesthesiaStartTime,
        anesthesia?.anesthesiaEndTime);
      return { AnesthesiaMinutes: minuteDiff };
    }
    return {};
  }, [anesthesia]);

  // RCM and Encounter Tabs
  const tabList = () => [
    { value: 'Encounter', label: 'Encounter' },
    { value: 'Rule', label: 'Rule Evaluation' },
  ];

  const tabSwitchHandler = useCallback(
    ({ target: { value } }) => {
      setActiveComponent(value);
    },
    [activeComponent],
  );

  const error = (encounterDataResponse?.claimDetails?.waystarRejectionError);
  const dataArray = error?.split(',,');

  useEffect(() => {
    if (!isEditLineItems) {
      setIsEditLineItems(form.getFieldsValue().lineItems && form.getFieldsValue().lineItems.some((x) => x.isEdited && (x.CPTCodeId > 0 || x.billingEncounterServiceLineId > 0)));
    }
  }, [form.getFieldsValue().lineItems]);

  const showPostChargesButton = useMemo(() => {
    const hasInsuranceProfileId = !!savedEncounter?.lineItems?.[0]?.insuranceProfileId;
    return (
      isEnterBillingChargesPermitted
      && ((hasInsuranceProfileId && isCreateClaimsPermitted)
      || (!hasInsuranceProfileId && isCreateStatementPermitted))
    );
  }, [savedEncounter, isCreateClaimsPermitted, isCreateStatementPermitted, isEnterBillingChargesPermitted, encounterId]);

  return (
    <div className="new-encounter" data-testid="new-encounter">
      {(loading || icdLoading) && <Loader />}
      {isUpdate && !isCollectionCharges && (
        <div className="encounter-rcm-tab">
          <RadioGroup
            tabList={tabList(labels)}
            onChange={tabSwitchHandler}
            value={activeComponent}
          />
        </div>
      )}
      {activeComponent === 'Encounter' && (
        <>
          <div className="top-title">
            <h3>
              {patientDetail?.firstName && (
                <>
                  <span>
                    {`${patientDetail.firstName} ${patientDetail.lastName} `}
                    (Chart No:
                    {patientDetail?.chartNumber?.trim()}
                    )
                  </span>
                  {isUpdate && <EncounterEvaluationStatus ruleEvaluationStatus={savedEncounter?.ruleEvaluationStatus} />}
                </>
              )}
            </h3>
            <div className="encounter-actions">
              <div className="encounter-btns">
                {isUpdate && !isCollectionCharges && (
                  <RulesEngineEncounter
                    encounterDataResponse={encounterDataResponse}
                    encounterId={encounterId}
                    selectedRule={selectedRule}
                    setSelectedRule={setSelectedRule}
                    setShowRcmHighlight={setShowRcmHighlight}
                    showRcmHighlight={showRcmHighlight}
                    hasInsuranceProfileSelf={hasInsuranceProfileSelf}
                    isCollectionCharges={isCollectionCharges}
                    isRulesEngineOverRidePermitted={isRulesEngineOverRidePermitted}
                    isUpdate={isUpdate}
                    rulesEngineEvaluationStatus={rulesEngineEvaluationStatus}
                    savedEncounter={savedEncounter}
                    setRulesEngineEvaluationStatus={setRulesEngineEvaluationStatus}
                    setShowDropDownOfOverrideRulesEngine={setShowDropDownOfOverrideRulesEngine}
                    activeTabName={activeTabName}
                  />
                )}
                {showCancel && (
                  <Button
                    className="btn sm-btn"
                    id="patients_encounter_new_cancel"
                    onClick={goBack}
                  >
                    Cancel
                  </Button>
                )}
                {!params?.financialId
                  && isEnterBillingChargesPermitted
                  && !isCollectionCharges && (
                    <Button
                      className="btn btn-success sm-btn"
                      id="patients_encounter_new_save"
                      onClick={onSubmit}
                    >
                      {labels.get('buttons.saveForLater')}
                    </Button>
                )}
                {showPostChargesButton && (
                <span
                  className="dropdown-btn"
                >
                  <Button
                    className="btn btn-success sm-btn"
                    id="patients_encounter_new_post"
                    name="postChanges"
                    onClick={onSubmit}
                    disabled={isCollectionCharges && (claimFilter === 'completed' || !isEditLineItems)}
                  >
                    {isCollectionCharges
                      ? labels.get('buttons.saveChanges') : selectedOption === 'evalRules'
                        ? labels.get('buttons.postChanges') : labels.get('buttons.postChargesWOEvaluation')}
                  </Button>
                  {showDropDownOfOverrideRulesEngine && <PostChargesDropdownBtn labels={labels} setSelectedOption={setSelectedOption} />}
                </span>
                )}
              </div>
            </div>
          </div>
          {dataArray?.length > 0 && (
            <div
              role="presentation"
              style={{
                marginLeft: '15px',
              }}
            >
              <ul>
                {dataArray?.map((item, index) => (
                  <li style={{ color: 'orange', listStyleType: 'disc' }} key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
          <Form
            form={form}
            // getUrl={`${getUrl}/${encounterId}`}
            url={isUpdate ? `${updateUrl}/${encounterId}` : addUrl}
            noOfColumns={noOfColumns}
            onRequestComplete={onRequestComplete}
            onRequestFail={onRequestFail}
            onFinishFailed={onFinishFail}
            parser={parser}
            formId={formId.ADD_ENCOUNTER}
            name={formId.ADD_ENCOUNTER}
            scrollToFirstErrors
            listId={listIds.PATIENT_ENCOUNTER_LIST}
            extraData={getExtraData}
            section
            initialData={
              !encounterId && !params?.financialId
                ? { serviceDate: moment(), toggleReferringPhysician: 'npi' }
                : {}
            }
            refetchId="patient-encounter-service-line-items"
            // onGetResponseComplete={onGetResponseComplete}
            className="patient-encounter-form"
          >
            <Form.Section>
              <NewEncounterForm
                noOfColumns={noOfColumns}
                labels={labels}
                params={params}
                form={form}
                formData={savedEncounter}
                isUpdate={isUpdate}
                setParentDataItems={setParentDataItems}
                handleParentInsuranceChange={handleParentInsuranceChange}
                handleParentAuthorizationChange={
                  handleParentAuthorizationChange
                }
                handleParentProviderChange={handleParentProviderChange}
                formId={formId.ADD_ENCOUNTER}
                financialId={params?.financialId}
                serviceDate={savedEncounter?.serviceDate}
                billingEncounterId={encounterId}
                setAuthorizationOptions={setAuthorizationOptions}
                isCollectionCharges={isCollectionCharges}
                tabId={tabId}
                showRcmHighlight={showRcmHighlight}
                finalRcmHighlightData={selectedRule?.relatedFailures}
                handleIsEdit={handleIsEdit}
              />
            </Form.Section>
            <Form.Section>
              <EncounterTabs
                setDiagnosesCodes={setDiagnosesCodes}
                diagnosesCodes={diagnosesCodes}
                handleParentDiagonsisChange={handleParentDiagonsisChange}
                anesthesia={anesthesia}
                setAnesthesia={setAnesthesia}
                encounterId={encounterId}
                handleAnesthesiaAutoSave={handleAnesthesiaAutoSave}
                loading={anesthesiaAutoSaveLoading}
                patientDateOfBirth={patientDetail?.dateOfBirth}
                isCollectionCharges={isCollectionCharges}
                lineItemsData={savedEncounter?.lineItems}
                claimFilter={claimFilter}
              />
            </Form.Section>
            <Form.Section>
              <div className="encounter-table">
                <EncounterTable
                  labels={labels}
                  form={form}
                  patientId={patientId}
                  isUpdate={isUpdate}
                  lineItemsData={savedEncounter?.lineItems}
                  billingEncounterId={encounterId}
                  setLineItems={setLineItems}
                  diagnosesCodes={diagnosesCodes}
                  providerId={savedEncounter?.providerId}
                  providerFullName={savedEncounter?.providerFullName}
                  locationId={savedEncounter?.locationId}
                  appointmentId={savedEncounter?.appointmentId}
                  serviceDate={savedEncounter?.serviceDate}
                  financialId={params?.financialId}
                  encounterStatus={savedEncounter?.status}
                  setDiagnosesCodes={setDiagnosesCodes}
                  savedEncounter={savedEncounter}
                  isCollectionCharges={isCollectionCharges}
                  isUpdateInsuranceData={isUpdateInsuranceData}
                  setIsUpdateInsuranceData={setIsUpdateInsuranceData}
                  isShowExtraColumnForAnesthesia={
                    !!(
                      anesthesia?.anesthesiaStartTime
                      && anesthesia?.anesthesiaEndTime
                    )
                  }
                  anesthesiaStartAndEndTimeDiffMin={
                    anesthesiaStartAndEndTimeDiffMin
                  }
                  tabId={tabId}
                  authorizationId={savedEncounter?.authorizationId}
                  showRcmHighlight={showRcmHighlight}
                  finalRcmHighlightData={selectedRule?.relatedFailures}
                />
              </div>
            </Form.Section>

            <div className="encounter-actions mr-top-8">
              <EncounterCheckBoxActions
                params={params}
                isCollectionCharges={isCollectionCharges}
                holdAllHandler={holdAllHandler}
              />
              <div className="encounter-btns">
                {isEnterBillingChargesPermitted && !isCollectionCharges && (
                  <Button
                    className="btn sm-btn"
                    id="patients_encounter_new_cancel"
                    onClick={goBack}
                  >
                    Cancel
                  </Button>
                )}
                {isCollectionCharges && (
                  <Button
                    className="btn sm-btn"
                    id="patients_encounter_new_cancel"
                    onClick={resetFormData}
                  >
                    Clear
                  </Button>
                )}
                {!params?.financialId
                  && isEnterBillingChargesPermitted
                  && !isCollectionCharges && (
                    <Button
                      className="btn btn-success sm-btn"
                      id="patients_encounter_new_save"
                      onClick={onSubmit}
                    >
                      {labels.get('buttons.saveForLater')}
                    </Button>
                )}
                {showPostChargesButton && (
                <span
                  className="dropdown-btn"
                >
                  <Button
                    className="btn btn-success sm-btn"
                    id="patients_encounter_new_post"
                    name="postChanges"
                    onClick={onSubmit}
                    disabled={isCollectionCharges && (claimFilter === 'completed' || !isEditLineItems)}
                  >
                    {isCollectionCharges
                      ? labels.get('buttons.saveChanges') : selectedOption === 'evalRules'
                        ? labels.get('buttons.postChanges') : labels.get('buttons.postChargesWOEvaluation')}
                  </Button>
                  {showDropDownOfOverrideRulesEngine && <PostChargesDropdownBtn labels={labels} setSelectedOption={setSelectedOption} />}
                </span>
                )}
              </div>
            </div>
          </Form>
        </>
      )}
      {activeComponent === 'Rule' && <RulesResult encounterId={encounterId} />}
    </div>
  );
};

NewEncounter.defaultProps = {
  goBackPath: UiRoutes.editPatientWithTabId,
  addUrl: apiUrls.ADD_PATIENT_ENCOUNTER,
  getUrl: apiUrls.GET_PATIENT_ENCOUNTER,
  updateUrl: apiUrls.UPDATE_PATIENT_ENCOUNTER,
  showCancel: true,
};

export default WithLabel(NewEncounter, labelPaths.PATIENT_ENCOUNTERS);
