import React, { useCallback, useState } from 'react';

import { apiUrls } from '../../../api/constants';
import { listId, UiRoutes } from '../../../lib/constants';

import FilterManager from '../../../components/FilterManager';
import FilterComponents from '../../../components/FilterComponents';

import columns from './columns';
import withReduxManagerAdvanced from '../../../hoc/withReduxManager/withReduxManagerAdvanced';
import withQueryAdvanced from '../../../hoc/withQuery/withQueryAdvanced';
import useTabLink from '../../../hooks/useTabLink';
import { getName } from '../../../lib/util';

const initialSort = [{ id: 'FirstName', desc: false }];

const CustomPatientTable = withReduxManagerAdvanced({
  listId: listId.PATIENTS,
})(withQueryAdvanced({
  url: apiUrls.GET_PATIENT,
  listId: listId.PATIENTS,
  alias: {
    firstName: 'FirstName',
    nextAppDate: 'NextAppDate',
    lastVisitedDate: 'LastVisitedDate',
    chartNumber: 'ChartNumber',
    patientBal: 'PatientBal',
    insuranceBal: 'InsuranceBal',
  },
  pageSize: 30,
})());

const statusOptions = [{
  name: 'Active',
  value: 'ExcludeInactivePatients',
  color: 'green',
}, {
  name: 'Inactive',
  value: 'InactivePatients',
  color: 'red',
}, {
  name: 'Do Not Contact',
  value: 'DoNotContact',
  color: 'geekblue',
}];

const FilterCollection = FilterComponents([
  {
    type: 'filterButton',
    filterProps: {
      placeholder: 'Recently Viewed',
      name: 'RecentlyViewed',
      id: 'patients_recently_viewed',
    },
  },
  {
    type: 'filterButton',
    filterProps: {
      placeholder: 'Recently Created',
      name: 'RecentlyCreated',
      id: 'patients_recently_created',
    },
  },
  // {
  //   type: 'filterButton',
  //   filterProps: {
  //     placeholder: 'Exclude Inactive Patients',
  //     name: 'ExcludeInactivePatients',
  //     id: 'patient_filter_inactive',
  //   },
  // },
  {
    type: 'search',
    filterProps: {
      placeholder: 'Search',
      name: 'SearchText',
      id: 'patients_filter_search',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'ExcludeInactivePatients',
      id: 'exclude_inactive_patients',
      label: 'Active',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'InactivePatients',
      id: 'inactive',
      label: 'Inactive',
    },
  },
  {
    type: 'checkbox',
    filterProps: {
      name: 'DoNotContact',
      id: 'do_not_contact',
      label: 'Do Not Contact',
    },
  },
]);

const PatientTable = ({ labels, ...props }) => {
  const [isPagination, setIsPagination] = useState(true);
  const filtersChange = (filters) => {
    if (filters?.RecentlyCreated === 'true' || filters?.RecentlyViewed === 'true') {
      setIsPagination(false);
    } else {
      setIsPagination(true);
    }
  };
  const { navigate: navigateToEditEncounter } = useTabLink({
    to: UiRoutes.editPatientWithTabId,
  });

  const onRowClick = useCallback((row) => {
    const {
      patientId,
      firstName,
      middleName,
      lastName,
    } = row.original;
    navigateToEditEncounter({
      id: patientId, // Dynamic ID
      data: { name: getName(firstName, middleName, lastName) },
      tab: 'summary',
    });
  }, [navigateToEditEncounter]);
  return (
    <CustomPatientTable
      isPagination={isPagination}
      initialFilters={{ ExcludeInactivePatients: true }}
      columns={columns(labels)}
      initialSort={initialSort}
      onRowClick={onRowClick}
      noDataText="Patient not found"
      statusOptions={statusOptions}
      {...props}
    >
      {({
        Component, initialFilters, onFiltersChange, totalCount,
      }) => (
        <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
          {({ onFilterChange, filters }) => (
            <>
              {filtersChange(filters)}
              <div className="table-filters" data-testid="patient-table-filters">
                <FilterCollection onFilterChange={onFilterChange} filters={filters} allowClear />
                <span className="table-count">
                  <span>{`${labels.get('titles.totalCount')}: ${totalCount}`}</span>
                </span>
              </div>
              {Component}
            </>
          )}
        </FilterManager>
      )}
    </CustomPatientTable>
  );
};

export default PatientTable;
