// documentGenerationActions.js

export const DOCUMENT_ACTIONS = {
  SET_STATUS: 'SET_DOCUMENT_STATUS',
  SET_DOWNLOAD_URL: 'SET_DOCUMENT_DOWNLOAD_URL',
  RESET: 'RESET_DOCUMENT',
  ADD_TASK: 'ADD_DOCUMENT_TASK',
  RESET_TASK: 'RESET_DOCUMENT_TASK',
  GET_DOCUMENT_TASK: 'GET_DOCUMENT_TASK',
  HYDRATE_TASKS: 'HYDRATE_DOCUMENT_TASKS',
  POLL_DOCUMENT_STATUS: 'POLL_DOCUMENT_STATUS',
  SET_POLLING: 'SET_DOCUMENT_POLLING',
  SET_DOWNLOADING: 'SET_DOCUMENT_DOWNLOADING',
  DISABLE_PRINT_BUTTON: 'DISABLE_DOCUMENT_PRINT_BUTTON',
  ENABLE_PRINT_BUTTON: 'ENABLE_DOCUMENT_PRINT_BUTTON',
};

export const documentActions = {
  addDocumentTask: (task) => ({
    type: DOCUMENT_ACTIONS.ADD_TASK,
    payload: task,
  }),
  resetDocumentTask: (taskId, documentType) => ({
    type: DOCUMENT_ACTIONS.RESET_TASK,
    taskId,
    documentType,
  }),
  getDocumentTask: (payload, documentType) => ({
    type: DOCUMENT_ACTIONS.GET_DOCUMENT_TASK,
    payload,
    documentType,
  }),
  setDocumentStatus: (status, taskId, documentType) => ({
    type: DOCUMENT_ACTIONS.SET_STATUS,
    payload: {
      status,
      taskId,
      documentType,
    },
  }),
  setDocumentDownloadUrl: (downloadUrl, taskId, documentType) => ({
    type: DOCUMENT_ACTIONS.SET_DOWNLOAD_URL,
    payload: {
      downloadUrl,
      taskId,
      documentType,
    },
  }),
  resetDocument: (documentType) => ({
    type: DOCUMENT_ACTIONS.RESET,
    documentType,
  }),
  hydrateDocumentTasks: (tasks, documentType) => ({
    type: DOCUMENT_ACTIONS.HYDRATE_TASKS,
    payload: tasks,
    documentType,
  }),
  pollDocumentStatus: (taskId, documentType) => ({
    type: DOCUMENT_ACTIONS.POLL_DOCUMENT_STATUS,
    payload: {
      taskId,
      documentType,
    },
  }),
  setDocumentPolling: (taskId, polling, documentType) => ({
    type: DOCUMENT_ACTIONS.SET_POLLING,
    payload: {
      taskId,
      polling,
      documentType,
    },
  }),
  setDocumentDownloading: (taskId, downloading, documentType) => ({
    type: DOCUMENT_ACTIONS.SET_DOWNLOADING,
    payload: {
      taskId,
      downloading,
      documentType,
    },
  }),
  disablePrintButton: (documentType) => ({
    type: DOCUMENT_ACTIONS.DISABLE_PRINT_BUTTON,
    documentType,
  }),
  enablePrintButton: (documentType) => ({
    type: DOCUMENT_ACTIONS.ENABLE_PRINT_BUTTON,
    documentType,
  }),
};

// Creating specific statement actions that use the document actions
export const statementActions = {
  addStatementTask: (task) => documentActions.addDocumentTask({ ...task, documentType: 'statement' }),
  resetStatementTask: (taskId) => documentActions.resetDocumentTask(taskId, 'statement'),
  getStatementTask: (payload) => documentActions.getDocumentTask(payload, 'statement'),
  setStatementStatus: (status, taskId) => documentActions.setDocumentStatus(status, taskId, 'statement'),
  setStatementDownloadUrl: (downloadUrl, taskId) => documentActions.setDocumentDownloadUrl(downloadUrl, taskId, 'statement'),
  resetStatement: () => documentActions.resetDocument('statement'),
  hydrateStatementTasks: (tasks) => documentActions.hydrateDocumentTasks(tasks, 'statement'),
  pollStatementStatus: (taskId) => documentActions.pollDocumentStatus(taskId, 'statement'),
  setStatementPolling: (taskId, polling) => documentActions.setDocumentPolling(taskId, polling, 'statement'),
  setStatementDownloading: (taskId, downloading) => documentActions.setDocumentDownloading(taskId, downloading, 'statement'),
  disablePrintButton: () => documentActions.disablePrintButton('statement'),
  enablePrintButton: () => documentActions.enablePrintButton('statement'),
};

// We can keep the existing SuperBill actions as is, or refactor them to use the document actions
// Here's how we'd refactor them:
export const superBillActions = {
  addSuperBillTask: (task) => documentActions.addDocumentTask({ ...task, documentType: 'superBill' }),
  resetSuperBillTask: (taskId) => documentActions.resetDocumentTask(taskId, 'superBill'),
  getSuperBillTask: (payload) => documentActions.getDocumentTask(payload, 'superBill'),
  setSuperBillStatus: (status, taskId) => documentActions.setDocumentStatus(status, taskId, 'superBill'),
  setSuperBillDownloadUrl: (downloadUrl, taskId) => documentActions.setDocumentDownloadUrl(downloadUrl, taskId, 'superBill'),
  resetSuperBill: () => documentActions.resetDocument('superBill'),
  hydrateSuperBillTasks: (tasks) => documentActions.hydrateDocumentTasks(tasks, 'superBill'),
  pollSuperBillStatus: (taskId) => documentActions.pollDocumentStatus(taskId, 'superBill'),
  setSuperBillPolling: (taskId, polling) => documentActions.setDocumentPolling(taskId, polling, 'superBill'),
  setSuperBillDownloading: (taskId, downloading) => documentActions.setDocumentDownloading(taskId, downloading, 'superBill'),
  setTimePeriod: (period) => ({
    type: 'SET_TIME_PERIOD',
    payload: period,
  }),
  disablePrintButton: () => documentActions.disablePrintButton('superBill'),
  enablePrintButton: () => documentActions.enablePrintButton('superBill'),
};
