export const GET_LIST_DATA_ADVANCED = '@queryApiAdvanced/GET_LIST_DATA_ADVANCED';
export const SET_LIST_DATA_ADVANCED = '@queryApiAdvanced/SET_LIST_DATA_ADVANCED';
export const SET_SORT_ORDER_ADVANCED = '@queryApiAdvanced/SET_SORT_ORDER_ADVANCED';
export const SET_FILTERS_ADVANCED = '@queryApiAdvanced/SET_FILTERS_ADVANCED';
export const SET_PAGE_ADVANCED = '@queryApiAdvanced/SET_PAGE_ADVANCED';
export const SET_LIST_LOADING_ADVANCED = '@queryApiAdvanced/SET_LIST_LOADING_ADVANCED';
export const SET_LIST_ERROR_ADVANCED = '@queryApiAdvanced/SET_LIST_ERROR_ADVANCED';
export const CLEAR_PAGE_CACHE_ADVANCED = '@queryApiAdvanced/CLEAR_PAGE_CACHE_ADVANCED';
export const UPDATE_DATA_WITH_LIST_ID_ADVANCED = '@queryApiAdvanced/UPDATE_DATA_WITH_LIST_ID_ADVANCED';

export const getListDataAdvanced = (url, listId, accessor, variables) => ({
  type: GET_LIST_DATA_ADVANCED,
  url,
  listId,
  accessor,
  variables,
});

export const setListDataAdvanced = (data, listId, reset,
  hasMore, totalCount, totalPages, otherTableData) => ({
  type: SET_LIST_DATA_ADVANCED,
  data,
  listId,
  reset,
  hasMore,
  totalCount,
  totalPages,
  otherTableData,
});

export const setSortOrderAdvanced = (sortBy, listId) => ({
  type: SET_SORT_ORDER_ADVANCED,
  sortBy,
  listId,
});

export const setFiltersAdvanced = (filters, listId) => ({
  type: SET_FILTERS_ADVANCED,
  filters,
  listId,
});

export const setPageAdvanced = (page, listId) => ({
  type: SET_PAGE_ADVANCED,
  page,
  listId,
});

export const setListLoadingAdvanced = (loading, listId) => ({
  type: SET_LIST_LOADING_ADVANCED,
  loading,
  listId,
});

export const setListErrorAdvanced = (error, listId) => ({
  type: SET_LIST_ERROR_ADVANCED,
  error,
  listId,
});

export const updateDataWithListIdAdvanced = (url, id, item) => ({
  type: UPDATE_DATA_WITH_LIST_ID_ADVANCED,
  url,
  id,
  item,
});

export const clearTableDataAdvanced = (listId) => ({
  type: CLEAR_PAGE_CACHE_ADVANCED,
  listId,
});
