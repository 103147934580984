import React, {
  useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import { Route } from 'react-router-dom';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Form, Menu } from 'antd';

import FilterManager from '../../../../../components/FilterManager';
import Button from '../../../../../components/Button';
import WiredSelectWithoutTab from '../../../../../wiredComponents/Select/selectBoxV2';
import Notification from '../../../../../components/Notification';
import Modals from '../../../../../components/Modal';
import Icon from '../../../../../components/Icon';
import CheckBox from '../../../../../components/SmCheckbox';

import {
  listId, UiRoutes, listIds,
} from '../../../../../lib/constants';
import Events from '../../../../../lib/events';
import rights from '../../../../../lib/rights';
import successMessages from '../../../../../lib/successMessages';
import iconNames from '../../../../../lib/iconNames';
import WarningMessages from '../../../../../lib/warningMessages';
import downloadFile from '../../../../../lib/downloadFile';
import { urltoFile, fileNameFromUrl, openUrlInNewTab } from '../../../../../lib/util';
import ErrorMessages from '../../../../../lib/errorMessages';
import ConfirmDialog from '../../../../../components/ConfirmDialog';

import withQuery from '../../../../../hoc/withQuery/withQuery';

import useRights from '../../../../../hooks/useRights';
import useRedirect from '../../../../../hooks/useRedirect';
import useTabLink from '../../../../../hooks/useTabLink';
import useCRUD from '../../../../../hooks/useCRUD';

import { getColumns, getInitialSort } from './utils';

import { apiUrls } from '../../../../../api/constants';

import NewBatchPopup from '../../../Components/NewBatchPopup';
import SuspenseTable from '../WorkListTab/Table';
import SuspenseDetailList from '../SuspenseDetails';
import AssignToModal from './AssignToModal';
import RemittanceQueueFilter from './RemittanceQueueFilter';
import Loader from '../../../../../components/Loader';
import TableWrapper from './TableWrapper';
import SearchBox from '../../../../../components/Search';

const TypeFilters = [
  {
    placeholder: 'Ready',
    name: 'ready',
    id: 'ready',
  },
  {
    placeholder: 'Errors',
    name: 'errors',
    id: 'errors',
  },
  {
    placeholder: 'Unbatched',
    name: 'unBatched',
    id: 'unBatched',
  },
  {
    placeholder: 'Inprogress',
    name: 'inProgress',
    id: 'inProgress',
  },
  {
    placeholder: 'Outbox',
    name: 'outbox',
    id: 'outbox',
  },
  {
    placeholder: 'Posting Inprogress',
    name: 'postingInProgress',
    id: 'postingInProgress',
  },
  {
    placeholder: 'Posted Batch',
    name: 'postedBatch',
    id: 'postedBatch',
  },
];

const StateMap = {
  ready: 'BillingStateReady',
  errors: 'BillingStateError',
  unBatched: 'BillingStateUnBatched',
  outbox: 'BillingStateOutBox',
  inProgress: 'BatchRemittanceInProgress',
  postedBatch: 'BillingStatePosted',
  trash: 'BillingStateTrash',
  postingInProgress: 'PostingInProgress',
};

const isAssignToVisible = (claimFilter, isAssignRemittanceBatchesPermitted) => ((claimFilter === 'ready' || claimFilter === 'errors') && isAssignRemittanceBatchesPermitted);

const getTotalBatchAmount = (data = []) => (
  data?.reduce((accum, batchItem) => accum + batchItem?.totalBatchAmount, 0)
);

const getTotalEnteredAmount = (data = []) => (
  data?.reduce((accum, batchItem) => accum + batchItem?.enteredAmount, 0)
);

const getReadyStageId = (filterStatus) => {
  if (filterStatus?.readyNew && filterStatus?.readyInProgress) return 'BillingStageNew,BillingStageInProgress';
  if (filterStatus?.readyNew) return 'BillingStageNew';
  if (filterStatus?.readyInProgress) return 'BillingStageInProgress';
  return '';
};

const getUnBatchedStageId = (filterStatus) => {
  if (filterStatus?.unBatchedNew && filterStatus?.unBatchedReady && filterStatus?.unBatchedPreviousAR) return 'BillingStageNew,BillingStageReady,PreviousAR';
  if (filterStatus?.unBatchedNew && filterStatus?.unBatchedReady) return 'BillingStageNew,BillingStageReady';
  if (filterStatus?.unBatchedNew && filterStatus?.unBatchedPreviousAR) return 'BillingStageNew,PreviousAR';
  if (filterStatus?.unBatchedReady && filterStatus?.unBatchedPreviousAR) return 'BillingStageReady,PreviousAR';
  if (filterStatus?.unBatchedReady) return 'BillingStageReady';
  if (filterStatus?.unBatchedNew) return 'BillingStageNew';
  if (filterStatus?.unBatchedPreviousAR) return 'PreviousAR';
  return '';
};

const getOutBoxStageId = (filterStatus) => {
  if (filterStatus?.outboxReadyToPost && filterStatus?.outboxSuspenseReadyToPost) return 'ReadyToPost,SuspenseReadyToPost';
  if (filterStatus?.outboxReadyToPost) return 'ReadyToPost';
  if (filterStatus?.outboxSuspenseReadyToPost) return 'SuspenseReadyToPost';
  return '';
};

const getErrorStageId = (filterStatus) => {
  if (filterStatus?.errorsPostError && filterStatus?.errorsPrePostError) return 'PostError,PrePostError';
  if (filterStatus?.errorsPostError) return 'PostError';
  if (filterStatus?.errorsPrePostError) return 'PrePostError';
  return '';
};

const CheckBoxFilters = ({ claimFilter, handleCheckBoxFilter }) => {
  if (claimFilter === 'ready') {
    return (
      <>
        <CheckBox name="New" onChange={handleCheckBoxFilter} isFormItem={false}>New</CheckBox>
        <CheckBox name="InProgress" onChange={handleCheckBoxFilter} isFormItem={false}>InProgress</CheckBox>
      </>
    );
  }
  if (claimFilter === 'unBatched') {
    return (
      <>
        <CheckBox name="New" onChange={handleCheckBoxFilter} isFormItem={false}>New</CheckBox>
        <CheckBox name="Ready" onChange={handleCheckBoxFilter} isFormItem={false}>Ready</CheckBox>
        <CheckBox name="PreviousAR" onChange={handleCheckBoxFilter} isFormItem={false}>PreviousAR</CheckBox>
      </>
    );
  }
  if (claimFilter === 'outbox') {
    return (
      <>
        <CheckBox name="ReadyToPost" onChange={handleCheckBoxFilter} isFormItem={false}>Ready To Post</CheckBox>
        <CheckBox name="SuspenseReadyToPost" onChange={handleCheckBoxFilter} isFormItem={false}>Suspense Ready To Post</CheckBox>
      </>
    );
  }
  if (claimFilter === 'errors') {
    return (
      <>
        <CheckBox name="PostError" onChange={handleCheckBoxFilter} isFormItem={false}>Post Error</CheckBox>
        <CheckBox name="PrePostError" onChange={handleCheckBoxFilter} isFormItem={false}>Pre-Post Error</CheckBox>
      </>
    );
  }
  return <div />;
};

const RemittanceTable = ({ labels, ...props }) => {
  const {
    params, generatePath, replace, push,
  } = useRedirect();

  const [selectedRow, setSelectedRow] = useState([]);
  const [isAssignToModalVisible, setAssignToModalVisibility] = useState(false);
  const [businessEntityId, setBusinessEntityId] = useState(null);
  const [worklistSearch, setWorklistSearch] = useState();
  const [remittanceSearch, setRemittanceSearch] = useState();
  const [unBatchQueueActiveAction, setUnBatchQueueActiveAction] = useState();
  const [filterStatus, setFilterStatus] = useState({});
  const [initialDataFilters, setInitialDataFilters] = useState({});
  const [batchType, setBatchType] = useState('');
  const tableRef = useRef();
  const [isBatchEntryPermitted, isAssignRemittanceBatchesPermitted,
    isCreateBatchPermitted,
    batchDeletePermitted, editBatchPermitted, postRemittancePermitted] = useRights([
    rights.access_to_batch_entry,
    rights.assign_remittance_batches,
    rights.create_batch,
    rights.can_delete_remittances, rights.can_edit_batch,
    rights.can_post_remittance_batches,
  ]);

  const [form] = Form.useForm();

  const { claimFilter } = params;
  const [activeTab, setActiveTab] = useState(claimFilter || 'ready');

  const [
    assignToResponse,,
    assignToLoading,
    assignTo,
    clearAssignTo,
  ] = useCRUD({
    id: listIds.REMITTANCE_ASSIGN_TO,
    url: apiUrls.REMITTANCE_ASSIGN_TO,
    type: 'create',
  });

  const [
    unBatchQueueActionResponse,
    unBatchQueueActionError,
    unBatchQueueActionResLoading,
    unBatchQueueAction,
    clearunBatchQueueActionRes,
  ] = useCRUD({
    id: listIds.REMITTANCE_UNBATCH_QUEUE_ACTIONS,
    url: apiUrls.REMITTANCE_UNBATCH_QUEUE_ACTIONS,
    type: 'create',
    shouldClearError: false,
  });

  const [
    postBatchResponse,,,
    postBatch,
    clearPostBatchRes,
  ] = useCRUD({
    id: listIds.REMITTANCE_PATIENT_FINANCIAL_POST_BATCH,
    url: apiUrls.REMITTANCE_PATIENT_FINANCIAL_NEW_POST_BATCH,
    type: 'update',
    shouldClearError: false,
  });

  const [
    ediToHtmlFile,,
    ediToHtmlFileLoading,
    getEdiToHtmlFile,
    clearEdiToHtmlFileRes,
  ] = useCRUD({
    id: listIds.REMITTANCE_BATCH_EDIFILETOHTML,
    url: apiUrls.REMITTANCE_BATCH_EDIFILETOHTML,
    type: 'read',
  });

  const [
    deleteBatchResponse,,
    deleteBatchLoading,
    deleteBatch,
    clearDeleteBatchResponse,
  ] = useCRUD({
    id: 'delete-remittance-batch',
    url: apiUrls.DELETE_REMITTANCE_BATCH,
    type: 'delete',
  });

  const [
    deleteBatchResponseElectronic,,
    deleteBatchLoadingElectronic,
    deleteBatchElectronic,
    clearDeleteBatchResponseElectronic,
  ] = useCRUD({
    id: 'delete-remittance-batch-electronic',
    url: apiUrls.DELETE_REMITTANCE_BATCH_ELECTRONIC,
    type: 'update',
  });

  const onChangeStatus = useCallback((e) => {
    const { target: { name } } = e;
    setActiveTab(name);
    tableRef?.current?.resetCheckBoxes?.(false);
    setFilterStatus({});
    setRemittanceSearch('');
    replace(generatePath(UiRoutes.remittanceClaimsFilters, { ...params, claimFilter: name }));
  }, [generatePath, params, replace]);

  const onWorkListSelect = useCallback(() => {
    setActiveTab();
    replace(generatePath(UiRoutes.remittanceClaimsWorkList, { ...params, claimFilter: 'worklist', parentTab: 'suspense' }));
  }, [generatePath, params, replace]);

  const onTrashSelect = useCallback(() => {
    tableRef?.current?.resetCheckBoxes?.(false);
    replace(generatePath(UiRoutes.remittanceClaimsFilters, { ...params, claimFilter: 'trash' }));
  }, [generatePath, params, replace]);

  const handleOnBack = useCallback(() => {
    setActiveTab('unBatched');
    replace(generatePath(UiRoutes.remittanceClaimsFilters, { ...params, claimFilter: 'unBatched' }));
  }, [generatePath, params, replace]);

  const { navigate } = useTabLink({
    to: UiRoutes.claimsRemittance,
    id: UiRoutes.claimsWithRemittanceTabs,
    params,
  });

  const onRowClick = useCallback(({ original: { remittanceBatchId, batchNumber } }) => {
    if (activeTab !== 'unBatched' && activeTab !== 'inProgress' && activeTab !== 'postingInProgress' && (isBatchEntryPermitted || editBatchPermitted)) {
      navigate({
        remittanceId: remittanceBatchId, tab: 'remittance', claimFilter, data: { name: `Remittance: ${batchNumber}` },
      });
    }
  }, [activeTab, isBatchEntryPermitted, editBatchPermitted, navigate, claimFilter]);

  const onClickNewBatch = useCallback(() => {
    push(generatePath(UiRoutes.remittanceNewBatchPopup, { ...params, claimFilter }));
  }, [claimFilter, generatePath, params, push]);

  const toggleAssignToModal = useCallback(() => (selectedRow?.length ? setAssignToModalVisibility(
    !isAssignToModalVisible,
  ) : Notification({ message: 'Please select at least one row' })), [isAssignToModalVisible, selectedRow]);

  const handleAssignTo = useCallback(() => {
    const formData = form.getFieldValue();
    if (!formData?.userId) {
      Notification({ message: 'Please select user' });
      return;
    }
    assignTo({
      data: {
        ...formData,
        remittanceBatchIds: selectedRow?.map((item) => item?.original?.remittanceBatchId),
      },
    });
  }, [assignTo, form, selectedRow]);

  const handleUnBatchQueueActions = useCallback((e) => {
    const { target: { name } } = e;
    if (selectedRow?.length > 10 && name === 'Download') {
      Notification({ message: ErrorMessages.DOWNLOAD_EDI_MAX_CLAIM_REACHED });
      return null;
    }
    setUnBatchQueueActiveAction(name);
    unBatchQueueAction({
      data: {
        actionType: name,
        remittanceBatchIds: selectedRow?.map((item) => item?.original?.remittanceBatchId),
      },
    });
    return false;
  }, [selectedRow, unBatchQueueAction]);

  const handlePostBatch = useCallback(() => {
    Notification({ message: successMessages.POSTING_BATCH_IS_IN_PROGRESS, success: true });
    postBatch({
      remittanceBatchIds: selectedRow?.map((item) => item?.original?.remittanceBatchId),
    });
  }, [postBatch, selectedRow]);

  const handleTrashActions = useCallback((original, actionType) => () => {
    unBatchQueueAction({
      data: {
        actionType,
        remittanceBatchIds: original?.remittanceBatchId ? [original?.remittanceBatchId] : [],
      },
    });
  }, [unBatchQueueAction]);

  const handleEDIRightClickActions = useCallback((original, actionType) => () => {
    setUnBatchQueueActiveAction(actionType);
    setBatchType(original?.batchType);
    unBatchQueueAction({
      data: {
        actionType,
        remittanceBatchIds: original?.remittanceBatchId ? [original?.remittanceBatchId] : [],
      },
    });
  }, [unBatchQueueAction]);

  const handleEdiToHtml = useCallback((original) => () => {
    getEdiToHtmlFile({ batchId: original?.remittanceBatchId });
  }, [getEdiToHtmlFile]);

  const handleDeleteRemittanceBatch = useCallback((remittanceBatchId, isElectronic) => () => {
    if (isElectronic) {
      ConfirmDialog({
        onOk: (close) => {
          deleteBatchElectronic({
            remittanceBatchIds: remittanceBatchId,
            isUpdatingTransactionStatus: true,
          });
          close();
        },
        okText: 'Ok',
        title: 'Warning',
        content: WarningMessages.WANT_TO_MOVE,
        icon: <Icon name={iconNames.exclamationCircleOutlined} />,
      })();
    } else {
      ConfirmDialog({
        onOk: (close) => {
          deleteBatch({}, `/${remittanceBatchId}`);
          close();
        },
        okText: 'Ok',
        title: 'Warning',
        content: WarningMessages.WANT_TO_DELETE,
        icon: <Icon name={iconNames.exclamationCircleOutlined} />,
      })();
    }
  }, [deleteBatch, deleteBatchElectronic]);

  const TrashQueueRightClickMenu = useCallback(({ original }) => () => (
    <Menu className="profile-menu-container">
      <Menu.Item key="restore remittance" onClick={handleTrashActions(original, 'Restore')}>
        <span>Restore remittance</span>
      </Menu.Item>
      <Menu.Item key="delete remittance" onClick={handleTrashActions(original, 'Delete')}>
        <span>Delete Remittance</span>
      </Menu.Item>
    </Menu>
  ), [handleTrashActions]);

  const UnBatchQueueRightClickMenu = useCallback(({ original }) => () => {
    if (original?.batchType?.toLowerCase() === 'electronic') {
      return (
        <Menu className="profile-menu-container">
          <Menu.Item key="restore remittance" onClick={handleEDIRightClickActions(original, 'Download')}>
            <span>Download EDI</span>
          </Menu.Item>
          <Menu.Item key="restore remittance" onClick={handleEdiToHtml(original)}>
            <span>View Preview</span>
          </Menu.Item>
        </Menu>
      );
    }

    if (original?.batchType?.toLowerCase() === 'manual') {
      return (
        <Menu className="profile-menu-container">
          <Menu.Item key="restore remittance" onClick={handleEDIRightClickActions(original, 'Download')}>
            <span>Download EOB</span>
          </Menu.Item>
          <Menu.Item key="restore remittance" onClick={handleEDIRightClickActions(original, 'Download')}>
            <span>View Preview</span>
          </Menu.Item>
        </Menu>
      );
    }
    return <Menu />;
  },

  [handleEDIRightClickActions, handleEdiToHtml]);

  const InprogressRightClickMenu = useCallback(({ original }) => () => {
    if (original?.batchType?.toLowerCase() === 'electronic') {
      return (
        <Menu className="profile-menu-container">
          <Menu.Item key="restore remittance" onClick={handleEDIRightClickActions(original, 'Download')}>
            <span>Download EDI</span>
          </Menu.Item>
        </Menu>
      );
    }
    return <Menu />;
  },

  [handleEDIRightClickActions]);

  const DownloadEdiRightClickMenu = useCallback(({ original }) => () => {
    if (original?.batchType?.toLowerCase() === 'electronic') {
      return (
        <Menu className="profile-menu-container">
          <Menu.Item key="restore remittance" onClick={handleEDIRightClickActions(original, 'Download')}>
            <span>Download EDI</span>
          </Menu.Item>
          <Menu.Item key="restore remittance" onClick={handleEdiToHtml(original)}>
            <span>View Preview</span>
          </Menu.Item>
        </Menu>
      );
    }

    if (original?.batchType?.toLowerCase() === 'manual') {
      return (
        <Menu className="profile-menu-container">
          <Menu.Item key="restore remittance" onClick={handleEDIRightClickActions(original, 'Download')}>
            <span>Download EOB</span>
          </Menu.Item>
          <Menu.Item key="restore remittance" onClick={handleEDIRightClickActions(original, 'Download')}>
            <span>View Preview</span>
          </Menu.Item>
        </Menu>
      );
    }

    return <Menu />;
  }, [handleEDIRightClickActions, handleEdiToHtml]);

  const rightClickMenus = {
    trash: TrashQueueRightClickMenu,
    unBatched: UnBatchQueueRightClickMenu,
    inProgress: InprogressRightClickMenu,
    errors: DownloadEdiRightClickMenu,
    outbox: DownloadEdiRightClickMenu,
    postedBatch: DownloadEdiRightClickMenu,
    ready: DownloadEdiRightClickMenu,
  };

  const rightClickMenu = rightClickMenus[claimFilter];

  useEffect(() => {
    if (assignToResponse && !Array.isArray(assignToResponse)) {
      Notification({ message: 'Assigned user has been changed successfully', success: true });
      setAssignToModalVisibility(false);
      clearAssignTo(true);
      form.resetFields();
      tableRef?.current?.resetCheckBoxes?.(false);
      Events.trigger('refetchRemittanceTable');
    }
  }, [assignToResponse]);

  const downloadEDIFile = useCallback(async (fileUrls = []) => {
    if (fileUrls.length === 0) {
      return Notification({ message: 'No EOB/EDI files present', success: false });
    }
    for (let i = 0; i < fileUrls.length; i += 1) {
      if (fileUrls[i] === null) {
        Notification({ message: 'No EOB/EDI files present', success: false });
      } else if (batchType === 'Electronic') {
        const fileName = fileNameFromUrl(fileUrls[i]);
        // eslint-disable-next-line no-await-in-loop
        const file = await urltoFile(fileUrls[i], fileName);
        downloadFile(file, fileName);
        Notification({ message: 'Files has been downloaded successfully', success: true });
      } else {
        openUrlInNewTab(fileUrls[i]);
      }
    }
    setBatchType('');
    return null;
  }, [batchType]);

  useEffect(() => {
    if (unBatchQueueActionResponse) {
      if (!Array.isArray(unBatchQueueActionResponse) && unBatchQueueActiveAction !== 'Download') {
        Notification({ message: unBatchQueueActionResponse?.message, success: true });
      } else if (Array.isArray(unBatchQueueActionResponse) && unBatchQueueActiveAction === 'Download') {
        downloadEDIFile(unBatchQueueActionResponse);
      }
      tableRef?.current?.resetCheckBoxes?.(false);
      setUnBatchQueueActiveAction();
      clearunBatchQueueActionRes();
      Events.trigger('refetchRemittanceTable');
    }
  }, [clearunBatchQueueActionRes, unBatchQueueActionResponse, unBatchQueueActiveAction]);

  useEffect(() => {
    if (unBatchQueueActionError) {
      if (unBatchQueueActionError?.includes('BatchIds with redundant check Number and Amount')) {
        ConfirmDialog({
          onOk: (close) => {
            unBatchQueueAction({
              data: {
                actionType: 'BatchRemittance',
                remittanceBatchIds: selectedRow?.map((item) => item?.original?.remittanceBatchId),
                SkipCheckDuplication: true,
              },
            });
            close();
          },
          onCancel: () => {
            clearunBatchQueueActionRes();
          },
          okText: 'Ok',
          title: 'Warning',
          content: WarningMessages.PAYMENT_WITH_SAME_CHECK_ALREADY_POSTED,
          icon: <Icon name={iconNames.exclamationCircleOutlined} />,
        })();
      } else {
        Notification({ message: unBatchQueueActionError });
        clearunBatchQueueActionRes();
      }
    }
  }, [clearunBatchQueueActionRes, labels, selectedRow,
    unBatchQueueAction, unBatchQueueActionError]);

  useEffect(() => {
    if (postBatchResponse && !Array.isArray(postBatchResponse)) {
      tableRef?.current?.resetCheckBoxes?.(false);
      clearPostBatchRes(true);
      Events.trigger('refetchRemittanceTable');
    }
  }, [clearPostBatchRes, postBatchResponse]);

  useEffect(() => {
    if (deleteBatchResponse && !Array.isArray(deleteBatchResponse)) {
      Notification({
        message: successMessages.REMITTANCE_BATCH_DELETED_SUCCESSFULLY, success: true,
      });
      clearDeleteBatchResponse(true);
      Events.trigger('refetchRemittanceTable');
    }
  }, [clearDeleteBatchResponse, deleteBatchResponse]);

  // Akshat Garg - 10/7/24 - adding moving the electronic batch to unbatchQueue
  useEffect(() => {
    if (deleteBatchResponseElectronic && !Array.isArray(deleteBatchResponseElectronic)) {
      Notification({
        message: successMessages.REMITTANCE_BATCH_MOVED_SUCCESSFULLY, success: true,
      });
      clearDeleteBatchResponseElectronic(true);
      Events.trigger('refetchRemittanceTable');
    }
  }, [clearDeleteBatchResponseElectronic, deleteBatchResponseElectronic]);

  useEffect(() => {
    if (ediToHtmlFile && !Array.isArray(ediToHtmlFile)) {
      const w = window.open('');
      w?.document?.write(ediToHtmlFile);
      w?.document?.close();
      clearEdiToHtmlFileRes(true);
    }
  }, [clearEdiToHtmlFileRes, ediToHtmlFile]);

  const CustomRemittanceTable = useMemo(() => withQuery({
    url: apiUrls.GET_REMITTANCE_LIST,
    listId: listId.REMITTANCE + claimFilter,
  })(), [claimFilter]);

  const debouncedSearch = useMemo(() => debounce(
    setWorklistSearch, 500,
  ), [setWorklistSearch]);

  const handleInputChange = useCallback(({ target: { value } }) => {
    debouncedSearch(value);
  }, [debouncedSearch]);

  const debouncedRemittanceSearch = useMemo(() => debounce(
    setRemittanceSearch, 500,
  ), []);

  const handleRemittanceSearch = useCallback(({ target: { value } }) => {
    debouncedRemittanceSearch(value);
  }, [debouncedRemittanceSearch]);

  const remittanceQueueStageId = useMemo(() => {
    if (claimFilter === 'ready') {
      return getReadyStageId(filterStatus);
    }
    if (claimFilter === 'unBatched') {
      return getUnBatchedStageId(filterStatus);
    }
    if (claimFilter === 'outbox') {
      return getOutBoxStageId(filterStatus);
    }
    if (claimFilter === 'errors') {
      return getErrorStageId(filterStatus);
    }
    return '';
  }, [claimFilter, filterStatus]);

  const handleCheckBoxFilter = useCallback(({ target: { name, checked } }) => {
    setFilterStatus({ ...filterStatus, [`${claimFilter}${name}`]: checked });
  }, [claimFilter, filterStatus]);

  const handleAssignToChange = useCallback(() => {
    form.resetFields();
    toggleAssignToModal();
  }, [form, toggleAssignToModal]);

  useEffect(() => {
    if (claimFilter) {
      setInitialDataFilters({
        State: StateMap[claimFilter || 'ready'],
        Stage: remittanceQueueStageId || '',
      });
    }
  }, [remittanceQueueStageId, claimFilter]);

  const getPlaceholder = useCallback(() => {
    if (claimFilter === 'ready' || claimFilter === 'errors') {
      return 'Search by Batch Number, Assigned To, Total Amount';
    } if (claimFilter === 'unBatched') {
      return 'Search by Batch Number, Payer Name, Check Number, Amount';
    } if (claimFilter === 'inProgress') {
      return 'Search by Batch Number, Amount';
    } if (claimFilter === 'outbox') {
      return 'Search by Batch Number, Assigned To, Amount';
    } if (claimFilter === 'postingInProgress') {
      return 'Search by Batch Number, Amount';
    } if (claimFilter === 'postedBatch') {
      return 'Search by Batch Number, Batch Amount';
    }
    return 'Search';
  }, [claimFilter]);

  return (
    <>
      <Route exact path={[UiRoutes.collectionClaimsFilters, UiRoutes.remittanceClaimsWorkList]}>
        {(assignToLoading || unBatchQueueActionResLoading || ediToHtmlFileLoading
        || deleteBatchLoading || deleteBatchLoadingElectronic) && <Loader />}
        <Modals
          visible={isAssignToModalVisible}
          toggleModal={toggleAssignToModal}
          footer={[
            <div className="group-btns mng-footer-btn" key="footer">
              <Button className="btn min-wt-86" onClick={toggleAssignToModal}>{labels.get('buttons.cancel')}</Button>
              <Button className="btn btn-success min-wt-86 inline" onClick={handleAssignTo} data-testid="assign-to-modal-save">
                Save
              </Button>
            </div>,
          ]}
        >
          <div className="addition-header">
            <div className="lookup sprite-img-before" data-testid="remittance-modal-header">
              <p className="">Assign To</p>
            </div>
          </div>
          <Form form={form}>
            <AssignToModal
              toggleModal={toggleAssignToModal}
              selectedRow={selectedRow}
            />
          </Form>
        </Modals>
        <div className="heading-area flex">
          { TypeFilters.map((value, index) => (
            <button
              type="button"
              name={value.name}
              className={classNames('btn btn-primary sm-btn filter-button', { active: claimFilter === value.name || activeTab === value.name })}
              key={index + 1}
              onClick={onChangeStatus}
            >
              {value.placeholder}
            </button>
          ))}
          <button
            type="button"
            className={classNames('btn btn-primary sm-btn filter-button', { active: claimFilter === 'worklist' })}
            onClick={onWorkListSelect}
          >
            WORKLIST
          </button>
        </div>
      </Route>
      {(claimFilter !== 'worklist') && (
      <CustomRemittanceTable
        onRowClick={onRowClick}
        filters={{
          ...initialDataFilters,
          searchText: remittanceSearch,
        }}
        initialSort={getInitialSort(claimFilter)}
        columns={getColumns(claimFilter)(labels)}
        noDataText="No Data Found"
        showRowSelection={claimFilter !== 'postedBatch' && claimFilter !== 'trash' && claimFilter !== 'inProgress' && claimFilter !== 'postingInProgress'}
        checkedRowIds={selectedRow}
        setSelectedRow={setSelectedRow}
        rightClickMenu={rightClickMenu}
        rowSelectionRef={tableRef}
        handleDeleteRemittanceBatch={handleDeleteRemittanceBatch}
        batchDeletePermitted={batchDeletePermitted}
        skipInitialFetch
        {...props}
      >
        {({
          Component, initialFilters, onFiltersChange, reFetch, data,
        }) => (
          <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
            {({ onFilterChange, filters }) => (
              <>
                {claimFilter === 'trash' && (
                <div className="back-pannel mr-top-10 mr-bt-10">
                  <div className="pannel-heading">
                    <p className="back-arrow" onClick={handleOnBack} role="presentation">
                      {labels.get('buttons.goBack')}
                    </p>
                  </div>
                </div>
                )}
                <div className="remittance-search-filter-wrap-all">
                  <div className="table-filters" data-testid="remittance-search-filter">
                    <div className="remittance-search-filter-wrap">
                      <div className="search-checkbox-wrap">
                        <SearchBox
                          id="contract_filter_search"
                          placeholder={getPlaceholder()}
                          name="searchText"
                          style={{ width: '450px', height: '28px' }}
                          className="mr-rt-8 mr-bt-8 mng-custom-search"
                          onChange={handleRemittanceSearch}
                          allowClear
                        />
                        <div className="filter-btm-section">
                          <div className="flex mr-top-4">
                            <CheckBoxFilters
                              claimFilter={claimFilter}
                              handleCheckBoxFilter={handleCheckBoxFilter}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className={`filter-and-btn-wrap ${(activeTab === 'outbox') ? 'outbox-filter' : ''}`}>
                    <div className="group-btns align-content-and-button">
                      {isAssignToVisible(claimFilter, isAssignRemittanceBatchesPermitted)
                      && (
                        <Button className="btn btn-success sm-btn" id="remittance_add" data-testid="remittance-assignBatch" onClick={handleAssignToChange}>
                          Assign To
                        </Button>
                      )}
                      {
                      (isCreateBatchPermitted && activeTab === 'ready')
                      && (
                      <Button className="btn btn-success sm-btn" id="remittance_add" data-testid="new-batch-button" onClick={onClickNewBatch}>
                        {labels.get('buttons.newBatch')}
                      </Button>
                      )
                    }
                      {
                      (activeTab === 'unBatched' && claimFilter !== 'trash') && (
                        <>
                          <Button
                            className={classNames('btn btn-success sm-btn', {
                              'cursor-not-allowed': !selectedRow?.length,
                            })}
                            disabled={!selectedRow?.length}
                            name="BatchRemittance"
                            onClick={handleUnBatchQueueActions}
                          >
                            {labels.get('buttons.batchRemittance')}
                          </Button>
                          <Button
                            className={classNames('btn btn-success sm-btn', {
                              'cursor-not-allowed': !selectedRow?.length,
                            })}
                            disabled={!selectedRow?.length}
                            name="Download"
                            onClick={handleUnBatchQueueActions}
                          >
                            {labels.get('buttons.downloadEDI')}
                          </Button>
                          <Button
                            className={classNames('btn btn-success sm-btn', {
                              'cursor-not-allowed': !selectedRow?.length,
                            })}
                            disabled={!selectedRow?.length}
                            name="Trash"
                            onClick={handleUnBatchQueueActions}
                          >
                            {labels.get('buttons.deleteRemittance')}
                          </Button>
                          <Button
                            className="btn sm-btn"
                            name="trash"
                            onClick={onTrashSelect}
                          >
                            <Icon
                              name={iconNames.deleteIcon}
                              color="red"
                            />
                          </Button>
                        </>
                      )
                    }
                      {
                      (activeTab === 'outbox' && postRemittancePermitted) && (
                        <Button
                          className={classNames('btn btn-success sm-btn', {
                            'cursor-not-allowed': !selectedRow?.length,
                          })}
                          disabled={!selectedRow?.length}
                          name="PostBatch"
                          onClick={handlePostBatch}
                        >
                            {labels.get('buttons.postBatch')}
                        </Button>
                      )
                    }
                    </div>
                  </div>
                </div>
                <div className="filter-text-wrap">
                  <RemittanceQueueFilter
                    onFilterChange={onFilterChange}
                    filters={filters}
                    activeTab={activeTab}
                  />
                  {(claimFilter === 'ready' || claimFilter === 'errors') && (
                  <span className="total-values">
                    <span className="key">
                      {`${labels.get('titles.totalBatchesValue')}`}
                      :
                      {' '}
                      <span className="value">{`${(getTotalBatchAmount(data) || 0).toFixed(2)}`}</span>
                    </span>
                    <span className="mr-lt-10 key">
                      {`${labels.get('titles.enteredValue')}`}
                      :
                      {' '}
                      <span className="value">{`${(getTotalEnteredAmount(data)).toFixed(2)}`}</span>
                    </span>
                  </span>
                  )}
                </div>
                <TableWrapper Component={Component} reFetch={reFetch} />
              </>
            )}
          </FilterManager>
        )}
      </CustomRemittanceTable>
      )}
      <Route exact path={UiRoutes.remittanceNewBatchPopup}>
        <NewBatchPopup isNewbatch />
      </Route>
      <Route exact path={UiRoutes.remittanceClaimsWorkList}>
        <div className="worklist-tab-wrap">
          <SearchBox
            placeholder="Search"
            name="search"
            style={{ width: '450px', height: '28px' }}
            className="mr-lt-8 mr-bt-8 mng-custom-search"
            onChange={handleInputChange}
            allowClear
          />
          <WiredSelectWithoutTab
            required
            id="businessEntity"
            label={labels.get('labels.businessEntity')}
            url={apiUrls.GET_BUSINESS_ENTITY}
            name="businessEntity"
            nameAccessor="entityName"
            onChange={setBusinessEntityId}
            valueAccessor="businessEntityId"
            selectProps={{
              style: { minWidth: 130 },
              showArrow: true,
              dropdownMatchSelectWidth: 200,
            }}
          />
        </div>
        <SuspenseTable
          labels={labels}
          businessEntityId={businessEntityId}
          searchText={worklistSearch}
        />
      </Route>
      <Route
        exact
        path={[UiRoutes.remittanceClaimsWorkListEditSuspense,
          UiRoutes.remittanceClaimsWorkListEditSuspenseAssignItem]}
      >
        <SuspenseDetailList
          labels={labels}
          businessEntityId={businessEntityId}
          searchText={worklistSearch}
          setBusinessEntityId={setBusinessEntityId}
          setWorklistSearch={setWorklistSearch}
          {...props}
        />
      </Route>
    </>
  );
};
export default RemittanceTable;
