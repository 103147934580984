import React, { useEffect } from 'react';
import { difference, isEmpty, set } from 'lodash';
import Button from '../../../../../../components/Button';
import SuccessMessages from '../../../../../../lib/successMessages';
import Notification from '../../../../../../components/Notification';
import { apiUrls } from '../../../../../../api/constants';
import useCRUD from '../../../../../../hooks/useCRUD';
import Loader from '../../../../../../components/Loader';

const MoveToOutbox = ({
  selectedClaims,
  tableFilters,
  filtersData,
  isAllClaimCheckSelected,
  notProcessingClaims,
  tableRef,
  reFetchClaims,
}) => {
  const [
    moveToOutboxResponse,,
    movieToOutBoxLoading,
    moveToOutbox,
    clearMoveToOutbox,
  ] = useCRUD({
    id: apiUrls.MOVE_WAYSTAR_DISCARDED_TO_OUTBOX,
    url: apiUrls.MOVE_WAYSTAR_DISCARDED_TO_OUTBOX,
    type: 'update',
  });

  useEffect(() => {
    if (moveToOutboxResponse) {
      Notification({
        message: SuccessMessages.CLAIM_MOVED_TO_OUTBOX_SUCCESS,
        success: true,
      });
      clearMoveToOutbox();
      reFetchClaims();
      tableRef?.current?.resetCheckBoxes?.(false);
    }
  }, [clearMoveToOutbox, moveToOutboxResponse, reFetchClaims]);

  const handleMoveToOutbox = () => {
    const claimIds = selectedClaims.map(({ original }) => {
      const { claimId } = original;
      return claimId;
    });

    const requestParams = {
      deliveryMethodId: tableFilters?.deliveryMethodId,
      FromDate: filtersData.FromDate,
      ToDate: filtersData.ToDate,
      SearchText: tableFilters?.SearchText,
    };
    if (isEmpty(claimIds) || isAllClaimCheckSelected) {
      let notProcessing = [];
      if (!isEmpty(claimIds)) {
        const notProcessingClaimsIds = notProcessingClaims.map(
          ({ original }) => {
            const { claimId } = original;
            return claimId;
          },
        );
        notProcessing = difference(notProcessingClaimsIds, claimIds);
      }
      set(requestParams, 'SelectAllClaims', true);
      set(requestParams, 'NotSelectedClaims', notProcessing.join(','));
    } else {
      set(requestParams, 'claimIds', claimIds.join(','));
    }
    moveToOutbox(requestParams);
  };
  return (
    <>
      {movieToOutBoxLoading && <Loader />}
      <Button
        className="btn btn-success sm-btn"
        id="claims_add"
        data-testid="override-claims"
        disabled={isEmpty(selectedClaims)}
        onClick={handleMoveToOutbox}
      >
        Move to Outbox
      </Button>
    </>
  );
};

export default MoveToOutbox;
