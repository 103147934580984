import React, { useCallback, useEffect, useMemo } from 'react';
import set from 'lodash/set';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import useCRUD from '../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../hooks/useRedirect';
import { isValueUptoTwoDecimalPlaces } from '../../../../../../lib/util';

import { apiUrls } from '../../../../../../api/constants';
import { listIds } from '../../../../../../lib/constants';

import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Form/Input';

import '../../../../financial.scss';

const TableHeader = ({ labels }) => (
  <thead>
    <tr>
      <th>{labels.get('tableColumns.dos')}</th>
      <th>{labels.get('tableColumns.provider')}</th>
      <th>{labels.get('tableColumns.description')}</th>
      <th>{labels.get('tableColumns.responsibility')}</th>
      <th className="text-align-right">{labels.get('tableColumns.amount')}</th>
      <th className="text-align-right">{labels.get('tableColumns.balance')}</th>
      <th className="text-align-right">{labels.get('tableColumns.applied')}</th>
    </tr>
  </thead>
);

const TableFooter = ({ amountTotal, balanceTotal, appliedTotal }) => (
  <tr>
    <td>Total</td>
    <td />
    <td />
    <td />
    <td className="text-align-right">{amountTotal.toFixed(2)}</td>
    <td className="text-align-right">{balanceTotal.toFixed(2)}</td>
    <td className="text-align-right">{appliedTotal.toFixed(2)}</td>
  </tr>
);

const TableLineItem = ({
  item, handleLineItemData, index, lineItemData,
}) => {
  const handleTextBoxChange = useCallback((e) => {
    const { target: { value } } = e;
    if (isValueUptoTwoDecimalPlaces(value, /^-?[0-9]+(?:\.\d{0,2})?$/) || !value) {
      handleLineItemData(index, e);
    }
  }, [handleLineItemData, index]);

  return (
    <tr key={item.id}>
      <td className="text-align-left">{item?.dos}</td>
      <td className="text-align-left">{item?.provider}</td>
      <td className="text-align-left">{item?.cptCodeDescription}</td>
      <td className="text-align-left">{item?.responsibility}</td>
      <td className="text-align-right">{parseFloat(item?.amount || 0).toFixed(2)}</td>
      <td className="text-align-right">{parseFloat(item?.balance || 0).toFixed(2)}</td>
      <td>
        <Input
          isFormItem={false}
          numberOnly
          decimalPlaces={2}
          maxValueLength={11}
          labelSpan={0}
          inputSpan={24}
          value={lineItemData?.[index]?.applied}
          defaultValue="0.00"
          onChange={handleTextBoxChange}
          name="applied"
          dataTestId={`applied-${index}`}
        />
      </td>
    </tr>
  );
};

const TableBody = ({
  lineItemData, handleLineItemData, amountTotal, balanceTotal, appliedTotal,
}) => {
  if (lineItemData?.length) {
    return (
      <tbody>
        {lineItemData?.map((item, index) => (
          <TableLineItem
            key={`line-item-${index}`}
            item={item}
            handleLineItemData={handleLineItemData}
            index={index}
            lineItemData={lineItemData}
          />
        ))}
        <TableFooter
          amountTotal={amountTotal}
          balanceTotal={balanceTotal}
          appliedTotal={appliedTotal}
        />
      </tbody>
    );
  }
  return (
    <tbody>
      <tr>
        <td colSpan={7} className="custom-no-data-text">
          No Data Found
        </td>
      </tr>
    </tbody>
  );
};

const getLineItemData = ({ lineItemData, amount }) => {
  let fieldsAmount = amount;
  return (
   lineItemData?.map((item) => {
     const lineItemBalance = item?.balance ? parseFloat(item?.balance) : 0;
     if (lineItemBalance > fieldsAmount) {
       const applied = fieldsAmount;
       fieldsAmount = 0;
       return ({
         ...item,
         applied,
       });
     }
     if (lineItemBalance <= 0) {
       return ({
         ...item,
         applied: 0,
       });
     }
     fieldsAmount -= lineItemBalance;
     return ({
       ...item,
       applied: lineItemBalance,
     });
   })
  );
};

const LineItemsTable = ({
  labels,
  lineItemData,
  setLineItemData,
  form,
}) => {
  const {
    params: { id: patientId },
  } = useRedirect();

  const [
    claimDetails,,,
    getClaimDetails,
  ] = useCRUD({
    id: listIds.CLAIM_DETAILS,
    url: apiUrls.FINANCIAL_LINE_ITEMS,
    type: 'read',
  });

  useEffect(() => {
    if (!lineItemData?.length) {
      getClaimDetails({
        PatientId: patientId,
        IsPatientResponsibility: true,
        isCharge: true,
        pageSize: 10000,
      });
    }
  }, [patientId]);

  useEffect(() => {
    if (claimDetails?.result?.length && !lineItemData?.length) {
      const formattedData = claimDetails?.result
        .map((item) => ({
          financeId: item?.financeId,
          cptCodeDescription: item?.description,
          amount: item?.amount,
          balance: item?.balance,
          dos: moment(item?.effectiveDate).format('MM-DD-YYYY'),
          provider: item?.providerName,
          responsibility: item?.isPatientResponsibility ? 'Patient' : 'Insurance',
          patientId: item?.patientId,
        }))
        .filter(item => item?.cptCodeDescription !== 'Credit Payment');
      
      setLineItemData(formattedData);
    }
  }, [claimDetails]);

  const handleLineItemData = useCallback((index, { target: { value, name } }) => {
    const clonedLineItemData = cloneDeep(lineItemData);
    setLineItemData(set(clonedLineItemData, `${index}.${name}`, value));
  }, [lineItemData, setLineItemData]);

  const filteredLineItems = useMemo(() => {
    return lineItemData?.filter(item => item?.cptCodeDescription !== 'Credit Payment') || [];
  }, [lineItemData]);

  const handleChronologically = useCallback(() => {
    const { amount } = form.getFieldsValue();
    if (amount === undefined) {
      form.setFields([{
        name: 'amount',
        value: undefined,
        errors: ['Amount is required'],
      }]);
    } else {
      const updatedData = getLineItemData({ lineItemData: filteredLineItems, amount });
      setLineItemData(updatedData);
    }
  }, [form, filteredLineItems, setLineItemData]);

  const handleWeighted = useCallback(() => {
    const { amount } = form.getFieldsValue();
    if (amount === undefined) {
      form.setFields([{
        name: 'amount',
        value: undefined,
        errors: ['Amount is required'],
      }]);
    } else {
      let totalAmount = 0;
      filteredLineItems?.forEach((item) => {
        totalAmount += item?.amount ? parseFloat(item?.amount, 10) : 0;
      });
      
      if (amount >= totalAmount) {
        const updatedData = filteredLineItems?.map((item) => ({
          ...item,
          applied: parseFloat(item?.amount).toFixed(2),
        }));
        setLineItemData(updatedData);
      } else {
        const percentage = (amount / totalAmount) * 100;
        let balance = 0;
        const updatedData = filteredLineItems?.map((item, index) => {
          balance += Number(parseFloat((item?.balance * percentage) / 100).toFixed(2));
          let deductBalance = 0;
          if (index === filteredLineItems?.length - 1 && balance > amount) {
            deductBalance = balance - amount;
          }
          return {
            ...item,
            applied: Number((parseFloat(
              (item?.amount * percentage) / 100,
            ) - deductBalance).toFixed(2)),
          };
        });
        setLineItemData(updatedData);
      }
    }
  }, [form, filteredLineItems, setLineItemData]);

  const { amountTotal, balanceTotal, appliedTotal } = useMemo(() => {
    let totalAmount = 0;
    let totalBalance = 0;
    let totalApplied = 0;
    if (filteredLineItems?.length) {
      filteredLineItems.forEach((item) => {
        totalAmount += parseFloat(item.amount, 10) || 0;
        totalApplied += parseFloat(item.applied, 10) || 0;
        totalBalance += parseFloat(item.balance, 10) || 0;
      });
    }
    return { amountTotal: totalAmount, balanceTotal: totalBalance, appliedTotal: totalApplied };
  }, [filteredLineItems]);

  return (
    <>
      <div className="lists-wrap">
        <div className="top-section">
          <span />
          <div className="group-btns">
            {`${labels.get('labels.spread')} : `}
            <Button className="btn sm-btn btn-success" id="patients_financial_patientAdjustment_chronological" data-testid="button-chronological" onClick={handleChronologically}>{labels.get('buttons.chronological')}</Button>
            <Button className="btn sm-btn btn-success" id="patients_financial_patientAdjustment_weighted" data-testid="button-weighted" onClick={handleWeighted}>{labels.get('buttons.weighted')}</Button>
          </div>
        </div>
      </div>
      <div className="patient-line-item-table">
        <div className="app-table patient-adjustment">
          <table>
            <TableHeader labels={labels} />
            <TableBody
              lineItemData={filteredLineItems}
              handleLineItemData={handleLineItemData}
              amountTotal={amountTotal}
              balanceTotal={balanceTotal}
              appliedTotal={appliedTotal}
            />
          </table>
        </div>
      </div>
    </>
  );
};

export default LineItemsTable;