import { isImmutable } from 'immutable';

// Login
export const getCurrentUser = (state) => state.login.get('current');
export const isLoading = (state) => state.login.get('loading');

// User
export const userSettings = (state) => state.users.get('userSettings');

// Schedular
export const doctors = (state) => state.doctors.get('doctors');
export const locations = (state) => state.doctors.get('locations');
export const selectedDoctorAppointment = (state) => state.doctors.get('selectedDoctorAppointment');
export const selectedDoctorSchedule = (state) => state.doctors.get('selectedDoctorSchedule');
export const allDoctorAppointment = (state) => state.doctors.get('doctorsAppointments');
export const schedulerData = (state) => state.doctors.get('schedulerData');
export const schedulerLoading = (state) => state.doctors.get('loading');
export const schedulerError = (state) => state.doctors.get('error');

export const getTableDataById = (state, id) => (state.queryApi.get(id) && isImmutable(state.queryApi.get(id)) ? state.queryApi.get(id).get('data') : state.queryApi.get(id));

export const getAdvancedTableDataById = (state, id) => (state.queryApiAdvanced.get(id) && isImmutable(state.queryApiAdvanced.get(id)) ? state.queryApiAdvanced.get(id).get('data') : state.queryApiAdvanced.get(id));

export const getCrudDataById = (state, id, tabId) => {
  if (state.crud.get(tabId)) {
    if (state.crud.get(tabId).get(id) && isImmutable(state.crud.get(tabId).get(id))) {
      return state.crud?.get(tabId)?.get(id)?.get('read')?.get('data');
    }
    return state.crud?.get(tabId)?.get(id);
  }
  return [];
};
export const getTableLoading = (state) => state.queryApi.get('loading');
export const getTableError = (state) => state.queryApi.get('error');

// Enum
export const getEnumOptions = (state) => state.enums.get('field');
export const getEnumMaster = (state) => state.enums.get('enums');

// Provider
export const getProviderRedirectToEditKey = (state) => state.providers.get('redirectToEditKey');
export const getProviderLoading = (state) => state.providers.get('loading');
export const getProviderError = (state) => state.providers.get('error');

export const getCrudData = (state, id) => state?.crud?.get?.(id)?.get?.('read')?.get?.('data');
export const getCrudWithoutTab = (state, id) => state?.crudWithoutTab?.get(id)?.get('read');

export const getCrudDataWithOrWithoutTabId = (state, id, tabId) => {
  if (state?.crud?.get?.(tabId)) {
    return state.crud.get(tabId)?.get(id)?.get('read')?.get('data');
  }
  return getCrudData(state, id);
};
