import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import iconNames from '../../lib/iconNames';
import encode from '../../lib/encode';

import useRedirect from '../../hooks/useRedirect';

import Button from '../../components/Button';
import Icon from '../../components/Icon';
import RadioGroup from '../../components/RadioGroup';

import './Scheduler.scss';
import { superBillActions } from '../../store/actions/superBillActions';

const { WeekPicker, MonthPicker } = DatePicker;

const tabList = [
  { value: 'days', label: 'Day' },
  { value: 'weeks', label: 'Week' },
  { value: 'months', label: 'Month' },
];

const tabListSwitch = [
  { value: 'scheduler', label: <Icon title="Calendar" name={iconNames.schedularIcon} className="custom-switch-icon" /> },
  { value: 'list', label: <Icon title="List" name={iconNames.listIcon} className="custom-switch-icon" /> },
];

const scheduleText = {
  days: 'Daily',
  weeks: 'Weekly',
  months: 'Monthly',
};

// component modifies date picker view to match the selected view (day/week/month)
const DatePickerComponent = ({ view, value, ...otherProps }) => {
  const dateFormat = 'MMM D, YYYY';
  const weekFormat = `[${moment(value).day('Monday').format('MMM D')} - ${moment(value).day('Saturday').format('MMM D, YYYY')}]`;
  const monthFormat = 'MMMM YYYY';

  switch (view) {
    case 'weeks': return <WeekPicker value={value} format={weekFormat} {...otherProps} />;
    case 'months': return <MonthPicker value={value} format={monthFormat} {...otherProps} />;
    default: return <DatePicker value={value} format={dateFormat} {...otherProps} />;
  }
};

const SchedulerTitle = ({
  selectedDate,
  setSelectedDate,
  viewType,
  setViewType,
  doctorName,
  labels,
  dataViewType,
  setDataViewType,
  saveSchedulerFilters,
}) => {
  const dispatch = useDispatch();
  const { replace: replaceUrl, path, query } = useRedirect();
  const timePeriod = useSelector((state) => state.superBill.superBill.timePeriod);

  useEffect(() => {
    setViewType(timePeriod);
  }, []);

  const replace = useCallback((data) => {
    replaceUrl(data);
    saveSchedulerFilters(data);
  }, [replaceUrl, saveSchedulerFilters]);

  const onChangeDate = useCallback((date) => {
    const dateMoment = moment(date);
    setSelectedDate(dateMoment);
    replace({
      search: encode({ ...query, date: dateMoment.format('YYYY-MM-DD') }),
      pathname: path,
    });
  }, [setSelectedDate, path, query, replace]);

  const onChangeTimePeriod = useCallback((event) => {
    setViewType(event.target.value);
    dispatch(superBillActions.setTimePeriod(event.target.value));
  }, [setViewType]);

  const goToPreviousDate = useCallback(() => {
    onChangeDate(moment(selectedDate).subtract(1, viewType));
  }, [onChangeDate, selectedDate, viewType]);

  const goToNextDate = useCallback(() => {
    onChangeDate(moment(selectedDate).add(1, viewType));
  }, [onChangeDate, selectedDate, viewType]);

  const goToToday = useCallback(() => {
    onChangeDate(moment());
  }, [onChangeDate]);

  const onViewTypeChange = useCallback((element) => {
    const { value = '' } = element.target || {};
    setDataViewType(value);
    replace({
      search: encode({ ...query, mode: value }),
      pathname: path,
    });
  }, [setDataViewType, replace, query, path]);

  return (
    <div className="scheduler-title-main">
      <div className="feild-row">
        <div className="left-side-title">
          <Button className="lt-arrow" onClick={goToPreviousDate} />
          <DatePickerComponent
            view={viewType}
            defaultValue={selectedDate}
            value={selectedDate}
            onChange={onChangeDate}
            allowClear={false}
          />
          <Button className="rt-arrow" onClick={goToNextDate} />
        </div>
        <Button className="sm-btn mr-left-20" onClick={goToToday}>
          {labels.get('buttons.today')}
        </Button>
      </div>
      <div className="title heading">
        {doctorName ? `${doctorName} ` : ''}
        {`${scheduleText[viewType]} Schedule`}
      </div>
      <div className="right-side-title">
        <RadioGroup
          tabList={tabList}
          value={viewType}
          onChange={onChangeTimePeriod}
        />
        <RadioGroup
          tabList={tabListSwitch}
          className="radio-switch-tab-group"
          value={dataViewType}
          onChange={onViewTypeChange}
        />
      </div>
    </div>
  );
};

export default SchedulerTitle;
