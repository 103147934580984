import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';

const clearingHouseAccpetedColumns = (labels) => [
  {
    Header: labels.get('tableColumns.claimNo'),
    accessor: 'claimNo',
    fixWidth: '150',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.patientName'),
    accessor: 'patientName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.waystarSubmissionDate'),
    accessor: 'claimSubmissionDate',
    fixWidth: '200',
    Cell: (e) => (
      <TimeCell
        value={e?.row.original?.claimSubmissionDate?.dateString}
        format="MM-DD-YYYY"
      />
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.payer'),
    accessor: 'insurancePayerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.policyType'),
    accessor: 'insurancePolicyTypeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'profileTypeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.dos'),
    accessor: 'billingservicelineitemcreateddate.timestemp',
    Cell: (e) => (
      <TimeCell
        value={e?.row.original?.billingServiceLineItemCreatedDate?.dateString}
        format="MM-DD-YYYY"
      />
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    Cell: ({
      row: {
        original: { amount },
      },
    }) => (amount || 0).toFixed(2),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.balance'),
    accessor: 'balance',
    Cell: ({
      row: {
        original: { balance },
      },
    }) => (balance || 0).toFixed(2),
    sort: true,
    className: 'text-align-right',
  },
];

export default clearingHouseAccpetedColumns;
