import React, {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react';
import { Route } from 'react-router-dom';

import classNames from 'classnames';
import { useReactToPrint } from 'react-to-print';

import { apiUrls } from '../../../../../api/constants';
import { listIds, UiRoutes } from '../../../../../lib/constants';
import SuccessMessages from '../../../../../lib/successMessages';
import useCRUD from '../../../../../hooks/useCRUD';
import useRedirect from '../../../../../hooks/useRedirect';
import useTabLink from '../../../../../hooks/useTabLink';
import usePatientDetail from '../../../../../hooks/usePatientDetail';

import Button from '../../../../../components/Button';
import Notification from '../../../../../components/Notification';
import Loader from '../../../../../components/Loader';

import InsurancePayment from '../Popups/Insurance/InsurancePayment';
import PatientPayment from '../Popups/Patient/PatientPayment';
import PatientRefund from '../Popups/Patient/PatientRefund';
import ReversePaymentModal from './ReversePaymentModal';
import AuditLogModal from './AuditLogModal';
import InsuranceRefund from '../Popups/Insurance/InsuranceRefund';
import Events from '../../../../../lib/events';

const isEditButtonVisible = ({ type, isPaymentReversed, isEditProcedureAuthenticated }) => ((type?.toLowerCase() === 'insurance payment' && !isPaymentReversed)
      || (type?.toLowerCase() === 'patient payment' && !isPaymentReversed)
      || (type?.toLowerCase() === 'charge' && isEditProcedureAuthenticated));

const isRefundPaymentVisible = ({
  isAddRefundAuthenticated, isPaymentReversed, type, isCreditPayment,
}) => (
  isAddRefundAuthenticated
        && (type?.toLowerCase() === 'insurance payment' || type?.toLowerCase() === 'patient payment')
        && !isPaymentReversed
        && !isCreditPayment
);

const getUiRoute = ({
  name, navigate, patientInfo, params, encounterId, financialId,
}) => {
  let uiRoute = null;
  if (name === 'patient payment') {
    uiRoute = UiRoutes.editPatientPayment;
  } else if (name === 'insurance payment') {
    uiRoute = UiRoutes.editInsurancePayment;
  } else if (name === 'charge') {
    navigate({
      data: { name: patientInfo?.[0]?.fullName || 'Test Name' }, ...params, encounterId, financialId,
    });
  }
  return uiRoute;
};

const ChargeFormButton = ({
  labels,
  type,
  claimId,
  isWayStarQueue,
  onPrint,
  reAssembleCharge,
}) => (
  type?.toLowerCase() === 'charge' && (
    <>
      <Button
        className={classNames('btn sm-btn mr-lt-10', {
          'btn-success': claimId ? isWayStarQueue : false,
          'cursor-not-allowed': !((claimId ? isWayStarQueue : false) || undefined),
        })}
        name={type?.toLowerCase()}
        onClick={onPrint}
        disabled={!claimId ? true : !isWayStarQueue}
      >
        {labels.get('buttons.print')}
      </Button>
      <Button
        className={classNames('btn sm-btn mr-lt-10', {
          'btn-success': claimId ? isWayStarQueue : false,
          'cursor-not-allowed': !((claimId ? isWayStarQueue : false) || undefined),
        })}
        name={type?.toLowerCase()}
        onClick={reAssembleCharge}
        disabled={!claimId ? true : !isWayStarQueue}
      >
        {labels.get('buttons.reAssemble')}
      </Button>
    </>
  )
);

const EditPaymentButton = ({
  type, isEditable, handleEdit, labels,
}) => (
  <Button
    className={classNames('btn sm-btn mr-lt-10', {
      'btn-success': (type?.toLowerCase() === 'charge' ? isEditable : true),
      'cursor-not-allowed': !((type?.toLowerCase() === 'charge') ? isEditable : true),
    })}
    name={type?.toLowerCase()}
    onClick={handleEdit}
    disabled={!isEditable && type?.toLowerCase() === 'charge'}
  >
    {labels.get(type?.toLowerCase() === 'charge' ? 'buttons.editCharge' : 'buttons.editPayment')}
  </Button>
);

const RenderButtons = ({
  labels, type, financialId, hasLineItems, isPaymentReversed, encounterId, detail, setShowReversal,
  showReversal, claimId, claimNo, queueMasterCode, transactionTypeId, isEditable,
  isReversePatientPaymentAuthenticated, isAddRefundAuthenticated, isEditProcedureAuthenticated,
  isCreditPayment = false,
  isInsuranceAdjustment,
}) => {
  const { params, push, generatePath } = useRedirect();
  const viewPanel = useRef();
  const [isAuditModalVisible, setAuditModalVisibility] = useState(false);
  const patientInfo = usePatientDetail({ patientId: params.id });
  const isWayStarQueue = useMemo(() => queueMasterCode === 'WayStarQueue', [queueMasterCode]);

  const { navigate } = useTabLink({
    to: UiRoutes.editFinancialEncounterWithTabId,
    id: UiRoutes.editFinancialEncounterWithTabId,
  });

  const [printHtml, , printLoading, getPrintHtml, clearPrintResponse] = useCRUD({
    id: `${listIds.PRINT_FINANCE_DETAIL}-${claimId}`, url: apiUrls.GET_CLAIMS_PDF, type: 'create',
  });

  const [receiptHtml, , receiptLoading, getReceipt, clearReceiptResponse] = useCRUD({
    id: listIds.FINANCIAL_Details_PATIENT_PAYMENT_RECEIPT, url: apiUrls.GET_PATIENT_PAYMENT_RECEIPT_HTML, type: 'read',
  });

  const [
    reAssembleResponse,,
    reAssembleLoading,
    reAssemble,
    clearReAssemble,
  ] = useCRUD({
    id: `${listIds.ASSEMBLE_FINANCE_CHARGE}-${claimId}`, url: apiUrls.ASSEMBLE_FINANCE, type: 'create',
  });

  useEffect(() => {
    if (reAssembleResponse) {
      Notification({ message: SuccessMessages.CHARGE_RE_ASSEMBLED_SUCCESSFULLY, success: true });
      clearReAssemble();
      Events.trigger(`reFetchFinancialDetail-${financialId}`);
    }
  }, [financialId, reAssembleResponse]);

  useEffect(() => {
    if (printHtml) {
      window.open(printHtml?.pdfUrl);
      clearPrintResponse();
    }
  }, [clearPrintResponse, printHtml]);

  const handleHtmlPrint = useReactToPrint({
    content: () => viewPanel.current,
    copyStyles: false,
  });

  useEffect(() => {
    if (receiptHtml) {
      viewPanel.current.innerHTML = receiptHtml || '';
      handleHtmlPrint();
      clearReceiptResponse(true);
    }
  }, [receiptHtml]);

  const [
    deleteResponse,,
    deleteLoading,
    deleteTransaction,
    clearDeleteResponse] = useCRUD({
    id: `delete-financial-transaction-${financialId}`,
    url: apiUrls.DELETE_FINANCE_TRANSACTION,
    type: 'delete',
  });

  const [
    reverseAdjustmentResponse,,
    reverseAdjustmentLoading,
    reverseAdjustment,
    clearReverseAdjustmentResponse] = useCRUD({
    id: `reverse-insurnace-adjustment-${financialId}`,
    url: apiUrls.REVERSE_INSURANCE_ADJUSTMENT,
    type: 'update',
  });

  useEffect(() => {
    if (deleteResponse) {
      Notification({ message: SuccessMessages.TRANSACTION_DELETED_SUCCESSFULLY, success: true });
      clearDeleteResponse();
      push(generatePath(UiRoutes.editPatientWithTabId, params));
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (reverseAdjustmentResponse) {
      Notification({ message: SuccessMessages.TRANSACTION_DELETED_SUCCESSFULLY, success: true });
      clearReverseAdjustmentResponse();
      push(generatePath(UiRoutes.editPatientWithTabId, params));
    }
  }, [reverseAdjustmentResponse]);

  const onDelete = useCallback(() => {
    if (financialId) deleteTransaction({}, `/${financialId}`);
  }, [deleteTransaction, financialId]);

  const handleEdit = useCallback(({ target: { name } }) => {
    const uiRoute = getUiRoute({
      name, navigate, patientInfo, params, encounterId, financialId,
    });
    if (uiRoute) push(generatePath(uiRoute, params));
  }, [encounterId, financialId, generatePath, navigate, params, patientInfo, push]);

  const handleRefund = useCallback(({ target: { name } }) => {
    if (name === 'patient payment') {
      return push(generatePath(UiRoutes.editPatientRefund, params));
    }
    if (name === 'insurance payment') {
      return push(generatePath(UiRoutes.editInsuranceRefund, params));
    }
    return 0;
  }, [generatePath, params, push]);

  const handleReversePayment = useCallback(() => {
    push(generatePath(UiRoutes.financePaymentReversal, params));
  }, [generatePath, params, push]);

  const handleReverseAdjustment = useCallback(() => {
    if (financialId) reverseAdjustment({}, `/${financialId}`);
  }, [reverseAdjustment, financialId]);

  const onShowReversal = useCallback(() => {
    setShowReversal(!showReversal);
  }, [setShowReversal, showReversal]);

  const onPrint = useCallback(() => {
    getPrintHtml({ data: { claimIds: claimId, isClaimCorrected: false } });
  }, [claimId, getPrintHtml]);

  const handlePatientPaymentReceipt = useCallback(() => {
    getReceipt({ PatientId: params?.id, FinanceId: financialId });
  }, [financialId, getReceipt]);

  const closeModal = useCallback(() => {
    push(generatePath(UiRoutes.viewFinancialDetailsWithId, params));
  }, [generatePath, params, push]);

  const toggleAuditModal = useCallback(() => {
    setAuditModalVisibility((previousData) => !previousData);
  }, []);

  const reAssembleCharge = useCallback(() => {
    reAssemble({ data: { ClaimId: claimId } });
  }, [claimId, reAssemble]);

  return (
    <>
      <div ref={viewPanel} className="display-none" />
      <div className="flex justify-content-flex-end mr-tp-20 m-btn-manage">
        {(deleteLoading || printLoading || reAssembleLoading || receiptLoading
        || reverseAdjustmentLoading)
        && <Loader />}
        {type?.toLowerCase() === 'simple charge' && (
        <Button
          className={classNames('min-wt-86 sm-btn btn',
            { 'btn-success': !hasLineItems, 'cursor-not-allowed': (hasLineItems || undefined) })}
          onClick={onDelete}
          name={type?.toLowerCase()}
          disabled={hasLineItems}
        >
          {labels.get('buttons.delete')}
        </Button>
        )}
        {isEditButtonVisible({ type, isPaymentReversed, isEditProcedureAuthenticated }) && (
        <EditPaymentButton
          type={type}
          isWayStarQueue={isWayStarQueue}
          isEditable={isEditable}
          handleEdit={handleEdit}
          labels={labels}
        />
        )}
        {isRefundPaymentVisible({
          isAddRefundAuthenticated, isPaymentReversed, type, isCreditPayment,
        }) && (
        <Button
          className="btn sm-btn btn-success mr-lt-10"
          name={type?.toLowerCase()}
          onClick={handleRefund}
        >
          {labels.get('buttons.refundPayment')}
        </Button>
        )}
        {(type?.toLowerCase() === 'insurance payment'
        || (type?.toLowerCase() === 'patient payment' && isReversePatientPaymentAuthenticated)) && !isPaymentReversed && !isCreditPayment && (
        <Button
          className="btn sm-btn btn-success mr-lt-10"
          name={type?.toLowerCase()}
          onClick={handleReversePayment}
        >
          {labels.get('buttons.reversePayment')}
        </Button>
        )}
        {(isInsuranceAdjustment && (
          <Button
            className="btn sm-btn btn-success mr-lt-10"
            name={type?.toLowerCase()}
            onClick={handleReverseAdjustment}
          >
            {labels.get('buttons.reverseAdjustment')}
          </Button>
        ))}
        {type?.toLowerCase() === 'patient payment' && (
        <Button
          className="btn sm-btn btn-success mr-lt-10"
          name={type?.toLowerCase()}
          onClick={handlePatientPaymentReceipt}
        >
          {labels.get('buttons.printReceipt')}
        </Button>
        )}
        {(type?.toLowerCase() === 'patient payment' || type?.toLowerCase() === 'insurance payment' || type?.toLowerCase() === 'charge' || type?.toLowerCase() === 'simple charge') && !isCreditPayment && (
        <Button
          className="btn sm-btn btn-success mr-lt-10"
          name={type?.toLowerCase()}
          onClick={onShowReversal}
        >
          {labels.get(showReversal ? 'buttons.hideReversal' : 'buttons.showReversal')}
        </Button>
        )}
        {(type?.toLowerCase() === 'charge' || type?.toLowerCase() === 'simple charge') && (
        <Button
          className="btn sm-btn btn-success mr-lt-10"
          name={type?.toLowerCase()}
          onClick={toggleAuditModal}
        >
          {labels.get('buttons.auditLog')}
        </Button>
        )}
        <ChargeFormButton
          labels={labels}
          type={type}
          claimId={claimId}
          isWayStarQueue={isWayStarQueue}
          onPrint={onPrint}
          reAssembleCharge={reAssembleCharge}
        />
      </div>
      <Route path={UiRoutes.editInsuranceRefund}>
        <InsuranceRefund visible={financialId} toggleModal={closeModal} claimId={claimId} />
      </Route>
      <Route path={UiRoutes.editInsurancePayment}>
        <InsurancePayment
          visible={financialId}
          claimDetails={{
            claimId, claimNo, prevClaimId: claimId, prevClaimNo: claimNo,
          }}
          transactionTypeId={transactionTypeId}
          toggleModal={closeModal}
          isEditInsurancePayment
        />
      </Route>
      <Route path={UiRoutes.editPatientPayment}>
        <PatientPayment
          visible={financialId}
          toggleModal={closeModal}
          isCreditPayment={isCreditPayment}
        />
      </Route>
      <Route path={UiRoutes.editPatientRefund}>
        <PatientRefund
          visible={financialId}
          toggleModal={closeModal}
          detailData={detail}
          hasLineItems={hasLineItems}
        />
      </Route>
      <Route path={UiRoutes.financePaymentReversal}>
        <ReversePaymentModal
          visible={financialId}
          toggleModal={closeModal}
          labels={labels}
          type={type}
        />
      </Route>
      <AuditLogModal labels={labels} toggleModal={toggleAuditModal} visible={isAuditModalVisible} />
    </>
  );
};

export default RenderButtons;
