// documentGenerationReducer.js

import { DOCUMENT_ACTIONS } from '../actions/superBillActions';

const initialDocumentState = {
  tasks: [],
  isPrintButtonDisabled: false,
};

const initialState = {
  superBill: {
    ...initialDocumentState,
    timePeriod: 'days',
  },
  statement: initialDocumentState,
  // Add other document types as needed
};

const documentGenerationReducer = (state = initialState, action) => {
  let { documentType } = action;
  let updatedTasks;

  // Extract documentType from payload for some actions
  if (action.payload && action.payload.documentType) {
    documentType = action.payload.documentType;
  }

  // If no document type is specified, return state unchanged
  if (!documentType) return state;

  // Get the current state for this document type
  const currentDocTypeState = state[documentType];

  // Early return if no document type state exists
  if (!currentDocTypeState) return state;

  switch (action.type) {
    case DOCUMENT_ACTIONS.SET_STATUS:
      updatedTasks = currentDocTypeState.tasks.map((task) => (
        task.taskId === action.payload.taskId
          ? { ...task, status: action.payload.status }
          : task
      ));

      localStorage.setEncryptedData(`${documentType}Tasks`, JSON.stringify(updatedTasks));

      return {
        ...state,
        [documentType]: {
          ...currentDocTypeState,
          tasks: updatedTasks,
        },
      };

    case DOCUMENT_ACTIONS.SET_DOWNLOAD_URL:
      updatedTasks = currentDocTypeState.tasks.map((task) => (
        task.taskId === action.payload.taskId
          ? { ...task, downloadUrl: action.payload.downloadUrl }
          : task
      ));

      localStorage.setEncryptedData(`${documentType}Tasks`, JSON.stringify(updatedTasks));

      return {
        ...state,
        [documentType]: {
          ...currentDocTypeState,
          tasks: updatedTasks,
        },
      };

    case DOCUMENT_ACTIONS.ADD_TASK:
      // eslint-disable-next-line no-case-declarations
      const task = action.payload;
      updatedTasks = [
        ...currentDocTypeState.tasks,
        {
          taskId: task.taskId,
          status: task.status,
          downloadUrl: task.downloadUrl,
          query: task.query,
          polling: false,
          downloading: false,
          documentType: task.documentType,
        },
      ];

      localStorage.setEncryptedData(`${documentType}Tasks`, JSON.stringify(updatedTasks));

      return {
        ...state,
        [documentType]: {
          ...currentDocTypeState,
          tasks: updatedTasks,
        },
      };

    case DOCUMENT_ACTIONS.HYDRATE_TASKS:
      return {
        ...state,
        [documentType]: {
          ...currentDocTypeState,
          tasks: action.payload,
        },
      };

    case DOCUMENT_ACTIONS.RESET_TASK:
      return {
        ...state,
        [documentType]: {
          ...currentDocTypeState,
          tasks: currentDocTypeState.tasks.filter((t) => t.taskId !== action.taskId),
        },
      };

    case DOCUMENT_ACTIONS.SET_POLLING:
      return {
        ...state,
        [documentType]: {
          ...currentDocTypeState,
          tasks: currentDocTypeState.tasks.map((t) => (
            t.taskId === action.payload.taskId
              ? { ...t, polling: action.payload.polling }
              : t
          )),
        },
      };

    case DOCUMENT_ACTIONS.SET_DOWNLOADING:
      return {
        ...state,
        [documentType]: {
          ...currentDocTypeState,
          tasks: currentDocTypeState.tasks.map((t) => (
            t.taskId === action.payload.taskId
              ? { ...t, downloading: action.payload.downloading }
              : t
          )),
        },
      };

    case DOCUMENT_ACTIONS.DISABLE_PRINT_BUTTON:
      return {
        ...state,
        [documentType]: {
          ...currentDocTypeState,
          isPrintButtonDisabled: true,
        },
      };

    case DOCUMENT_ACTIONS.ENABLE_PRINT_BUTTON:
      return {
        ...state,
        [documentType]: {
          ...currentDocTypeState,
          isPrintButtonDisabled: false,
        },
      };

    // Handle SuperBill-specific actions
    case 'SET_TIME_PERIOD':
      if (documentType === 'superBill') {
        return {
          ...state,
          superBill: {
            ...state.superBill,
            timePeriod: action.payload,
          },
        };
      }
      return state;

    default:
      return state;
  }
};

export default documentGenerationReducer;
