import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import { setSortOrderAdvanced, setFiltersAdvanced, setPageAdvanced } from '../../store/actions/queryApiAdvanced';
import * as selectors from '../../store/selectors';

const withReduxManagerAdvanced = (options) => {
  const { listId, pageSize: pageItemSize } = options;
  return (Component) => {
    const ReduxManager = (props) => {
      const { list, pageSize: pageSizeFromProps, page: pageFromProp } = props;

      const [sortBy, setSortBy] = useState((list && list.sortBy) || []);
      const [filter, setFilter] = useState((list && list.filters) || {});
      const [pageSize] = useState((pageItemSize || pageSizeFromProps) || 30);
      const [pages, setPages] = useState(pageFromProp || 0);

      const onSortChange = useCallback((value) => {
        const { dispatch } = props;
        setSortBy(value);
        dispatch(setSortOrderAdvanced(value, listId));
      }, [props]);

      const onFiltersChange = useCallback((value) => {
        const { dispatch } = props;
        setFilter(value);
        dispatch(setFiltersAdvanced(value, listId));
      }, [props]);

      const onPageChange = useCallback((value) => {
        const { dispatch } = props;
        setPages(value);
        dispatch(setPageAdvanced(value, listId));
      }, [props]);

      return (
        <Component
          sortBy={sortBy}
          prevFilters={filter}
          page={pages}
          pageSize={pageSize}
          onSortChange={onSortChange}
          onFiltersChange={onFiltersChange}
          onPageChange={onPageChange}
          {...props}
        />
      );
    };

    const mapStateToProps = (state) => ({
      list: selectors.getAdvancedTableDataById(state, listId),
    });

    return connect(mapStateToProps)(ReduxManager);
  };
};

export default withReduxManagerAdvanced;
