/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import * as selectors from '../../store/selectors';
import AdvancedTable from '../../components/Table/AdvancedTable';
import { clearTableDataAdvanced, getListDataAdvanced, setListDataAdvanced } from '../../store/actions/queryApiAdvanced';

const getSortValue = ({ sortBy }) => {
  if (sortBy[0]?.reverseSort) {
    return sortBy[0].desc ? 'asc' : 'desc';
  }

  return sortBy[0].desc ? 'desc' : 'asc';
};

const hasQueryParamsChanged = (prevList, currentFilters, currentSort) => {
  if (!prevList) return true;

  const prevFilters = prevList.lastQueryParams?.filters;
  const prevSort = prevList.lastQueryParams?.sortBy;

  return !isEqual(prevFilters, currentFilters) || !isEqual(prevSort, currentSort);
};

function onSort({
  url,
  listId,
  accessor,
  initialVariables,
  alias,
}, isReFetch = false) {
  return (sortBy, skipInitialFetch, isMounted) => {
    const {
      dispatch, onSortChange, filters: defaultFilters = {},
    } = this.props;
    const {
      pageSize, filters, paredSortBy,
    } = this.state;
    const sortParameter = paredSortBy;
    if (sortBy?.length) {
      const sortKey = sortBy[0].id;
      sortParameter.SortBy = alias[sortKey] || sortKey;
      sortParameter.SortOrder = getSortValue({ sortBy });
    }

    this.setState({
      sortBy,
      page: 0,
      paredSortBy: sortParameter,
    }, () => {
      if (!skipInitialFetch || isMounted) {
        dispatch(getListDataAdvanced(url, listId, accessor, {
          ...initialVariables,
          page: 0,
          pageSize,
          sortBy: sortParameter,
          filters: this.getRawFilter({ ...filters, ...defaultFilters }),
        }));
      }
      if (typeof onSortChange === 'function' && !isReFetch) {
        onSortChange(sortBy);
      }
    });
  };
}

function onFetchMore({
  url,
  listId,
  accessor,
  initialVariables,
}) {
  return (pageNumber) => {
    const {
      dispatch, onPageChange, filters: defaultFilters, list = {},
    } = this.props;
    const { pageSize, filters, paredSortBy } = this.state;

    const pageData = list.pageData || {};
    const currentQueryParams = {
      filters: this.getRawFilter({ ...filters, ...defaultFilters }),
      sortBy: paredSortBy,
    };

    const hasCachedData = pageData[pageNumber]
      && pageData[pageNumber].length > 0
      && !hasQueryParamsChanged(list, currentQueryParams.filters, currentQueryParams.sortBy);

    this.setState({ page: pageNumber }, () => {
      if (hasCachedData) {
        dispatch(setListDataAdvanced(
          pageData[pageNumber],
          listId,
          true,
          list.hasMore,
          list.totalCount,
          list.totalPages,
          {
            ...list.otherTableData,
            pageIndex: pageNumber,
            pageSize,
          },
        ));
      } else {
        dispatch(getListDataAdvanced(url, listId, accessor, {
          ...initialVariables,
          page: pageNumber,
          pageSize,
          sortBy: paredSortBy,
          filters: this.getRawFilter({ ...filters, ...defaultFilters }),
        }));
      }

      if (typeof onPageChange === 'function') {
        onPageChange(pageNumber);
      }
    });
  };
}

function onFiltersChange({
  url,
  listId,
  accessor,
  initialVariables,
}) {
  return (filters) => {
    const { dispatch, onFiltersChange: onChangeFilter, filters: defaultFilters } = this.props;
    const { pageSize, paredSortBy } = this.state;

    this.setState({ filters, page: 0 }, () => {
      dispatch(getListDataAdvanced(url, listId, accessor, {
        ...initialVariables,
        page: 0,
        pageSize,
        sortBy: paredSortBy,
        filters: this.getRawFilter({ ...filters, ...defaultFilters }),
      }));
      if (typeof onChangeFilter === 'function') {
        onChangeFilter(filters);
      }
    });
  };
}

function onRefresh({
  url,
  listId,
  accessor,
  initialVariables,
}) {
  return (currentSortBy) => {
    const { dispatch, filters: defaultFilters } = this.props;
    const { pageSize, filters, paredSortBy } = this.state;

    dispatch(clearTableDataAdvanced(listId));

    this.setState({ page: 0 }, () => {
      dispatch(getListDataAdvanced(url, listId, accessor, {
        ...initialVariables,
        page: 0,
        pageSize,
        sortBy: currentSortBy?.length ? {
          SortBy: currentSortBy[0].id,
          SortOrder: currentSortBy[0].desc ? 'desc' : 'asc',
        } : paredSortBy,
        filters: this.getRawFilter({ ...filters, ...defaultFilters }),
      }));
    });
  };
}

const defaultOptions = {
  accessor: (data) => data,
  initialVariables: {},
  alias: {},
};

const getState = (props) => ({
  sortBy: props?.sortBy?.length ? props.sortBy : props.initialSort,
  paredSortBy: {},
  initialSort: props?.sortBy?.length ? props.sortBy : props.initialSort,
  filters: { ...props.initialFilters, ...props.prevFilters },
  pageSize: props.pageSize,
  page: props.page,
  data: (props?.list?.data) || [],
  onRowClick: props.onRowClick,
  rightClickMenu: props.rightClickMenu,
});

const withQueryAdvanced = (options) => {
  const {
    url,
    listId,
    accessor,
    initialVariables,
    alias,
    getRawFilter = (filter) => filter,
  } = { ...defaultOptions, ...options };

  if (!url && !listId) {
    return () => null;
  }

  const getEssential = () => ({
    url, listId, accessor, initialVariables, alias,
  });

  return () => {
    class Query extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          ...getState(props),
        };
        this.onSort = onSort.bind(this);
        this.onFetchMore = onFetchMore.bind(this);
        this.onFiltersChange = onFiltersChange.bind(this);
        this.onRefresh = onRefresh.bind(this);
        this.getRawFilter = getRawFilter;
        this.setPageSize = this.setPageSize.bind(this);
      }

      componentDidMount() {
        const { initialFetch, filters, dispatch } = this.props;
        const { pageSize, initialSort, filters: manualFilter } = this.state;
        if (initialFetch) {
          dispatch(getListDataAdvanced(url, listId, accessor, {
            ...initialVariables,
            page: 0,
            pageSize,
            sortBy: { SortBy: initialSort?.[0]?.id, SortOrder: initialSort?.[0]?.desc ? 'desc' : 'asc' },
            filters: { ...filters, ...manualFilter },
          }));
        }
      }

      componentDidUpdate(prevProps) {
        const { rightClickMenu } = this.props;
        const { rightClickMenu: prevRightClickMenu } = prevProps;

        if (prevRightClickMenu !== rightClickMenu) {
          this.setState({ rightClickMenu });
        }
        const { filters, dispatch } = this.props;
        const { pageSize, paredSortBy, filters: manualFilter } = this.state;
        if (!isEqual(filters, prevProps.filters)) {
          this.setState({ page: 0 });
          dispatch(getListDataAdvanced(url, listId, accessor, {
            ...initialVariables,
            page: 0,
            pageSize,
            sortBy: paredSortBy,
            filters: { ...filters, ...manualFilter },
          }));
        }
      }

      setPageSize(newPageSize) {
        const { dispatch, filters } = this.props;
        const { paredSortBy, filters: manualFilter } = this.state;

        this.setState({
          pageSize: newPageSize,
          page: 0,
        }, () => {
          dispatch(getListDataAdvanced(url, listId, accessor, {
            ...initialVariables,
            page: 0,
            pageSize: newPageSize,
            sortBy: paredSortBy,
            filters: { ...filters, ...manualFilter },
          }));
        });
      }

      static getDerivedStateFromProps(props, state) {
        if (props.list && !isEqual(props.list.data, state.data)) {
          return {
            data: props.list.data,
          };
        }
        return null;
      }

      render() {
        const {
          children, Component, customData, list = {}, showPageSizeSelector = false, ...props
        } = this.props;

        const {
          filters, initialSort, data, onRowClick, rightClickMenu, pageSize,
        } = this.state;

        const {
          loading, hasMore, totalPages, otherTableData = {}, totalCount = 0,
        } = list;

        return (
          children({
            Component: <Component
              {...props}
              onSort={this.onSort(getEssential())}
              onFetchMore={this.onFetchMore(getEssential())}
              onRefresh={this.onRefresh(getEssential())}
              data={customData || data}
              initialSort={initialSort}
              onRowClick={onRowClick}
              rightClickMenu={rightClickMenu}
              loading={loading}
              hasMore={hasMore}
              fetch={this.onSort(getEssential())}
              customData={customData}
              totalCount={totalCount}
              otherTableData={otherTableData}
              totalPages={totalPages}
              pageSize={pageSize}
              setPageSize={this.setPageSize}
              showPageSizeSelector={showPageSizeSelector}
            />,
            initialFilters: filters,
            onFiltersChange: this.onFiltersChange(getEssential()),
            totalCount,
            otherTableData,
            totalPages,
            reFetch: this.onSort(getEssential(), true),
            onRefresh: this.onRefresh(getEssential()),
            onSort: this.onSort(getEssential()),
            onFetchMore: this.onFetchMore(getEssential()),
            data,
            initialSort,
            loading,
            hasMore,
            pageSize,
            setPageSize: this.setPageSize,
            showPageSizeSelector: { showPageSizeSelector },
          })
        );
      }
    }

    Query.defaultProps = {
      Component: AdvancedTable,
      page: 0,
      pageSize: 50,
      initialSort: [],
      filterParams: {},
      initialFilters: {},
      prevFilters: {},
    };

    const mapStateToProps = (state) => ({
      list: selectors.getAdvancedTableDataById(state, listId),
    });
    return connect(mapStateToProps)(Query);
  };
};

export default withQueryAdvanced;
