import React, {
  useCallback, useState,
} from 'react';
import Search from 'antd/lib/input/Search';
import debounce from 'lodash/debounce';

import { apiUrls } from '../../../../../../api/constants';
import {
  COLLECTIONS,
  listId, UiRoutes,
} from '../../../../../../lib/constants';
import useTabLink from '../../../../../../hooks/useTabLink';

import withReduxManager from '../../../../../../hoc/withReduxManager';
import withQuery from '../../../../../../hoc/withQuery/withQuery';
import Select from '../../../../../../components/SelectBox';

import useRedirect from '../../../../../../hooks/useRedirect';

import columns from './columns';
import WiredSelectWithoutTab from '../../../../../../wiredComponents/Select/selectBoxV2';
import FilterManager from '../../../../../../components/FilterManager';

const initialSort = [{ id: 'name', desc: false }];

const CustomClaimsTable = withReduxManager({
  listId: listId.COLLECTION_CLAIMS,
})(withQuery({
  url: apiUrls.GET_COLLECTION_CLAIMS_LIST,
  listId: listId.COLLECTION_CLAIMS,
})());

const stageMap = {
  Denial: 'BillingStageDenial',
  Manual: 'BillingStageManual',
  'No Response': 'BillingStageNoResponse',
};

const stateMap = {
  new: 'BillingStateNew',
  inProgress: 'BillingStateInProgress',
  needsAction: 'BillingStateNeedAction',
  completed: 'BillingStateCompleted',
};

const options = [
  { name: '30 Days', value: 30 },
  { name: '60 Days', value: 60 },
  { name: '90 Days', value: 90 },
];
const ClaimsTable = ({
  labels, searchText, ...props
}) => {
  const { push, generatePath, params } = useRedirect();
  const [filterValue, setFilterValue] = useState(30);
  const {
    type, collectionId, claimFilter, selectedAdditionFilter, agingMasterCode, groupByFilter,
    filterId, denialCategoryCode,
  } = params;

  const filterDebounced = debounce((value, onFiltersChange, clonedFilters) => {
    onFiltersChange(clonedFilters, true);
  }, 500);

  const { navigate } = useTabLink({
    to: UiRoutes.claimsCollections,
    id: UiRoutes.claimsWithTabs,
    params,
  });

  const searchHandler = ({ target: { value } }, onFiltersChange, filters) => {
    const clonedFilters = filters;
    clonedFilters.SearchText = value;
    filterDebounced(value, onFiltersChange, clonedFilters);
  };

  const stateId = stateMap[claimFilter];
  const stageId = stageMap[type];
  const onRowClick = useCallback(({ original: { claimId, patientId, patientName } }) => {
    navigate({
      claimNumber: claimId, id: patientId, tab: 'overview', data: { name: `Claim: ${patientName}`, showFullTitle: true },
    });
  }, [navigate]);

  const payerFilterHandler = (item, filters, onFiltersChange) => {
    onFiltersChange({ ...filters, filterId: item?.insurancePayerId, additionalFilter: item ? 'Payer' : null }, true);
    if (item) {
      push(generatePath(UiRoutes.collectionClaimsAdditionalFilters,
        {
          ...params,
          selectedAdditionFilter: 'Payer',
          filterId: item?.insurancePayerId,
        }));
    } else {
      push(generatePath(UiRoutes.collectionClaimsAdditionalFilters,
        {
          ...params,
          selectedAdditionFilter: 0,
          filterId: 0,
        }));
    }
  };

  return (
    <div>
      {type === COLLECTIONS.TYPE.NO_RESPONSE && (
      <Select
        isFormItem={false}
        options={options}
        selectProps={{
          style: { minWidth: 140, marginBottom: 10 },
        }}
        value={filterValue}
        onChange={setFilterValue}
      />
      )}
      <CustomClaimsTable
        columns={columns?.({ labels, stateId })}
        initialSort={initialSort}
        noDataText="Claims not found"
        onRowClick={onRowClick}
        initialFilters={{
          State: stateId,
          Stage: stageId,
          ItemId: collectionId,
          GroupBy: groupByFilter,
          GroupFilter: groupByFilter,
          agingMasterCode,
          additionalFilter: selectedAdditionFilter,
          filterId: Number(filterId),
          denialCategoryCode,
          iscollectionlisting: true,
        }}
        filters={{
          NoResponseDuration: type === COLLECTIONS.TYPE.NO_RESPONSE ? filterValue : null,
        }}
        footer
        {...props}
      >
        {({ Component, initialFilters, onFiltersChange }) => (
          <>
            <FilterManager
              initialFilters={initialFilters}
              onChange={onFiltersChange}
            >
              {({ onFilterChange, filters }) => (
                <div className="collection-claims-table-filters">
                  <Search
                    className="collection-claims-filter-search"
                    placeholder="Search by DOS, Claim Number, Chart Number, Patient Name"
                    defaultValue={filters.SearchText}
                    onChange={
                      (currentFilter) => searchHandler(currentFilter, onFilterChange, filters)
                    }
                    allowClear
                  />
                  <div className="input-wrap filter-select-box">
                    <div>
                      <WiredSelectWithoutTab
                        id="collection-payer"
                        name={null}
                        url={apiUrls.GET_PAYERS}
                        nameAccessor="name"
                        valueAccessor="insurancePayerId"
                        isStatCaseOptions={false}
                        Component={Select}
                        labelSpan="0"
                        selectProps={{
                          style: { width: 300 },
                          showSearch: true,
                        }}
                        params={{
                          pageIndex: 0,
                          pageSize: 1000,
                        }}
                        onChange={(value, data) => payerFilterHandler(
                          data?.item,
                          filters,
                          onFilterChange,
                        )}
                        placeholder="Select Payer"
                        value={(selectedAdditionFilter === 'Payer' || filters.additionalFilter === 'Payer') ? filters?.filterId : null}
                      />
                    </div>
                  </div>
                </div>
              )}
            </FilterManager>
            {Component}
          </>
        )}
      </CustomClaimsTable>
    </div>
  );
};

export default ClaimsTable;
