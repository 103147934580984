import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { Form as AntdForm } from 'antd';
import classNames from 'classnames';

import { LoadingOutlined } from '@ant-design/icons';
import { apiUrls } from '../../../../../../api/constants';
import SuccessMessages from '../../../../../../lib/successMessages';
import Events from '../../../../../../lib/events';
import { isValueUptoTwoDecimalPlaces } from '../../../../../../lib/util';

import withQuery from '../../../../../../hoc/withQuery/withQuery';
import useRedirect from '../../../../../../hooks/useRedirect';

import ActionIcons from '../../../../../../wiredComponents/ActionIcons';
import {
  formId,
} from '../../../../../../lib/constants';

import Table from '../../../../../../components/Table';
import Form from '../../../../../../components/Form';
import Button from '../../../../../../components/Button';
import Notification from '../../../../../../components/Notification';

import columns from './columns';
import useReduxState from '../../../../../../hooks/useReduxState';
import useCRUD from '../../../../../../hooks/useCRUD';
import dateFormatter from '../../../../../../lib/dateFormatter';

const TableWrapper = ({
  Component, data, initializeLineItems,
}) => {
  useEffect(() => {
    initializeLineItems(data);
  }, [data]);
  return Component;
};

const getNormalizedData = (data = []) => {
  const normalizedData = [];
  data?.forEach((element) => {
    normalizedData.push({ ...element, applied: element?.isAlreadyApplied ? element?.applied : 0 });
  });
  return normalizedData;
};

const RemittancePatientPayment = ({
  labels,
  onCancel,
  paymentForm,
  navigateToPostedClaims,
  transactionTypeId,
  formValidation,
  selectedPatientId,
  isEditWorkList,
  setPatientData,
  setClaimPosted,
  setCurrentPath,
  setExtensionParams,
  currentPageNo,
  isElectronicBatch,
  customPatientFormInstance,
  setClaimPageNo,
  totalNoOfPages,
  pdfPageNo,
  setNeedToScroll,
  billingState,
}) => {
  const [innerFormInstance] = AntdForm.useForm();
  const form = customPatientFormInstance || innerFormInstance;

  const {
    path, params: {
      PatientId: paramsPatientId, remittanceId, remittancePaymentId, tabId,
    },
  } = useRedirect();

  const [patientInfo, , patientInfoLoading, getPatientInfo] = useCRUD({ id: 'basic-info-demographic', url: apiUrls.GET_PATIENT, type: 'read' });

  const patientPaymentReduxId = path.indexOf('new') > -1 ? tabId : remittancePaymentId;
  const PatientId = useMemo(() => paramsPatientId || selectedPatientId,
    [paramsPatientId, selectedPatientId]);

  const PaymentTable = useMemo(() => withQuery({
    url: apiUrls.REMITTANCE_PATIENT_FINANCIAL_LINE_ITEMS,
    listId: `REMITTANCE_PATIENT_FINANCIAL_LINE_ITEMS_${PatientId}`,
    accessor: (data) => ({ result: data }),
  })(Table), [PatientId]);

  const [lineItems, setLineItems] = useReduxState(`patient-payment-lineItems-${patientPaymentReduxId}`, []);
  const [isAllClaimsPosted, setIsAllClaimPost] = useReduxState(`patient-payment-isAllClaimsPosted-${patientPaymentReduxId}`, false);

  const initializeLineItems = useCallback((data = []) => {
    if (data?.length) {
      setLineItems(getNormalizedData(data)?.map(
        ({
          financePaymentId: financeId, balance = 0, applied = 0,
          amount = 0, adjustment = 0, patientId, billingEncounterServiceLineId, description,
        }) => ({
          financeId,
          balance,
          applied,
          amount,
          adjustment,
          patientId,
          billingEncounterServiceLineId,
          description,
        }),
      ));
    } else {
      setLineItems([]);
    }
  }, [setLineItems]);

  const getLineItemSetter = useCallback((index, key) => (event) => {
    const { target: { value } } = event;
    if (isValueUptoTwoDecimalPlaces(value)) {
      const newLineItems = [...lineItems];
      if (newLineItems[index]) {
        newLineItems[index] = {
          ...lineItems[index],
          [key]: parseFloat(Number(parseFloat(value, 10) || 0)).toFixed(2),
          isEdited: true,
        };
      }
      setLineItems(newLineItems);
    }
  }, [lineItems, setLineItems]);

  useEffect(() => {
    if (setCurrentPath) {
      setCurrentPath(path);
    }
    if (setExtensionParams) {
      setExtensionParams({ patientId: paramsPatientId });
    }
  }, []);

  useEffect(() => {
    if (PatientId) getPatientInfo({}, `/${PatientId}`);
  }, [PatientId]);

  const handleWeighted = useCallback(() => {
    const { checkAmount } = paymentForm?.getFieldsValue();
    if (checkAmount === undefined) {
      paymentForm.setFields([{
        name: 'checkAmount',
        value: undefined,
        errors: ['Amount is required'],
      }]);
    } else {
      let totalBalance = 0;
      // eslint-disable-next-line no-unused-expressions
      lineItems?.forEach((item) => {
        totalBalance += item?.balance ? parseFloat(item?.balance) : 0;
      });
      if (checkAmount >= totalBalance) {
        setLineItems(lineItems?.map((item) => ({
          ...item,
          applied: parseFloat(Number(parseFloat(item?.balance || 0))).toFixed(2),
          isEdited: true,
          pageNo: !isElectronicBatch ? form.getFieldValue('pageNo') || currentPageNo : null,
        })));
      } else {
        const percentage = (checkAmount / totalBalance) * 100;
        let balance = 0;
        setLineItems(lineItems?.map((item, index) => {
          balance += Number(parseFloat((item?.balance * percentage) / 100).toFixed(2));
          let deductBalance = 0;
          if (index === lineItems?.length - 1 && balance > checkAmount) {
            deductBalance = balance - checkAmount;
          }
          return {
            ...item,
            applied: Number((parseFloat(
              (item?.balance * percentage) / 100,
            ) - deductBalance).toFixed(2)),
            isEdited: true,
            pageNo: !isElectronicBatch ? form.getFieldValue('pageNo') || currentPageNo : null,
          };
        }));
      }
    }
  }, [currentPageNo, form, isElectronicBatch, lineItems, paymentForm, setLineItems]);

  const handleChronologically = useCallback(() => {
    let { checkAmount } = paymentForm?.getFieldsValue();
    if (checkAmount === undefined) {
      paymentForm.setFields([{
        name: 'checkAmount',
        value: undefined,
        errors: ['Amount is required'],
      }]);
    } else {
      // eslint-disable-next-line no-unused-expressions
      setLineItems(lineItems?.map((item) => {
        const lineItemBalance = item?.balance ? parseFloat(item?.balance).toFixed(2) : 0;
        if (lineItemBalance > checkAmount) {
          const applied = checkAmount;
          checkAmount = 0;
          return ({
            ...item,
            applied,
            isEdited: true,
            pageNo: !isElectronicBatch ? form.getFieldValue('pageNo') || currentPageNo : null,
          });
        }
        if (lineItemBalance <= 0) {
          return ({
            ...item,
            applied: 0,
            isEdited: true,
            pageNo: !isElectronicBatch ? form.getFieldValue('pageNo') || currentPageNo : null,
          });
        }
        checkAmount -= lineItemBalance;
        return ({
          ...item,
          applied: lineItemBalance,
          isEdited: true,
          pageNo: !isElectronicBatch ? currentPageNo : null,
        });
      }));
    }
  }, [currentPageNo, form, isElectronicBatch, lineItems, paymentForm, setLineItems]);

  const hasCreditPayment = useMemo(() => lineItems?.some((item) => item.description === 'Credit Payment'),
    [lineItems]);

  useEffect(() => {
    const isClaimsPosted = lineItems?.every((item) => item?.balance <= 0);
    const isLineItemEdited = lineItems?.some((item) => item?.isEdited);

    if (isEditWorkList) {
      if (hasCreditPayment) {
        setClaimPosted(false);
      } else {
        setClaimPosted(isClaimsPosted || !isLineItemEdited);
      }
    }

    if (hasCreditPayment) {
      setIsAllClaimPost(false);
    } else {
      setIsAllClaimPost(isClaimsPosted || !isLineItemEdited);
    }
  }, [lineItems, hasCreditPayment, isEditWorkList]);

  const parsedEditedLineItems = useMemo(() => {
    const filteredLineItems = [];
    lineItems?.forEach((item) => {
      if (item?.isEdited) {
        // eslint-disable-next-line no-param-reassign
        item.pageNo = !isElectronicBatch ? form?.getFieldValue('pageNo') || currentPageNo : null;
        filteredLineItems.push({ ...item, applied: parseFloat(item.applied).toFixed(2) });
      }
    });
    if (isEditWorkList) { setPatientData(filteredLineItems); }
    return filteredLineItems;
  }, [currentPageNo, form, isEditWorkList, isElectronicBatch, lineItems, setPatientData]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({ message: SuccessMessages.PATIENT_PAYMENT_ADDED_SUCCESSFULLY, success: true });
      Events.trigger(`refetch-view-entered-item-details${remittancePaymentId}`);
      Events.trigger('refetchBatchDetails');
      paymentForm.setFieldsValue({
        pageNo: parseInt(pdfPageNo, 10),
      });
      if (setClaimPageNo) {
        setClaimPageNo(parseInt(pdfPageNo, 10) || 1);
      }
      if (setNeedToScroll) {
        setNeedToScroll(false);
      }
      Events.trigger('PDF_VIEWER', { pageNo: parseInt(pdfPageNo || 1, 10) });
      Events.trigger('PDF_VIEWER_PAGE_NO', { pageNo: parseInt(pdfPageNo || 1, 10), shouldTriggerClaimPage: false });
      if (!isEditWorkList) {
        navigateToPostedClaims();
      }
    }
  }, [isEditWorkList, navigateToPostedClaims, paymentForm, pdfPageNo, remittancePaymentId,
    setClaimPageNo, setNeedToScroll]);

  const handleSubmit = useCallback(async () => {
    const isValidated = await formValidation(true, labels, totalNoOfPages);
    if (!isValidated) return;

    const allZeroApplied = lineItems.every((item) => parseFloat(item.applied) === 0);

    if (allZeroApplied) {
      Notification({ message: 'Applied amount cannot be zero' });
      return;
    }

    form.submit();
  }, [formValidation, labels, totalNoOfPages, lineItems, form]);

  return (
    <Form
      form={form}
      section
      formId={formId.PATIENT_FINANCIAL_PAYMENT}
      extraData={() => {
        const lineItemsWithoutDescription = parsedEditedLineItems.map((item) => {
          const { description, ...itemWithoutDescription } = item;
          return itemWithoutDescription;
        });

        return ({
          remittanceBatchId: parseInt(remittanceId, 10),
          lineItems: lineItemsWithoutDescription,
          method: 'POST',
          transactionTypeId,
          paymentId: parseInt(remittancePaymentId, 10),
          pageNo: !isElectronicBatch ? form?.getFieldValue('pageNo') || currentPageNo : null,
          isPatientPayment: true,
          isCreditPayment: hasCreditPayment,
        });
      }}
      url={apiUrls.EDIT_PATIENT_PAYMENT}
      onRequestComplete={onRequestComplete}
    >
      <div className="flex justify-content-sp-bt align-center mr-top-8 mr-bt-8">
        <div>
          <p style={{ color: '#83888f' }}> Patient Demographic </p>
          <div className="flex align-center patient-name-title">
            <label
              title="Patient Name"
              htmlFor="patient-name"
            >
              {labels.get('patientName')}
              :
            </label>
            {patientInfoLoading && <LoadingOutlined spin />}
            {!patientInfoLoading && <span>{`${patientInfo?.firstName || ''} ${patientInfo?.middleName ? patientInfo.middleName : ''} ${patientInfo?.lastName || ''}`}</span>}
          </div>
          <div className="flex align-center patient-name-title">
            <label
              title="Patient DOB"
              htmlFor="patient-dob"
            >
              DOB
              :
            </label>
            {patientInfoLoading && <LoadingOutlined spin />}
            {!patientInfoLoading && <span>{dateFormatter(patientInfo?.dateOfBirth?.split('T')?.[0])}</span>}
            {' '}
          </div>
          <div className="flex align-center patient-name-title">
            <label
              title="Patient MRN"
              htmlFor="patient-dob"
            >
              Chart No.
              :
            </label>
            {patientInfoLoading && <LoadingOutlined spin />}
            {!patientInfoLoading && <span>{`${patientInfo?.chartNumber && patientInfo.chartNumber}`}</span>}
          </div>
        </div>
      </div>
      <div className="flex justify-content-sp-bt collection-service-wrap">
        <div className="flex align-center mr-top-8" />
        <div className="group-btns justify-content-flex-end flex align-center mr-top-8 mr-bottom-8" key="footer">
          <Button
            className="btn btn-success sm-btn"
            onClick={handleWeighted}
            data-testid="patient-payment-weighted"
          >
            {labels.get('buttons.weighted')}
          </Button>
          <Button
            className="btn btn-success sm-btn inline"
            onClick={handleChronologically}
            data-testid="patient-payment-chronological"
          >
            {labels.get('buttons.chronological')}
          </Button>
          { !isEditWorkList
            ? (
              <ActionIcons
                userNotes
                edit
                demoGraphic
                transaction
                insurance
                notesProps={{
                  page: 'patientpaymentremittance',
                }}
                patientId={PatientId}
              />
            ) : null}
        </div>
      </div>
      <div className="service-table-container mr-top-12">
        <PaymentTable
          columns={columns(labels, billingState)}
          filters={{
            PatientId,
            paymentId: remittancePaymentId,
            isPatientPayment: true,
            remittanceBatchId: remittanceId,
          }}
          scrollId="patient-financial-service-table"
          getLineItemSetter={getLineItemSetter}
          isValueUptoTwoDecimalPlaces={isValueUptoTwoDecimalPlaces}
          lineItems={lineItems}
          showNoDataText={false}
          footer
        >
          {({ Component, data }) => (
            <TableWrapper
              Component={Component}
              data={data}
              initializeLineItems={initializeLineItems}
            />
          )}
        </PaymentTable>
      </div>
      { !isEditWorkList
        ? (
          <div className="flex mr-top-16 flex-end">
            <button
              type="button"
              className={classNames('btn mr-rt-16 sm-btn', {
                'btn-success': !isAllClaimsPosted,
                'cursor-not-allowed': isAllClaimsPosted,
              })}
              onClick={handleSubmit}
              disabled={isAllClaimsPosted}
            >
              {labels.get('post')}
            </button>
            <button
              type="button"
              className="btn btn-primary sm-btn"
              onClick={onCancel}
            >
              {labels.get('cancel')}
            </button>
          </div>
        ) : null}
    </Form>
  );
};

export default RemittancePatientPayment;
