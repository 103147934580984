export const baseUrl = process.env.REACT_APP_API_PATH;

export const apiUrls = {
  LOGIN: 'identityservice/user/authenticate',
  LOGOUT: 'identityservice/user/logout',
  CHANGE_PASSWORD: 'identityservice/user/AdminBulkSetPassword',
  GET_ENUM: 'masterservice/EnumMasters',
  GET_ENUM_FIELDS: 'masterservice/Masters',
  GET_PATIENT: 'masterservice/Patient',
  GET_CLAIMS: 'masterservice/Claims',
  LOG_ERROR_BOUNDARY_ERROR_MESSAGE: 'masterservice/masters/LogException',
  GET_COLLECTION_CLAIMS: 'masterservice/ClaimsCollections',
  GET_ERRORS: 'masterservice/Claims/Assemble',
  OVERRIDE_ERROR: 'masterservice/Claims/OverrideClaimErrorAssemble',
  PATIENT_CLAIM_TABLE: 'masterservice/CreateNewClaimTable',
  GET_COLLECTIONS: 'masterservice/Collections',
  GET_REMITTANCE_LIST: 'masterservice/RemittanceBatch/RemittanceBatchList',
  GET_LOCATION: 'masterservice/locations',
  GET_PROVIDER: 'masterservice/Provider',
  GET_PROVIDER_WITH_SUGGESTIVE_SEARCH: 'masterservice/Provider/SuggestiveSearch',
  GET_VISIT_TYPES: 'schedulerservice/visittype',
  GET_EXAM_TYPES: 'schedulerservice/ExamType',
  UPDATE_EXAM_TYPE: 'schedulerservice/ExamType',
  GET_LOCATIONS: 'masterservice/locations/SuggestiveSearch',
  GET_PROVIDERS: 'masterservice/provider/suggestivesearch',
  GET_CREDENTIALS: 'masterservice/ProviderCredentials',
  ADD_PATIENT: 'masterservice/Patient',
  ADD_PROVIDER: 'masterservice/Provider',
  ADD_LOCATIONS: 'masterservice/Locations',
  UPDATE_PATIENT: 'masterservice/Patient',
  UPDATE_LOCATION: 'masterservice/locations',
  REQUEST_NEW_PAYER: 'masterservice/InsurancePayer',
  REQUEST_PAYER_MAPPING: 'masterservice/InsurancePayer/PayerMappingConfiguration',
  GET_PAYER_MAPPING: 'masterservice/InsurancePayer',
  COPY_CONFIGURATION: 'masterservice/InsurancePayer/CopyConfiguration',
  UPDATE_PROVIDER: 'masterservice/Provider',
  ZIP_LOOKUP: '/masterservice/masters/zipcodelookup',
  SPECIALTY_LOOKUP: '/masterservice/masters/',
  GET_PAYERS: 'masterservice/InsurancePayer',
  GET_CPT_PAYER_MAPPING: 'masterservice/CodeMaster/GetCodePayerMapping',
  DEL_CPT_PAYER_MAPPING: 'masterservice/CodeMaster/DeleteCodePayerMapping',
  GET_DOCUMENT_CATEGORY: 'masterservice/Masters',
  GET_DOCUMENT_COUNTS: 'masterservice/PatientDocument/Categories',
  GET_PATIENT_DOCUMENT: 'masterservice/PatientDocument',
  GET_DOCTOR_TECHNICIAN_DOCUMENTS: 'masterservice/PatientDocument/FilteredDocuments',
  SEARCH_PATIENT_DOCUMENT: 'masterservice/PatientDocument/FilteredDocuments',
  UPLOAD_PATIENT_DOCUMENT: 'masterservice/PatientDocument',
  ROLE_BASED_USER_LIST: 'identityservice/user/RoleBasedUserList',
  GET_USERS: 'identityservice/user',
  ADD_USER: 'identityservice/user',
  UPDATE_USER: '/identityservice/user',
  GET_USER: 'identityservice/user',
  GET_SETTINGS_ICD: '/masterservice/CodeMaster/GetICDCode',
  ADD_NEW_CPT: 'masterservice/CodeMaster/AddCPTCode',
  SET_USER_PROFILE: '/identityservice/userprofile',
  GET_USER_PROFILE: 'identityservice/userprofile',
  GET_USER_PROFILE_ROLES: 'identityservice/masterroles',
  GET_PLACE_OF_SERVICE: 'masterservice/ProviderCredentials/PlaceOfService',
  ADD_CREDENTIAL: 'masterservice/ProviderCredentials',
  CASES_LIST: 'masterservice/case',
  EDIT_CREDENTIAL: 'masterservice/ProviderCredentials',
  GET_CREDENTIAL: 'masterservice/ProviderCredentials',
  ADD_NEW_CASE: 'masterservice/case',
  UPDATE_CASE: 'masterservice/case',
  GET_CASE: 'masterservice/case',
  ADD_BILLING: 'masterservice/ProviderBilling',
  GET_BILLINGS: 'masterservice/ProviderBilling',
  UPDATE_BILLINGS: 'masterservice/ProviderBilling',
  GET_USER_RIGHTS_MASTER: 'identityservice/rightsmaster',
  GET_USER_RIGHTS: 'identityservice/userclaims',
  ADD_CPT_ICD_MAPPING: 'masterservice/CodeMaster/CodePayerMapping',
  GET_CPTS: 'masterservice/CodeMaster/GetCPTCode',
  GET_CPT_PAGINATED: 'masterservice/CodeMaster/Paginated/GetCPTCode',
  GET_MODIFIER_CPTS: 'masterservice/ContractTermModifierException/Modifier',
  GET_MODIFIERS: '/masterservice/CodeMaster/GetModfiers',
  ADD_AUTHORIZATION: 'masterservice/authorization',
  GET_AUTHORIZATION_LIST: 'masterservice/authorization',
  UPDATE_USER_RIGHTS: 'identityservice/userclaims',
  DELETE_PATIENT_DOCUMENT: 'masterservice/PatientDocument',
  UPDATE_AUTHORIZATION: 'masterservice/authorization',
  GET_AUTHORIZATION_BY_ID: 'masterservice/Authorization/AuthorizationById',
  GET_EXAMROOM_LIST: 'masterservice/ExamRoom',
  Add_EXAMROOM: 'masterservice/ExamRoom',
  UPDATE_EXAMROOM: 'masterservice/ExamRoom',
  GET_EXAMROOM_DETAIL: 'masterservice/ExamRoom',
  ADD_CONTRACT_GENERAL: 'masterservice/Contract',
  GET_CONTRACT: 'masterservice/Contract',
  UPDATE_CONTRACT: 'masterservice/Contract',
  GET_SCHEDULAR_SETTINGS_VISIT_TYPES: 'schedulerservice/VisitType',
  GET_SCHEDULAR_SETTINGS_EXAM_TYPES_LIST: '/schedulerservice/examtype',
  GET_RESOURCES: '/schedularSettings/resources',
  USER_SUGGESTIVE_SEARCH: 'identityservice/user/SuggestiveUserList',
  GET_TECHNICIAN_MEDICATION_LIST: '/technician/medications/currentmedications',
  GET_BLOCK_OUT_LIST: 'schedulerservice/Blockout/BlockoutList',
  GET_TEMPLATE_LIST: 'schedulerservice/Template/TemplateList',
  GET_REFERRING_PHYSICIAN: 'masterservice/ReferringPhysician/SuggestiveSearch',
  GET_ENCOUNTER_LIST: 'masterservice/encounter',
  GET_HISTORY_ENCOUNTER_LIST: 'masterservice/encounter/history',
  GET_RULES_EVALUATION: 'masterservice/encounter/RulesEngineGetEvaluationStatus',
  DOCTOR_ENCOUNTER_DETAIL_PROCEDURE: 'doctor/encounterDetail/procedure',
  ADD_ENCOUNTER: 'masterservice/encounter',
  ADD_SCHEDULAR_SETTINGS_EXAM_TYPE: 'schedulerservice/examtype',
  ADD_VISIT_TYPE: 'schedulerservice/VisitType',
  UPDATE_VISIT_TYPE: 'schedulerservice/VisitType',
  EDIT_PAYER: 'masterservice/InsurancePayer',
  AUTHORIZATION_LIST_DROPDOWN: 'masterservice/Authorization/AuthorizationList',
  INSURANCE_PROFILE_DROPDOWN: 'masterservice/PatientInsurance/InsuranceProfileList',
  GET_PATIENT_INSURANCE: '/masterservice/PatientInsurance',
  EDIT_PATIENT_INSURANCE: '/masterservice/PatientInsurance',
  UPDATE_PATIENT_INSURANCE: 'masterservice/PatientInsurance/UpdateSelfInsuranceProfile',
  UPDATE_PATIENT_INSURANCE_PROFILE: 'masterservice/PatientInsurance/UpdateResponsibleParty',
  UPDATE_APPOINTMENT_INSURANCE_FROM_DASHBOARD: 'schedulerservice/Appointment/UpdateAppointmentInsurance',
  ADD_SELF_INSURANCE: 'masterservice/PatientInsurance/AddSelfInsurance',
  ADD_PERSON: 'masterservice/Person',
  GET_PERSON_BY_ID: 'masterservice/Person/GetPersonById',
  GET_RESPONSIBLE_PARTY: 'masterservice/Person',
  GET_RESPONSIBLE_PARTY_BY_ID: 'masterservice/Person/GetPersonById',
  GET_SELF_PAY_INSURANCE_PROFILE_BY_ID: '/masterservice/PatientInsurance',
  DOCTOR_PROCEDURES: 'doctorservice/PatientProcedureCode',
  ADD_PATIENT_PROCEDURE: 'doctorservice/PatientProcedureCode',
  DOCTOR_IMAGING_HISTORY: 'masterservice/doctor/imagingHistory',
  CLAIM_DETAILS_LIST: '/patient/bill/details',
  GET_ENCOUNTER_REVIEW_LIST: '/doctor/encounterReview',
  GET_SENSORIMOTOR_SECTIONS: 'doctorservice/Sensorimotor',
  UPDATE_SENSORIMOTOR_SECTION_1: 'doctorservice/Sensorimotor/FirstSection',
  UPDATE_SENSORIMOTOR_SECTION_2: 'doctorservice/Sensorimotor/SecondSection',
  UPDATE_SENSORIMOTOR_SECTION_3: 'doctorservice/Sensorimotor/SterioVision',
  GET_ALLERGIES: 'doctorservice/Allergiesmaster',
  ADD_TECHNICIAN_EXAM_VISION: 'doctorservice/PatientEncounterVision',
  GET_TECHNICIAN_EXAM_VITALS: 'doctorservice/PatientEncounterVital',
  UPDATE_TECHNICIAN_EXAM_VITALS: 'doctorservice/PatientEncounterVital',
  ADD_TECHNICIAN_EXAM_PRESSURE: 'doctorservice/PatientEncounterPressure',
  ADD_SECONDARY_INSURANCE_POLICY: '/masterservice/PatientInsurance/AddSecondayInsurance',
  GET_CANCELLATIONS: 'masterservice/Masters/GetMastersByEnumId',
  UPDATE_CANCELLATION_REASON: 'masterservice/Masters',
  ADD_CANCELLATION_REASON: 'masterservice/Masters',
  RESET_PASSWORD: '/identityservice/user/setpassword',
  ADD_APPOINTMENT: 'schedulerservice/Appointment',
  ADD_TEMPLATE: 'schedulerservice/Template',
  EDIT_TEMPLATE: 'schedulerservice/Template',
  GET_TEMPLATE: 'schedulerservice/Template',
  GET_SCHEDULER_DATA: 'schedulerservice/Appointment/GetProvidersAppointment',
  GET_SCHEDULER_FREE_SLOTS: 'schedulerservice/Appointment/GetProvidersFreeSlot',
  GET_MONTHLY_SCHEDULER_DATA: 'schedulerservice/Appointment/GetProviderMonthView',
  SCHEDULAR_PROVIDER_DROPDOWN: 'masterservice/Provider/ProviderListForScheduler',
  SCHEDULAR_LOCATION_DROPDOWN: 'masterservice/Locations/LocationListForScheduler',
  CONTRACT_PAYER_DROPDOWN: 'masterservice/InsurancePayer/GetContractPayers',
  GET_LIST_VIEW_SCHEDULAR: 'schedulerservice/Appointment/GetListViewScheduler',
  CANCEL_APPOINTMENT: 'schedulerservice/Appointment/CancelAppointment',
  RESCHEDULE_APPOINTMENT: 'schedulerservice/Appointment/RescheduleAppointment',
  FORGOT_PASSWORD: '/identityservice/user/forgotpassword',
  ADD_CONTRACT_TERM_GENERAL: 'masterservice/ContractTerm',
  UPDATE_CONTRACT_TERM_GENERAL: 'masterservice/ContractTerm',
  GET_CONTRACT_TERM_DETAIL: 'masterservice/ContractTerm',
  GET_CONTRACT_TERM: 'masterservice/ContractTerm',
  ADD_BLOCKOUT: '/schedulerservice/Blockout',
  GET_BOOK_APPOINTMENT: 'masterservice/Provider/LocationsWithinRadius',
  GET_BILLING_ENCOUNTER: 'masterservice/billingEncounter',
  GET_APPOINTMENT_DETAIL: 'schedulerservice/Appointment',
  GET_PATIENT_BILL_STATEMENT: '/patient/bill/statement',
  GET_APPOINTMENT_CARDS: 'schedulerservice/Appointment/GetAppointmentCards',
  ADD_CC_HPI: 'doctorservice/EnconterCC',
  GET_CC_HPI: 'doctorservice/EnconterCC',
  UPDATE_CC_HPI: 'doctorservice/EnconterCC',
  GET_APPOINTMENTS_LIST: 'schedulerservice/Appointment/GetPatientAppointment',
  GET_FEE_PREFERENCE_GENERAL: 'masterservice/FeePreferencesGeneral',
  UPDATE_FEE_PREFERENCE_GENERAL: 'masterservice/FeePreferencesGeneral',
  GET_TECHNICIAN_COLORS_SCHIRMERS: 'doctorservice/PatientColorSchirmer',
  UPDATE_TECHNICIAN_COLORS_SCHIRMERS: 'doctorservice/PatientColorSchirmer',
  GET_PATIENT_REFRACTION: 'doctorservice/PatientRefraction',
  CREATE_PATIENT_REFRACTION: 'doctorservice/PatientRefraction',
  UPDATE_PATIENT_REFRACTION: 'doctorservice/PatientRefraction',
  DELETE_PATIENT_REFRACTION: 'doctorservice/PatientRefraction',
  GET_TECHNICIAN_DEMOGRAPHIC: 'schedulerservice/Appointment/GetDemographicInfo',
  SCHEDULER_UPDATE_APPOINTMENT_STATUS: 'schedulerservice/Appointment/UpdateAppointmentStatus',
  GET_PATIENT_ENCOUNTER_LIST: 'doctorservice/PatientEncounter/GetVersionedPatientEncounterList',
  GET_PATIENT_ALLERGY_LIST: 'doctorservice/PatientAllergies',
  GET_PATIENT_ALLERGIES: 'doctorservice/PatientAllergies/PullPatientAllergies',
  ADD_PATIENT_ALLERGY: 'doctorservice/PatientAllergies',
  DELETE_ALLERGY: 'doctorservice/PatientAllergies',
  DELETE_ALL_ALLERGIES: 'doctorservice/PatientAllergies/DeleteAllAllergies',
  CONTRACT_TERMS_EXCEPTION_LIST: '/masterservice/ContractTermException',
  ADD_CONTRACT_TERMS_EXCEPTION: '/masterservice/ContractTermException',
  UPDATE_CONTRACT_TERMS_EXCEPTION: '/masterservice/ContractTermException',
  DELETE_CONTRACT_TERMS_EXCEPTION: '/masterservice/ContractTermException',
  GET_QUESTIONNAIRES_MASTER_DATA: 'doctorservice/QuestionnairesMaster',
  GET_IFRAME_URL: 'doctorservice/PatientEncounterPrescription/Iframe/GetUrl',
  GET_SUPER_BILL_QUESTIONNAIRE: 'doctorservice/PatientEncounterSuperBillQuestionnaire',
  UPDATE_SUPER_BILL_QUESTIONNAIRE: 'doctorservice/PatientEncounterSuperBillQuestionnaire',
  GET_STATEMENT_DETAIL_LIST: 'patient/bill/statement/statementDetail',
  GET_APPOINTMENT_DURATION: 'schedulerservice/Appointment/GetAppointmentDuration',
  BLOCK_OUT: 'schedulerservice/Blockout',
  GET_ENCOUNTER_VISION: 'doctorservice/PatientEncounterVision',
  GET_ENCOUNTER_PRESSURE: 'doctorservice/PatientEncounterPressure',
  GET__APPOINTMENT_CONFIRMATION_HISTORY: '/schedulerservice/Appointment/GetAppointmentConfirmationHistory',
  GET_PATIENT_LIST_APPOINTMENTS: '/schedulerservice/Appointment/GetPatientAppointment',
  UPDATE_BASICS: 'schedulerservice/AppointmentSetting/AddUpdateAppointmentSetting',
  GET_BASICS: 'schedulerservice/AppointmentSetting',
  SAVE_PMFSHX: 'doctorservice/PatientQuestionnairesPMFSHX',
  ADD_UPDATE_FEE_PREFERENCES_EXCEPTION: '/masterservice/FeePreferencesException',
  PROVIDER_FEE_PREFERENCES_EXCEPTION: 'masterservice/FeePreferencesException',
  GET_TECHNICIAN_MOBILITY: 'doctorservice/PatientEnconterMotility',
  SAVE_MOBILITY: 'doctorservice/PatientEnconterMotility',
  UPDATE_MOBILITY: 'doctorservice/PatientEnconterMotility',
  GET_TECHNICIAN_MOTILITY: 'doctorservice/PatientEnconterMotility',
  SAVE_MOTILITY: 'doctorservice/PatientEnconterMotility',
  UPDATE_MOTILITY: 'doctorservice/PatientEnconterMotility',
  SAVE_ROS: 'doctorservice/PatientQuestionnairesROS',
  GET_OCULAR_PROBLEM_LIST: 'doctorservice/EncounterOcularProblem',
  DELETE_OCULAR_PROBLEM: 'doctorservice/EncounterOcularProblem',
  PAYER_EXPORT: 'masterservice/InsurancePayer/GetExportPayer',
  PAYER_IMPORT: 'masterservice/InsurancePayer/ImportInsurancePayers',
  PRESSURE_GRAPH: 'doctorservice/PatientEncounterPressure/Graph',
  VISION_GRAPH: 'doctorservice/PatientEncounterVision/Graph',
  SAVE_SLIT_EXAM: 'doctorservice/PatientEncounterSlitLampExam',
  SAVE_FUNDUS_EXAM: 'doctorservice/PatientEncounterFundusExam',
  GET_SLIT_EXAM: 'doctorservice/PatientEncounterSlitLampExam',
  GET_FUNDUS_EXAM: 'doctorservice/PatientEncounterFundusExam',
  CPT_IMPORT: 'masterservice/CodeMaster/ImportExcel',
  ADD_OCULAR_PROBLEM: 'doctorservice/EncounterOcularProblem',
  ADD_OCULAR_FAVORITE: 'doctorservice/ProviderOcularFavorite',
  ADD_OCULAR_FOLDER: 'doctorservice/ProviderOcularFavorite/Folder',
  GET_OCULAR_FAVOURITES_AND_FOLDERS: 'doctorservice/ProviderOcularFavorite/GetAllFavoritesAndFolder',
  DELETE_OCULAR_FOLDER: 'doctorservice/ProviderOcularFavorite/Folder',
  ADD_OCULAR_GENERAL_FAVORITE: 'doctorservice/GeneralOcularFavorite',
  ADD_OCULAR_GENERAL_FOLDER: 'doctorservice/GeneralOcularFavorite/Folder',
  GET_OCULAR_GENERAL_FAVOURITES_AND_FOLDERS: 'doctorservice/GeneralOcularFavorite/GetAllFavoritesAndFolder',
  DELETE_GENERAL_OCULAR_FOLDER: 'doctorservice/GeneralOcularFavorite/Folder',
  GET_OCULAR_GENERAL_FAVOURITES_OF_A_FOLDER: 'doctorservice/GeneralOcularFavorite/FavoritesOfaFolder',
  GET_OCULAR_FAVOURITES_OF_A_FOLDER: 'doctorservice/ProviderOcularFavorite/FavoritesOfaFolder',
  FORWARD_OCULAR_FAVOURITE: 'doctorservice/ProviderOcularFavorite/MapFavoriteToFolder',
  FORWARD_GENERAL_OCULAR_FAVOURITE: 'doctorservice/GeneralOcularFavorite/MapFavoriteToFolder',
  UPDATE_OCULAR_FAVOURITE: 'doctorservice/ProviderOcularFavorite',
  UPDATE_GENERAL_OCULAR_FAVOURITE: 'doctorservice/GeneralOcularFavorite',
  QUESTIONNAIRES_MASTER_PROCEDURE_HISTORY: 'doctorservice/QuestionnairesMaster',
  ADD_PROCEDURE_FAVORITE: 'doctorservice/PatientProcedureCodeFavourite',
  ADD_PROCEDURE_FOLDER: 'doctorservice/PatientProcedureCodeFavourite/Folder',
  GET_PROCEDURE_FAVOURITES_AND_FOLDERS: 'doctorservice/PatientProcedureCodeFavourite/GetAllfavouritesAndFolder',
  DELETE_PROCEDURE_FOLDER: 'doctorservice/PatientProcedureCodeFavourite/Folder',
  FORWARD_PROCEDURE_FAVOURITE: 'doctorservice/PatientProcedureCodeFavourite/MapfavouriteToFolder',
  UPDATE_PROCEDURE_FAVOURITE: 'doctorservice/PatientProcedureCodeFavourite',
  GET_PROCEDURE_FAVOURITES_OF_A_FOLDER: 'doctorservice/PatientProcedureCodeFavourite/favouritesOfaFolder',
  DELETE_PROCEDURE_FAVOURITE: 'doctorservice/PatientProcedureCodeFavourite/favourite',
  GET_IMAGING_HISTORY: 'doctorservice/EncounterImage/BundleList',
  GET_PMFSHX_STATUS: 'doctorservice/PatientQuestionnairesPMFSHX/GetPMFSHXReviewStatus',
  UPDATE_PMFSHX_STATUS: 'doctorservice/PatientQuestionnairesPMFSHX/UpsertPMFSHXReviewStatus',
  ADD_ASSESSMENT_AND_PLAN: 'doctorservice/PatientEncounter',
  SAVE_DRAW_IMAGE: 'doctorservice/PatientEncounterDrawing',
  GET_ENCOUNTER_DRAWING_LIST: 'doctorservice/PatientEncounterDrawing/Encounter',
  UPDATE_ENCOUNTER_DRAWING: 'doctorservice/PatientEncounterDrawing',
  CONTRACT_TERMS_MODIFIER_LIST: '/masterservice/ContractTermModifierException',
  ADD_CONTRACT_TERMS_MODIFIER: '/masterservice/ContractTermModifierException',
  UPDATE_CONTRACT_TERMS_MODIFIER: '/masterservice/ContractTermModifierException',
  DELETE_CONTRACT_TERMS_MODIFIER: '/masterservice/ContractTermModifierException',
  IMAGES_TEST_TYPES: 'doctorservice/TestTypeMaster',
  GET_BUNDLED_IMAGES: 'doctorservice/EncounterImage/BundleImages',
  UPDATE_IMAGE_OSOD_TYPE: 'doctorservice/EncounterImage/SetODOSValue',
  UPLOAD_ENCOUNTER_IMAGES: 'doctorservice/EncounterImage/UploadImage',
  UPSERT_ENCOUNTER_IMAGES: 'doctorservice/EncounterImage/UpsertEncounterBundle',
  UPLOAD_DEFAULT_DRAW_IMAGES: 'masterservice/FileOperations/UploadFile',
  UPDATE_ENCOUNTER_IMAGE_TEST_TYPE: 'doctorservice/EncounterImage/UpdateTestType',
  DELETE_ENCOUNTER_IMAGE: 'doctorservice/EncounterImage',
  APPLY_FAVOURITE: 'doctorservice/EncounterOcularProblem/Multiple',
  DOCTOR_ENCOUNTER_CHIEF_COMPLAINT: 'doctorservice/EnconterCC/encounter',
  GET_ENCOUNTER_HPI: 'doctorservice/EnconterCC',
  UPDATE_DOCTOR_ENCOUNTER_HPI: 'doctorservice/EnconterCC/encounter',
  UPDATE_ALL_NORMAL: 'doctorservice/PatientQuestionnairesPMFSHX/UpdatePMFSHXStatus',
  COPY_OCULAR_PROBLEMS: 'doctorservice/EncounterOcularProblem/PreviousEncounter',
  EXPORT_CONTRACTS: 'masterservice/Contract/GetExportContract',
  GET_ICD_CODE: 'masterservice/CodeMaster/paginated/GetICDCode',
  SAVE_OUTSIDE_TEST: 'doctorservice/PatientEncounterOutsideTesting',
  DELETE_OUTSIDE_TEST: 'doctorservice/PatientEncounterOutsideTesting/Encounter',
  GET_OUTSIDE_TEST: 'doctorservice/PatientEncounterOutsideTesting/Encounter',
  GET_PATIENT_ENCOUNTERS_LIST: '/patient/encounter/newEncounter',
  ADD_DOCTOR_FOLLOW_UP: '/doctorservice/PatientFollowup',
  GET_DOCTOR_FOLLOW_UP: '/doctorservice/PatientFollowup',
  APPLY_SUPER_BILL_CODES: '/doctorservice/PatientEncounterSuperBillQuestionnaire/TestingProcedurePerformed',
  UPDATE_DOCTOR_FOLLOW_UP: '/doctorservice/PatientFollowup',
  GET_DOCTOR_FOLLOW_UP_EXAM_TYPE: 'schedulerservice/ExamType',
  GET_DOCTOR_FOLLOW_UP_VISIT_TYPE: 'schedulerservice/VisitType',
  GET_DOCTOR_FOLLOW_UP_PROCEDURE_CODE: 'doctorservice/PatientProcedureCodeFavourite/ProviderfavouritesProcedureCodeList',
  ADD_OUTSIDE_TEST_FAVOURITE: 'DoctorService/OutsideTestingFavorite',
  ADD_OUTSIDE_TEST_FOLDER: 'DoctorService/OutsideTestingFavorite/addFolder',
  GET_OUTSIDE_TEST_FAVOURITES_AND_FOLDERS: 'DoctorService/OutsideTestingFavorite/GetAllFavoritesAndFolder',
  DELETE_OUTSIDE_TEST_FOLDER: 'DoctorService/OutsideTestingFavorite/Folder',
  UPDATE_OUTSIDE_TEST_FAVOURITE: 'DoctorService/OutsideTestingFavorite',
  DELETE_OUTSIDE_TEST_FAVOURITE: 'DoctorService/OutsideTestingFavorite/Favorite',
  FORWARD_OUTSIDE_TEST_FAVOURITE: 'DoctorService/OutsideTestingFavorite/MapFavoriteToFolder',
  GET_OUTSIDE_TEST_OF_A_FOLDER: 'DoctorService/OutsideTestingFavorite/GetFolderFavorite',
  PRINT_REFRACT: 'doctorservice/patientrefraction/wearingpdf',
  ADD_DOCTOR_PRESSURE_GOAL: 'doctorservice/PatientEncounter/PressureGoal',
  GET_ENCOUNTER_BY_ID: 'doctorservice/PatientEncounter/GetPatientEncounterList',
  GET_MEDICATION_TOKEN: 'doctorservice/PatientEncounterPrescription/SaveNewPrescription',
  GET_MEDICATION_LIST: 'doctorservice/PatientEncounterPrescription/GetPatientEncounterPrescription',
  DOCTOR_FOLLOW_UP_REFERRAL: 'doctorservice/PatientEcounterReferralPhysician',
  CONFIRM_EMAIL: 'masterservice/Patient/confirmemail',
  GET_PROVIDERS_ADDRESS_BOOK: 'masterservice/ProviderAddressBook/AddressBookList',
  ADD_ADDRESS_BOOK: 'masterservice/ProviderAddressBook',
  UPDATE_ADDRESS_BOOK: 'masterservice/ProviderAddressBook',
  PHYSICAL_CARE_TEAM_ADD: 'masterservice/PatientCareTeam',
  PHYSICAL_CARE_TEAM_LIST: 'masterservice/PatientCareTeam/CareTeamList',
  PHYSICAL_CARE_TEAM_UPDATE: 'masterservice/PatientCareTeam',
  PHYSICAL_CARE_TEAM_DELETE: 'masterservice/PatientCareTeam',
  DELETE_DRAW_TAB: 'doctorService/PatientEncounterDrawing/Encounter',
  GET_CARE_TEAM: 'masterservice/PatientCareTeam/CareTeamList',
  DELETE_CARE_TEAM: 'masterservice/PatientCareTeam',
  APPOINTMENT_COUNT: '/schedulerservice/Appointment/GetFrontdeskAppointmentCount',
  GET_ACTIVITY: '/schedulerservice/Appointment',
  ROS_REVIEWED: 'doctorservice/PatientQuestionnairesROS/ROSReviewNoChanges',
  DEFAULT_SLIT_VALUES: 'doctorservice/PatientEncounterSlitLampExam',
  DEFAULT_FUNDUS_VALUES: 'doctorservice/PatientEncounterFundusExam',
  CLEAR_SLIT_VALUES: 'doctorservice/PatientEncounterSlitLampExam/Delete',
  CLEAR_FUNDUS_VALUES: 'doctorservice/PatientEncounterFundusExam/Delete',
  COPY_PREVIOUS_FUNDUS: 'doctorservice/PatientEncounterFundusExam/CopyFromPreviousEncounter',
  COPY_PREVIOUS_SLIT: 'doctorservice/PatientEncounterSlitLampExam/CopyFromPreviousEncounter',
  ADD_PROVIDER_CARE_TEAM: 'masterservice/PatientCareTeam',
  CPT_CODE_LIST: 'masterservice/CodeMaster/Paginated/GetCPTCode',
  CPT_ICD_CROSSWALK_LIST: 'masterservice/CodeMaster/Paginated/GetMappedCPTICDCode',
  CPT_CODE_SYNONYMS_LIST: 'masterservice/Synonym',
  ICD_CODE_SYNONYMS_LIST: 'masterservice/Synonym',
  GET_AS_SORTED_CPT_ICD_CODE_LIST: 'masterservice/CodeMaster/GetAssortedICDCPTMapping',
  GET_ICD_CPT_MAPPING: 'masterservice/CodeMaster/GetICDCPTMapping',
  DELETE_ADDRESS_BOOK_ITEM: 'masterservice/ProviderAddressBook',
  GET_DOCTOR_FOLLOW_UP_EXAM_TYPE_ORDER_SET: 'schedulerservice/ExamType',
  GET_DOCTOR_FOLLOW_UP_VISIT_TYPE_ORDER_SET: 'schedulerservice/VisitType',
  GET_DOCTOR_FOLLOW_UP_PROCEDURE_CODE_ORDER_SET: 'doctorservice/PatientProcedureCodeFavourite/ProviderfavouritesProcedureCodeList',
  ADD_OUTSIDE_TEST_FAVOURITE_ORDER_SET: 'DoctorService/OutsideTestingFavorite',
  ADD_OUTSIDE_TEST_FOLDER_ORDER_SET: 'DoctorService/OutsideTestingFavorite/addFolder',
  GET_OUTSIDE_TEST_FAVOURITES_AND_FOLDERS_ORDER_SET: 'DoctorService/OutsideTestingFavorite/GetAllFavoritesAndFolder',
  DELETE_OUTSIDE_TEST_FOLDER_ORDER_SET: 'DoctorService/OutsideTestingFavorite/Folder',
  GET_REFERRAL_LIST: 'doctorservice/PatientEcounterReferralPhysician/ReferralList',
  MODIFIERS_CODE_LIST: 'masterservice/CodeMaster/GetCPTModiferMapping',
  ORDER_SET_FOLDER: 'doctorservice/OrderSet/Folder',
  ORDER_SET_FAVOURITE: 'doctorservice/OrderSet/Favorite',
  GET_ORDER_SET: 'doctorservice/OrderSet/GetAllOrderSetAndFolder',
  MOVE_ORDER_SET: 'doctorservice/OrderSet/MapOrderSetToFolder',
  FAVOURITE_FOLDER_ORDER_SET: 'doctorservice/OrderSet/OrderSetOfFolder',
  SAVE_UPDATE_ORDER_SET: 'doctorservice/OrderSet',
  DELETE_PROCEDURE_CPT_CODE: 'doctorservice/PatientProcedureCode',
  GET_ORDER_SET_BY_ID: 'doctorservice/OrderSet/GetAllOrderSetAndFolder',
  APPLY_ORDER_SET: 'doctorservice/OrderSet/ApplyOrderSet',
  REQUISITION_PDF: 'doctorservice/PatientEncounterOutsideTesting/Requisitionpdf',
  GET_PATIENT_ENCOUNTER: 'masterservice/encounter',
  ADD_PATIENT_ENCOUNTER: 'masterservice/encounter',
  UPDATE_PATIENT_ENCOUNTER: 'masterservice/encounter',
  SIGN_AND_BILL_LIST: 'doctorservice/SignAndBill',
  ENM_CODE: 'doctorservice/EnMCodeMaster',
  SIGN_AND_BILL: 'doctorService/SignAndBill/SaveSignAndBill',
  SIGN_AND_BILL_AUTOSAVE: 'doctorService/SignAndBill/SaveOnlySignAndBill',
  GET_CLAIMS_LIST: 'masterservice/Claims/ClaimsList',
  GET_COLLECTION_CLAIMS_LIST: 'masterservice/Collection/CollectionList',
  GET_COLLECTIONS_LIST: 'masterservice/Collection/CollectionGroupingList',
  ASSEMBLE_CLAIMS: 'masterservice/Claims/Assemble',
  PATIENT_CLAIM_LIST: 'masterservice/Claims/ServiceLineItems',
  CHANGE_PATIENT_RESPONSIBILITY: 'masterservice/Claims/ChangePaymentResponsibilityToPatient',
  GET_CLAIMS_BY_CLAIM_ID: 'masterservice/Claims/ServiceLineItems',
  CHANGE_INSURANCE_RESPONSIBILITY: 'masterservice/Claims/ChangePaymentResponsibility',
  GET_CLAIMS_DETAILS: 'masterservice/Claims/ClaimDetails',
  INSURANCE_PAYER_LIST_BY_PATIENT: 'masterservice/PatientInsurance/GetPatientPayerList',
  CREATE_NEW_CLAIM: 'masterservice/Claims/AddNewBillingClaim',
  GET_CLAIM_DETAILS: 'masterservice/Claims/ClaimDetails',
  GET_MODIFIER_OPTIONS: 'DoctorService/PatientProcedureCode/GetODOSModifiers',
  COLLECTIONS_INCIDENT_ADD: 'masterservice/Claims/Incident',
  SEND_TO_WAYSTAR: 'masterservice/waystar',
  NEW_SEND_TO_WAYSTAR: 'waystarservice/waystar',
  GET_USE_LAST_DIAGNOSIS: 'masterservice/Encounter/PreviousEncounterIcdCodes',
  ENCOUNTER_LINE_ITEM_GET_AMOUNT: 'masterservice/encounter/CalculateCPTCodeRate',
  DELETE_CLAIM: 'MasterService/Claims',
  GET_PATIENT_NOTES: 'MasterService/PatientNote/GetNotes',
  ADD_PATIENT_NOTES: 'MasterService/PatientNote/SaveNotes',
  GET_ACTIVITY_LOGS: 'masterService/Patient/GetPatientAuditLog',
  PROCESS_CLAIMS_FOR_MASS_REBILL: 'MasterService/Claims/ProcessClaimsForMassReBill',
  DATA_LIST_CATEGORIES: 'inventory/data/list/categories',
  UNPOSTED_INVOICE_LIST: 'InventoryService/InventoryInvoice/UnpostedList',
  SEARCH_INVOICE_LIST: 'InventoryService/InventoryInvoice/InvoiceList',
  DISPENSE_PROMOTION_LIST: 'InventoryService/Promotions/Promotions',
  GET_SUB_PRODUCTS: 'inventoryService',
  GET_DATA_PRODUCT_LIST: '/inventoryservice/InventoryProduct',
  GET_REPRINT_LABELS: 'GET_REPRINT_LABELS',
  SEARCH_INVENTORY_BARCODE: 'inventoryservice/InventoryItems',
  SEARCH_TRANSFER_INVENTORY: 'InventoryService/TransferProductLineItems/searchreports',
  SEARCH_PHYSICAL_INVENTORY: 'InventoryService/PhysicalInventory/PhysicalInventoryReport',
  GET_BUSINESS_ENTITY_CREDENTIALS: 'masterservice/BusinessEntityCredentials',
  GET_BUSINESS_ENTITY_BILLING_NDC_PROCEDURE: 'masterservice/BusinessEntityBillingNDC',
  CREATE_NEW_REMITTANCE_BATCH: 'masterservice/RemittanceBatch/NewBatch',
  GET_USER_SETTINGS: 'masterservice/UserDefaultSettings',
  UPDATE_USER_SETTINGS: 'masterservice/UserDefaultSettings',
  DELETE_DOCTOR_VISION: 'doctorservice/PatientEncounterVision',
  UPDATE_DOCTOR_VISION: 'doctorservice/PatientEncounterVision',
  DELETE_DOCTOR_PRESSURE: 'doctorservice/PatientEncounterPressure',
  UPDATE_DOCTOR_PRESSURE: 'doctorservice/PatientEncounterPressure',
  CHANGE_CLAIMS_DELIVERY_METHOD: 'MasterService/Claims',
  GET_TASK_LIST: 'TaskService/Task/GetTaskList',
  ADD_TASK: 'TaskService/Task',
  REASSIGN_TASK: 'TaskService/Task/ReassignTask',
  MARK_READ_UNREAD: 'TaskService/Task/UpdateReadStatus',
  GET_REMITTANCE_BATCH_DETAILS: 'masterservice/RemittanceBatch/RemittanceBatchDetails',
  ADD_WORK_LIST: 'MasterService/RemittanceWorklist',
  GET_WORK_LIST_DATA: 'masterservice/remittance/worklist',
  UPDATE_WORK_LIST: 'masterservice/remittance/worklist',
  GET_PATIENT_TASK: '/TaskService/Task/GetTaskList',
  INSURANCE_PAYMENT_ENTERED_LINE_ITEMS: 'masterservice/RemittanceBatch/InsurancePaymentWithClaimDetail',
  PATIENT_PAYMENT_ENTERED_LINE_ITEMS: 'masterservice/RemittanceBatch/PatientPaymentWithClaimDetail',
  GET_INSURANCE_PAYMENT: 'masterservice/RemittanceBatch/InsurancePayment',
  GET_TASK_DETAIL: 'TaskService/Task/GetTaskById',
  GET_TASK_COMMENTS: 'TaskService/Task',
  GET_TASK_LOGS: 'TaskService/Task/GetTaskAuditLog',
  GET_REMITTANCE_WORK_LIST_SUSPENSE: 'masterservice/RemittanceWorklist/GetWorkListAnalysis',
  GET_REMITTANCE_WORK_LIST_REASON: 'masterservice/RemittanceWorklist/GetWorkList',
  ADD_COMMENT: 'TaskService/Task',
  GET_CUTERA_LASER: 'doctorservice/PatientEncounterCuteraLaser',
  ADD_CUTERA_LASER: 'doctorservice/PatientEncounterCuteraLaser',
  GET_ULTHERAPY: 'doctorservice/PatientEncounterUltherapy',
  ADD_ULTHERAPY: 'doctorservice/PatientEncounterUltherapy',
  MARK_AS_BLANK: 'masterservice/RemittanceBatch/MarkAsBlankPage',
  MARKUP_FOR_FOLLOWUP: 'masterservice/Claims/MarkupForFollowup',
  COOLTONE_CREATE_AND_UPDATE: 'doctorservice/PatientEncounterCooltone',
  MICRO_NEEDLING_ADD: '/doctorservice/EncounterMicroNeedling',
  MICRO_NEEDLING_GET: 'doctorservice/EncounterMicroNeedling',
  REMOVE_POSTED_CHECK: 'masterservice/RemittanceBatch/DeleteRemittancePayment',
  UPDATE_MEDICATION: 'doctorservice/PatientEncounter',
  COOL_SCULPT_ADD: 'doctorservice/CoolSculpt',
  COOL_SCULPT_UPDATE: 'doctorservice/CoolSculpt',
  COOL_SCULPT_GET: 'doctorservice/CoolSculpt',
  BREAST_EXAM_DRAW_LIST: 'doctorservice/PatientEncounterBreastExam',
  ADD_BREAST_EXAM_DRAW: 'doctorservice/PatientEncounterBreastExam',
  GET_INSURANCE_PAYMENT_DETAILS: 'masterservice/RemittanceBatch/RemittancePaymentDetails',
  DELETE_LILLY_LEE_DRAW_TAB: 'doctorservice/PatientEncounterBreastExam/DeleteTab',
  GET_REMITTANCE_PAYMENT_DETAILS: 'masterservice/RemittanceBatch/RemittancePaymentDetails',
  SUBMIT_CLAIM: 'masterservice/Claims/MarkClaimAsSubmitted',
  TECHNICIAN_EYELIDS_GET: 'doctorservice/PatientEncounterEyeLids',
  TECHNICIAN_EYELIDS_UPDATE: 'doctorservice/PatientEncounterEyeLids',
  ADD_PAYER_AGING_OVERRIDE: 'MasterService/InsurancePayer/UpdatePayerAging',
  GET_PAYER_AGING_OVERRIDE: 'masterservice/InsurancePayer',
  GET_PAYER_AGING_OVERRIDE_LISTING: 'masterservice/InsurancePayer',
  INSURANCE_PAYMENT_COMPLETE_CHECK: 'masterservice/RemittanceBatch/CompleteCheck',
  SET_PATIENT_PAYMENT: 'masterservice/PatientFinancial/AddPayment',
  SET_INSURANCE_PAYMENT: 'masterservice/PatientFinancial/AddPayment',
  DELETE_ENUM_FIELDS: 'masterservice/masters',
  GET_PATIENT_BALANCE_SHEET: 'masterservice/PatientFinancial/BalanceSheet',
  ADD_LILLY_LEE_HPI_FAVORITE: 'doctorservice/FavouriteFolder/AddFavorite',
  ADD_LILLY_LEE_HPI_FOLDER: 'doctorservice/FavouriteFolder/AddFolder',
  DELETE_LILLY_LEE_HPI_FOLDER: 'doctorservice/FavouriteFolder/Folder',
  GET_LILLY_LEE_HPI_FAVORITES_OF_FOLDER: 'doctorservice/FavouriteFolder/GetFavorites',
  DELETE_LILLY_LEE_HPI_FAVORITE: 'doctorservice/FavouriteFolder/Favorite',
  MOVE_LILLY_LEE_HPI_FAVORITE: 'doctorservice/FavouriteFolder/MoveFavorites',
  GET_ALL_LILLY_LEE_HPI_FAVORITES_AND_FOLDER: 'doctorservice/FavouriteFolder/GetAllFavoritesWithFolder',
  ADD_PATIENT_SIMPLE_CHARGE: 'MasterService/PatientSimpleCharge',
  ASSIGN_WORK_LIST: 'masterservice/RemittanceWorklist/AssignWorklist',
  POST_INSURANCE_WORKLIST_LINE_ITEMS: 'POST_INSURANCE_WORKLIST_LINE_ITEMS',
  ADD_APPOINTMENT_PATIENT_STATUS: 'SchedulerService/Appointment/AddAppointmentConfirmation',
  ADD_BATCH_RECEIVE_INVENTORY: 'InventoryService/RecieveInventory/AddProductItem',
  GET_RECEIVE_INVENTORY_BATCH_LIST: 'InventoryService/RecieveInventory/BatchItemList',
  ADD_INVENTORY_PROMOTION: 'InventoryService/Promotions/AddPromotion',
  UPDATE_INVENTORY_PROMOTION: 'InventoryService/Promotions',
  GET_INVENTORY_PROMOTION: 'InventoryService/Promotions/GetPromotion',
  GET_UNPOSTED_INVENTORY_LIST: 'InventoryService/RecieveInventory/UnpostedBatchList',
  DELETE_UNPOSTED_BATCH_INVENTORY: 'InventoryService/RecieveInventory/DeleteBatch',
  GET_PRODUCT_DETAILS: 'inventoryservice/inventoryproduct',
  DELETE_PROMOTION_LINE_ITEM: 'InventoryService/Promotions/Promotion',
  CREATE_DISPENSE_INVOICE: 'InventoryService/InventoryInvoice',
  GET_INVOICE_DETAILS: 'InventoryService/InventoryInvoice',
  UPDATE_INVOICE_DETAILS: 'InventoryService/InventoryInvoice',
  ADD_ITEM_IN_INVOICE: 'InventoryService/InventoryInvoice/AddItem',
  ADD_AUTHORIZATION_IN_INVOICE: 'InventoryService/InventoryInvoice/UploadAuthDocument',
  GET_ITEM_FROM_INVOICE: 'InventoryService/InventoryInvoice/ItemDetails',
  GET_ITEM_LIST_FROM_INVOICE: 'InventoryService/InventoryInvoice/Items',
  DELETE_ITEM_FROM_ITEM_LIST: 'InventoryService/InventoryInvoice/Item',
  GET_INVOICE_OPTICAL_RX: 'InventoryService/InventoryInvoice/OpticalRx',
  UPDATE_INVOICE_OPTICAL_RX: 'InventoryService/InventoryInvoice/UpsertOpticalRx',
  ADD_INSURANCE_ADJUSTMENT: 'masterservice/PatientFinancial/ApplyInsuranceAdjustment',
  ADD_PATIENT_ADJUSTMENT: 'masterservice/PatientFinancial/ApplyPatientAdjustment',
  GET_TRANSACTIONS_LIST: 'MasterService/PatientTransaction',
  GET_FINANCIAL_LIST: 'masterservice/PatientFinancial/List',
  GET_PATIENT_CHARGE_LIST: 'MasterService/PatientFinancial/Detail',
  POST_UNPOSTED_INVOICE: 'InventoryService/InventoryInvoice/Post',
  DELETE_UNPOSTED_INVOICE: 'InventoryService/InventoryInvoice',
  ADD_PRODUCT_IN_INVENTORY: 'inventoryservice/inventoryproduct',
  MARK_COMPLETE_WORKLIST: 'MasterService/RemittanceWorklist/MarkAsComplete',
  DELETE_MICRO_NEEDLING_DRAW_TAB: 'doctorservice/EncounterMicroNeedling/DeleteTab',
  GET_PENDING_INVENTORY_LIST: 'inventoryservice/TransferProductLineItems/PendingTransfers',
  DELETE_TRANSFER_LINE_ITEM: 'inventoryservice/TransferProductLineItems/Reject',
  ACCEPT_TRANSFER_LINE_ITEM: 'inventoryservice/TransferProductLineItems/Accept',
  TRANSFER_INVENTORY_ADD_ITEM: 'inventoryservice/TransferProductLineItems/AddProductItem',
  DISCARD_INVENTORY_ITEM: 'InventoryService/RecieveInventory/DiscardInventoryItems',
  RECALL_INVENTORY_ITEM: 'InventoryService/RecieveInventory/RecalledInventoryItems',
  EXPORT_INVENTORY_ITEM: 'InventoryService/RecieveInventory/ExportInventoryItemList',
  BARCODE_PRODUCT_SEARCH: 'inventoryservice/inventoryproduct',
  INVENTORY_LINE_ITEMS: 'inventoryservice/InventoryItems',
  PRICING_AND_COST_EXCEPTION: 'InventoryService/InventoryProduct/PriceRuleExceptions',
  REORDER_POINT_EXCEPTION: 'inventoryservice/inventoryProduct/ReorderPointRuleExceptions',
  UPLOAD_INVENTORY_DATA_FILE: 'InventoryService/RecieveInventory/ImportProductQuantityBasedOnLocation',
  UPLOAD_PRODUCT_DATA_FILE: 'InventoryService/InventoryProduct/ImportProduct',
  EXPORT_INVENTORY_TRANSFER: 'InventoryService/TransferProductLineItems/export',
  EXPORT_PHYSICAL_INVENTORY: 'InventoryService/PhysicalInventory/ExportPhysicalInventory',
  EXPORT_DATA_LIST_PRODUCTS: 'Inventoryservice/InventoryProduct/ExportProducts',
  PRINT_INVENTORY_REPORT: 'InventoryService/TransferProductLineItems/printreports',
  SIGNED_PDF_DOCTOR: 'doctorservice/SignAndBill/ConvertHtmlToPdf',
  REFRESH_TOKEN: 'identityservice/user/refresh',
  ADD_COLLECTED_FINANCIAL: 'MasterService/PatientFinancial/AddCheckOutFinance',
  GET_FINANCIAL_COLLECTION: 'MasterService/PatientFinancial/GetPendingPayment',
  ADD_FINANCIAL_COLLECTION: 'MasterService/PatientFinancial/AddCheckInFinance',
  GET_CHECIN_FINANCIAL: 'MasterService/PatientFinancial/GetCheckInFinanceDetail',
  POST_BATCH_TO_INVENTORY: 'InventoryService/RecieveInventory/PostBatch',
  DELETE_INVENTORY_BATCH_ITEM: 'InventoryService/RecieveInventory/RemoveBatchItem',
  DELETE_INVENTORY_BATCH: 'InventoryService/RecieveInventory/DeleteBatch',
  ENCOUNTER_IMAGE_UPDATE_ODOS: 'doctorservice/EncounterImage/UpdateODOS',
  ADD_REORDER_EXCEPTION: 'inventoryservice/InventoryProduct/ReorderPointRuleException',
  TRANSFER_ITEMS_TO_INVENTORY: 'InventoryService/TransferProductLineItems/CreateTransferRequest',
  ADD_PRICE_RULE_EXCEPTION: 'inventoryservice/InventoryProduct/PriceRuleException',
  EXPORT_INVENTORY_RECEIPT_ITEM: 'inventoryservice/InventoryItems/export',
  INVENTORY_PROMOTIONS_LIST: 'InventoryService/Promotions/PromotionsList',
  GET_LATEST_PATIENT_REFRACTION: 'doctorservice/PatientRefraction/Latest',
  EXPORT_PRODUCT_TEMPLATE: 'InventoryService/InventoryProduct/ExportProductList',
  GET_ON_HAND_ADJUSTMENT_LIST: 'inventoryservice/InventoryAdjustOnHandQuantity/GetAdjustOnHandQuantity',
  ADD_ADJUST_ON_HAND_QUANTITY: 'InventoryService/InventoryAdjustOnHandQuantity/AdjustOnHandQuantity',
  EXPORT_STOCK_COUNT: 'InventoryService/InventoryAdjustOnHandQuantity/ExportStockCount',
  EXPORT_ADJUSTMENT_REPORT: 'InventoryService/InventoryAdjustOnHandQuantity/ExportAdjustmentReport',
  PRINT_STOCK_COUNT: 'InventoryService/InventoryAdjustOnHandQuantity/PrintStockCount',
  ADD_MANUFACTURER: 'InventoryService/Manufacturer/AddManufacturer',
  INVENTORY_MANUFACTURER_LIST: 'InventoryService/Manufacturer/Manufacturers',
  MANUFACTURER_SUGGESTIVE_SEARCH: 'InventoryService/Manufacturer/ManufacturerList',
  GET_MANUFACTURER: 'InventoryService/Manufacturer/GetManufacturer',
  UPDATE_MANUFACTURER: 'InventoryService/Manufacturer',
  DELETE_MANUFACTURER: 'InventoryService/Manufacturer/Manufacturer',
  PRINT_ADJUSTMENT_REPORT: 'InventoryService/InventoryAdjustOnHandQuantity/PrintAdjustmentReport',
  DELETE_INVOICE: 'InventoryService/InventoryInvoice',
  COMPOSER_LIST: 'masterservice/ComposerTemplate',
  GET_COMPOSER_LABELS: 'COMPOSER_LABELS',
  UPDATE_TEMPLATE: 'masterservice/ComposerTemplate/Quick',
  GET_COMPOSER_TEMPLATE_DETAIL: 'masterservice/ComposerTemplate',
  DELETE_COMPOSER_TEMPLATE: 'masterservice/ComposerTemplate',
  GET_TEMPLATE_HEADER_FOOTER_LIST: 'masterservice/ComposerTemplateHeaderFooter',
  DELETE_PRODUCT_ITEM: 'InventoryService/Manufacturer/Manufacturer',
  SAVE_TEMPLATE: 'masterservice/ComposerTemplate',
  GET_COMPOSER_TEMPLATE: 'masterservice/ComposerTemplate',
  GET_CLAIMS_PDF: 'masterservice/Claims/CreateClaimPdf',
  GET_BUSINESS_ENTITY: 'masterservice/BusinessEntity',
  ADD_BUSINESS_ENTITY: 'masterservice/BusinessEntity',
  ADD_BUSINESS_ENTITY_CREDENTIAL: 'masterservice/BusinessEntityCredentials',
  GET_BUSINESS_ENTITY_CREDENTIAL: 'masterservice/BusinessEntityCredentials',
  UPDATE_BUSINESS_ENTITY_CREDENTIAL: 'masterservice/BusinessEntityCredentials',
  GET_TEMPLATE_DROPDOWN: 'masterservice/ComposerTemplate',
  ADD_BUSINESS_ENTITY_STATEMENT: 'masterservice/BusinessEntityStatements',
  ADD_BUSINESS_REMITTANCE: 'masterservice/BusinessEntityRemittance',
  GET_BUSINESS_REMITTANCE: 'masterservice/BusinessEntityRemittance',
  GET_BUSINESS_ENTITY_GENERAL: 'masterservice/BusinessEntityGeneral',
  ADD_BUSINESS_ENTITY_GENERAL: 'masterservice/BusinessEntityGeneral',
  ADD_BUSINESS_ENTITY_CONTRACT: 'masterservice/BusinessEntityContractTerms',
  GET_BUSINESS_ENTITY_CONTRACT: 'masterservice/BusinessEntityContractTerms/ByBusinessEntity',
  ADD_BUSINESS_ENTITY_PATIENT_BASICS: 'masterservice/BusinessEntityPatientBasics',
  ADD_BUSINESS_ENTITY_P_VERIFY_AUTO_RUN: 'masterservice/BusinessEntityPverifySchedule',
  GET_BUSINESS_ENTITY_P_VERIFY_AUTO_RUN: 'masterservice/BusinessEntityPverifySchedule',
  GET_BUSINESS_ENTITY_PATIENT_BASICS: 'masterservice/BusinessEntityPatientBasics',
  RENAME_HEADER_FOOTER_TEMPLATE: 'masterservice/ComposerTemplateHeaderFooter/RenameTemplateHeaderFooter',
  DELETE_HEADER_FOOTER_TEMPLATE: 'masterservice/ComposerTemplateHeaderFooter/DeleteTemplateHeaderFooter',
  ADD_BUSINESS_ENTITY_CLAIMS: 'masterservice/BusinessEntityClaims',
  GET_BUSINESS_ENTITY_CLAIMS: 'masterservice/BusinessEntityClaims',
  BUSINESS_ENTITY_BILLING_BASICS: 'masterservice/BusinessEntityBillingBasics',
  GET_TEMPLATE_CARE_TEAM_MAP: 'masterservice/PatientCareTeam/GetCareTeamLettersSummary',
  UPDATE_TEMPLATE_CARE_TEAM_MAP: 'masterservice/PatientCareTeam/SaveorUpdateCareTeamLetter',
  GENERATE_MULTIPLE_TEMPLATE: 'masterservice/PatientCareTeam/SaveOrUpdateMultipleCareTeamLetter',
  SEND_CARE_TEAM_LETTER: 'masterservice/PatientCareTeam/SendCareTeamLetter',
  SEND_ALL_CARE_TEAM_LETTER: 'masterservice/PatientCareTeam/SendAllCareTeamLetters',
  BUSINESS_ENTITY_PATIENT_CHART: '/masterservice/BusinessEntity/ChartNumbersettings',
  GET_BUSINESS_ENTITY_MODIFIERS_EXCEPTION: 'masterservice/BusinessEntityModifiersException',
  SAVE_BUSINESS_ENTITY_MODIFIERS_EXCEPTION: 'masterservice/BusinessEntityModifiersException',
  SEARCH_PATIENT_CARE_TEAM_LETTERS: 'masterservice/PatientCareTeam/SearchCareTeamLetters',
  DOCTOR_PRESSURE_CCT_ADD: 'doctorservice/patientencounter/AddCCT',
  DOCTOR_PRESSURE_CCT_GET: 'doctorservice/patientencounter/GetCCT',
  DOCTOR_FOLLOW_UP_TOP_TEN_PROCEDURES: 'doctorservice/PatientFollowup/GetProvidersCommonProcedures',
  SAVE_BUSINESS_ENTITY_BILLING_NDC: 'masterservice/BusinessEntityBillingNDC',
  GET_BUSINESS_ENTITY_BILLING_NDC: 'masterservice/BusinessEntityBillingNDC',
  GET_CPT_IN_RANGE: 'masterservice/CodeMaster/GetCPTCodeOutsideTest',
  UPDATE_MOOD: 'doctorservice/EnconterCC/encounter',
  SAVE_BUSINESS_ENTITY_SCHEDULE: 'masterservice/BusinessEntitySchedule',
  GET_BUSINESS_ENTITY_SCHEDULE: 'masterservice/BusinessEntitySchedule',
  GET_BUSINESS_ENTITY_TASK: 'taskService/TaskCategoryConfiguration/TaskCategoryList',
  GET_BUSINESS_ENTITY_TASK_DETAIL: 'taskService/TaskCategoryConfiguration/TaskCategory',
  GET_BUSINESS_ENTITY_SUB_TASK: 'taskService/TaskCategoryConfiguration/TaskSubCategoryList',
  GET_BUSINESS_ENTITY_SUB_TASK_DETAIL: 'taskService/TaskCategoryConfiguration/TaskSubCategory',
  ADD_BUSINESS_ENTITY_TASK: 'taskService/TaskCategoryConfiguration/AddTaskCategory',
  ADD_BUSINESS_ENTITY_SUB_TASK: 'taskService/TaskCategoryConfiguration/AddTaskSubCategory',
  UPDATE_BUSINESS_ENTITY_TASK: 'taskService/TaskCategoryConfiguration/UpdateTaskCategory',
  UPDATE_BUSINESS_ENTITY_SUB_TASK: 'taskService/TaskCategoryConfiguration/UpdateTaskSubCategory',
  GET_PATIENT_CLINICAL_SUMMARY: 'masterservice/Patient/GetPatientClinicalSummary',
  GET_PATIENT_PHARMACY: 'doctorservice/PatientEncounterPrescription/GetPatientPharmacy',
  SYNC_PATIENT_PHARMACY: 'doctorservice/PatientEncounterPrescription/SyncPatientPharmacy',
  REMOVE_TASK_CATEGORY_USERS: 'taskService/TaskCategoryConfiguration/RemoveTaskCategoryUsers',
  SAVE_TASK_CATEGORY_USERS: 'taskService/TaskCategoryConfiguration/AddTaskCategoryUser',
  ADD_CHECKOUT_FINANCE: 'MasterService/PatientFinancial/AddCheckOutFinance',
  GET_CHECKOUT_FINANCE: 'MasterService/PatientFinancial/GetCheckOutFinanceDetail',
  GET_CHECKOUT_FINANCIAL: 'MasterService/PatientFinancial/GetCheckoutPatientPayment',
  CLAIMS_BILL_PATIENT: 'MasterService/Claims/BillPatient',
  FAVORITE_SHARED_PROCEDURES: 'doctorservice/SharedProceduresFavorites',
  FAVORITE_RESPOND_TO_SHARED_PROCEDURES: 'doctorservice/SharedProceduresFavorites',
  FAVORITE_SHARED_OCULAR_PROBLEMS: 'doctorservice/SharedOcularFavorites',
  FAVORITE_RESPOND_TO_SHARED_OCULAR_PROBLEMS: 'doctorservice/SharedOcularFavorites',
  SHARE_PROCEDURE: 'doctorservice/SharedProceduresFavorites',
  SHARE_OCULAR: 'doctorservice/SharedOcularFavorites',
  UPLOAD_PATIENT_IMAGE: 'masterservice/Patient/UploadImage',
  ADD_SYNONYM_FOR_CPT_ICD: 'masterservice/Synonym',
  UPDATE_SYNONYM_FOR_CPT_ICD: 'masterservice/Synonym/update',
  SEARCH_BARCODE: 'InventoryService/InventoryProduct/Product',
  EDIT_CCT: 'doctorservice/PatientEncounter/UpdateCCT',
  GET_ICD_WITH_FAVORITES: 'masterservice/codemaster/Paginated/GetProviderICDCode',
  UPDATE_MULTIPLE_SHARE_PROCEDURE: 'doctorservice/SharedProceduresFavorites/UpdateSharedProceduresFavorites',
  UPDATE_MULTIPLE_SHARE_OCULAR: 'doctorservice/SharedOcularFavorites/UpdateSharedOcularFavorites',
  GET_PATIENT_RECORDED_MEDICATIONS: 'doctorservice/PatientEncounterPrescription/GetPatientEncounterPrescription',
  GET_APPOINTMENT_ENCOUNTER_ID: 'doctorservice/PatientEncounter/GetEncounterIdByAppointmentId',
  GET_TASK_MANAGEMENT_CATEGORY_WITH_COUNT: 'taskservice/task/GetTaskCategoryWithTaskCount',
  MARK_AS_CLOSED: 'TaskService/Task/UpdateTasksToClosed',
  GET_TASK_PRESCRIPTIONS: 'doctorservice/PatientEncounterPrescription/GetPatientPrescriptionDrugs',
  GET_ALLERGIES_SEARCH: 'doctorservice/PatientAllergies/AllergiesFilter',
  GET_MEDICATION_SEARCH: 'doctorservice/PatientEncounterPrescription/MedicationFilter',
  GET_DATE_OPERATOR_FILTER: 'reportservice/Filter/DateOperator',
  GET_PAYER_DATE_OPERATOR_FILTER: 'reportservice/Filter/FourthArithmeticOperator',
  GET_ARITHMETIC_OPERATOR_FILTER: 'reportservice/Filter/ArithmeticOperator',
  GET_SAVED_REPORTS: 'reportservice/ReportTemplates/List',
  INSURANCE_VERIFICATION_HISTORY: 'masterservice/Pverify/InsuranceVerificationHistory',
  GET_SHARED_REPORTS: 'reportservice/ReportTemplates/ShareWith',
  SAVE_REPORTS: 'reportservice/ReportTemplates',
  DOWNLOAD_EXCEL_REPORTS: 'reportservice/AppointmentReport/Export',
  DOWNLOAD_PAYMENT_REPORTS: 'reportservice/PaymentReport/Export',
  DOWNLOAD_CHARGE_REPORTS: 'reportservice/ChargesReport/Export',
  DOWNLOAD_PATIENTS_EXCEL_REPORTS: 'reportservice/PatientReport/Export',
  SAVE_REPORT_TEMPLATE: 'reportservice/ReportTemplates',
  GET_APPOINTMENT_DATES: 'reportservice/Filter/DateOperator',
  GET_THIRD_DATE_TYPE_ENUM: 'reportservice/Filter/ThirdDateTypeEnum',
  GET_SECOND_DATE_TYPE_ENUM: 'reportservice/Filter/SecondaryDateTypeEnum',
  GET_FOURTH_DATE_TYPE_ENUM: 'reportservice/Filter/FourthDateTypeEnum',
  GET_ARITHMETIC_OPERATORS: 'reportservice/Filter/ArithmeticOperator',
  DOWNLOAD_AUTHORIZATIONS_EXCEL_REPORTS: 'reportservice/AuthorizationReport/Export',
  DOWNLOAD_PATIENT_REQUEST_REPORTS: 'reportservice/RequestReport/Export',
  MAIL_RECEIPT_CHECKOUT: 'masterservice/PatientFinancial/SendStatementAsEmail',
  GET_RECEIPT_HTML: 'masterservice/PatientFinancial/StatementAsHtml',
  GET_FINANCIAL_RECEIPT_HTML: 'masterservice/PatientFinancial/Receipt',
  MAIL_NOTES_CHECKOUT: 'doctorservice/SignAndBill/SendSignAndBillNotesAsEmail',
  GET_NOTES_HTML: 'doctorservice/SignAndBill/SignAndBillNotesHtml',
  GET_PATIENT_APPLIED_BALANCE: 'masterservice/PatientTransaction/GetAppliedPayment',
  PATIENT_CLAIM_DETAIL: 'masterservice/Claims/ClaimDetails',
  PRINT_ADJUSTMENT_RECEIPT: 'masterservice/PatientFinancial/Receipt',
  DOWNLOAD_CLAIM_PROOF_OF_FILING: 'masterservice/Claims/ProofOfFiling',
  GET_PATIENT_CLAIMS_ERRORS: 'MasterService/Claims/Assemble',
  MOVE_WAYSTAR_DISCARDED_TO_OUTBOX: 'MasterService/Claims/MoveWaystarDiscardedToOutbox',
  DELETE_DOCTOR_PROCEDURE: 'doctorservice/PatientProcedureCode',
  PROOF_OF_FILLING: 'masterservice/Claims/ProofOfFiling',
  CHECK_ELIGIBILITY: 'masterservice/claims/CreateInsuranceVerificationTask',
  DELETE_SHARED_REPORT: 'reportservice/ReportTemplates/DeleteTemplate',
  SAVE_SHARED_REPORT: 'reportservice/ReportTemplates/UpdateSharedTemplateStatus',
  REMITTANCE_ASSIGN_TO: 'masterservice/RemittanceBatch/AssignTo',
  GET_CLAIMS_PDF_WITHOUT_FORM: 'masterservice/Claims/CreateClaimWithoutFormPdf',
  FINANCIAL_LINE_ITEMS: 'masterservice/PatientFinancial/List',
  ADD_FINANCIAL_INSURANCE_PAYMENT: 'masterservice/PatientFinancial/AddInsurancePaymentWithLineItems',
  ADD_INSURANCE_REFUND: 'masterservice/PatientFinancial/AddInsuranceRefundWithLineItems',
  ADD_PATIENT_REFUND: 'masterservice/PatientFinancial/AddPatientRefundWithLineItems',
  GET_FINANCIAL_DETAIL: 'MasterService/PatientFinancial/Detail',
  GET_DENIAL_REASONS: 'masterservice/RemittanceBatch/GetDenialReasons',
  DELETE_FINANCE_TRANSACTION: 'masterservice/PatientFinancial',
  ADD_PAYMENT_REVERSAL: 'masterservice/PatientFinancial/Reversal',
  REVERSE_INSURANCE_ADJUSTMENT: 'masterservice/PatientFinancial/ReverseInsuranceAdjustment',
  GET_PATIENT_PAYMENT_EDIT_LINE_ITEMS: 'masterservice/PatientFinancial/GetFinanceDetail',
  GET_INSURANCE_REFUND_EDIT_LINE_ITEMS: 'masterservice/PatientFinancial/GetFinanceDetail',
  ADD_PATIENT_PAYMENT: 'masterservice/PatientFinancial/AddPatientPayment',
  ADD_PATIENT_PAYMENT_WITH_LINE_ITEMS: 'masterservice/PatientFinancial/AddPatientPaymentWithLineItems',
  EDIT_PATIENT_PAYMENT: 'masterservice/PatientFinancial/EditPayment',
  EDIT_INSURANCE_PAYMENT: 'masterservice/PatientFinancial/EditPayment',
  GET_FINANCE_DETAIL_HTML: 'GET_FINANCE_DETAIL_HTML',
  GET_EDIT_INSURANCE_REFUND_LINE_ITEMS: 'masterservice/PatientFinancial/GetLineItemForInsuranceRefund',
  INSURANCE_REFUND_IN_DETAIL_PAGE: 'masterservice/PatientFinancial/AddInsuranceRefundWithLineItems',
  GET_AUDIT_LOG: 'masterservice/PatientFinancial/GetFinaceAuditLog',
  GET_COOLSCULPT_TABLE_DATA: 'doctorservice/CoolSculpt',
  GET_STATEMENTS: 'masterservice/Statements/StatementsList',
  HOLD_STATEMENTS: 'masterservice/Statements/Hold',
  ASSEMBLE_STATEMENTS: 'masterservice/Statements/Assemble',
  SUBMIT_STATEMENTS: 'masterservice/Statements/Submitted',
  STATEMENTS_DETAILS: 'masterservice/Statements/details',
  GET_STATEMENT_HTML: 'masterservice/Statements/Preview',
  INJECTABLE_ADD: 'doctorservice/PatientEncounterInjectables',
  INJECTABLE_GET: 'doctorservice/PatientEncounterInjectables',
  INJECTABLE_DELETE: 'DoctorService/PatientEncounterInjectables/DeleteTab',
  ASSEMBLE_FINANCE: 'masterservice/PatientFinancial/SendClaimAsMassReBill',
  GET_REPORTS_HISTORY: 'reportservice/ReportHistory/List',
  GET_REPORTS_HISTORY_COMMENTS: 'reportservice/ReportHistory/Comments',
  ADD_REPORT_HISTORY_COMMENT: 'reportservice/ReportHistory/AddComment',
  DOWNLOAD_REPORT_HISTORY: 'reportservice/ReportHistory/Download',
  GET_ALL_DENIAL_REASON: 'masterservice/RemittanceBatch/GetDenialReasonsList',
  GET_DENIAL_CATEGORY: 'masterservice/RemittanceBatch/GetDenialCategory',
  GET_ICD: 'masterservice/CodeMaster/GetICDCode',
  ADD_ICD: 'masterservice/CodeMaster/AddICDCode',
  IMPORT_ICD: 'masterservice/CodeMaster/ImportICDCodes',
  ADD_CPT: 'masterservice/CodeMaster/AddCPTCode',
  IMPORT_CPT: 'masterservice/CodeMaster/ImportCPTCodes',
  IMPORT_CPT_ICD_MAPPING: 'masterservice/CodeMaster/ImportICDCPTMapping',
  SECOND_DATE_ENUM: 'reportservice/Filter/SecondaryDateTypeEnum',
  CITY_SEARCH_CRITERIA_ENUM: 'reportservice/Filter/SecondArithmeticOperator',
  THIRD_DATE_ENUM: 'reportservice/Filter/ThirdArithmeticOperator',
  DATE_ENUM: 'reportservice/Filter/ThirdDateTypeEnum ',
  SAVE_IN_ORDER_PROCEDURES: 'doctorservice/PatientProcedureCode/EncounterDiagnosis',
  GET_IN_OFFICE_PROCEDURE_LIST: 'doctorservice/PatientProcedureCode/EncounterDiagnosis',
  ADD_PROCEDURES_IN_DX: 'doctorservice/PatientProcedureCode/EncounterDiagnosisProcedure',
  MOVE_IN_ORDER_PROCEDURES: 'doctorservice/PatientProcedureCode/MoveDiagnosisProcedureCode',
  DELETE_IN_OFFICE_PROCEDURES: 'doctorservice/PatientProcedureCode/EncounterDiagnosisProcedure',
  DELETE_DIAGNOSIS_CODE: 'doctorservice/PatientProcedureCode/EncounterDiagnosis',
  UPDATE_IN_OFFICE_PROCEDURE: 'doctorservice/PatientProcedureCode/EncounterDiagnosisProcedure',
  SAVE_PROCEDURE_ORDER_SET: 'doctorservice/ProcedureSet',
  DELETE_IN_OFFICE_PROCEDURE_SET: 'doctorservice/ProcedureSet',
  GET_IN_OFFICE_PROCEDURE_SETS: 'doctorservice/ProcedureSet',
  EXPORT_CLAIM_REPORT: 'reportservice/ClaimsReport/Export',
  FAVORITE_RESPOND_TO_SHARED_ORDER_SET: 'doctorservice/SharedOrderSetFavorites',
  UPDATE_MULTIPLE_SHARE_ORDER_SET: 'doctorservice/SharedOrderSetFavorites/UpdateSharedOrderSetFavorites',
  SHARE_ORDER_SET: 'doctorservice/SharedOrderSetFavorites',
  FAVORITE_SHARED_ORDER_SET: 'doctorservice/SharedOrderSetFavorites',
  GET_BILLING_RATES: 'masterservice/BillingRates/GetBillingRate',
  GET_DIAGNOSIS_PROCEDURE_LIST: 'doctorservice/PatientProcedureCode/Encounter',
  CREATE_FULL_ENCOUNTER: 'schedulerservice/Appointment/CreateFullEncounter',
  SAVE_AP_NOTES: 'doctorservice/OrderSet/AddNotes',
  GET_DIAGNOSIS_FAVORITES: 'doctorservice/DiagnosisFavorite/SuggestiveSearch',
  GET_NOTES_LIST: 'doctorservice/OrderSet/GetNotes',
  APPLY_PROCEDURE_ORDER_SET: 'doctorservice/ProcedureSet/ApplyProcedureSet',
  GET_ORDER_SET_LIST_AP: 'doctorservice/ProcedureSet/OrderSets',
  DELETE_ORDER_SET_AP: 'doctorservice/ProcedureSet',
  SAVE_DIAGNOSIS_FAVORITE: 'doctorservice/ProcedureSet/AddDiagnosisFavouriteSet',
  SAVE_AP_ORDER_SET: 'doctorservice/ProcedureSet/AddOrderset',
  DELETE_AP_NOTES: 'doctorservice/OrderSet/DeleteNotes',
  DELETE_DIAGNOSIS_FAVORITES: 'doctorservice/DiagnosisFavorite',
  DELETE_FAVOURITE_SET: 'doctorservice/ProcedureSet/FavouriteSet',
  COPY_PREVIOUS_ENCOUNTER: 'doctorservice/PatientEncounterSuperBillQuestionnaire/CopyPreviousSuperBill',
  APPLY_AP_ORDER_SET: 'doctorservice/ProcedureSet/ApplyOrderSet',
  ASSOCIATE_WITH_PREVIOUS_SESSIONS: 'doctorservice/CoolSculpt/CopyFromPreviousEncounter',
  GET_PREVIOUS_ORDER_SET_LIST_AP: 'doctorservice/PatientProcedureCode/GetPatientsOldProcedureCodesWithNotes',
  AGE_BASED_ON: 'reportservice/Filter/AgingBasedOnEnum',
  AR_REPORT: 'reportservice/ARReport/Export',
  UPDATE_OUTSIDE_TESTING_SORT_ORDER: 'doctorService/OutsideTestingFavorite',
  DELETE_BLOCKOUT_ITEM: 'schedulerservice/Blockout/DeleteBlockout',
  HIDE_ICD_CPT_SYNONYM_SEARCH: 'masterservice/CodeMaster/UpdateCodeStatus',
  EDIT_CPT_CODE: 'masterservice/CodeMaster/EditCPTCode',
  COPY_PREVIOUS_PATIENT_ENCOUNTER: 'doctorservice/PatientProcedureCode/CopyOcular',
  MOVE_ENCOUNTER_TO_PROVIDER: 'doctorservice/PatientEncounter/MoveEncounterToProvider',
  REORDER_GROUP: 'doctorservice/PatientProcedureCode/ReorderDiagnosis',
  RE_ORDER_OCULAR_LIST: 'doctorservice/EncounterOcularProblem/ReorderOcular',
  CURRENT_ENCOUNTER_DIAGNOSIS: 'doctorservice/PatientProcedureCode/GetDiagnosisByEncounter',
  MOVE_INSURANCE_POLICY: 'masterservice/PatientInsurance/UpdateInsuranceProfileType',
  GET_ASSOCIATE_NDC_FOR_JCODES: 'masterservice/BusinessEntityBillingNDC/GetAssociateNDCForJCode',
  CREATE_ENCOUNTER_VERSION: 'doctorservice/PatientEncounter/CreateEncounterVersion',
  GET_VERSION_PATIENT_ENCOUNTER_LIST: 'doctorservice/PatientEncounter/GetVersionedPatientEncounterList',
  GET_VERSION_ENCOUNTER_DATA: 'doctorservice/PatientEncounter/GetVersionedPatientEncounterList',
  SAVE_VERSIONED_ENCOUNTER: 'doctorservice/SignAndBill/SaveVersionedEncounter',
  GET_PATIENT_ENCOUNTER_BY_ID: 'doctorservice/PatientEncounter/GetPatientEncounterById',
  GET_ENM_DETAILS: 'doctorService/SignAndBill/GetEnMDetails',
  REPORT_FIFTH_ARITMATIC_OPERATOR: 'reportservice/Filter/FifthArithmeticOperator',
  GET_NOTES_BY_ID: 'masterservice/PatientNote/GetNotesById',
  GET_FACESHEET_DATA: 'masterservice/Patient/GetFaceSheetPdf',
  GET_ALL_FACESHEET_DATA: 'masterservice/Patient/GetAllFaceSheetPdf',
  CREATE_SIMPLE_ENCOUNTER: 'doctorservice/PatientEncounter/CreateSimpleEncounter',
  GET_APPOINTMENTS_LIST_DATA: 'schedulerservice/Appointment/GetPatientAppointmentList',
  UNDO_CHECK_IN: 'schedulerservice/Appointment/UndoAppointmentCheckIn',
  GET_SUPERVISING_PROVIDERS: 'masterservice/ResourceProvider/SupervisingProviderList',
  ADD_RESOURCE_PROVIDER: 'masterservice/ResourceProvider',
  GET_RESOURCE_PROVIDER_BY_ID: 'masterservice/ResourceProvider',
  GET_RESOURCE_PROVIDER_LIST: 'masterservice/ResourceProvider/List',
  DOWNLOAD_IMAGING: 'doctorservice/PatientProcedureCode/LabRequisitionPDF',
  GET_PATIENT_REPORT_LIST: 'reportservice/ReportHistory/ProgressList',
  GET_SELECTED_SUPER_BILL_QUESTIONNAIRES: 'doctorservice/PatientFollowup/SuperBillQuestionnaires',
  GET_PATIENT_DETAIL_STATEMENT_PDF: 'masterservice/Statements/DetailStatement',
  GET_P_VERIFY_INTEGRATION: 'masterservice/PatientInsurance/InsuranceNotes',
  APPLY_SUB_SET: 'doctorservice/ProcedureSet/ApplyProcedureSetGroup',
  GET_PROFILE_CONTACTS: 'reportservice/UserContact/ContactList',
  SHARE_REPORT_WITH_CONTACTS: 'reportservice/ReportTemplates/SaveAndShare',
  GET_IMAGE_LIST: 'doctorservice/EncounterImage/ImageListForGraph',
  GET_NOTES_CATEGORY: 'masterservice/Masters',
  GET_SUPERBILL_DATA: 'schedulerservice/Appointment/GetSuperBillPDF',
  GENERATE_STATEMENTS_HTML: 'masterservice/Statements/GenerateStatementsHtml',
  CHECK_SUPERBILL_STATUS: 'schedulerservice/Appointment/CheckSuperBillStatus',
  CHECK_STATEMENT_STATUS: 'masterservice/Statements/CheckStatementStatus',
  SHARE_UNSAVED_REPORT: 'reportservice/ReportTemplates/SaveAndShare',
  DELETE_FAVOURITE_DIAGNOSIS: 'doctorservice/ProcedureSet/FavouriteSetDiagnosis',
  APPLY_DIAGNOSIS_FAVORITE_FOLDER: 'doctorservice/ProcedureSet/ApplyDiagnosisFavoruiteSet',
  COPY_PROVIDERS_FAVORITE_SET: 'doctorservice/ProcedureSet/CopyFavouriteSetLibrary',
  UPDATE_REMITTANCE_BATCH_DETAILS: 'masterservice/RemittanceBatch/UpdateBatchDetails',
  GET_REMITTANCE_PAYMENT_DETAILS_LIST: 'masterservice/RemittanceBatch/RemittancePaymentDetailsList',
  GET_PREVIOUS_ENCOUNTER_DX_LIST: 'doctorservice/PatientProcedureCode',
  SCHEDULE_REPORT: 'reportservice/ScheduleReport/Schedule',
  SCHEDULE_REPORT_LIST: 'reportservice/ScheduleReport/List',
  SCHEDULE_REPORT_UPDATE: 'reportservice/ScheduleReport',
  SCHEDULE_REPORT_BY_ID: 'reportservice/ScheduleReport/GetScheduleById',
  DELETE_OCULAR_PROBLEM_BY_ICD: 'doctorservice/PatientProcedureCode/EncounterDiagnosisByIcdCodeId',
  CREATE_BUNDLE: 'doctorService/ProcedureSet/AddBundleFavouriteSet',
  APPLY_BUNDLE: 'doctorService/PatientEncounterSuperBillQuestionnaire/ApplySuperBillQuestionarire',
  GET_PROCEDURE_SET_BY_ID: 'doctorService/ProcedureSet/GetProcedureSetById',
  GET_GOOGLE_VERIFY_DETAILS: 'identityservice/user/SSO/login',
  REPORT_SIXTH_ARITMATIC_OPERATOR: 'reportservice/Filter/SixthArithmeticOperator',
  REMITTANCE_PATIENT_FINANCIAL_LINE_ITEMS: 'masterservice/PatientFinancial/GetRemittancePatientFinanceLineItems',
  REMITTANCE_FINANCIAL_COMPLETE_CHECK: 'masterservice/PatientFinancial/CompleteCheck',
  GET_VIEW_ENTERED_ITEMS: 'masterservice/PatientFinancial/ViewEnteredItems',
  GET_REMITTANCE_INSURANCE_CLAIMS_CHARGES: 'masterservice/PatientFinancial/GetRemittanceInsuranceClaimCharges',
  GET_VERSIONED_SIGNED_DOCUMENTS: 'masterservice/PatientDocument/DocumentsByRootEncounterId',
  SAVE_COMPLETE_BATCH: 'masterservice/RemittanceBatch/SaveCompleteBatch',
  SAVE_COMPLETE_BATCH_NEW_API: 'masterservice/PatientFinancial/validate-save-complete-batch', // todo Rohit2Bansal now moved to master service
  GET_TASK_SUB_CATEGORY_DETAILS: 'taskService/TaskCategoryConfiguration/TaskSubCategory',
  REMITTANCE_UNBATCH_QUEUE_ACTIONS: 'masterservice/RemittanceBatch/UnbatchedQueue/Action',
  REMITTANCE_ERRORLIST: 'masterservice/RemittanceBatch/ErrorList',
  REMITTANCE_POSTED_INSURANCE_CLAIMS: 'masterservice/RemittanceBatch/PostedInsuranceClaims',
  REMITTANCE_PATIENT_FINANCIAL_POST_BATCH: 'masterservice/PatientFinancial/PostBatch',
  REMITTANCE_PATIENT_FINANCIAL_NEW_POST_BATCH: 'masterservice/RemittanceBatch/validate-post-batch',
  REMITTANCE_BATCH_EDIFILETOHTML: 'masterservice/RemittanceBatch/EdiFileToHtml',
  COPY_VITALS_FROM_LAST_SIGNED_ENCOUNTER: 'doctorservice/PatientEncounterVital/CopyFromPreviousEncounter',
  SKIP_ERROR_VALIDATION: 'masterservice/RemittanceBatch/SkipErrorValidation',
  DELETE_TRANSACTION: 'masterservice/RemittanceBatch/DeleteTransaction',
  EDIT_APPLY_REMITTANCE_ERROR_TRANSACTION: 'masterservice/RemittanceBatch/EditErrorTransaction',
  UNMARK_AS_BLANK_PAGE: 'masterservice/RemittanceBatch/UnmarkAsBlankPage',
  GET_PATIENT_LAST_SIGNED_ENCOUNTER_DETAILS: 'doctorservice/PatientEncounter/GetLastPatientEncounter',
  GET_SEVENTH_ARITHMETICOPERATORS: 'reportservice/Filter/SeventhArithmeticOperator',
  GET_REPORTS_PROGRESS_FILTER: 'reportservice/Filter/ReportProgressFilter',
  GET_REPORTS_SOURCE: 'reportservice/ScheduleReport/GetReportSource',
  GET_BATCH_DETAIL_PDF: 'masterservice/RemittanceBatch/BatchDetailStatement',
  DELETE_REMITTANCE_BATCH: 'masterservice/RemittanceBatch/DeleteBatch',
  // Akshat Garg - 10/7/24 - adding delete batch electronic api
  DELETE_REMITTANCE_BATCH_ELECTRONIC: 'masterService/RemittanceBatch/RemoveBatchFromError',
  GET_REPORT_TEMPLATE_BY_ID: 'reportservice/ReportTemplates',
  RUN_REPORT_TEMPLATE_BY_ID: 'reportservice/ReportTemplates/RunReportTemplate',
  STRIPE_PAYMENT_GATEWAY: 'masterservice/Stripe/PaymentLink',
  CHANGING_SECONDARY_PROFILE_STATUS: 'masterservice/PatientInsurance/ChangeInactiveSecondaryInsuranceOfInsuranceProfile',
  MAKE_SECONDARY_PROFILE_PRIMARY: 'masterservice/PatientInsurance/ChangeInactiveSecondaryInsuranceOfInsuranceProfile',
  CHANGE_INACTIVE_PROFILE: 'masterservice/PatientInsurance/ChangeInActivePrimaryInsuranceOfInsuranceProfile',
  GET_COMPOSER_TEMPLATE_DATA: 'masterservice/PatientCareTeam/GetComposerConsentForm',
  USER_RIGHTS_DATA: 'identityservice/RoleClaim',
  GET_EDUCATION_MATERIAL: 'masterservice/PatientDocument/MIPSEducationMaterial',
  PRINT_EDUCATION_MATERIAL: 'doctorservice/PatientEncounterVital/PrintMIPSEducationMaterial',
  GET_MIPS_ICON: 'masterservice/PatientDocument/MIPSDocument',
  SAVE_PAYER_CLAIM_NUMBER: 'masterservice/Claims/UpdatePayerClaimNo',
  GET_AMOUNT_AFTER_POSTED_BATCH: 'masterservice/PatientFinancial/GetPaymentPaidDetails',
  DELETE_REMITTANCE_CHECK: 'masterservice/RemittanceBatch/DeleteCheck',
  DELETE_REMITTANCE_PAYMENT: 'masterservice/RemittanceBatch/DeleteRemittancePayment',
  GET_MIPS_PARENT_DOCUMENT_BY_ID: 'masterservice/PatientDocument/DocumentsByRootEncounterId',
  GET_PATIENT_LAST_UNSIGNED_ENCOUNTER: 'doctorservice/PatientEncounter/GetPatientLastUnSignedEncounter',
  ADD_FAX_QUEUE_SHOPPING_CART: 'masterservice/EFax/AddToFaxCart',
  UPLOAD_PATIENT_MULTIPLE_DOCUMENTS: 'masterservice/EFax/AddManualDocuments  ',
  GET_PATIENT_DOCUMENT_COUNT: 'masterservice/EFax/DocumentCount',
  GET_MFAX_DOCUMENTS_LIST: 'masterservice/EFax',
  MAKE_REMITTANCE_EDI_JOB: 'masterservice/BusinessEntityRemittance/ReadEdiConfig',
  GET_REMITTANCE_EDI_JOB: 'masterservice/BusinessEntityRemittance/ReadEdiConfigDetails',
  DELETE_FAX_DOCUMENT_IN_SHOPPING_CART: 'masterservice/EFax',
  REORDER_FAX_DOCUMENT_IN_SHOPPING_CART: 'masterservice/EFax/ReorderCartDocument',
  MANUAL_P_VERIFY_VERIFICATION: 'masterservice/Pverify/ManualInsuranceVerification',
  MANUAL_INTERVENTION_QUEUE: 'masterservice/Pverify/ManualInterventionQueue',
  PVERIFY_SUMMARY_RESPONSE: 'masterservice/Pverify/SummaryResponse',
  GET_PLAN_TYPE_LIST: 'masterservice/PatientInsurance/PlanTypeList',
  ADD_UPDATE_PLAN_TYPE: 'masterservice/PatientInsurance/AddPlanType',
  GET_TOP_MOST_FAX_NUMBERS: 'masterservice/EFax/GetProviderCommonFaxNumber',
  SEND_FAX_BY_SHOPPING_CART: 'masterservice/EFax/SendFax',
  CHANGE_RESPONSIBILITY_IN_FINANCIAL: 'masterservice/Claims/ChangePaymentResponsibility',
  CHANGE_RESPONSIBILITY_IN_FINANCIAL_FOR_NO_CLAIM_REQUIRED: 'masterservice/Claims/NoClaimRequired',
  GET_ADDRESS_BOOK_DATA_IN_LETTER: 'masterservice/ProviderAddressBook/GetAddressBookProviderDetail',
  GET_PVERIFY_FREQUENCY_ATTEMPTS: 'masterservice/pverify/InsuranceVerificationCount',
  CREATE_FAX_DOCUMENT_BUNDLE: 'masterservice/EFax/CreateBundle',
  UPDATE_FAX_DOCUMENT_BUNDLE: 'masterservice/EFax/Bundle',
  ADD_UPDATE_CONTRACT_PROVIDER: 'masterservice/Contract/AddOrUpdateContractProviderMapping',
  USER_LOCATION: 'identityservice/RoleClaim/GetLocationPermissionList',
  ADD_USER_LOCATION_CHILD_ROLE: 'identityservice/RoleClaim/AddLocationsToRole',
  GET_MFAX_COVERPAGES: 'masterservice/EFax/CoverPages',
  GET_CHILD_ROLES: 'identityservice/RoleClaim/GetChildRoles',
  GET_PATIENT_PAYMENT_RECEIPT_HTML: 'masterservice/PatientFinancial/PatientPaymentReceipt',
  GET_PATIENT_ENCOUNTERS_BY_APPOINTMENT: 'doctorService/PatientEncounter/GetPatientEncounter',
  GET_MICROSOFT_LOGIN_DETAILS: 'identityservice/user/SSO/login',
  GET_CARE_TEAM_LETTERS: 'masterservice/PatientCareTeam/GetCareTeamLetters',
  GET_COLLECTION_CONFIG: 'masterservice/BusinessEntityCollections/EmptyCompletedConfig',
  PREVIEW_MFAX_DOCUMENTS_WITH_COVERPAGE: 'masterservice/EFax/FaxPreview',
  DELETE_USER_ROLE: 'identityservice/RoleClaim/DeleteRole',
  DELETE_USER_PERMISSION: 'identityservice/RoleClaim/DeletePermissionSet',
  DELETE_AUTHORIZATION_BUNDLE: 'doctorservice/ProcedureSet/FavouriteSet',
  REGENERATE_MFAX_API_KEY: 'masterservice/EFax/CreateApiKey',
  CREATE_MFAX_LOCATION: 'masterservice/Locations/CreateMFaxLocation',
  GENERATE_MANUAL_STATEMENT: 'masterservice/Statements/GenerateManualStatement',
  ADD_CPT_ICD_CROSSWALK: 'masterservice/CodeMaster/ICDCPTMapping',
  STATEMENTS_PDF_PREVIEW: 'masterservice/Statements/preview',
  LOCATIONS_FOR_MFAX: 'masterservice/Locations/UserRolesLocationList',
  REMAKE_SIGNED_PDF: 'doctorservice/SignAndBill/CorrectEncounterPdfData',
  UPDATE_BILLING_ENCOUNTER_WITH_UNBILLABLE: 'masterservice/encounter/unbillable',
  GET_BUSINESS_ENTITY_FEATURES_FLAGS: 'masterservice/BusinessEntity/featureFlags',
  UPDATE_BUSINESS_ENTITY_FEATURES_FLAGS: 'masterservice/BusinessEntity/updateFeatureFlags',
  GET_THRESHOLD_VALUE: 'masterservice/businessEntityCollections/thresholdValue',
  GCP_SIGNED_URL_JSON: 'masterservice/BusinessEntitySchedule/GenerateSignedUrlToUploadJson',
  RUN_ENCOUNTER_REPORT: 'reportservice/EncountersReport/Export',
  RUN_STATEMENT_REPORT: 'reportservice/StatementsReport/Export',
  RUN_PATIENT_PAYMENTS_REPORT: 'reportservice/PatientPaymentsReport/Export',
  GET_BUSINESS_ENTITY_LOGO: 'masterservice/ComposerTemplateHeaderFooter/GetComposerTemplateHeaderImage',
  MARK_AS_SUBMITTED_BY_CLIENT: 'masterservice/Claims/MarkSubmittedByClient',
  Move_BATCH_OUTBOX_TO_READY: 'masterservice/RemittanceBatch/UpdateRemittanceBatchFromOutboxToReady',
};

export const STUBBORN_API_URLS = {
  '/masterservice/masters/zipcodelookup': true,
  '/masterservice/CodeMaster/GetICDCode': true,
  'identityservice/rightsmaster': true,
  'identityservice/userclaims': true,
  'masterservice/CodeMaster/GetCPTCode': true,
  'masterservice/Person': true,
  'masterservice/Person/GetPersonById': true,
  'identityservice/user/authenticate': true,
  'schedulerservice/ExamType': true,
  'schedulerservice/visittype': true,
  'masterservice/InsurancePayer/GetContractPayers': true,
  'masterservice/Provider/ProviderListForScheduler': true,
  'masterservice/Locations/LocationListForScheduler': true,
  'schedulerservice/Appointment/GetProviderMonthView': false,
  'schedulerservice/Appointment/GetListViewScheduler': true,
  'doctorservice/Allergiesmaster': true,
};

export const filePathsInGCP = {
  acuityEyeLogo: 'BusinessEntityLogo/BusinessEntityLogo.png',
};

export const statusCodeForRetry = [500];
