import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Form as AntdForm } from 'antd';
import { useReactToPrint } from 'react-to-print';

import classNames from 'classnames';
import { apiUrls } from '../../../../../../api/constants';

import WithLabel from '../../../../../../hoc/withLabel';
import withQuery from '../../../../../../hoc/withQuery/withQuery';
import useRedirect from '../../../../../../hooks/useRedirect';

import {
  formId, labelPaths, listIds, UiRoutes,
} from '../../../../../../lib/constants';
import Events from '../../../../../../lib/events';
import formFieldValueParser from '../../../../../../lib/formFieldValuesParser';

import Table from '../../../../../../components/Table';
import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import Form from '../../../../../../components/Form';
import Loader from '../../../../../../components/Loader';
import WidgetLoader from '../../../../../../components/WidgetLoader';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import Icon from '../../../../../../components/Icon';

import columns from './Columns/PatientPayment';
import PatientPaymentForm from './PatientPaymentForm';
import Notification from '../../../../../../components/Notification';
import useCRUD from '../../../../../../hooks/useCRUD';
import useRights from '../../../../../../hooks/useRights';
import { getDateWithZeroTimeFormat, isValueUptoTwoDecimalPlaces } from '../../../../../../lib/util';
import rights from '../../../../../../lib/rights';

let financeIds = {};

const constantFilters = {
  IsPatientResponsibility: true,
  isCharge: true,
  BalanceGreaterThanZero: true,
};

const disableLineItemSource = ['CheckIn-Copay', 'CheckOut-Deductible', 'CheckOut-CoInsurance'];

const TableWrapper = ({ Component, data, initializeLineItems }) => {
  useEffect(() => {
    initializeLineItems(data);
  }, [data]);
  return Component;
};

const PatientPayment = ({
  visible, toggleModal, labels, isBillingFinancial, isCollectionsTab = false,
  stateId, stageId, queueId, isCreditPayment,
}) => {
  const viewPanel = useRef();
  const { path } = useRedirect();
  const closeConfirmDialog = useRef();
  const [form] = AntdForm.useForm();
  const [formData, setFormData] = useState({});
  const [hasTransactionItems, setHasTransactionItems] = useState(false);
  const [isEditPaymentAuthenticated] = useRights([rights.access_to_apply_unapplied_credit]);

  const {
    params: { id: PatientId, financialId }, params, generatePath, replace,
  } = useRedirect();

  const PaymentTable = useMemo(() => withQuery({
    // eslint-disable-next-line no-nested-ternary
    url: isCreditPayment ? apiUrls.FINANCIAL_LINE_ITEMS
      : (financialId ? apiUrls.GET_PATIENT_PAYMENT_EDIT_LINE_ITEMS
        : apiUrls.FINANCIAL_LINE_ITEMS),
    listId: `PATIENT_FINANCIAL_INSURANCE_FINANCIAL${PatientId}`,
    accessor: (data) => (financialId && !isCreditPayment ? ({ result: data }) : data),
  })(Table), [PatientId, financialId, isCreditPayment]);

  const handleHtmlPrint = useReactToPrint({
    content: () => viewPanel.current,
    copyStyles: false,
  });

  const [lineItems, setLineItems] = useState([]);

  const [receiptHtml, , receiptLoading, getReceipt, clearReceiptResponse] = useCRUD({
    id: listIds.GET_FINANCIAL_RECEIPT_HTML, url: apiUrls.GET_PATIENT_PAYMENT_RECEIPT_HTML, type: 'read',
  });

  const parser = useCallback((formValues) => {
    const filteredData = { ...formValues };

    if (isCreditPayment) {
      delete filteredData.locationId;
    }

    return {
      ...filteredData,
      effectiveDate: getDateWithZeroTimeFormat(formValues.effectiveDate),
      checkDate: getDateWithZeroTimeFormat(formValues.checkDate),
      stateId,
      stageId,
      queueId,
    };
  }, [queueId, stageId, stateId, isCreditPayment]);

  const initialDataParser = useCallback((values) => {
    const { detailsLineItem } = values || {};
    setHasTransactionItems(!!values?.transactions?.length);
    const { financeDetails } = detailsLineItem || {};
    delete detailsLineItem.financeDetails;
    const clonedValues = { ...detailsLineItem, ...financeDetails, amount: `${detailsLineItem?.amount ?? 0}` };
    return formFieldValueParser(clonedValues, {
      date: [
        'effectiveDate',
        'expirationDate',
        'checkDate',
      ],
    });
  }, []);

  const initializeLineItems = useCallback((data = []) => {
    if (data?.length) {
      setLineItems(data.map(
        ({
          financeId, balance = 0, applied = 0, amount = 0, patientId, description,
        }) => ({
          financeId, balance, applied, amount, patientId, description,
        }),
      ));
    } else {
      setLineItems([]);
    }
  }, []);

  const getLineItemSetter = useCallback((index, key) => (event) => {
    if (event?.target?.value !== parseFloat(lineItems[index]?.applied).toFixed(2)) {
      const newLineItems = [...lineItems];
      if (newLineItems[index]) {
        newLineItems[index] = {
          ...lineItems[index],
          [key]: parseFloat(event?.target?.value, 10) || 0,
          isEdited: true,
        };
      }
      setLineItems(newLineItems);
    }
  }, [lineItems]);

  const handleWeighted = useCallback(() => {
    const { amount } = form.getFieldsValue();
    if (amount === undefined) {
      form.setFields([{
        name: 'amount',
        value: undefined,
        errors: ['Amount is required'],
      }]);
    } else {
      let totalBalance = 0;
      // eslint-disable-next-line no-unused-expressions
      lineItems?.forEach((item) => {
        totalBalance += item?.balance ? parseFloat(item?.balance) : 0;
      });
      if (amount >= totalBalance) {
        setLineItems(lineItems?.map((item) => ({
          ...item,
          applied: Number(parseFloat(item?.balance)) || 0,
          isEdited: true,
        })));
      } else {
        const percentage = (amount / totalBalance) * 100;
        let balance = 0;
        setLineItems(lineItems?.map((item, index) => {
          balance += Number(parseFloat((item?.balance * percentage) / 100).toFixed(2));
          let deductBalance = 0;
          if (index === lineItems?.length - 1 && balance > amount) {
            deductBalance = balance - amount;
          }
          return {
            ...item,
            applied: Number((parseFloat(
              (item?.balance * percentage) / 100,
            ) - deductBalance).toFixed(2)),
            isEdited: true,
          };
        }));
      }
    }
  }, [form, lineItems]);

  const handleChronologically = useCallback(() => {
    let { amount } = form.getFieldsValue();
    if (amount === undefined) {
      form.setFields([{
        name: 'amount',
        value: undefined,
        errors: ['Amount is required'],
      }]);
    } else {
      // eslint-disable-next-line no-unused-expressions
      setLineItems(lineItems?.map((item) => {
        const lineItemBalance = item?.balance ? parseFloat(item?.balance) : 0;
        if (lineItemBalance > amount) {
          const applied = amount;
          amount = 0;
          return ({
            ...item,
            applied,
            isEdited: true,
          });
        }
        if (lineItemBalance <= 0) {
          return ({
            ...item,
            applied: 0,
            isEdited: true,
          });
        }
        amount -= lineItemBalance;
        return ({
          ...item,
          applied: lineItemBalance,
          isEdited: true,
        });
      }));
    }
  }, [form, lineItems]);

  const onRequestComplete = useCallback(({ response }) => {
    toggleModal(false);
    financeIds = response?.financeIds || response?.financeId;
    Notification({
      message: financialId ? 'Payment has been updated successfully.'
        : 'Payment has been added successfully.',
      success: true,
    });
    Events.trigger('refetch-charge-table');
    Events.trigger('refetch-balance-sheet');
    setLineItems([]);
    if (financialId) Events.trigger(`reFetchFinancialDetail-${financialId}`);
    ConfirmDialog({
      onOk: (close) => {
        getReceipt({ PatientId, FinanceId: financeIds || financialId });
        closeConfirmDialog.current = close;
      },
      okButtonProps: {
        'data-testid': 'confirm-popup-ok-btn',
      },
      cancelButtonProps: {
        'data-testid': 'confirm-popup-cancel-btn',
      },
      okText: labels.get('buttons.print'),
      title: labels.get('titles.printReceipt'),
      icon: <Icon name="ExclamationCircleOutlined" />,
      onCancel: () => {
        if (!isCollectionsTab) {
          return !isBillingFinancial
      && !financialId && replace(generatePath(UiRoutes.viewFinancialDetailsWithId,
            { ...params, financialId: response?.financeId }));
        }
        return null;
      },
    })();
  }, [PatientId, financialId, generatePath, getReceipt,
    isBillingFinancial, isCollectionsTab, labels, params, replace, toggleModal]);

  useEffect(() => {
    if (receiptHtml) {
      viewPanel.current.innerHTML = receiptHtml || '';
      handleHtmlPrint();
      clearReceiptResponse(true);
      if (!isBillingFinancial && !isCollectionsTab) {
        replace(generatePath(UiRoutes.viewFinancialDetailsWithId,
          { ...params, financialId: financeIds }));
      }
    }
    if (closeConfirmDialog.current) {
      closeConfirmDialog.current();
      closeConfirmDialog.current = null;
    }
  }, [receiptHtml]);

  useEffect(() => {
    if (!visible) {
      setLineItems([]);
      form.resetFields();
    }
  }, [visible]);

  const parsedLineItems = useMemo(() => {
    const filteredLineItems = [];
    lineItems?.forEach((item) => {
      if (item?.applied > 0) {
        filteredLineItems.push({ ...item, applied: parseFloat(item.applied).toFixed(2) });
      }
    });
    return filteredLineItems;
  }, [lineItems]);

  const parsedEditedLineItems = useMemo(() => {
    const filteredLineItems = [];
    lineItems?.forEach((item) => {
      if (item?.isEdited) {
        filteredLineItems.push({ ...item, applied: parseFloat(item.applied).toFixed(2) });
      }
    });
    return filteredLineItems;
  }, [lineItems]);

  const hasCreditPayment = useMemo(() => lineItems?.some((item) => (item?.description === 'Credit Payment')), [lineItems]);

  const handleSubmit = useCallback(() => {
    const totalApplied = parsedLineItems?.reduce(
      (sum, { applied = 0 }) => sum + Number(applied), 0,
    ) || 0;
    const amount = parseFloat(form.getFieldValue('amount') || 0);

    // hasCreditPayment checks for Credit Payment
    // isCreditPayment checks for Remitted Patient Payment or Credit Patient Payment
    if (hasCreditPayment) {
      if (parseFloat(totalApplied) === 0) {
        Notification({ message: 'Total applied amount cannot be zero' });
        return;
      }
      if (parseFloat(totalApplied) !== parseFloat(amount)) {
        Notification({ message: 'For Credit payments Total applied should be equal to the amount' });
        return;
      }
      form.submit();
    } else if (isCreditPayment) {
      if (parseFloat(totalApplied) === 0) {
        Notification({ message: 'Total applied amount cannot be zero' });
        return;
      }
      if (parseFloat(totalApplied) > parseFloat(amount)) {
        Notification({ message: 'Total applied cannot be more than the amount for credit payments' });
        return;
      }
      form.submit();
    } else {
      if (parseFloat(totalApplied).toFixed(3) !== parseFloat(amount).toFixed(3)) {
        Notification({ message: 'Amount should be equal to total applied' });
        return;
      }
      form.submit();
    }
  }, [parsedLineItems, form, hasCreditPayment, isCreditPayment]);

  const onGetResponseComplete = useCallback((formValues) => {
    setFormData(formValues);
  }, []);

  const shouldEditPayment = useMemo(() => !isEditPaymentAuthenticated && disableLineItemSource.includes(formData?.source) && !hasTransactionItems && formData?.isPatientResponsibility && path.includes('edit/patientPayment'), [formData, hasTransactionItems, isEditPaymentAuthenticated, path]);

  return (
    <>
      <div ref={viewPanel} className="display-none" />
      {(receiptLoading) && <Loader />}
      <Modals
        className="financial-patient-payment-modal"
        visible={!!visible}
        toggleModal={toggleModal}
        width="1000px"
        destroyOnClose
        footer={[
          <div className="group-btns" key="footer">
            <Button
              id="patients_patientPayment_cancel"
              className="btn sm-btn"
              onClick={() => toggleModal(false)}
              data-testid="patient-payment-cancel"
            >
              {labels.get('buttons.cancel')}
            </Button>
            <Button
              id="patients_patientPayment_save"
              className="btn btn-success sm-btn inline"
              onClick={handleSubmit}
              data-testid="patient-payment-save"
            >
              {labels.get('buttons.post')}
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          section
          formId={formId.PATIENT_FINANCIAL_PAYMENT}
          getUrl={financialId && `${apiUrls.GET_FINANCIAL_DETAIL}?FinanceId=${financialId}`}
          isUpdate={!!financialId}
          initialDataParser={financialId && initialDataParser}
          extraData={() => {
            const baseData = {
              PaymentTypeMasterCode: 'FinancialPatientPayment',
              remittancePaymentId: null,
              lineItems: financialId ? parsedEditedLineItems : parsedLineItems,
              PaymentSource: 'OverTheCounter',
              PaymentMethod: 'Manual',
              patientId: PatientId,
              method: 'POST',
            };

            // Only add transactionTypeId if not a credit payment
            if (!isCreditPayment) {
              baseData.transactionTypeId = formData?.transactionTypeId;
            }

            // Only add paymentId if financialId exists
            if (financialId) {
              baseData.paymentId = financialId;
            }

            // Add isCreditPayment flag if there's a credit payment item
            if (hasCreditPayment) {
              baseData.isCreditPayment = true;
            }

            return baseData;
          }}
          parser={parser}
          // eslint-disable-next-line no-nested-ternary
          url={isCreditPayment
            ? apiUrls.ADD_PATIENT_PAYMENT_WITH_LINE_ITEMS
            : (financialId
              ? apiUrls.EDIT_PATIENT_PAYMENT : 'masterservice/PatientFinancial/AddPatientPaymentWithLineItems')}
          loaderComponent={WidgetLoader}
          onRequestComplete={onRequestComplete}
          onGetResponseComplete={onGetResponseComplete}
          refetchId="rePatientPayment"
        >
          <>
            <div className="addition-header">
              <div className="lookup sprite-img-before">
                <p>{labels.get('titles.patientPayment')}</p>
              </div>
            </div>
            <div className="modal-content-weapper shadow-wrap">
              <div className="modal-from-feilds">
                <PatientPaymentForm
                  labels={labels}
                  financialId={financialId}
                  formData={formData}
                  form={form}
                  patientId={PatientId}
                />
                <div className="group-btns justify-content-flex-end flex" key="footer">
                  <Button
                    className={classNames('btn btn-success sm-btn', { 'cursor-not-allowed disabled-btn': shouldEditPayment })}
                    onClick={handleWeighted}
                    disabled={shouldEditPayment}
                    data-testid="patient-payment-weighted"
                  >
                    {labels.get('buttons.weighted')}
                  </Button>
                  <Button
                    className={classNames('btn btn-success sm-btn inline', { 'cursor-not-allowed disabled-btn': shouldEditPayment })}
                    onClick={handleChronologically}
                    disabled={shouldEditPayment}
                    data-testid="patient-payment-chronological"
                  >
                    {labels.get('buttons.chronological')}
                  </Button>
                </div>
                <div className="service-table-container mr-top-12">
                  <PaymentTable
                    columns={columns(labels)}
                    filters={financialId
                      ? {
                        ...(isCreditPayment
                          ? { isCreditPayment: true, ...constantFilters }
                          : { financeId: financialId }),
                        IsPatientResponsibility: true,
                        PatientId,
                      }
                      : {
                        PatientId,
                        ...constantFilters,
                      }}
                    scrollId="patient-financial-service-table"
                    getLineItemSetter={getLineItemSetter}
                    lineItems={lineItems}
                    isValueUptoTwoDecimalPlaces={isValueUptoTwoDecimalPlaces}
                    showNoDataText={false}
                    financialId={financialId}
                    shouldEditPayment={shouldEditPayment}
                    isCreditPayment={isCreditPayment}
                    footer
                  >
                    {({ Component, data }) => (
                      <TableWrapper
                        Component={Component}
                        data={data}
                        initializeLineItems={initializeLineItems}
                      />
                    )}
                  </PaymentTable>
                </div>
              </div>
            </div>
          </>
        </Form>
      </Modals>
    </>
  );
};

export default React.memo(WithLabel(PatientPayment, labelPaths.FINANCIAL_POPUP_PATIENT_PAYMENT));
