const UiRoutes = {
  login: '/',
  supportLogin: '/support_login',
  appointment: '/appointment',
  dashboard: '/dashboard',
  dashboardWithAppointment: '/dashboard/activity/:activityLogAppointmentId',
  schedular: '/scheduler',
  schedularWithId: '/scheduler/:id',
  schedularWithExtraInfo: '/scheduler/:id/:appointmentId/:tab/:tabId/:appointmentStatus',
  patients: '/patients',
  newPatient: '/patients/new',
  editPatient: '/patients/edit/:id/:tabId',
  editPatientWithTabId: '/patients/edit/:id/:tab/:tabId',
  editPatientDocumentWithTabId: '/patients/edit/:id/documents/:tabId/document/:documentId',
  patientInsuranceBaseRoute: '/patients/edit/:id/:tab/:tabId',
  claimInsuranceBaseRoute: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber',
  viewInsuranceProfileWithTabId: '/patients/edit/:id/:tab/:tabId/profile/view/:profileId',
  viewInsuranceProfileWithTabIdInSurgery: '/settings/surgery/surgerySteps/:tabId/:parentTab/:tab/:id/profile/view/:profileId',
  viewInsuranceProfileWithTabIdInClaims: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/profile/view/:profileId',
  editInsuranceProfileWithTabId: '/patients/edit/:id/:tab/:tabId/profile/edit/:profileId/:policyId',
  editInsuranceProfileWithTabIdInSurgery: '/settings/surgery/surgerySteps/:tabId/:parentTab/:tab/:id/profile/edit/:profileId/:policyId',
  editInsuranceProfileWithTabIdInClaims: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/profile/edit/:profileId/:policyId',
  newInsuranceProfileWithTabId: '/patients/edit/:id/:tab/:tabId/profile/new',
  newInsuranceProfileWithTabIdInSurgery: '/settings/surgery/surgerySteps/:tabId/:parentTab/:tab/:id/profile/new',
  newInsuranceProfileWithTabIdInClaims: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/profile/new',
  viewResponsiblePartyWithTabId: '/patients/edit/:id/:tab/:tabId/rp/view/:rpId',
  viewResponsiblePartyWithTabIdInSurgery: '/settings/surgery/surgerySteps/:tabId/:parentTab/:tab/:id/rp/view/:rpId',
  viewResponsiblePartyWithTabIdInClaims: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/rp/view/:rpId',
  editResponsiblePartyWithTabId: '/patients/edit/:id/:tab/:tabId/rp/edit/:rpId',
  editResponsiblePartyWithTabIdInSurgery: '/settings/surgery/surgerySteps/:tabId/:parentTab/:tab/:id/rp/edit/:rpId',
  editResponsiblePartyWithTabIdInClaims: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/rp/edit/:rpId',
  addInsurancePolicyWithTabId: '/patients/edit/:id/:tab/:tabId/profile/new/:profileId/:policyId',
  addInsurancePolicyWithTabIdInSurgery: '/settings/surgery/surgerySteps/:tabId/:parentTab/:tab/:id/profile/new/:profileId/:policyId',
  addInsurancePolicyWithTabIdInClaims: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/profile/new/:profileId/:policyId',
  addResponsiblePartyWithTabId: '/patients/edit/:id/:tab/:tabId/rp/add',
  addResponsiblePartyWithTabIdInSurgery: '/settings/surgery/surgerySteps/:tabId/:parentTab/:tab/:id/profile/new/rp/add',
  addResponsiblePartyWithTabIdInClaims: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/profile/new/rp/add',
  viewFinancialDetailsWithId: '/patients/edit/:id/:tab/:tabId/view/:financialId',
  editAuthorizationWithTabId: '/patients/edit/:id/:tab/:tabId/authorization/edit/:authorizationId',
  newAuthorizationWithTabId: '/patients/edit/:id/lists/:tabId/authorization/new',
  editListCasesWithTabId: '/patients/edit/:id/:tab/:tabId/cases/edit/:caseId',
  newListCasesWithTabId: '/patients/edit/:id/lists/:tabId/cases/new',
  viewEncounterWithId: '/patients/edit/:id/encounters/:tabId/view/:encounterId',
  editEncounterWithId: '/patients/edit/:id/encounters/:tabId/edit/:encounterId',
  newEncounterWithTabId: '/patients/edit/:id/encounters/:tabId/new',
  editFinancialEncounterWithTabId: '/patients/edit/:id/encounters/:tabId/editEncounter/:encounterId/:financialId',
  newAdjustmentWithTabId: '/patients/edit/:id/:tab/:tabId/adjustment/:adjustmentType/new',
  editClaimWithTabId: '/patients/edit/:id/:tab/:tabId/claim/edit/:claimId',
  viewStatementsWithTabId: '/patients/edit/:id/:tab/:tabId/claim/view/:statementId',
  claims: '/claims',
  claimsTab: '/claims/:tab',
  newClaims: '/cpt/new',
  doctor: '/doctor',
  encounterDetailView: '/new-doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/:tab',
  doctorWithPatientID: '/doctor/:providerId/:patientId/:appointmentId',
  doctorWithPatientIDAndEncounterId: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId',
  doctorWithPatientIDAndSignedEncounterId: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/signedNote',
  doctorWithPatientIDAndSignedEncounterIdWithLatestEncounter: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/signedNote/:isLatestEncounter',
  addMedication: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/addMedication',
  addMedicationTechnician: '/technician/:providerId/:patientId/:appointmentId/:encounterId/addMedication',
  addAllergy: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/addAllergy',
  addTeamCare: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/addTeamCare',
  orderSet: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/orderSet',
  createOrderSet: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/createOrderSet/:tab',
  editOrderSet: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/createOrderSet/:orderSetId/:tab',
  addHistory: '/doctor/:patientId/:encounterId/history/addHistory',
  addOther: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/other/new',
  addOcularProblem: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/ocularProblem/new',
  editOcularProblem: '/doctor/:patientId/:encounterId/ocularProblem/edit/:id',
  addOtherTechnician: '/technician/:providerId/:patientId/:appointmentId/:encounterId/other/new',
  addProcedure: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/addProcedure',
  editProcedure: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/editProcedure/:procedureId',
  technician: '/technician',
  pverify: '/pverify',
  technicianLilyLee: '/technicianLilyLee',
  technicianWithPatientID: '/technician/:providerId/:patientId/:appointmentId',
  technicianWithPatientIDAndEncounterId: '/technician/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tab/:tabId',
  technicianWithPatientIDAndAppointmentId: '/technician/:providerId/:patientId/:appointmentId',
  technicianLilyLeeWithPatientID: '/technicianLilyLee/:providerId/:patientId/:appointmentId',
  technicianLilyLeeWithPatientIDAndEncounterId: '/technicianLilyLee/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:parentTab/:tabId/:tab',
  technicianLilyLeeWithPatientIDAndAppointmentId: '/technicianLilyLee/:providerId/:patientId/:appointmentId',
  technicianLilyLeeWithPatientIDAndEncounterIdDraw: '/technicianLilyLee/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:parentTab/:tabId/:tab',
  technicianLilyLeeWithPatientIDAndEncounterIdDrawWithTabId: '/technicianLilyLee/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:parentTab/:tabId/:tab',
  technicianLilyLeeWithPatientIDAndEncounterIdDrawWithTabIdAndCoolScupltId: '/technicianLilyLee/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:parentTab/:tabId/:tab/:coolSculptId',
  document: '/document',
  contract: '/contract',
  newContract: '/contract/new',
  editContract: '/contract/edit/:id',
  editContractWithTabId: '/contract/edit/:id/:tab/:tabId',
  addContractTermsWithTabId: '/contract/edit/:id/:tab/:tabId/:termsTab/new',
  editContractTermsWithTabId: '/contract/edit/:id/:tab/:tabId/:termsTab/edit/:termId',
  billing: '/billing',
  addBilling: '/billing/:tabId/:id/new',
  tasksWithTabId: '/tasks',
  tasksDetailWithTabId: '/tasks/edit/:taskId',
  editBillingEncounterWithTabId: '/billing/edit/:id/:encounterId/:tabId',
  settings: '/settings',
  businessEntity: '/settings/businessEntity',
  newBusinessEntity: '/settings/businessEntity/new',
  editBusinessEntity: '/settings/businessEntity/edit/:id',
  editBusinessEntityWithTabId: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab',
  editBusinessEntityWithTabIdMasterId: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/:masterDataId',
  editBusinessEntityWithTabIdPayerAgingId: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/edit/:payerAgingId',
  editBusinessEntityCredentials: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/:credentialId',
  editBusinessEntityNewBlockOut: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/:providerId/blockOut/new',
  editBusinessEntityEditBlockOut: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/:providerId/blockOut/edit/:blockOutId',
  editBusinessEntityNewTemplate: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/:providerId/template/new',
  editBusinessEntityEditTemplate: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/:providerId/template/edit/:templateId',
  editBusinessEntityNewCancellation: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/new',
  editBusinessEntityEditCancellation: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/edit',
  editBusinessEntityNewResource: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/new',
  editBusinessEntityEditResource: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/edit/:resourceProviderId',
  editBusinessEntityNewTestType: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/new',
  editBusinessEntityEditTestType: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/edit/:examTypeId',
  editBusinessEntityNewVisitType: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/new',
  editBusinessEntityEditVisitType: '/settings/businessEntity/:entityType/edit/:id/:tabId/:tab/:subTab/edit/:visitTypeId',
  provider: '/settings/provider',
  CPT: '/settings/cpt',
  ICD: '/settings/icd',
  CPT_ICD_CROSSWALK: '/settings/cpticd',
  schedularSettings: '/settings/schedularSettings/:tab',
  newBlockOut: '/settings/schedularSettings/:tab/:providerId/new/blockOut',
  editBlockOut: '/settings/schedularSettings/:tab/edit/:providerId/blockOut/:blockOutId',
  newTemplate: '/settings/schedularSettings/:tab/:providerId/new/template',
  editTemplate: '/settings/schedularSettings/:tab/:providerId/edit/template/:templateId',
  payer: '/settings/payer',
  Inventory: '/settings/inventory',
  InventoryTabWithOutLocation: '/settings/inventory/:tab',
  InventoryTab: '/settings/inventory/:locationName/:locationId/:tab',
  InventorySubTab: '/settings/inventory/:locationName/:locationId/:tab/:subTab',
  EditManufacturer: '/settings/inventory/:locationName/:locationId/:tab/:subTab/edit/:manufacturerId',
  AddPromotion: '/settings/inventory/:locationName/:locationId/:tab/:subTab/new',
  EditPromotion: '/settings/inventory/:locationName/:locationId/:tab/:subTab/edit/:promotionId',
  InventorySubTabWithBatchId: '/settings/inventory/:locationName/:locationId/:tab/:subTab/:batchId',
  ConcernForm: '/settings/ConcernForm',
  Synonyms: '/settings/synonyms',
  UserRoles: '/settings/UserRole',
  UserRolesAdd: '/settings/UserRole/add',
  UserRolesEdit: '/settings/UserRole/edit',
  ManageFavDoc: '/settings/ManageFavDoc',
  SynonymsWithTab: '/settings/synonyms/:tab',
  Reports: '/settings/inventory/:locationName/:locationId/reports/:tab',
  SurgerySteps: '/settings/surgery/surgerySteps/:tabId/:parentTab/:tab/:id',
  SurgeryPatient: '/settings/surgery',
  newPayer: '/settings/payer/new',
  editPayer: '/settings/payer/:id/:tab',
  newProvider: '/settings/provider/new',
  editProvider: '/settings/provider/edit/:id',
  editProviderWithTabId: '/settings/provider/edit/:id/:tab/:tabId',
  newBillingWithTabId: '/settings/provider/edit/:id/:tab/:tabId/new',
  editBillingWithTabId: '/settings/provider/edit/:id/:tab/:tabId/edit/:billingId',
  newPayerHoldWithTabId: '/settings/provider/edit/:id/:tab/:tabId/new',
  editPayerHoldWithTabId: '/settings/provider/edit/:id/:tab/:tabId/edit/:payerHoldId',
  newPrescriptionWithTabId: '/settings/provider/edit/:id/:tab/:tabId/new',
  editPrescriptionWithTabId: '/settings/provider/edit/:id/:tab/:tabId/edit/:prescriptionId',
  users: '/settings/users',
  newUser: '/settings/users/new',
  editUser: '/settings/users/edit/:id',
  editUserWithTabId: '/settings/users/edit/:id/:tab/:tabId',
  locations: '/settings/locations',
  newLocations: '/settings/locations/new',
  editLocations: '/settings/locations/edit/:id/:tab',
  editLocationWithTabId: '/settings/locations/edit/:id/:tab/:tabId',
  newExamRoomWithTabId: '/settings/locations/edit/:id/:tab/:tabId/new',
  editExamRoomWithTabId: '/settings/locations/edit/:id/:tab/:tabId/edit/:examId',
  patientDocumentsWithCategoryId: '/patients/edit/:id/:tab/:tabId/:categoryId/:categoryName',
  encounterAddImages: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/addImages',
  encounterAddImagesWithoutTestType: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/addImagesWithOutTestType',
  encounterAddImagesTechnician: '/technician/:patientId/:encounterId/addImages',
  encounterViewImages: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/viewImages/:imageId/:imageEncounterId',
  encounterViewImagesTechnician: '/technician/:patientId/:encounterId/viewImages',
  encounterViewSensorimotor: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/viewSensorimotor',
  viewSuperBill: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/superbill',
  encounterViewSensorimotorTechnician: '/technician/:patientId/:encounterId/viewSensorimotor',
  addCredential: '/settings/provider/edit/:id/:tab/:tabId/new',
  editCredential: '/settings/provider/edit/:id/:tab/:tabId/edit/:credentialId',
  patientAuthorizationListWithTabId: '/patients/edit/:id/:tab/:tabId/authorization',
  patientBillStatementListWithTabId: '/patients/edit/:id/:tab/:tabId/BillDetails',
  patientListWithListTabId: '/patients/edit/:id/:tab/:tabId/:listTab',
  draw: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/draw',
  drawWithTabId: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/draw/:tab',
  refract: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/refract',
  outsideTesting: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId/outsideTesting',
  technicianRefract: '/technician/:patientId/:encounterId/refract',
  viewDoctorEncounter: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:previousEncounterId/:tabId',
  viewTechnicianEncounter: '/technician/:patientId/:encounterId',
  cancellationReason: '/settings/schedularSettings/:tab',
  editCancellationReason: '/settings/schedularSettings/:tab/edit/:id',
  addCancellationReason: '/settings/schedularSettings/:tab/new',
  newSchedularSettingsVisitType: '/settings/schedularSettings/:tab/new',
  editSchedularSettingsVisitType: '/settings/schedularSettings/:tab/edit/:visitTypeId',
  newSchedularSettingsExamType: '/settings/schedularSettings/:tab/new',
  schedularSettingsExamType: '/settings/schedularSettings/:tab',
  editSchedularSettingsExamType: '/settings/schedularSettings/:tab/edit/:examTypeId',
  newResource: '/settings/schedularSettings/:tab/new',
  editResource: '/settings/schedularSettings/:tab/edit/:resourceId',
  forgotPassword: '/forgotPassword',
  resetPassword: '/ConfirmPassword',
  EmailVerification: '/patient/ConfirmEmail',
  claimsErrorPopup: '/claims/:tab/error/:errorTab/:claimNumber/:patientId/:encounterId/:providerId',
  remittanceNewBatchPopup: '/claims/:tab/:claimFilter/newBatch',
  collectionClaims: '/claims/:tab/:claimFilter/:type/:collectionId',
  collectionClaimsFilters: '/claims/:tab/:claimFilter',
  collectionClaimsAdditionalFilters: '/claims/:tab/:claimFilter/:type/:collectionId/:groupByFilter/:selectedAdditionFilter/:agingMasterCode/:filterId/:denialCategoryCode',
  claimsFilters: '/claims/:tab/:claimFilter',
  remittanceClaimsFilters: '/claims/:tab/:claimFilter',
  statementClaimsFilters: '/claims/:tab/:claimFilter',
  statementClaimsFiltersAddTask: '/claims/:tab/:claimFilter/addTask/:patientId',
  remittanceClaimsWorkList: '/claims/:tab/worklist/:parentTab',
  remittanceClaimsWorkListEditSuspense: '/claims/:tab/:claimFilter/:parentTab/:suspenseId/:reasonName',
  remittanceClaimsWorkListEditSuspenseAssignItem: '/claims/:tab/:claimFilter/:parentTab/:suspenseId/assignItem',
  claimsRemittance: '/claims/:tab/:claimFilter/:tabId/batch/:remittanceId',
  claimsRemittanceNewInsurance: '/claims/:tab/:claimFilter/:tabId/batch/:remittanceId/:remittancePaymentId/:pageNo/insurance/new',
  claimsRemittanceEditInsurance: '/claims/:tab/:claimFilter/:tabId/batch/:remittanceId/insurance/edit/:remittancePaymentId/:insurancePaymentPage',
  claimsRemittanceErrorEditInsurance: '/claims/:tab/:claimFilter/:tabId/batch/:remittanceId/insurance/batchDetailsError/:remittancePaymentId/:insurancePaymentPage/:errorTab',
  postClaimTablePopUp: '/postedClaimTable/:title/:patientId/:amount/:worklistId/:ClaimId/:balance/:pageNumber/:claimNo/:remittancePaymentIdentifier',
  postClaimTablePopUpWithSearchText: '/postedClaimTable/:title/:patientId/:amount/:worklistId/:ClaimId/:balance/:pageNumber/:claimNo/:remittancePaymentIdentifier/:searchTextData',
  postClaimTablePopUpWithSearchTextWithErrorId: '/postedClaimTable/:title/:patientId/:amount/:worklistId/:ClaimId/:balance/:pageNumber/:claimNo/:remittancePaymentIdentifier/:searchTextData/:errorId',
  postClaimTablePopUpWithSelectedClaim: '/postedClaimTable/:title/:patientId/:amount/:worklistId/:ClaimId/:balance/:pageNumber/:claimNo/:remittancePaymentIdentifier/:searchTextData/:selectedClaimData/:editTransactionPatientId',
  postClaimTablePopUpWithSelectedClaimWithErrorId: '/postedClaimTable/:title/:patientId/:amount/:worklistId/:ClaimId/:balance/:pageNumber/:claimNo/:remittancePaymentIdentifier/:searchTextData/:errorId/:selectedClaimData/:editTransactionPatientId',
  claimsRemittanceNewPatient: '/claims/:tab/:claimFilter/:tabId/batch/:remittanceId/:remittancePaymentId/:pageNo/patient/new',
  claimsRemittanceEditPatient: '/claims/:tab/:claimFilter/:tabId/batch/:remittanceId/patient/edit/:remittancePaymentId/:patientPaymentPage',
  claimsWithTabs: '/claims/:tab/:tabId',
  claimsWithRemittanceTabs: '/claims/:tab/:claimFilter/:tabId',
  claimsPatient: '/claims/:tabId/patient/:id',
  claimsProvider: '/claims/:tabId/provider/:id',
  claimsEncounter: '/claims/:tabId/encounter/:id',
  claimsCollections: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber',
  createNewClaimsPopup: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/createNewClaim',
  markForFollowUpPopUp: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/markForFollowUp',
  patientPaymentPopUp: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/paymentPatient',
  changeResponsibilityPopUp: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/changeResponsibility',
  billPatientPopUp: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/billPatient',
  enterAdjustmentsClaims: '/claims/:tab/:tabId/collection/:claimFilter/:type/:collectionId/:id/:claimNumber/enterAdjustment',
  masters: '/settings/masters',
  mastersWithId: '/settings/masters/:id',
  editMastersWithId: '/settings/masters/:id/:masterDataId',
  composer: '/settings/composer',
  newComposerTemplate: '/settings/composer/:templateType/:templateTypeId',
  editComposerTemplate: '/settings/composer/:templateId',
  reportsMain: '/reports',
  enterReportsWithType: '/reports/:reportType',
  enterReportsWithSubType: '/reports/:reportType/:subType',
  enterReportsWithSubTypeAndId: '/reports/:reportType/:subType/:reportId',
  editPatientPayment: '/patients/edit/:id/:tab/:tabId/view/:financialId/edit/patientPayment',
  editPatientRefund: '/patients/edit/:id/:tab/:tabId/view/:financialId/edit/patientRefund',
  financePaymentReversal: '/patients/edit/:id/:tab/:tabId/view/:financialId/paymentReversal',
  editInsurancePayment: '/patients/edit/:id/:tab/:tabId/view/:financialId/edit/insurancePayment',
  editInsuranceRefund: '/patients/edit/:id/:tab/:tabId/view/:financialId/edit/insuranceRefund',
  doctorWithPatientIDAndAddSimpleEncounter: '/doctor/:providerId/:patientId/:appointmentId/:encounterId/:type',
};

const formName = {
  patientForm: 'patient-form',
  providerForm: 'provider-form',
  usersForm: 'users-form',
  usersProfileForm: 'users-profile-form',
  locationsForm: 'locations-form',
  feePreferencesGeneralForm: 'fee-preferences-general-form',
  billingForm: 'billing-form',
  credentialForm: 'credential-form',
  patientCaseForm: 'patient-case-form',
  patientAuthorizationForm: 'patient-authorization-form',
  businessEntityForm: 'business-entity-form',
  appointmentBasicReportForm: 'appointment-basic-report-form',
  patientsReportForm: 'patients-report-form',
  feeScheduleUploadForm: 'fee-schedule-upload-form',
};

const formId = {
  PATIENT_DEMOGRAPHIC: 'patient-demographic',
  LOCATION_GENERAL: 'location-general',
  PROVIDER: 'provider',
  USER: 'setting-user-form',
  E_PRESCRIPTION: 'setting-provider-ePrescription',
  billing: 'provider-billing-form',
  REQUEST_NEW_PAYER: 'request-new-payer',
  EDIT_PATIENT_DEMOGRAPHIC: 'patient-edit-demographic',
  ADD_PATIENT: 'add-patient',
  ADD_LOCATION: 'add-location',
  EDIT_LOCATION_GENERAL: 'location-edit-general',
  ADD_PROVIDER: 'add-provider',
  EDIT_PROVIDER_GENERAL: 'settings-provider-edit-general',
  ADD_USER: 'setting-add-user',
  EDIT_USER_GENERAL: 'setting-user-edit-general',
  ADD_E_PRESCRIPTION: 'setting-provider-add-ePrescription',
  EDIT_E_PRESCRIPTION: 'setting-provider-edit-ePrescription',
  ADD_BILLING: 'provider-add-billing',
  EDIT_BILLING: 'provider-edit-billing',
  ADD_PAYERHOLD: 'provider-add-payerHold',
  EDIT_PAYERHOLD: 'provider-edit-payerHold',
  MEDICATION_FORM: 'doctor-encounter-medication',
  PROCEDURE_FORM: 'doctor-encounter-procedure',
  EDIT_PROCEDURE_FAVOURITE: 'doctor-encounter-procedure-favourite-edit',
  UPLOAD_DOCUMENT: 'patient-document-upload',
  ADD_NEW_CPT: 'add_new_cpt',
  ADD_NEW_CASE: 'add_new_case',
  UPDATE_CASE: 'update_case',
  EDIT_AUTHORIZATION: 'edit-authorization',
  ADD_AUTHORIZATION: 'add-authorization',
  ADD_ENCOUNTER: 'add-encounter',
  EDIT_PROVIDER_CREDENTIALS: 'provider-edit-credentials',
  ADD_PROVIDER_CREDENTIALS: 'provider-add-credentials',
  ADD_PAYER: 'add-payer',
  ADD_PAYER_MAPPING: 'add-payer-mapping',
  ADD_EXAM_ROOM: 'add-exam-room',
  EDIT_EXAM_ROOM: 'edit-exam-room',
  ADD_CANCELLATION_REASON: 'add-cancellation-reason',
  EDIT_CANCELLATION_REASON: 'edit-cancellation-reason',
  ADD_VISIT_TYPE: 'add-visit-type',
  EDIT_VISIT_TYPE: 'edit-visit-type',
  ADD_EXAM_TYPE: 'add-exam-type',
  EDIT_EXAM_TYPE: 'edit-exam-type',
  ADD_RESOURCE: 'add-resource',
  EDIT_RESOURCE: 'edit-resource',
  ADD_BLOCKOUT: 'add-block-out',
  EDIT_BLOCKOUT: 'edit-block-out',
  ADD_TEMPLATE: 'add-template',
  EDIT_TEMPLATE: 'edit-template',
  ADD_CONTRACT_GENERAL: 'add-contract',
  EDIT_CONTRACT: 'edit-contract',
  EDIT_PAYER: 'edit-payer',
  ADD_PERSON: 'add-person',
  EDIT_PERSON: 'edit-person',
  ADD_RESPONSIBLE_PARTY: 'add-responsible-party',
  EDIT_RESPONSIBLE_PARTY: 'edit-responsible-party',
  ADD_SECONDARY_POLICY: 'add-secondary-policy',
  EDIT_INSURANCE_PROFILE: 'edit-insurance-profile',
  ALLERGIES: 'allergies',
  LOGIN_FORM: 'login-form',
  RESET_PASSWORD_FORM: 'reset-password-form',
  SCHEDULAR_ADD_APPOINTMENT_PATIENT: 'schedular-add-appointment-patient',
  ADD_APPOINTMENT: 'add-appointment',
  FORGOT_PASSWORD_FORM: 'forgot-password-form',
  ADD_TERM_GENERAL: 'contract-add-term-general',
  EDIT_TERM_GENERAL: 'contract-edit-term-general',
  BOOK_APPOINTMENT_FILTER: 'book-appointment-filter',
  UPDATE_PROVIDER_FEE_PREFERENCE_GENERAL: 'provider-fee-preference-general',
  CC_HPI: 'cc_hpi',
  PATIENT_REFRACTION: 'patient-refraction',
  ADD_TECHNICIAN_EXAM_VISION: 'technician-exam-vision-add',
  TECHNICIAN_EXAM_VITALS: 'technician-exam-vitals',
  ADD_TECHNICIAN_EXAM_PRESSURE: 'technician-exam-pressure-add',
  SCHEDULAR_SETTINGS_BASICS: 'schedular-settings-basics',
  PROVIDER_FEE_PREFERENCES_EXCEPTION: 'provider-fee-preferences-exception',
  UPDATE_PROVIDER_FEE_PREFERENCES_EXCEPTION: 'update-provider-fee-preferences-exception',
  ADD_FAVOURITE: 'add-favourite',
  ADD_FOLDER: 'add-folder',
  ADD_DOCTOR_PRESSURE: 'doctor-pressure-add',
  EDIT_DOCTOR_TECHNICIAN_PRESSURE: 'doctor-pressure-edit',
  EDIT_DOCTOR_TECHNICIAN_VISION: 'doctor-vision-edit',
  DELETE_DOCTOR_PRESSURE: 'doctor-pressure-remove',
  DELETE_DOCTOR_VISION: 'doctor-vision-remove',
  ADD_DOCTOR_GOAL_PRESSURE: 'doctor-pressure-goal-add',
  ADD_DOCTOR_VISION: 'doctor-vision-add',
  TECHNICIAN_MOTILITY: 'technician-motility',
  ASSESSMENT_AND_PLAN: 'assessment-and-plan',
  PMFSHX_NO_REVIEWED: 'pmfshx-no-reviewed',
  CHIEF_COMPLAINT_DOCTOR: 'chief-complaint-doctor',
  DOCTOR_FOLLOW_UP_FORM: 'doctor-follow-up-form',
  DOCTOR_FOLLOW_UP_FORM_ORDER_SET: 'doctor-follow-up-form-order-set',
  OUTSIDE_TESTING: 'doctor-outside-testing',
  EDIT_PATIENT_CARD: 'check-in-card',
  EDIT_PATIENT_CARD_USER_PROFILE: 'user-profile-check-in',
  DROPDOWN_HPI: 'Dropdown-hpi',
  ADD_ADDRESS_BOOK: 'add-address-book',
  UPDATE_ADDRESS_BOOK: 'update-address-book',
  ADD_INCIDENT: 'add-incident',
  NEW_CLAIM: 'add-new-claim',
  NEW_REMITTANCE_BATCH: 'add-new-remittance-batch',
  TASK_REASSIGN: 'task-reassign',
  REMITTANCE_INSURANCE_WORK_LIST: 'remittance-insurance-work-list',
  TECHNICIAN_COOLTONE_ADD_UPDATE: 'technician-cooltone-add-and-update',
  ADD_CUTERA: 'add-cutera',
  MARKUP_FOR_FOLLOWUP: 'markup-for-followup',
  MICRO_NEEDLING: 'micro-needling',
  EDIT_TASK_COMMENT: 'edit-task-comment',
  ADD_ULTHERAPY: 'Ultherapy-form',
  COOL_SCULPT: 'cool-sculpt-add',
  LILLY_LEE_HPI: 'lilly-lee-hpi',
  TECHNICIAN_EYELIDS_FORM: 'Technician-Eyelids-form',
  PAYER_AGING_ADD: 'add-payer-aging',
  PAYER_AGING_UPDATE: 'update-payer-aging',
  ADD_SIMPLE_CHARGE: 'add-simple-charge',
  SUSPENSE_ASSIGN_TO: 'suspense-assigned-to',
  ADD_PATIENT_STATUS: 'appointment-patient-status',
  UPDATE_PROMOTION: 'update-inventory-promotion',
  ADD_PROMOTION: 'add-inventory-promotion',
  ADD_BATCH_TO_RECEIVE: 'add-batch-to-inventory',
  TRANSFER_INVENTORY_ADD: 'add-item-transfer-inventory',
  ADD_PATIENT_ADJUSTMENT: 'add-patient-adjustment',
  Add_INSURANCE_ADJUSTMENT: 'add-insurance-adjustment',
  EDIT_INVENTORY_LINE_ITEM: 'edit-inventory-line-item',
  UPLOAD_INVENTORY_AUTHORIZATION_FILE: 'upload-inventory-authorization-file',
  GET_INVENTORY_OPTICAL_RX: 'get-inventory-optical-rx',
  INVENTORY_INVOICE_DETAILS: 'inventory-invoice-details',
  SEND_ITEM_TO_LOCATION: 'send-inventory-item',
  ADD_ON_HAND_ADJUSTMENT: 'add-on-hand-adjustment',
  CHECK_IN_FINANCIAL: 'add-check-in financial',
  UPLOAD_DATA_FILE: 'upload-data-file',
  ADD_MANUFACTURER: 'add-manufacturer',
  UPDATE_MANUFACTURER: 'update-manufacturer',
  UPDATE_COMPOSER_TEMPLATE: 'update-composer-template',
  UPDATE_COMPOSER_SETTINGS: 'update-composer-settings',
  SAVE_COMPOSER_HEADER: 'SAVE_COMPOSER_HEADER',
  SAVE_COMPOSER_FOOTER: 'SAVE_COMPOSER_FOOTER',
  ADD_BUSINESS_ENTITY: 'ADD_BUSINESS_ENTITY',
  ADD_BUSINESS_ENTITY_STATEMENT: 'ADD_BUSINESS_ENTITY_STATEMENT',
  ADD_BUSINESS_REMITTANCE: 'ADD_BUSINESS_REMITTANCE',
  ADD_BUSINESS_PATIENT_BASICS: 'ADD_BUSINESS_PATIENT_BASICS',
  ADD_P_VERIFY: 'ADD_P_VERIFY',
  ADD_P_VERIFY_PAYOR_REQUEST: 'ADD_P_VERIFY_PAYOR_REQUEST',
  ADD_P_VERIFY_NON_EDI: 'ADD_P_VERIFY_NON_EDI',
  ADD_P_VERIFY_MANUAL_REQUEST: 'ADD_P_VERIFY_MANUAL_REQUEST',
  ADD_BUSINESS_ENTITY_CLAIMS: 'ADD_BUSINESS_ENTITY_CLAIMS',
  ADD_BUSINESS_CONTACT_TERMS: 'ADD_BUSINESS_CONTACT_TERMS',
  BUSINESS_ENTITY_BILLING_BASICS: 'BUSINESS_ENTITY_BILLING_BASICS',
  BUSINESS_ENTITY_PATIENTS_CHART: 'BUSINESS_ENTITY_PATIENTS_CHART',
  ADD_BUSINESS_ENTITY_MODIFIERS: 'ADD_BUSINESS_ENTITY_MODIFIERS',
  ADD_PRESSURE_CCT: 'ADD_DOCTOR_PRESSURE_CCT',
  SAVE_BUSINESS_ENTITY_BILLING_NDC: 'SAVE_BUSINESS_ENTITY_BILLING_NDC',
  SAVE_BUSINESS_ENTITY_SCHEDULE: 'SAVE_BUSINESS_ENTITY_SCHEDULE',
  ADD_BUSINESS_ENTITY_TASK: 'ADD_BUSINESS_ENTITY_TASK',
  ADD_BUSINESS_ENTITY_SUB_TASK: 'ADD_BUSINESS_ENTITY_SUB_TASK',
  DASHBOARD_CHECK_IN_INSURANCE_FORM: 'DASHBOARD_CHECK_IN_INSURANCE_FORM',
  UPDATE_BUSINESS_ENTITY_TASK: 'UPDATE_BUSINESS_ENTITY_TASK',
  ADD_SYNONYM_CPT_ICD: 'ADD_SYNONYM_CPT_ICD',
  EDIT_CCT: 'edit-encounter-cct',
  APPOINTMENT_BASIC_REPORT_FORM: 'APPOINTMENT_BASIC_REPORT_FORM',
  PATIENTS_REPORT_FORM: 'PATIENTS_REPORT_FORM',
  PATIENT_FINANCIAL_PAYMENT: 'PATIENT_FINANCIAL_PAYMENT',
  CHECKOUT_FINANCIAL: 'CHECKOUT_FINANCIAL',
  ADD_PATIENT_REFUND: 'ADD_PATIENT_REFUND',
  REVERSE_PAYMENT: 'REVERSE_PAYMENT',
  INJECTABLE_FORM: 'INJECTABLE_FORM',
  SCHEDULE_FORM: 'SCHEDULE_FORM',
  ADD_UPDATE_PLAN_TYPE_FORM: 'ADD_UPDATE_PLAN_TYPE_FORM',
  ADD_CHILD_ROLE: 'ADD_CHILD_ROLE',
  EMPTY_COLLECTION_QUEUE: 'EMPTY_COLLECTION_QUEUE',
  EMPTY_THRESHOLD_VALUE: 'EMPTY_THRESHOLD_VALUE',
  ADD_WAYSTAR_STATEMENT: 'ADD_WAYSTAR_STATEMENT',
  PROVIDER_EDIT_BILLING: 'PROVIDER_EDIT_BILLING',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};

const featureKeys = {
  RULES_ENGINE_FLAG: 'rules_engine_evaluation',
};

const listIds = {
  SETTINGS_USERS: 'SETTINGS_USERS',
  PATIENTS_DOCUMENTS: 'PATIENTS_DOCUMENTS',
  SETTINGS_PROVIDER_E_PRESCRIPTION: 'SETTINGS_PROVIDER_E_PRESCRIPTION',
  PROVIDER_CREDENTIALS: 'SETTING_PROVIDER_CREDENTIALS',
  AUTHORIZATION_LIST: 'LIST_AUTHORIZATIONS',
  SETTING_PAYERS: 'SETTING_PAYERS',
  SETTING_PROVIDER_BILLING: 'SETTING_PROVIDER_BILLING',
  GET_PATIENT_DOCUMENT: 'GET_PATIENT_DOCUMENT',
  SETTING_PROVIDER_PAYERHOLD: 'SETTING_PROVIDER_PAYERHOLD',
  SETTING_GET_CPT_PAYER_MAPPING: 'SETTING_GET_CPT_PAYER_MAPPING',
  CPT_CODE_SYNONYMS_LIST: 'CPT_CODE_SYNONYMS_LIST',
  ICD_CODE_SYNONYMS_LIST: 'ICD_CODE_SYNONYMS_LIST',
  SETTING_GET_ICD_CODE_MAPPING: 'SETTING_GET_ICD_CODE_MAPPING',
  SETTING_DEL_ICD_CODE_MAPPING: 'SETTING_DEL_ICD_CODE_MAPPING',
  DELETE_PATIENT_DOCUMENT: 'DELETE_PATIENT_DOCUMENT',
  DELETE_MASTER_DATA: 'DELETE_MASTER_DATA',
  LIST_CASES: 'LIST_CASES',
  LOCATION_EXAMROOM_LIST: 'EXAMROOM_LIST',
  GET_PAYER: 'GET_PAYER',
  TECHNICIAN_CURRENT_MEDICATIONS: 'TECHNICIAN_CURRENT_MEDICATIONS',
  SCHEDULAR_SETTINGS_BLACK_OUT_LIST: 'SCHEDULAR_SETTINGS_BLACKOUT_LIST',
  SCHEDULAR_MONTHLY_SCHEDULES_LIST: 'SCHEDULAR_MONTHLY_SCHEDULES_LIST',
  CANCELLATION_REASON: 'CANCELLATION_REASON',
  SCHEDULAR_SETTINGS_VISIT_TYPE: 'SCHEDULAR_SETTINGS_VISIT_TYPE',
  SCHEDULAR_SETTINGS_EXAM_TYPE: 'SCHEDULAR_SETTINGS_EXAM_TYPE',
  SCHEDULAR_SETTINGS_RESOURCE: 'SCHEDULAR_SETTINGS_RESOURCE',
  SCHEDULAR_SETTINGS_TEMPLATE_LIST: 'SCHEDULAR_SETTINGS_TEMPLATE_LIST',
  CONTRACT: 'CONTRACT',
  PATIENT_ENCOUNTER_LIST: 'PATIENT_ENCOUNTER_LIST',
  GET_HISTORY_ENCOUNTER_LIST: 'GET_HISTORY_ENCOUNTER_LIST',
  ENCOUNTER_DETAIL_PROCEDURE: 'ENCOUNTER_DETAIL_PROCEDURE',
  DOCTOR_PROCEDURES: 'DOCTOR_PROCEDURES',
  MIPS_PROCEDURES: 'MIPS_PROCEDURES',
  DOCTOR_PROCEDURES_ORDER_SET: 'DOCTOR_PROCEDURES_ORDER_SET',
  DOCTOR_IMAGING_HISTORY: 'DOCTOR_IMAGING_HISTORY',
  CLAIMS_LIST: 'PATIENTS_BILLS',
  CLAIMS_DETAILS_LIST: 'PATIENTS_BILLS_DETAILS_LIST',
  DOCTOR_ENCOUNTER_REVIEW: 'DOCTOR_ENCOUNTER_REVIEW',
  TECHNICIAN_ENCOUNTER_REVIEW: 'TECHNICIAN_ENCOUNTER_REVIEW',
  ALLERGIES: 'allergies',
  USER_PROFILE_TYPE: 'user-profile-type',
  PATIENT_BILL_STATEMENT: 'BILL_STATEMENT',
  APPOINTMENT_CARDS_LIST: 'patient-appointment-cards-list',
  SCHEDULER_UPDATE_STATUS: 'SCHEDULER_UPDATE_STATUS',
  LIST_APPOINTMENTS: 'LIST_APPOINTMENTS',
  PATIENT_ALLERGIES: 'patient-allergies',
  DELETE_PATIENT_ALLERGIES: 'delete-patient-allergies',
  DELETE_ALL_PATIENT_ALLERGIES: 'delete-all-patient-allergies',
  CONTRACT_TERMS_EXCEPTION: 'EXCEPTION_LIST',
  CONTRACT_TERMS_MODIFIER: 'CONTRACT_TERMS_MODIFIER',
  PMFSHX_LIST: 'pmfshx-listing',
  PATIENT_QUESTIONNAIRES_MASTER_PMFSHX: 'patient-questionnaire_master',
  DOCTOR_QUESTIONNAIRES_MASTER: 'doctor-questionnaire_master',
  BILL_STATEMENT_LIST: 'BILL_STATEMENT_LIST',
  PATIENT_REFRACTION_TYPE: 'PATIENT_REFRACTION_TYPE',
  PATIENT_WEARING_DATA: 'PATIENT_WEARING_DATA',
  PATIENT_REFRACT_DATA: 'PATIENT_REFRACT_DATA',
  ENCOUNTER_REFRACT_DATA: 'ENCOUNTER_REFRACT_DATA',
  PATIENT_REFRACT_HISTORY: 'PATIENT_REFRACT_HISTORY',
  PATIENT_UPDATE_REFRACTION: 'PATIENT_UPDATE_REFRACTION',
  PATIENT_DELETE_REFRACTION: 'PATIENT_DELETE_REFRACTION',
  PATIENTS_LIST_APPOINTMENTS: 'LIST_APPOINTMENTS',
  GET__APPOINTMENT_CONFIRMATION_HISTORY: 'GET__APPOINTMENT_CONFIRMATION_HISTORY',
  SAVE_PMFSHX: 'save-pmfshx',
  SAVE_ROS: 'save-ros',
  SAVED_PMFSHX: 'saved-pmfshx',
  PROVIDER_FEE_PREFERENCES_EXCEPTION: 'PROVIDER_FEE_PREFERENCES_EXCEPTION',
  ROS_QUESTIONNAIRE_ENUM: 'ros-questionnaire-enum',
  ROS_QUESTIONNAIRE: 'ros-questionnaire',
  AUTHORIZATIONS_QUESTIONNAIRE: 'authorizations-questionnaire',
  GET_AUTHORIZATIONS_QUESTIONNAIRE: 'get-authorizations-questionnaire',
  UPDATE_AUTHORIZATIONS_QUESTIONNAIRE: 'update-authorizations-questionnaire',
  SAVED_ROS: 'saved-ros',
  OCULAR_PROBLEM_LIST: 'ocular-problem-list',
  DELETE_OCULAR_PROBLEM: 'delete-ocular-problem',
  DELETE_OCULAR_PROBLEM_FROM_TABLE: 'delete-ocular-problem-from-table',
  ADD_OCULAR_PROBLEM: 'add-ocular-problem',
  ENCOUNTER_LIST: 'encounter-list',
  PRESSURE_GRAPH: 'pressure-graph',
  VISION_GRAPH: 'vision-graph',
  GET_SENSORIMOTOR_SECTIONS: 'GET_SENSORIMOTOR_SECTIONS',
  SENSORIMOTOR_SECTION_1: 'SENSORIMOTOR_SECTION_1',
  SENSORIMOTOR_SECTION_2: 'SENSORIMOTOR_SECTION_2',
  SENSORIMOTOR_SECTION_3: 'SENSORIMOTOR_SECTION_3',
  SLIT_EXAM_ENUM: 'slit-exam-enum',
  FUNDUS_EXAM_ENUM: 'fundus-exam-enum',
  SLIT_EXAM_SELECT: 'slit-exam-select',
  FUNDUS_EXAM_SELECT: 'fundus-exam-select',
  SAVE_SLIT_EXAM: 'save-slit-exam',
  SAVE_FUNDUS_EXAM: 'save-fundus-exam',
  GET_SLIT_EXAM: 'get-slit-exam',
  GET_FUNDUS_EXAM: 'get-fundus-exam',
  DEFAULT_FUNDUS_VALUES: 'default-fundus-exam',
  CLEAR_FUNDUS_VALUES: 'clear-fundus-exam',
  COPY_PREVIOUS_FUNDUS: 'copy-previous-fundus',
  OCULAR_FAVOURITES_AND_FOLDERS: 'ocular-favourites-and-folders',
  OCULAR_GENERAL_FAVOURITES_AND_FOLDERS: 'ocular-general-favourites-and-folders',
  OCULAR_FAVOURITES_OF_A_FOLDER: 'ocular-favourites-of-a-folder',
  DELETE_FOLDER: 'delete-folder',
  IMAGES_TEST_TYPES: 'images-test-types',
  GET_BUNDLED_IMAGES: 'get-bundled-images',
  GET_BUNDLED_IMAGES_FOR_COMPARISON: 'get-bundled-images-for-comparison',
  UPDATE_IMAGE_OSOD_TYPE: 'update-image-osod-type',
  UPLOAD_ENCOUNTER_IMAGES: 'upload-encounter-images',
  UPSERT_ENCOUNTER_IMAGES: 'upsert-encounter-images',
  UPSERT_ENCOUNTER_IMAGES_RIGHT_PANNEL: 'upsert-encounter-images-right-panel',
  UPSERT_TESTTYPE_ENCOUNTER_IMAGES: 'upsert-test-type-encounter-images',
  SAVE_ENCOUNTER_IMAGES: 'save-encounter-images',
  UPLOAD_DRAW_DEFAULT_IMAGES: 'upload-draw-default-images',
  UPDATE_ENCOUNTER_IMAGE_TEST_TYPE: 'update-encounter-image-test-type',
  DELETE_ENCOUNTER_IMAGE: 'delete-encounter-image',
  ALL_NORMAL: 'all-normal',
  ROS_CHANGE: 'ros-change',
  PATIENT_ENCOUNTER_LIST_DATA: 'PATIENT_ENCOUNTER_LIST_DATA',
  PRINT_REFRACT: 'print-refract',
  DOCTOR_ENCOUNTER_DETAILS: 'doctor-encounter',
  EXPORT_CONTRACT: 'export-contract',
  ROS_REVIEWED: 'ros-reviewed',
  DEFAULT_SLIT_VALUES: 'default-slADD_BUSINESS_ENTITY_TASKete',
  CLEAR_SLIT_VALUES: 'clear-slit-value',
  ADD_PROVIDER_CARE_TEAM: 'add-provider-care-team',
  DELETE_ADDRESS_BOOK_ITEM: 'delete-address-book-item',
  GET_REFERRAL_LIST: 'get-referral-list',
  SAVE_ORDER_SET: 'save-order-set',
  UPDATE_ORDER_SET: 'update-order-set',
  GET_ORDER_SET_BY_ID: 'get-order-set-id',
  APPLY_ORDER_SET: 'apply-order-set',
  GET_PATIENT_ENCOUNTER: 'get-patient-encounter',
  ADD_PATIENT_ENCOUNTER: 'add-patient-encounter',
  UPDATE_PATIENT_ENCOUNTER: 'update-patient-encounter',
  ENCOUNTER_DETAIL_BY_ID: 'encounter-detail-by-id',
  SIGN_AND_BILL_LIST: 'sign-and-bill-list',
  SIGN_AND_BILL_AUTOSAVE_LIST: 'sign-and-bill-autosave-list',
  ENM_CODE: 'enm-codes',
  SIGN_AND_BILL: 'sign-and-bill',
  GET_CLAIMS_BY_CLAIM_ID: 'get-claims-by-claim-id',
  CLAIM_DETAILS: 'patient-claim-details',
  GET_MODIFIER_OPTIONS: 'get-modifier-options',
  GET_ACTIVITY_LOGS: 'get-activity-logs',
  GET_PATIENT_NOTES: 'get-patient-notes',
  ADD_PATIENT_NOTES: 'add-patient-notes',
  PROCESSED_CLAIMS_FOR_MASS_REBILL: 'processed-claims-for-mass-rebill',
  LIST_PRODUCT: 'list-product',
  DATA_LIST_CATEGORIES: 'data-list-categories',
  IMPORT_FRAMES_DATA: 'import-frames-data',
  UNPOSTED_INVOICE_LIST: 'unposted-invoice-list',
  SEARCH_INVOICE_LIST: 'search-invoice-list',
  DISPENSE_PROMOTION_LIST: 'dispense-promotion-list',
  OPTICAL_REPRINT_LABELS: 'OPTICAL_REPRINT_LABELS',
  SEARCH_INVENTORY_BARCODE: 'SEARCH_INVENTORY_BARCODE',
  SEARCH_TRANSFER_INVENTORY: 'SEARCH_TRANSFER_INVENTORY',
  SEARCH_PHYSICAL_INVENTORY: 'SEARCH_TRANSFER_INVENTORY',
  SEARCH_INVENTORY_RECEIPT: 'SEARCH_INVENTORY_RECEIPT',
  BUSINESS_ENTITY_CREDENTIALS: 'BUSINESS_ENTITY_CREDENTIALS',
  REFERRAL_SOURCE_TABLE: 'REFERRAL_SOURCE_TABLE',
  BUSINESS_ENTITY_BILLING_NDC: 'BUSINESS_ENTITY_BILLING_NDC',
  BUSINESS_ENTITY_BILLING_NDC_PROCEDURE: 'BUSINESS_ENTITY_BILLING_NDC_PROCEDURE',
  NEW_REMITTANCE_BATCH: 'NEW_REMITTANCE_BATCH',
  ALL_TASK_TABLE: 'all-task-table',
  PATIENT_TASK_TABLE: 'patient-task-table',
  DOCTOR_DETAILS: 'doctor-details',
  POSTED_CLAIMS_TABLE: 'posted-claims-table',
  REMITTANCE_BATCH_DETAILS: 'remittance-batch-details',
  REMITTANCE_BATCH_MARK_AS_BLANK: 'remittance-batch-mark-as-blank',
  PMFSHX_REVIEW: 'pmfshx-review',
  INSURANCE_PAYMENT_ENTERED_LINE_ITEMS: 'insurance-payment-line-item',
  PATIENT_PAYMENT_ENTERED_LINE_ITEMS: 'patient-payment-line-item',
  GET_INSURANCE_PAYMENT: 'insurance-payment',
  EDIT_TASK: 'edit-task',
  MARKUP_FOR_FOLLOWUP: 'markup-for-followup',
  REMOVE_POSTED_CHECK: 'remove-posted-remittance',
  UPDATE_MEDICATION: 'update-medication',
  GET_REMITTANCE_PAYMENT_DETAILS: 'get-remittance-payment-details',
  INSURANCE_PAYMENT_COMPLETE_CHECK: 'insurance-payment-complete-check',
  FINANCIAL_LIST: 'financial-balance-list',
  FINANCIAL_CHARGE_LIST: 'financial-charge-list',
  FINANCIAL_CHARGE_HISTORY: 'financial-charge-history',
  SET_PATIENT_PAYMENT: 'set-patient-payment',
  SET_INSURANCE_PAYMENT: 'set-insurance-payment',
  FINANCIAL_INSURANCE_PAYMENT_LIST: 'financial-insurance-payment-list',
  POST_INSURANCE_WORKLIST_LINE_ITEMS: 'post-insurance-worklist-line-items',
  CREATE_DISPENSE_INVOICE: 'create-dispense-invoice',
  POST_UNPOSTED_INVOICE: 'post-unposted-invoice',
  DELETE_UNPOSTED_INVOICE: 'delete-unposted-invoice',
  ADD_ITEM_IN_INVOICE: 'add-item-in-invoice',
  GET_ITEM_TABLE_IN_INVOICE: 'get-item-table-in-invoice',
  DELETE_ITEM_FROM_ITEMS_TABLE: 'get-item-table-in-invoice',
  PATIENT_SERVICE_LINE_ITEM: 'patient-service-line-item',
  ADD_PRODUCT_IN_INVENTORY: 'add-product-in-inventory',
  MARK_COMPLETE_WORKLIST: 'mark-complete-worklist',
  DISCARD_INVENTORY_ITEM: 'discard-inventory-item',
  RECALL_INVENTORY_ITEM: 'recall-inventory-item',
  EXPORT_INVENTORY_ITEM: 'export-inventory-item',
  GET_APPOINTMENT_DETAILs: 'get-appointment-details',
  PRICING_AND_COST_EXCEPTION: 'pricing-and-cost-exception',
  REORDER_POINT_EXCEPTION: 're-order-point-exception',
  EXPORT_INVENTORY_TRANSFER: 'export-inventory-transfer',
  EXPORT_DATA_LIST_PRODUCTS: 'export-data-list-products',
  EXPORT_PHYSICAL_INVENTORY: 'export-physical-inventory',
  ADD_REORDER_EXCEPTION: 'add-reorder-exception',
  ADD_PRICE_RULE_EXCEPTION: 'add-price-rule-exception',
  EXPORT_INVENTORY_RECEIPT_ITEM: 'export-inventory-receipt-item',
  EXPORT_STOCK_COUNT: 'export-stock-count',
  EXPORT_ADJUSTMENT_REPORT: 'export-adjustment-report',
  INVENTORY_MANUFACTURER_LIST: 'inventory-manufacturer-list',
  PATIENT_LATEST_REFRACTION_DATA: 'patient-latest-refraction-data',
  DELETE_INVOICE: 'delete-invoice',
  BUSINESS_ENTITIES: 'business-entities',
  ADD_BUSINESS_REMITTANCE: 'add-business-remittance',
  TEMPLATE_CARE_TEAM_MAP: 'template-care-team-map',
  UPDATE_TEMPLATE_CARE_TEAM_MAP: 'update-template-care-team-map',
  GENERATE_MULTIPLE_TEMPLATE: 'generate-multiple-template',
  SEND_CARE_TEAM_LETTER: 'send-care-team-letter',
  BUSINESS_ENTITY_TASK_USERS: 'business-entity-task-users',
  SEND_CARE_TEAM_LETTER_DOCTOR: 'send-care-team-letter-doctor',
  GET_HISTORY_MASTER: 'get-history-master',
  UPDATE_MOOD: 'update-mood',
  GET_BUSINESS_ENTITY_SCHEDULE: 'get-business-entity-schedule',
  GET_BUSINESS_ENTITY_TASK: 'get-business-entity-task',
  GET_BUSINESS_ENTITY_SUB_TASK: 'get-business-entity-sub-task',
  GET_PATIENT_CLINICAL_SUMMARY: 'get-patient-clinical-summary',
  GET_PATIENT_PHARMACY: 'get-patient-pharmacy',
  REMOVE_TASK_CATEGORY_USERS: 'remove-task-category-users',
  SAVE_TASK_CATEGORY_USERS: 'save-task-category-users',
  SCHEDULER_APPOINTMENT_SETTINGS: 'scheduler-appointment-settings',
  FAVORITE_RESPOND_TO_SHARED_PROCEDURES: 'favorite-respond-to-shared-procedures',
  FAVORITE_RESPOND_TO_SHARED_OCULAR_PROBLEMS: 'favorite-respond-to-shared-ocular-problems',
  GET_INITIAL_REFERRING_PHYSICIAN: 'initial-referring-physician',
  CARE_TEAM_LIST: 'care-team-list',
  UPDATE_SYNONYM_CPT_ICD: 'ADD_SYNONYM_CPT_ICD',
  GET_TASK_CONFIGURATION_USERS: 'GET_TASK_CONFIGURATION_USERS',
  APPOINTMENT_BASIC_REPORT: 'APPOINTMENT_BASIC_REPORT',
  GET_SHARED_REPORTS: 'get-shared-reports',
  EXPORT_REPORTS: 'export-reports',
  SAVE_REPORT_TEMPLATE: 'save-report-template',
  PATIENTS_REPORT: 'PATIENTS_REPORT',
  COPY_PREVIOUS_SLIT: 'COPY_PREVIOUS_SLIT',
  PATIENT_BILLS_ERROR_LIST: 'PATIENT_BILLS_ERROR_LIST',
  GET_FINANCIAL_RECEIPT_HTML: 'GET_FINANCIAL_RECEIPT_HTML',
  DENIAL_CODES: 'DENIAL_CODES',
  WRITE_OFF_REASON: 'WRITE_OFF_REASON',
  ADJUSTMENT_REASON: 'ADJUSTMENT_REASON',
  PRINT_ADJUSTMENT_RECEIPT: 'PRINT_ADJUSTMENT_RECEIPT',
  PROOF_OF_FILLING: 'PROOF_OF_FILLING',
  CHECK_ELIGIBILITY: 'CHECK_ELIGIBILITY',
  REMITTANCE_ASSIGN_TO: 'REMITTANCE_ASSIGN_TO',
  PRINT_FINANCE_DETAIL: 'PRINT_FINANCE_DETAIL',
  GET_LILY_LEE_QUESTIONNAIRE_MASTER: 'GET_LILY_LEE_QUESTIONNAIRE_MASTER',
  ASSEMBLE_FINANCE_CHARGE: 'ASSEMBLE_FINANCE_CHARGE',
  GET_CHECIN_FINANCIAL: 'GET_CHECIN_FINANCIAL',
  GET_CHECKOUT_FINANCE: 'GET_CHECKOUT_FINANCE',
  GET_ASSOCIATE_NDC_FOR_JCODES: 'GET_ASSOCIATE_NDC_FOR_JCODES',
  GET_VERSION_ENCOUNTER_DATA: 'GET_VERSION_ENCOUNTER_DATA',
  GET_FACESHEET_DATA: 'GET_FACESHEET_DATA',
  GET_RESOURCE_PROVIDER_LIST: 'GET_RESOURCE_PROVIDER_LIST',
  ADD_RESOURCE: 'ADD_RESOURCE',
  GET_IMAGE_LIST: 'GET_IMAGE_LIST',
  GET_CONSENT_PATIENT_LIST: 'GET_CONSENT_PATIENT_LIST',
  GET_PATIENT_DATA_FOR_CONSENT_FORM: 'GET_PATIENT_DATA_FOR_CONSENT_FORM',
  GET_REMITTANCE_PAYMENT_DETAILS_LIST: 'GET_REMITTANCE_PAYMENT_DETAILS_LIST',
  REMITTANCE_FINANCIAL_COMPLETE_CHECK: 'REMITTANCE_FINANCIAL_COMPLETE_CHECK',
  ADD_REMITTANCE_FINANCIAL_INSURANCE_PAYMENT: 'ADD_REMITTANCE_FINANCIAL_INSURANCE_PAYMENT',
  EDIT_REMITTANCE_FINANCIAL_INSURANCE_PAYMENT: 'EDIT_REMITTANCE_FINANCIAL_INSURANCE_PAYMENT',
  SAVE_COMPLETE_BATCH: 'SAVE_COMPLETE_BATCH',
  REMITTANCE_UNBATCH_QUEUE_ACTIONS: 'REMITTANCE_UNBATCH_QUEUE_ACTIONS',
  REMITTANCE_ERRORLIST: 'REMITTANCE_ERRORLIST',
  REMITTANCE_POSTED_INSURANCE_CLAIMS: 'REMITTANCE_POSTED_INSURANCE_CLAIMS',
  REMITTANCE_PATIENT_FINANCIAL_POST_BATCH: 'REMITTANCE_PATIENT_FINANCIAL_POST_BATCH',
  REMITTANCE_BATCH_EDIFILETOHTML: 'REMITTANCE_BATCH_EDIFILETOHTML',
  EDIT_WORKLIST_TO_CLAIMS: 'EDIT_WORKLIST_TO_CLAIMS',
  SKIP_ERROR_VALIDATION: 'SKIP_ERROR_VALIDATION',
  DELETE_TRANSACTION: 'DELETE_TRANSACTION',
  EDIT_APPLY_REMITTANCE_ERROR_TRANSACTION: 'EDIT_APPLY_REMITTANCE_ERROR_TRANSACTION',
  REMITTANCE_BATCH_UNMARK_AS_BLANK_PAGE: 'remittance-batch-unmark-as-blank-page',
  GET_PATIENT_LAST_UNSIGNED_ENCOUNTER: 'GET_PATIENT_LAST_UNSIGNED_ENCOUNTER',
  FINANCIAL_PATIENT_PAYMENT_RECEIPT: 'FINANCIAL_PATIENT_PAYMENT_RECEIPT',
  FINANCIAL_Details_PATIENT_PAYMENT_RECEIPT: 'FINANCIAL_Details_PATIENT_PAYMENT_RECEIPT',
  GET_PATIENT_ENCOUNTERS_BY_APPOINTMENT: 'GET_PATIENT_ENCOUNTERS_BY_APPOINTMENT',
  CHANGE_INACTIVE_PROFILE: 'CHANGE_INACTIVE_PROFILE',
  LOG_ERROR_BOUNDARY_ERROR_MESSAGE: 'LOG_ERROR_BOUNDARY_ERROR_MESSAGE',
  GET_RIGHTS_DATA: 'GET_RIGHTS_DATA',
  CREATE_MANUAL_STATEMENT: 'CREATE_MANUAL_STATEMENT',
  TASK_CATEGORY_ID: 'TASK_CATEGORY_ID',
  MOVE_TO_READY: 'MOVE_TO_READY',
};

const enums = {
  PHONE_TYPE: 'PhoneType',
  COUNTRY: 'Country',
  STATE: 'State',
  RELATION: 'Relation',
  REFERRAL: 'ReferralSource',
  REGION: 'Region',
  GENDER: 'Gender',
  BUSINESS_ENTITY: 'BusinessEntity',
  PLACE_OF_SERVICE: 'Placeofservice',
  FACILITY_TYPE: 'FacilityType',
  FACILITY_SUB_TYPE: 'FacilitySubType',
  LOCALITY: 'Locality',
  LOCATION_TYPE: 'LocationType',
  BILLING_NAME: 'Billing Name',
  SCHEDULE: 'Schedule',
  TIMEZONE: 'Timezone',
  STATEMENT_METHOD: 'StatementMethod',
  PREFERRED_CONTACT_METHOD: 'PreferredContactMethod',
  LANGUAGE: 'Language',
  PATIENT_DOCUMENT_CATEGORY: 'PatientDocumentCategory',
  CASE_TYPE: 'CaseType',
  DISCHARGE_STATUS: 'DischargeStatus',
  ACCIDENT_TYPE: 'AccidentType',
  ADJUSTOR: 'Adjuster',
  LIABLE_PARTY: 'LiabelParty',
  INVITATION_SEND_TYPE: 'InvitationSendType',
  DEFAULT_APP: 'DefaultApp',
  CREDENTIAL_TYPE: 'CredentialType',
  BUSINESS_ENTITY_CREDENTIAL_TYPE: 'BussinessEntityCredentialType',
  SPECIALTY: 'Speciality',
  PROVIDER_SPECIALTY: 'ProviderSpeciality',
  INSURANCE_PAYER_TYPE: 'InsurancePayerType',
  SUBMISSION_METHOD: 'SubmissionMethod',
  SUB_SPECIALITY: 'SubSpeciality',
  PATIENT_AGE_LIMIT: 'PatientAgeLimit',
  PRIMARY_JOB_FUNCTION_TYPE: 'PrimaryJobFunctionType',
  INSURANCE_POLICY_LEVEL: 'InsurancePolicyLevel',
  POLICY_TYPE: 'PolicyType',
  SCHEDULAR_PROVIDER: 'SchedulerProvider',
  CONTRACT_PANEL_STATUS: 'ContractPanelStatus',
  CONTRACT_TYPE: 'ContractType',
  CANCELLATIONS: 'Cancellations',
  CONTRACT_TERM_TYPE: 'ContractTermType',
  CAPITATION_PAYMENT_ARRANGEMENT: 'CapitationPaymentArrangement',
  CONTRACT_TERM_POLICY_TYPE: 'ContractTermPolicyType',
  CONTRACT_TERM_PLAN: 'ContractTermPlan',
  FEE_FOR_SERVICES_PAYMENT_ARRANGEMENT: 'FeeForServicesPaymentArrangement',
  CONTRACT_TERM_PLACES_OF_SERVICE: 'ContractTermPlacesOfService',
  CONTRACT_TERM_SPECIFIC_PLACES_OF_SERVICE: 'Placeofservice',
  CONTRACT_TERM_POLICY_OF_CATEGORY: 'ContractTermPolicyTypeCategory',
  CONTRACT_TERM_PLAN_TYPE_CATEGORY: 'ContractTermPlanTypeCategory',
  CONTRACT_TERM_YEAR: 'ContractTermYear',
  CONTRACT_TERM_LOCALITY: 'ContractTermLocality',
  FEE_FOR_SERVICE_VALUE_IF_NO_PROCEDURE: 'FeeForServiceValueIfNoProcedure',
  APPOINTMENT_STATUS: 'AppointmentStatus',
  PREFERRED_LANGUAGE: 'PreferredLanguage',
  TECHNICIAN_VISION_EXAM: 'TechnicianVisionExam',
  CONTRACT_TERM_EXCEPTION_TYPE: 'ContractTermExceptionType',
  CONTRACT_TERM_EXCEPTION_TYPE_VALUE_TYPE: 'ContractTermExceptionTypeValueType',
  PATIENT_QUESTIONNAIRES_MASTER_PMFSHX: 'PatientQuestionnaresMasterPMFSHX',
  CAPITATION_ADJUST_CLAIM_REASON: 'CapitationAdjustClaimReason',
  CONTRACT_TERM_FEE_SCHEDULE_TYPE: 'ContractTermFeeScheduleType',
  MODIFIER: 'Modifier',
  FACILITY_TYPE_EXCEPTION: 'FacilityType',
  VALUE_TYPE: 'ValueType',
  PATIENT_REFRACTION_TYPE: 'PatientRefractionType',
  PATIENT_QUESTIONNAIRES_MASTER_ROS: 'PatientQuestionnaresMasterROS',
  PATIENT_ENCOUNTER_EXAM_TYPE: 'PatiantEncounterExamType',
  TECHNICIAN_PRESSURE_EXAM: 'TechnicianPressureExam',
  ENCOUNTER_PROCEDURE_STATUS: 'EncounterProcedureStatus',
  SENSORIMOTOR_FIRST_SECTION_TYPE: 'SensorimotorFirstSectionType',
  SENSORIMOTOR_SECOND_SECTION_DD1: 'SensorimotorSecondSectionDD1',
  SENSORIMOTOR_SECOND_SECTION_DD2: 'SensorimotorSecondSectionDD2',
  SENSORIMOTOR_FIRST_SECTION_DDS: 'SensorimotorIOSRDropdown',
  PATIENT_QUESTIONNAIRES_MASTER_SLIT_EXAM: 'PatientQuestionnaresMasterSLITLAMPEXAM',
  PATIENT_QUESTIONNAIRES_MASTER_FUNDUS_EXAM: 'PatientQuestionnaresMasterFUNDUSEXAM',
  PROCEDURE_QUESTIONNAIRES: 'Procedurequestionnaires',
  PREFERRED_METHOD: 'PreferredContactMethod',
  REFRACT_MANIFEST: 'RefractionManifest',
  MANUFACTURER: 'Manufacturer',
  BRAND: 'Brand',
  ADDRESS_BOOK_PROVIDER_TYPE: 'AddressBookProviderType',
  ADDRESS_BOOK_REGION: 'AddressBookRegion',
  PROVIDER_TYPE: 'AddressBookProviderType',
  CLAIMS_DELIVERY_METHOD: 'SubmissionMethod',
  COLLECTION_GROUPING: 'CollectionGrouping',
  HOW_DID_YOU_HEAR_ABOUT_US: 'HowDidYouHearAboutUs',
  LOCATION_SEARCH_RADIUS: 'LocationSearchRadius',
  DELIVERY_METHOD: 'SubmissionMethod',
  ERROR_TYPE: 'ClaimsErrorType',
  AGING_FILTER: 'CollectionGroupAging',
  TASK_CATEGORIES: 'TaskCategories',
  TASK_SUB_CATEGORIES: 'TaskSubCategories',
  TASK_PRIORITY_IDS: 'TaskPriorityIds',
  TASK_STATUS_IDS: 'TaskStatusIds',
  CUTERA_AREA_TREATED: 'CuteraLaserGenesisAreasTreated',
  CUTERA_SETTINGS_KJ: 'CuteraLaserIPLSettingsKJ',
  CUTERA_SPOT_SIZE: 'CuteraLaserSettingsSpotSize',
  NDYAG: 'Ndyag',
  CLAIM_FOLLOW_IN: 'ClaimFollowupIn',
  MICRO_NEEDLING_DEPTH: 'MicroNeedling-Depth',
  MICRO_NEEDLING_SPEED: 'MicroNeedling-Speed',
  MICRO_NEEDLING_ADDITIVE_AGENT: 'MicroNeedling-Additive Agent',
  PTOSIS: 'BreastExamPtosis',
  PATIENT_PAYMENT_TYPE: 'PatientPaymentType',
  PATIENT_PAYMENT_CARD_TYPE: 'PatientPaymentCardType',
  EYELIDS_DERMATOCHALASIS: 'EyeLids-Dermatochalasis',
  EYELIDS_ECTROPION: 'EyeLids-Ectropion',
  EYELIDS_LESIONS: 'EyeLids-Lesions',
  EYELIDS_PTOSIS: 'EyeLids-Ptosis',
  EYELIDS_ENTROPION: 'EyeLids-Entropion',
  EYELIDS_EPIPHORA: 'EyeLids-Epiphora',
  EYELIDS_MRD1: 'EyeLids-MRD1',
  EYELIDS_PIGMENT: 'EyeLids-Pigment',
  EYELIDS_DESCRIPTION: 'EyeLids-Description',
  WORKLIST_REASONS: 'WorkListReasons',
  LILLY_LEE_FAVOURITE: 'FavoriteType',
  CHARGE_TYPE: 'ChargeTypes',
  CONFIRMATION_METHOD: 'ConfirmationMethod',
  CONFIRMATION_OUTCOME: 'ConfirmationOutcome',
  DAY_OF_WEEK: 'DayOfWeek',
  PRODUCT_TYPE: 'Product Type',
  PAYMENT_WRITE_OFF_REASON: 'PaymentWriteOffReason',
  PAYMENT_TRANSACTION_TYPE: 'PaymentTransactionType',
  ENCOUNTER_ORIGIN: 'EncounterOrigin',
  MANUFACTURER_LIST: 'ManufacturerList',
  CHARGE_CODE: 'ListofVCodes',
  INVENTORY_INVOICE_OPTICAL_PRESCRIPTION: 'InventoryInvoiceOpticalPrescription',
  INVENTORY_INVOICE_OPTICAL_PRESCRIPTION_TYPE: 'InventoryInvoiceOpticalPrescriptionType',
  UNABLE_TO_COMPLETE_BALANCE: 'UnableCompleteBalanceReason',
  COMPOSER_LABELS: 'ComposerTempleteFilters',
  INSURANCE_TYPE: 'InsuranceType',
  COMPOSER_TEMPLATE_CATEGORY: 'ComposerTempleteCategory',
  STATEMENT_TYPE: 'StatementType',
  STATEMENT_CYCLE: 'StatementCycle',
  AGING: 'Aging',
  ASSEMBLE_STATEMENTS: 'Assemble Statements',
  DATE_OLDER_THAN: 'BusinessEntityRemittance',
  CREDIT_CARD: 'CreditCard',
  RELEASE_OF_INFORMATION: 'ReleaseOfInformation',
  SIGNATURE_SOURCE: 'SourceSignature',
  ACCEPT_ASSIGNMENT: 'AcceptAssignmentType',
  COMPOSER_DELIVERY_METHOD: 'SubmissionMethod',
  ORDER_CHANGES_BY: 'OrderChangesBy',
  ASSEMBLE_CLAIMS_AUTOMATICALLY: 'AssembleClaimAutomatically',
  DEFAULT_BILLING_REFERRING_PHYSICIAN_FROM: 'DefaultBillingReferringPhysician',
  NDC_QUANTITY_UNIT: 'NDCQuantityUnit',
  IMAGE_SEVERITY: 'ImageSeverity',
  IMAGE_QUALITY: 'ImageQuality',
  CUTERA_WAVELENGTH: 'Wavelength',
  IPL_TYPE: 'IPLType',
  Date_Driver: 'DateDriver',
  TASK_PAYMENT_TYPE: 'TaskPaymentType',
  PATIENT_STATUS: 'PatientStatus',
  GET_SAVED_REPORTS: 'get-saved-reports',
  DENIAL_CODES: 'DenialCodes',
  CHECKOUT_ACKNOWLEDGEMENT: 'CheckoutAck',
  BILLING_PASADENA_TYPE: 'BillingPasadenaType',
  SIMPLE_CHARGE_TYPE_STATUS: 'SimpleChargeTypeStatus',
  ADJUSTMENT_REASON: 'AdjustmentReason',
  REVERSAL_REASON: 'ReversalReason',
  REVERSAL_FILTER: 'ReversalFiter',
  CHARGE_TYPE_STATUS: 'ChargeTypeStatus',
  BILLING_STATE: 'BillingState',
  BILLING_STAGE: 'BillingStage',
  ON_HOLD_FILTER: 'OnHoldFilter',
  CLOSED_INCIDENT: 'ClosedIncidentFilter',
  PAYMENT_SOURCE: 'PaymentSource',
  RACE: 'Race',
  ETHNICITY: 'Ethnicity',
  MSP_TYPE: 'MSPType',
  NOTES: 'Notes',
  PROVIDER_TYPES: 'Provider Type',
  WRITE_OFF_MEDICA_SECONDARY_POLICY_TYPE: 'WriteoffMedicaidSecondaryPolicyType',
  FOLLOW_UP_REASON: 'FollowUpReason',
  WorkListStatus: 'workliststatus',
  CLAIMS_QUEUE: 'ClaimQueue',
  REFRACTION_GLASS_TYPE: 'GlassesType',
  MIPSEducationMaterial: 'MIPSEducationMaterial',
  MASTER_CONFIG: 'MasterConfig',
  PATIENT_LABEL: 'PatientLabel',
  COLLECTION_GROUP_INCIDENT_DATE: 'CollectionGroupIncidentDate',
  LETTER_FILTER: 'LetterFilter',
  CLAIMS_DELIVERY_OPTIONS: 'ClaimDeliveryMethod',
  REMITTANCE_PAYMENT_TYPE: 'RemittancePaymentType',
  WRITE_OFF_REASON: 'PaymentWriteOffReason',
  FAVORITE_TYPE: 'FavoriteType',
  COMPOSER_TEMPLATE_TYPES: 'ComposerTempleteTypes',
  SIMPLE_CHARGES: 'SimpleChargeTypeStatus',
  DELIVERYMETHOD: 'DeliveryMethod',
  TRANSMISSION_QUEUE: 'TransmissionQueue',
  DENIAL_TYPE: 'DenialType',
  Identity_Provider: 'IdentityProvider',
  Provider_Degree: 'ProviderDegree',
};

const listId = {
  PATIENTS: 'PATIENTS',
  CLAIMS: 'CLAIMS',
  COLLECTION_CLAIMS: 'COLLECTION_CLAIMS',
  CLAIMS_ERRORS: 'CLAIMS_ERRORS',
  PATIENT_CLAIM_TABLE: 'PATIENT_CLAIM_TABLE',
  REMITTANCE: 'REMITTANCE',
  COLLECTIONS: 'COLLECTIONS',
  CLAIMS_COLLECTIONS: 'CLAIMS_COLLECTIONS',
  CLAIMS_REMITTANCE: 'CLAIMS_REMITTANCE',
  LOCATIONS: 'LOCATIONS',
  PROVIDERS: 'PROVIDERS',
  SETTINGS_PROVIDERS: 'SETTINGS_PROVIDERS',
  PAYER_CPT_ICD: 'PAYER_CPT_ICD',
  SETTINGS_USERS: 'SETTINGS_USERS',
  USER_RIGHTS_MASTER: 'USER_RIGHTS_MASTER',
  USER_RIGHTS: 'USER_RIGHTS',
  UPDATE_USER_RIGHTS: 'UPDATE_USER_RIGHTS',
  CONTRACT: 'CONTRACT',
  PROVIDER_CREDENTIALS: 'CREDENTIALS',
  AUTHORIZATIONS: 'authorizations',
  REQUESTS: 'requests',
  CONTRACT_TERMS: 'CONTRACT_TERMS',
  DOCUMENT_CATEGORY: 'DOCUMENT_CATEGORY',
  DOCUMENT_CATEGORY_COUNTS: 'DOCUMENT_CATEGORY_COUNTS',
  PATIENT_AUTHORIZATION_DOCUMENTS: 'PATIENT_DOCUMENTS',
  PATIENT_AUTHORIZATION_DOCUMENT: 'PATIENT_DOCUMENT',
  ADD_PAYER: 'ADD_PAYER',
  ADD_PAYER_MAPPING: 'ADD_PAYER_MAPPING',
  EDIT_PAYER: 'EDIT_PAYER',
  BA_PATIENT: 'BA_PATIENTS',
  SCHEDULAR_ADD_APPOINTMENT_PATIENT: 'SCHEDULAR_ADD_APPOINTMENT_PATIENT',
  SCHEDULAR_PATIENTS_LIST: 'SCHEDULAR_PATIENTS_LIST',
  ADD_APPOINTMENT: 'ADD_APPOINTMENT',
  PATIENT_INSURANCE_PROFILE_POLICY: 'PATIENT_INSURANCE_PROFILE_POLICY',
  RECENTLY_CREATED_PATIENTS: 'RECENTLY_CREATED_PATIENTS',
  BILLING: 'BILLING',
  IMAGING_HISTORY: 'IMAGING_HISTORY',
  NEW_PATIENT_PROCEDURE: 'NEW_PATIENT_PROCEDURE',
  ADDRESS_BOOK_TABLE: 'ADDRESS_BOOK_TABLE',
  PATIENT_CLAIM_LIST: 'PATIENT_CLAIM_LIST',
  CHANGE_PATIENT_RESPONSIBILITY: 'CHANGE_PATIENT_RESPONSIBILITY',
  CHANGE_INSURANCE_RESPONSIBILITY: 'CHANGE_INSURANCE_RESPONSIBILITY',
  GET_CLAIMS_DETAILS: 'GET_CLAIMS_DETAILS',
  INSURANCE_PROFILE_DETAILS_SELECT: 'INSURANCE_PROFILE_DETAILS_SELECT',
  INSURANCE_DETAILS: 'INSURANCE_DETAILS',
  REMITTANCE_WORK_LIST_SUSPENSE: 'REMITTANCE_WORK_LIST_SUSPENSE',
  REMITTANCE_WORK_LIST_SUSPENSE_DETAIL: 'remittance-worklist-suspense-detail',
  REMITTANCE_WORK_LIST_REASON: 'REMITTANCE_WORK_LIST_REASON',
  GET_UNPOSTED_INVENTORY_LIST: 'un-posted-inventory-list',
  COMPOSER_LIST: 'COMPOSER_LIST',
  SEARCH_PATIENT_CARE_TEAM_LETTERS: 'SEARCH_PATIENT_CARE_TEAM_LETTERS',
  CPT_CODE_LIST: 'CPT_CODE_LIST',
  ICD_CODE_LIST: 'ICD_CODE_LIST',
  GET_COOLSCULPT_TABLE_DATA: 'GET_COOLSCULPT_TABLE_DATA',
  ADD_RESOURCE_PROVIDER: 'ADD_RESOURCE_PROVIDER',
  GET_PATIENT_REPORT_LIST: 'GET_PATIENT_REPORT_LIST',
  P_VERIFY_INTEGRATION: 'P_VERIFY_INTEGRATION',
  GET_NOTES_CATEGORY: 'GET_NOTES_CATEGORY',
  ADD_FAX_QUEUE_SHOPPING_CART: 'ADD_FAX_QUEUE_SHOPPING_CART',
  MANUAL_INTERVENTION_QUEUE: 'MANUAL_INTERVENTION_QUEUE',

};

const contentType = {
  MULTIPART: 'multipart',
};

const labelPaths = {
  DASHBOARD: 'dashboard',
  ADD_PATIENT: 'patients/add',
  EDIT_PATIENT_DEMOGRAPHIC: 'patients/edit/demographic',
  PATIENT_LIST: 'patients/list',
  CLAIMS: 'claims',
  CLAIMS_LIST: 'claims/claims/list',
  STATEMENT_LIST: 'claims/statements/list',
  REMITTANCE_LIST: 'claims/remittance/list',
  COLLECTIONS_LIST: 'claims/collections/list',
  COLLECTION_CLAIMS_LIST: 'claims/collections/claims/list',
  EDIT_PATIENT: 'patients/edit',
  ADD_PROVIDER: 'provider/add',
  PROVIDER_LIST: 'provider/list',
  EDIT_PROVIDER: 'provider/edit',
  EDIT_PROVIDER_ASSISTANTS: 'provider/edit/assistants',
  EDIT_PROVIDER_GENERAL: 'provider/edit/general',
  EDIT_PROVIDER_CREDENTIALS: 'provider/edit/credentials',
  EDIT_PROVIDER_BILLING: 'provider/edit/billing',
  EDIT_PROVIDER_PAYERHOLD: 'provider/edit/payerHold',
  EDIT_PROVIDER_EPRESCRIPTION: 'provider/edit/ePrescription',
  EDIT_PROVIDER_FEEPREFRENCES: 'provider/edit/feePrefrences',
  ADD_USER: 'user/add',
  USER_LIST: 'user/list',
  EDIT_USER: 'user/edit',
  EDIT_USER_GENERAL: 'user/edit/general',
  EDIT_USER_PROFILE: 'user/edit/profile',
  EDIT_USER_RIGHTS: 'user/edit/rights',
  PATIENT_FINANCIAL: 'patients/edit/financial',
  FINANCIAL_POPUP_PATIENT_PAYMENT: 'patients/edit/financial/popups/patientPayment',
  FINANCIAL_POPUP_PATIENT_SIMPLE_CHARGE: 'patients/edit/financial/popups/patientSimpleCharge',
  FINANCIAL_POPUP_PATIENT_ADJUSTMENT: 'patients/edit/financial/popups/patientAdjustment',
  FINANCIAL_POPUP_INSURANCE_PAYMENT: 'patients/edit/financial/popups/insurancePayment',
  FINANCIAL_POPUP_INSURANCE_REFUND: 'patients/edit/financial/popups/insuranceRefund',
  FINANCIAL_POPUP_PATIENT_REFUND: 'patients/edit/financial/popups/patientRefund',
  FINANCIAL_POPUP_INSURANCE_ADJUSTMENT: 'patients/edit/financial/popups/insuranceAdjustment',
  PATIENT_DASHBOARD: 'patients/edit/dashboard',
  PATIENT_DOCUMENTS: 'patients/edit/documents',
  PATIENT_ENCOUNTERS: 'patients/edit/encounters',
  ENCOUNTERS_BALANCE: 'patients/edit/encounters/tab/balance',
  ENCOUNTERS_ANESTHESIA: 'patients/edit/encounters/tab/anesthesiaBilling',
  ENCOUNTERS_DIAGNOSIS: 'patients/edit/encounters/tab/diagnosis',
  ENCOUNTERS_DEMOGRAPHIC: 'patients/edit/encounters/tab/demographic',
  ENCOUNTERS_CASES: 'patients/edit/encounters/tab/cases',
  ENCOUNTER_HISTORY: 'patients/edit/encounters/tab/history',
  ENCOUNTER_AUTHORIZATION: 'patients/edit/encounters/tab/authorization',
  CLAIMS_DETAILS: 'patients/edit/bills/claims/details',
  STATEMENT_DETAILS: 'patients/edit/bills/statements/details',
  PATIENT_BILLS: 'patients/edit/bills',
  PATIENT_LISTS: 'patients/edit/lists',
  CONTRACT_LIST: 'contracts/list',
  NEW_CONTRACT: 'contracts/new',
  EDIT_CONTRACT: 'contracts/edit',
  EDIT_CONTRACT_TERMS: 'contracts/edit/terms',
  EDIT_CONTRACT_TERMS_GENERAL: 'contracts/edit/terms/general',
  EDIT_CONTRACT_TERMS_EXCEPTIONS: 'contracts/edit/terms/exceptions',
  EDIT_CONTRACT_TERMS_MODIFIERS: 'contracts/edit/terms/modifiers',
  PAYER_MODAL: 'modals/Payer',
  PERSON_MODAL: 'modals/Person',
  TASKS_MODAL: 'modals/Tasks',
  NOTES_MODAL: 'modals/Notes',
  PRINT_MODAL: 'modals/Print',
  PVERIFY_MODAL: 'modals/pVerify',
  ERROR_POPUP: 'claims/errorPopup',
  BATCH_POPUP: 'claims/batchPopup',
  LOCATION_LIST: 'locations/list',
  ADD_LOCATION: 'locations/add',
  EDIT_LOCATION: 'locations/edit',
  EDIT_LOCATION_GENERAL: 'locations/edit/general',
  EDIT_LOCATION_EXAMROOM_LIST: 'locations/edit/examRoom/list',
  EDIT_LOCATION_ADD_EXAMROOM: 'locations/edit/examRoom/add',
  EDIT_LOCATION_EDIT_EXAMROOM: 'locations/edit/examRoom/edit',
  VIEW_RESPONSIBLE_PARTY: 'insurance/responsibleParty/view',
  EDIT_RESPONSIBLE_PARTY: 'insurance/responsibleParty/edit',
  PROFILE_NAME: 'insurance/responsibleParty/rename',
  EDIT_INSURANCE_POLICY: 'insurance/policy/edit',
  ADD_INSURANCE_POLICY: 'insurance/policy/add',
  ADD_INSURANCE_PROFILE: 'insurance/profile/add',
  VIEW_INSURANCE_PROFILE: 'insurance/profile/view',
  SCHEDULAR: 'schedular',
  ADD_APPOINTMENT_SCHEDULAR: 'schedular/dialogs/addAppointment',
  APPOINTMENT_COMPONENT_SCHEDULAR: 'schedular/appointmentComponents',
  DOCTOR: 'doctor',
  DOCTOR_VISION_MODAL: 'doctor/modals/vision',
  DOCTOR_PRESSURE_MODAL: 'doctor/modals/pressure',
  DOCTOR_SUPERBILL_MODAL: 'doctor/modals/superbill',
  TECHNICIAN: 'technician',
  // TECHNICIAN_HPI: 'technician/tabs/hpi',
  TECHNICIAN_COOLSCULPT: 'technician/coolsculpt',
  TECHNICIAN_ULTHERAPY: 'technician/ultherapy',
  TECHNICIAN_TREATMENT: 'technician/treatment',
  DOCTOR_SIDE_PANEL: 'doctor/panel',
  DOCTOR_PATIENT_DETAIL: 'doctor/patientDetail',
  MEDICATIONS_LIST: 'doctor/encounterDetail/medications/list',
  TECHNICIAN_ENCOUNTER_TABS: 'technician/encounterDetail/encounterTabs',
  ADD_MEDICATION: 'doctor/encounterDetail/medications/addMedication',
  ADD_PROCEDURE: 'doctor/encounterDetail/procedure/addProcedure',
  IMAGING_HISTORY_LIST: 'doctor/encounterDetail/imagingHistory/list',
  VIEW_IMAGE: 'doctor/encounterDetail/imagingHistory/viewImage',
  ADD_IMAGE: 'doctor/encounterDetail/imagingHistory/addImage',
  VIEW_SENSORIMOTOR: 'doctor/encounterDetail/sensorimotor',
  DRAW: 'doctor/encounterDetail/draw',
  ADD_PAYER: 'payer/add',
  VIEW_PAYER: 'payer/list',
  ADD_ICD_CPT_MAPPING: 'payer/addMapping',
  ADD_AUTHORIZATION: 'patients/edit/lists/authorization/add',
  EDIT_AUTHORIZATION: 'patients/edit/lists/authorization/edit',
  // TECHNICIAN_ROS: 'technician/tabs/ros',
  SCHEDULAR_SETTING: 'schedularSetting',
  REFRACT: 'doctor/encounterDetail/refract',
  REFRACT_MODAL: 'doctor/encounterDetail/refract/refractModal',
  SCHEDULAR_SETTINGS: 'schedularSettings',
  BLOCK_OUT_LIST: 'schedularSettings/blockouts/list',
  ADD_BLOCKOUT: 'schedularSettings/blockouts/add',
  EDIT_BLOCKOUT: 'schedularSettings/blockouts/edit',
  SCHEDULAR_SETTING_VISIT_TYPE: 'schedularSettings/visitTypes',
  SCHEDULAR_SETTING_TEST_TYPE: 'schedularSettings/testTypes',
  SCHEDULAR_SETTING_BLOCKOUT_TEMPLATES_LIST: 'schedularSettings/BlockOut&Templates/list',
  CANCELLATION_REASONS: 'schedularSettings/cancellations',
  SCHEDULAR_SETTINGS_BASICS: 'schedularSettings/basics',
  SCHEDULAR_SETTINGS_RESOURCES: 'schedularSettings/resources',
  TEMPLATE_LIST: 'schedularSettings/template/list',
  ADD_TEMPLATE: 'schedularSettings/template/add',
  EDIT_TEMPLATE: 'schedularSettings/template/edit',
  DOCTOR_ENCOUNTER_DETAIL_OTHER: 'doctor/encounterDetail/other',
  INSURANCE_SIDEBAR: 'insurance/sideBar',
  VERIFICATION_HISTORY: 'insurance/verificationHistory',
  DOCTOR_ENCOUNTER_DETAIL_OCULAR_LIST: 'doctor/encounterDetail/ocularProblems/list',
  DOCTOR_ENCOUNTER_DETAIL_OCULAR_ADD: 'doctor/encounterDetail/ocularProblems/add',
  DOCTOR_ENCOUNTER_DETAIL_OCULAR_EDIT: 'doctor/encounterDetail/ocularProblems/edit',
  BOOK_APPOINTMENT: 'bookAppointment',
  DASHBOARD_CHECK_OUT: 'dashboard/checkOut',
  DASHBOARD_CHECK_IN: 'dashboard/checkIn',
  BILLING_LIST: 'billing/list',
  BILLING_ADD: 'billing/add',
  CREATE_NEW_CLAIM_POPUP: 'claims/createnewClaim',
  MARK_FOLLOW_UP_POPUP: 'claims/markFollowUpClaim',
  PATIENT_PAYMENT_POPUP: 'claims/patientPaymentClaim',
  CHANGE_RESPONSIBILITY_POPUP: 'claims/changeResponsibility',
  BILL_PATIENT_POPUP: 'claims/billPatient',
  TECHNICIAN_EXAM: 'technician/exam',
  DOCTOR_VISION: 'doctor/encounterDetail/vision',
  DOCTOR_PRESSURE: 'doctor/encounterDetail/pressure',
  DOCTOR_CHIEF_COMPLAINT: 'doctor/encounterDetail/chiefComplaint',
  OUTSIDE_TESTING: 'doctor/encounterDetail/outsideTesting',
  DOCTOR_FOLLOW_UP: 'doctor/followUp',
  DOCTOR_SIGN_AND_BILL: 'doctor/followUp/signAndBill',
  DOCTOR_ORDER_SET: 'doctor/orderSet',
  DASHBOARD_ACTIVITY_LOG: 'dashboard/activityLog',
  CARE_TEAM: 'doctor/encounterDetail/addCareTeam',
  CLAIM_OVERVIEW: 'claims/collections/claims/overview',
  IMPORT_DATA_FROM_FILE: 'data/tabs/importDataFromFile',
  IMPORT_FRAMES_DATA: 'data/tabs/importFramesData',
  LIST_PRODUCTS: 'data/tabs/listProducts',
  INVENTORY_DISPENSE: 'inventory/dispense',
  PHYSICAL_INVENTORY_REPORTS: 'inventory/reports/physicalInventory',
  DAILY_TRANSACTIONS_REPORTS: 'inventory/reports/dailyTransactions',
  ITEMS_SOLD_REPORTS: 'inventory/reports/itemsSold',
  DOLLAR_VALUE_SOLD_REPORTS: 'inventory/reports/dollarValueSold',
  SALES_TAX_REPORTS: 'inventory/reports/salesTax',
  RECALLED_ITEMS_REPORTS: 'inventory/reports/recalledItems',
  DISCOUNT_PROMOTION_REPORTS: 'inventory/reports/discountPromotion',
  INVENTORY_ADJUSTMENT_REPORTS: 'inventory/reports/inventoryAdjustment',
  INVENTORY_TRANSFERS_REPORTS: 'inventory/reports/inventoryTransfers',
  INVENTORY_TRANSACTION_REPORTS: 'inventory/reports/inventoryTransaction',
  INVENTORY_CREATE_INVOICE: 'inventory/optical/createInvoice',
  INVENTORY_RECEIPTS: 'inventory/optical/inventoryReceipts',
  INVENTORY_INVOICE_ADD_ITEMS: 'inventory/optical/createInvoice/addItem',
  INVENTORY_CREATE_KIT: 'inventory/optical/createKit',
  INVENTORY_UN_PORTED_BATCHES: 'inventory/optical/UnPostedBatches',
  INVENTORY_ADJUST_ON_HAND: 'inventory/optical/AdjustOnHandQty',
  INVENTORY_TRANSFER: 'inventory/optical/inventoryTransfer',
  INVENTORY_REPRINT_LABELS: 'inventory/optical/reprintLabels',
  INVENTORY_SEARCH_BARCODE: 'inventory/optical/searchBarcode',
  SETTINGS_BUSINESS_ENTITY_ADD: 'settings/businessEntity/add',
  SETTINGS_BUSINESS_ENTITY_EDIT: 'settings/businessEntity/edit',
  SETTINGS_BUSINESS_ENTITY_LIST: 'settings/businessEntity/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_GENERAL: 'settings/businessEntity/edit/tabs/general2',
  SETTINGS_BUSINESS_ENTITY_EDIT_FEE_SCHEDULE: 'settings/businessEntity/edit/tabs/feeSchedule',
  SETTINGS_BUSINESS_ENTITY_LIST_CREDENTIALS: 'settings/businessEntity/edit/tabs/credentials',
  SETTINGS_BUSINESS_ENTITY_LIST_STATEMENTS: 'settings/businessEntity/edit/tabs/statements',
  SETTINGS_BUSINESS_ENTITY_LIST_PATIENT: 'settings/businessEntity/edit/tabs/patients',
  SETTINGS_BUSINESS_ENTITY_LIST_CONTRACT: 'settings/businessEntity/edit/tabs/contract',
  SETTINGS_BUSINESS_ENTITY_LIST_TASK: 'settings/businessEntity/edit/tabs/task',
  SETTINGS_BUSINESS_ENTITY_LIST_PVERIFY_CONFIGURATION: 'settings/businessEntity/edit/tabs/pverifyConfiguration',
  SETTINGS_BUSINESS_ENTITY_LIST_PVERIFY_CONFIGURATION_AUTO_RUN: 'settings/businessEntity/edit/tabs/pverifyConfiguration/autoRun',
  SETTINGS_BUSINESS_ENTITY_LIST_PVERIFY_CONFIGURATION_PAYOR_REQUEST: 'settings/businessEntity/edit/tabs/pverifyConfiguration/payorRequest',
  SETTINGS_BUSINESS_ENTITY_LIST_PVERIFY_CONFIGURATION_MANUAL_REQUEST: 'settings/businessEntity/edit/tabs/pverifyConfiguration/manualRequest',
  SETTINGS_BUSINESS_ENTITY_LIST_TASK_CONFIGURATION: 'settings/businessEntity/edit/tabs/task/taskConfiguration',
  SETTINGS_BUSINESS_ENTITY_LIST_SUB_TASK_CONFIGURATION: 'settings/businessEntity/edit/tabs/task/subTaskConfiguration',
  SETTINGS_BUSINESS_ENTITY_LIST_CONTRACT_TERMS: 'settings/businessEntity/edit/tabs/contract/contractTerms',
  SETTINGS_BUSINESS_ENTITY_LIST_MODIFIERS: 'settings/businessEntity/edit/tabs/contract/modifiers',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_BASIC: 'settings/businessEntity/edit/tabs/patients/basics',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_CHARTS: 'settings/businessEntity/edit/tabs/patients/charts',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_REFERRAL_SOURCES: 'settings/businessEntity/edit/tabs/patients/referralSource/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_REFERRAL_SOURCES_ADD: 'settings/businessEntity/edit/tabs/patients/referralSource/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_REFERRAL_SOURCES_EDIT: 'settings/businessEntity/edit/tabs/patients/referralSource/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_ADJUSTMENT_REASON: 'settings/businessEntity/edit/tabs/patients/adjustmentReason/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_ADJUSTMENT_REASON_ADD: 'settings/businessEntity/edit/tabs/patients/adjustmentReason/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_ADJUSTMENT_REASON_EDIT: 'settings/businessEntity/edit/tabs/patients/adjustmentReason/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_SIMPLE_CHARGE: 'settings/businessEntity/edit/tabs/patients/simpleCharge/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_SIMPLE_CHARGE_ADD: 'settings/businessEntity/edit/tabs/patients/simpleCharge/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_SIMPLE_CHARGE_EDIT: 'settings/businessEntity/edit/tabs/patients/simpleCharge/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_CARE_TEAM: 'settings/businessEntity/edit/tabs/patients/careTeam/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_CARE_TEAM_ADD: 'settings/businessEntity/edit/tabs/patients/careTeam/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_PATIENT_CARE_TEAM_EDIT: 'settings/businessEntity/edit/tabs/patients/careTeam/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_BASICS: 'settings/businessEntity/edit/tabs/Billing/Basics',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_PAYER_AGING: 'settings/businessEntity/edit/tabs/Billing/payerAgingOverrides/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_PAYER_AGING_ADD: 'settings/businessEntity/edit/tabs/Billing/payerAgingOverrides/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_PAYER_AGING_EDIT: 'settings/businessEntity/edit/tabs/Billing/payerAgingOverrides/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_TABS: 'settings/businessEntity/edit/tabs/Billing',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_CASES: 'settings/businessEntity/edit/tabs/Billing/cases/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_CASES_ADD: 'settings/businessEntity/edit/tabs/Billing/cases/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_CASES_EDIT: 'settings/businessEntity/edit/tabs/Billing/cases/edit',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_CLAIMS: 'settings/businessEntity/edit/tabs/claims',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_REMITTANCE: 'settings/businessEntity/edit/tabs/remittance',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_PAYER_AGING_TABS: 'settings/businessEntity/edit/tabs/Billing/payerAgingOverrides',
  SETTINGS_BUSINESS_ENTITY_EDIT_DOCUMENTS_LIST: 'settings/businessEntity/edit/tabs/documents/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_DOCUMENTS_ADD_CATEGORY: 'settings/businessEntity/edit/tabs/documents/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_DOCUMENTS_EDIT_CATEGORY: 'settings/businessEntity/edit/tabs/documents/edit/index.json',
  SETTINGS_BUSINESS_ENTITY_EDIT_DOCUMENTS: 'settings/businessEntity/edit/tabs/documents/index.json',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_NDC: 'settings/businessEntity/edit/tabs/Billing/ndc',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_INSTITUTIONAL: 'settings/businessEntity/edit/tabs/Billing/institutional',
  BILLING_TASKS_ALL: 'billing/tasks/tabs/All',
  REMITTANCE_NEW_INSURANCE: 'claims/remittance/insurancePayment',
  REMITTANCE_PATIENT_PAYMENT: 'claims/remittance/patientPayment',
  TASK_LIST: 'billing/tasks/list',
  REPORTS: 'reports',
  REPORTS_TRANSACTION_CHARGES: 'reports/transactions/charges',
  REPORTS_TRANSACTION_CHARGES_CHARGESRVU: 'reports/transactions/charges/chargesByRVU',
  REMITTANCE_BATCH_DETAILS: 'claims/remittance/batch',
  REMITTANCE_WORK_LIST: 'claims/remittance/worklist',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_SCHEDULES_LIST: 'settings/businessEntity/edit/tabs/schedules/list',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_SCHEDULES_ADD: 'settings/businessEntity/edit/tabs/schedules/add',
  SETTINGS_BUSINESS_ENTITY_EDIT_BILLING_SCHEDULES_EDIT: 'settings/businessEntity/edit/tabs/schedules/edit',
  SETTINGS_COMPOSER: 'composer/list',
  SETTINGS_COMPOSER_NEW: 'composer/new',
  SETTINGS_SYNONYMS: 'settings/synonyms',
  SETTINGS_CONCERNFORM: 'settings/ConcernForm',
  END_OF_DAY_CLOSING: 'reports/transactions/payments/endOfDayClosing',
  PATIENTS_PAYMENTS: 'reports/transactions/payments/patientPayments',
  PAYMENTS: 'reports/transactions/payments/payments',
  REMITTANCE_BATCHES: 'reports/transactions/payments/remittanceBatches',
  ACCOUNTS_RECEIVABLE: 'reports/transactions/Accounts',
  PATIENTS_REPORTS: 'reports/Patients',
  GET_VERSION_PATIENT_ENCOUNTER_LIST: 'doctorservice/PatientEncounter/GetVersionedPatientEncounterList',
  SAVE_VERSIONED_ENCOUNTER: 'doctorservice/SignAndBill/SaveVersionedEncounter',
  GET_PATIENT_ENCOUNTER_BY_ID: 'doctorservice/PatientEncounter/GetPatientEncounterById',
  MANUAL_INTERVENTION_QUEUE: 'Pverify/manualInterventionQueue',
  SETTINGS_BUSINESS_ENTITY_EDIT_STATEMENTS: 'settings/businessEntity/edit/tabs/statements',
};

const enumDataIds = {
  INVITATION_ID: 350,
  AUTHORIZATION: 464,
  CAPITATION: 430,
  FEE_FOR_SERVICE: 431,
  FEE_FOR_SERVICE_VALUE_IF_NO_PROCEDURE_ID: 463,
  ALL_PLACES_OF_SERVICE: 451,
  SPECIFIC_PLACES_OF_SERVICE: 452,
  SPECIFIC_LOCATION: 453,
  CAPITATION_PAYMENT_ARRANGEMENT: 454,
  TERMS_EXCEPTION_TYPE_ID: 517,
  TERMS_EXCEPTION_VALUE_TYPE_ID_AMOUNT: 518,
  TECHNICIAN_VISION: 520,
  TECHNICIAN_PRESSURE: 521,
  PROVIDER_FEE_PREFERENCES_EXCEPTION_VALUE_TYPE: 544,
  PROVIDER_FEE_PREFERENCES_EXCEPTION_FACILITY: 45,
  PROVIDER_FEE_PREFERENCES_EXCEPTION_MODIFIER: 540,
  ALL_POLICY_TYPE_ID: 527,
  ALL_PLAN_TYPE_ID: 529,
  FOLLOW_UP_VISIT_TYPE_FOLLOW_SHORT: 118,
  REFERRING_PHYSICIAN_TYPE_PATIENT_CARE: 669,
  PRIMARY_CARE_PHYSICIAN_TYPE_PATIENT_CARE: 576,
  PREFERRED_LANGUAGE_ENGLISH: 11,
  PREFERRED_CONTACT_PHONE: 12,
  QUEUE_CLAIMS: 786,
  QUEUE_COLLECTION: 788,
  STATE_READY: 793,
  STATE_ERROR: 794,
  STATE_CLEARING_HOUSE: 795,
  STATE_NEW: 789,
  STATE_IN_PROGRESS: 790,
  STATE_NEED_ACTION: 791,
  STATE_SMALL_BALANCE: 799,
  STATE_COMPLETED: 792,
  STATE_UNBATCH: 797,
  STATE_OUTBOX: 798,
  STATE_TRASH: 2484,
  STAGE_READY: 861,
  STAGE_CLEARING_HOUSE: 865,
  STAGE_ERROR: 879,
  STAGE_COMPLETED: 860,
  STAGE_NEW: 862,
  STAGE_IN_PROGRESS: 863,
  STAGE_NEED_ACTION: 864,
  STAGE_SMALL_BALANCE: 869,
  STAGE_NO_RESPONSE: 870,
  STAGE_DENIAL: 871,
  STAGE_MANUAL: 872,
  STAGE_OUTBOX: 868,
  STAGE_UNBATCH: 867,
  STAGE_TRASH: 2485,
  STATEMENT_METHOD_ELECTRONIC: 374,
  ERROR_TYPE_ALL: 909,
  ERROR_TYPE_NONE: 910,
  COLLECTION_GROUPING: 109,
  QUEUE_REMITTANCE: 787,
  RETINA_SPECIALIST_ID: 589,
  PAPER_CLAIM: 379,
  WORKERS_COMP: 918,
  ADDITIONAL_FILTER_PAYER: 922,
  ADDITIONAL_FILTER_LOCATION: 921,
  ADDITIONAL_FILTER_AGING: 920,
  STAGE_SUBMITTED: 866,
  STATE_SUBMITTED: 796,
  REMITTANCE_INSURANCE_PAYMENT: 934,
  URGENT: 2048,
  STANDARD: 2049,
  CUTERA_AREA_OTHER: 995,
  CUTERA_AREA_IPL_KJ_OTHER: 1676,
  MICRO_NEEDLING_ADDITIVE_AGENT_OTHER: 992,
  ADDRESS_BOOK_PROVIDER_TYPE: 94,
  CASE_TYPE: 35,
  ADJUSTMENT_REASON: 149,
  REFERRAL_SOURCE: 27,
  SIMPLE_CHARGES: 153,
  FAVOURITE_TYPE_HPI: 1097,
  UNABLE_TO_COLLECT_REASON_OTHER: 1756,
  INSURANCE_TYPE: 165,
  TEMPLATE_TYPES: 179,
  PARENT_ID_ACUITY_EYE_GROUP: 8,
  PAYMENT_WRITE_OFF_REASON: 158,
  FEE_SCHEDULE_TYPE: 463,
  PERCENTAGE_BILLED_AMOUNT: 462,
  SUPER_BILL_BUNDLE_ID: 1891,
  userProfileImage: 1897,
  PRIORITY: 2050,
  WRITE_OFF_REASON: 158,
  POLICY_TYPE_VISION: 37,
  HOW_DID_YOU_HEAR_ABOUT_US: 110,
  PATIENT_DOCUMENT_CATEGORY: 48,
  PATIENT_CATEGORY_ID: 2148,
  CONSENT_FORM_ID: 2179,
  ENCOUNTER_PDF_CATEGORY_ID: 2235,
  WAYSTAR_QUEUE: 1861,
  WAYSTAR_STATE: 1862,
  WAYSTAR_STAGE: 1863,
  STATE_POSTED: 2493,
  STAGE_POSTED: 2492,
};

const enumMasterCode = {
  MedicareHMO: 'MedicareHMO',
  MedicaidHMO: 'MedicaidHMO',
  BlueCrossBlueShield: 'BlueCrossBlueShield',
  WorkersComp: 'WorkersComp',
  HMO: 'HMO',
  Capitation: 'Capitation',
  FAVOURITE_TYPE_HPI_MASTER_CODE: 'FavoriteType-HPI',
  SUPER_BILL_BUNDLE: 'SuperBillBundle',
  CA_MEDICARE: 'CA MEDICARE',
  ALL_NOTES: 'allnotes',
  PATIENTS: 'patients',
  CONSENT_FORM: 'consent form',
  ClaimsQueue: 'ClaimsQueue',
  BillingStageSubmitted: 'BillingStageSubmitted',
  BillingStateSubmitted: 'BillingStateSubmitted',
  WayStarQueue: 'WayStarQueue',
  WayStarClearingHouse: 'WayStarClearingHouse',
  WayStarStageClearingHouse: 'WayStarStageClearingHouse',
  ReferringPhysicianType: 'Referring Physician',
  PrimaryCarePhysicianType: 'PCP',
  Champus: 'Champus',
  CommercialInsurance: 'CommercialInsurance',
  IndemnityInsurance: 'IndemnityInsurance',
  refractionDefaultManifestCode: 'RefractionManifest',
  refractionContactLensesManifestCode: 'RefractionContactLenses',
  refractionContactLensesSphereCode: 'ContactLensesSphere',
  refractionContactLensesToricCode: 'ContactLensesToric',
  refractionContactLensesMultiFocalCode: 'ContactLensesMultifocal',
  refractionContactLensesRGPCode: 'ContactLensesRGP',
  refractionContactLensesKeratoCode: 'ContactLensesKeratoconus',
  preferredContactMethod: 'PreferredContactMethod',
  languages: 'Language',
  other: 'Other',
  Claim_Error_All: 'ClaimsErrorTypeAll',
  Claim_Error_None: 'ClaimsErrorTypeNone',
  Claim_Submission_Method_Paper: 'Paper',
  Claim_Submission_Method_Electronic: 'Electronic',
  Remittance_Insurance_Payment: 'RemittanceInsurancePayment',
  Claims_Queue: 'ClaimsQueue',
  Capitation_Visit_Arrangement_Per_Visit: 'Per Visit',
  Contract_Term_Plan_Type_Category_All: 'All',
  Contract_Term_Policy_Type_Category_All: 'All',
  Contract_Term_Type_Capitation: 'Capitation',
  Contract_Term_Type_Fee_For_Service: 'Fee for Service',
  Contract_Term_Policy_Type_Category_Specific: 'Specific',
  patientCategoryIdForNotes: 'Patients',
  CUTERA_AREA_OTHER: 'Other',
  customInvitation: 'Immediately',
  percentageofBilledAmount: 'PercentageofBilledAmount',
  feeScheduleType: 'FeeScheduleType',
  unableToCollectReasonOther: 'Other',
  allPlacesOfService: 'All Places of Service',
  contractSpecificPlacesOfService: 'Specific Places of Service',
  technicianVision: 'Vision',
  technicianPressure: 'Pressure',
  exceptionModifier: 'All',
  exceptionFacilityType: 'All',
  exceptionValueType: 'Amount',
  providerRetinaSpeciality: '207WX0107X',
  policyTypeVision: 'vision',
  statementMethodElectronic: 'Electronic',
  deliveryMethodPaper: 'Paper',
  contractTermExceptionTypeAmount: 'Amount',
  document_authorization: 'Authorization',
  contractTermsSpecificLocations: 'Specific Location',
  contractTermExceptionTypeIncluded: 'Included',
  billingStageClaimsQueue: 'ClaimsQueue',
  ndcQuantityUnit: 'Unit',
  billingStateNew: 'BillingStateNew',
  billingStateInProgress: 'BillingStateInProgress',
  billingStateNeedAction: 'BillingStateNeedAction',
  billingStateSmallBalance: 'BillingStateSmallBalance',
  billingStateCompleted: 'BillingStateCompleted',
  billingStageDenial: 'BillingStageDenial',
  billingStageManual: 'BillingStageManual',
  billingStageNoResponse: 'BillingStageNoResponse',
  collectionQueue: 'CollectionQueue',
  accident: 'Accident',
  patientPaymentCreditCard: 'CreditCard',
  patientPaymentCash: 'Cash',
  patientPaymentCheck: 'Check',
  patientPaymentGiftCard: 'GiftCard',
  patientPaymentCarecredit: 'Carecredit',
  patientPaymentEcheck: 'E-check',
  patientPaymentMoneyOrder: 'MoneyOrder',
  patientPaymentDebitCard: 'DebitCard',
  patientPaymentHRA: 'HRA',
  patientPaymentStripe: 'Stripe',
  lSent: 'L-Sent',
  patientAdjustment: 'PatientAdjustment',
  patientAdjustmentReversed: 'PatientAdjustmentReversed',
  DummyClaim: 'DummyClaim',
  InsuranceRelationSelf: 'Self',
  TodayRefraction: 'Today Refraction',
};

const I_FRAME_URL = 'I_FRAME';
const I_FRAME_URL_ALLERGY = 'I_FRAME_ALLERGY';

const urls = {
  MAP: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCVIIul_MNKpkpF_oZ0is6UZ3FEnMB9udc&v=3.exp&libraries=geometry,drawing,places',
  SEARCH_MAP: 'https://www.google.com/maps/search/?api=1&query=',
};

const keys = {
  CAPTCHA: '6LdmXKwZAAAAABDBhwL1jU5Ni5eK5rlyo40uablE',
};

const dateFormat = 'MM-DD-YYYY';
const timeFormat = 'HH:mm:ss';
const dateFormatWithHour = 'MM-DD-YYYY HH:mm';
const dateFormatWith12Hour = 'MM-DD-YYYY hh:mm A';
const dateFormatWithSlash = 'MM/DD/YYYY';
const dateFormatStartsWithYear = 'YYYY-MM-DD';
const dateFormatStartsWithYearHour = 'YYYY-MM-DD HH:mm';
const dateFormatStartsWithYearSeconds = 'YYYY-MM-DD HH:mm:ss';
const schedulerDataDateFormat = 'MM/DD/YYYY h:mm:ss A';
const timeFormat12 = 'h:mm A';

const schedularLocationColorRules = [{
  minPercent: -Infinity,
  maxPercent: 30,
  color: '#66BB6A',
}, {
  minPercent: 30,
  maxPercent: 60,
  color: '#ffeb3b',
}, {
  minPercent: 60,
  maxPercent: 80,
  color: '#ec407a',
},
{
  minPercent: 80,
  maxPercent: 100,
  color: '#FFA726',
}, {
  minPercent: 100,
  maxPercent: Infinity,
  color: '#f44336',
}];

const patientCardColorRules = [{
  minPercent: -Infinity,
  maxPercent: 10,
  class: 'green-pannel',
}, {
  minPercent: 10,
  maxPercent: 20,
  class: 'yellow-pannel',
}, {
  minPercent: 20,
  maxPercent: Infinity,
  class: 'orange-pannel',
}];

const mimeType = {
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  RAW: 'text/csv',
  CSV: 'text/csv',
};

const responseType = {
  ARRAY_BUFFER: 'arraybuffer',
  BLOB: 'blob',
};

const todaysRefractionTypeId = 523;

const defaultImage = {
  backgroundImage: {
    angle: 0,
    backgroundColor: '',
    clipTo: null,
    cropX: 0,
    cropY: 0,
    crossOrigin: '',
    fill: 'rgb(0,0,0)',
    fillRule: 'nonzero',
    filters: [],
    flipX: false,
    flipY: false,
    globalCompositeOperation: 'source-over',
    left: 0,
    opacity: 1,
    originX: 'left',
    originY: 'top',
    paintFirst: 'fill',
    scaleX: 1,
    scaleY: 1,
    shadow: null,
    skewX: 0,
    skewY: 0,
    stroke: null,
    strokeDashArray: null,
    strokeDashOffset: 0,
    strokeLineCap: 'butt',
    strokeLineJoin: 'miter',
    strokeMiterLimit: 4,
    strokeWidth: 0,
    top: 0,
    transformMatrix: null,
    type: 'image',
    version: '3.6.6',
    visible: true,
  },
};

const commonDefaultBackgroundWithTextBoxesProperties = {
  type: 'textbox',
  version: '3.6.6',
  originX: 'left',
  originY: 'top',
  height: 39.05,
  fill: 'black',
  stroke: null,
  strokeWidth: 1,
  strokeDashArray: null,
  strokeLineCap: 'butt',
  strokeDashOffset: 0,
  strokeLineJoin: 'miter',
  strokeMiterLimit: 4,
  angle: 0,
  flipX: false,
  flipY: false,
  opacity: 1,
  shadow: null,
  visible: true,
  clipTo: null,
  backgroundColor: '',
  fillRule: 'nonzero',
  paintFirst: 'fill',
  globalCompositeOperation: 'source-over',
  transformMatrix: null,
  skewX: 0,
  skewY: 0,
  text: '                      ',
  fontSize: 16,
  fontWeight: 'normal',
  fontFamily: 'Times New Roman',
  fontStyle: 'normal',
  lineHeight: 1.16,
  underline: false,
  overline: false,
  linethrough: false,
  textAlign: 'left',
  textBackgroundColor: '',
  charSpacing: 0,
  minWidth: 20,
  splitByGrapheme: false,
  styles: {

  },
};

const defaultBackgroundWithTextBoxes = {
  ...defaultImage,
  objects: [
    {
      left: 216,
      top: 101.33,
      scaleX: 1.16,
      scaleY: 1.24,
      width: 88,
      ...commonDefaultBackgroundWithTextBoxesProperties,
    },
    {
      left: 418.66,
      top: 98.33,
      width: 84,
      scaleX: 1.14,
      scaleY: 1.37,
      ...commonDefaultBackgroundWithTextBoxesProperties,

    },
    {
      left: 317,
      top: 189.33,
      width: 88,
      scaleX: 1.13,
      scaleY: 1.24,
      ...commonDefaultBackgroundWithTextBoxesProperties,
    },
    {
      left: 150,
      top: 271.33,
      width: 88,
      scaleX: 1.14,
      scaleY: 1.25,
      ...commonDefaultBackgroundWithTextBoxesProperties,
    },
    {
      left: 486.8,
      top: 274.33,
      width: 83.87,
      scaleX: 1.16,
      scaleY: 1.35,
      ...commonDefaultBackgroundWithTextBoxesProperties,
    },
    {
      left: 235,
      top: 372.33,
      width: 4,
      scaleX: 1,
      scaleY: 1,
      ...commonDefaultBackgroundWithTextBoxesProperties,
    },
    {
      left: 229,
      top: 358.33,
      width: 88,
      scaleX: 1.11,
      scaleY: 1.3,
      ...commonDefaultBackgroundWithTextBoxesProperties,
    },
    {
      left: 437,
      top: 357.33,
      width: 88,
      scaleX: 1.18,
      scaleY: 1.4,
      ...commonDefaultBackgroundWithTextBoxesProperties,
    },
  ],
};

const labelDescription = {
  Deceased: 'Deceased',
};

const defaultResponsibilityOptions = [
  {
    name: 'Patient',
    label: 'Patient',
    value: 'Patient',
  },
  {
    name: 'Insurance',
    label: 'Insurance',
    value: 'Insurance',
  },
];

const defaultResponsibilityTypes = [
  {
    name: 'Primary',
    label: 'Primary',
    value: 'Primary',
  },
  {
    name: 'Secondary',
    label: 'Secondary',
    value: 'Secondary',
  },
  {
    name: 'Tertiary',
    label: 'Tertiary',
    value: 'Tertiary',
  },
  {
    name: 'Quaternary',
    label: 'Quaternary',
    value: 'Quaternary',
  },
];

const CPTICDSynonymStatus = { pending: 0, approved: 1, reject: 2 };

const locationAddressObjectKeys = ['addressLine1', 'zip', 'city', 'stateId',
  'countryId', 'localityId', 'primaryPhone', 'fax', 'secondaryFax', 'secondaryFaxDescription', 'secondaryPhoneDescription',
  'primaryPhoneTypeId', 'secondaryPhoneTypeId', 'addressLine2', 'secondaryPhone', 'primaryPhoneExt', 'secondaryPhoneExt'];

const dayOptions = [
  { label: 'Sunday', value: 1 },
  { label: 'Monday', value: 2 },
  { label: 'Tuesday', value: 3 },
  { label: 'Wednesday', value: 4 },
  { label: 'Thursday', value: 5 },
  { label: 'Friday', value: 6 },
  { label: 'Saturday', value: 7 },
];
const dailyCheckBoxOptions = [
  { label: 'Every', value: 'Every' },
  { label: 'Every WeekDay', value: 'Every WeekDay' },
];

const scheduleValueOptions = [
  {
    label: 'Daily', value: 'daily', radioName: 'Daily', name: 'Daily',
  },
  {
    label: 'Weekly', value: 'weekly', radioName: 'Weekly', name: 'Weekly',
  },
  {
    label: 'Monthly', value: 'monthly', radioName: 'Monthly', name: 'Monthly',
  },
  {
    label: 'Yearly', value: 'yearly', radioName: 'Yearly', name: 'Yearly',
  },
];
const defaultMonthlyOptions = [
  {
    name: 'January',
    label: 'January',
    value: 1,
  },
  {
    name: 'February',
    label: 'February',
    value: 2,
  },
  {
    name: 'March',
    label: 'March',
    value: 3,
  },
  {
    name: 'April',
    label: 'April',
    value: 4,
  },
  {
    name: 'May',
    label: 'May',
    value: 5,
  },
  {
    name: 'June',
    label: 'June',
    value: 6,
  },
  {
    name: 'July',
    label: 'July',
    value: 7,
  },
  {
    name: 'August',
    label: 'August',
    value: 8,
  },
  {
    name: 'September',
    label: 'September',
    value: 9,
  },
  {
    name: 'October',
    label: 'October',
    value: 10,
  },
  {
    name: 'November',
    label: 'November',
    value: 11,
  },
  {
    name: 'December',
    label: 'December',
    value: 12,
  },
];

const allowAmountValidationForMipsSources = {
  BMI: 1,
  Medication: 1,
  Systolic: 1,
  Diastolic: 1,
  DiabetesWithRetinopathy: 1,
  DiabetesWithOutRetinopathy: 1,
  DiabetesWithPhysicianCommunication: 1,
  Cataract: 1,
  Tobacco: 1,
  TobaccoUserWithCessation: 1,
  HighRiskMedication: 1,
  IntraocularPressureReduction: 1,
  IntraocularPressureIncrease: 1,
  GlaucomaPlanCare: 1,
  OpenReferralLoop: 1,
};

const allowAmountValidationForManualEncounter = {
  G8752: 1,
  G8753: 1,
  G8754: 1,
  G8755: 1,
  G8427: 1,
  '2022F': 1,
  '2023F': 1,
  '5010F': 1,
  '4175F': 1,
  G9902: 1,
  G9903: 1,
  G9906: 1,
  '3285F': 1,
  '3284F': 1,
  '0517F': 1,
  G8420: 1,
  G8418: 1,
  G9970: 1,
  G9969: 1,
  G8417: 1,
};

const patientActiveStatus = [
  { label: 'Active', value: true },
  { label: 'InActive', value: false },
];

const acceptedFileTypes = ['.doc', '.docx', '.gif', '.jpeg', '.jpg', '.odp', '.ods', '.odt', '.pdf', '.png', '.ppt', '.pptx', '.rtf', '.tif', '.tiff', '.txt', '.xls', '.xlsx', '.DOC', '.DOCX', '.GIF', '.JPEG', '.JPG', '.ODP', '.ODS', '.ODT', '.PNG', '.PPT', '.PPTX', '.RTF', '.TIF', '.TIFF', '.TXT', '.XLSx', '.PDF', '.XLSX'];

/**
 * Only these types of files are accepted while uploading documents for patients.
 */
const acceptedFileTypesForPatient = ['.png', '.jpeg', '.jpg', '.gif', '.doc', '.docx', '.pdf', '.PNG', '.JPEG', '.JPG', '.GIF', '.DOC', '.DOCX', '.PDF'];

const RULES_ENGINE_HIGHLIGHT_KEYS = {
  PROCEDURE: ['Procedure CPT Code', 'Procedure Within Global Period'],
  MODIFIERS: ['Procedure Modifiers', 'Procedure Modifier Codes'],
  MODIFIERS_POSITION: ['Modifier Modifier Code', 'Modifier Modifier Position'],
  UNIT: ['Procedure Unit'],
  INSURANCE: [
    'Insurance Payer Name',
    'Insurance Payer ID',
    'Insurance Profile Type',
    'Insurance Policy Type Name',
  ],
  AUTHORIZATION: ['Insurance Requires Authorization'],
  DIAGNOSIS: ['Procedure Diagnosis Codes', 'Diagnosis Code ICD Code'],
  PROVIDER: ['Encounter Provider Specialty'],
};

const COLLECTIONS = {
  TYPE: {
    DENIAL: 'Denial',
    MANUAL: 'Manual',
    NO_RESPONSE: 'No Response',
  },
};

const RULES_ENGINE_EVALUATION_STATUS = {
  SUCCESS: 'Success',
  FAILURE: 'Failure',
};

const PAYMENT_TYPES = {
  CHECK: 949,
  CREDIT_CARD: 947,
  DEBIT_CARD: 959,
};

export {
  UiRoutes,
  formName,
  enums,
  formId,
  listId,
  contentType,
  listIds,
  labelPaths,
  enumDataIds,
  urls,
  dateFormat,
  timeFormat,
  timeFormat12,
  dateFormatWithHour,
  dateFormatWithSlash,
  dateFormatStartsWithYear,
  dateFormatStartsWithYearHour,
  dateFormatStartsWithYearSeconds,
  schedulerDataDateFormat,
  keys,
  schedularLocationColorRules,
  I_FRAME_URL,
  mimeType,
  I_FRAME_URL_ALLERGY,
  responseType,
  patientCardColorRules,
  todaysRefractionTypeId,
  enumMasterCode,
  defaultImage,
  dateFormatWith12Hour,
  defaultResponsibilityOptions,
  defaultResponsibilityTypes,
  labelDescription,
  defaultBackgroundWithTextBoxes,
  locationAddressObjectKeys,
  CPTICDSynonymStatus,
  dayOptions,
  dailyCheckBoxOptions,
  scheduleValueOptions,
  defaultMonthlyOptions,
  allowAmountValidationForMipsSources,
  patientActiveStatus,
  allowAmountValidationForManualEncounter,
  acceptedFileTypes,
  acceptedFileTypesForPatient,
  featureKeys,
  RULES_ENGINE_HIGHLIGHT_KEYS,
  COLLECTIONS,
  RULES_ENGINE_EVALUATION_STATUS,
  PAYMENT_TYPES,
};
