import React, { useCallback, useMemo, useState } from 'react';

import { dateFormat } from '../../../../../../lib/constants';

import TimeCell from '../../../../../../components/Cells/TimeCell';
import Input from '../../../../../../components/Form/Input';

const PatientPayment = (labels, billingState) => [
  {
    Header: labels.get('columns.dos'),
    accessor: 'dateOfService',
    Cell: ({ row: { original } }) => {
      if (original?.description === 'Credit Payment') {
        return '-';
      }
      return <TimeCell value={original?.effectiveDate} format={dateFormat} />;
    },
    className: 'text-align-left',
    fixWidth: '150',
  },
  {
    Header: labels.get('columns.description'),
    accessor: 'description',
    className: 'text-align-left',
    fixWidth: '150',
  },
  {
    Header: labels.get('columns.responsibility'),
    accessor: 'responsibility',
    Cell: ({ row: { original } }) => (original?.isPatientResponsibility ? 'Patient' : 'Insurance'),
    className: 'text-align-left',
    fixWidth: '150',
  },
  {
    Header: labels.get('columns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original } }) => (
      parseFloat(Number(parseFloat(original?.amount || 0))).toFixed(2)
    ),
    Footer: ({ lineItems = [] }) => {
      const total = useMemo(
        () => (
          lineItems.reduce((sum, { amount = 0 }) => sum + parseFloat(amount), 0) || 0
        ).toFixed(2), [lineItems],
      );
      if (!lineItems?.length) {
        return null;
      }
      return (
        <div style={{ fontWeight: 'bolder', color: '#2a4a79', textAlign: 'end' }}>
          {total}
        </div>
      );
    },
    className: 'text-align-right',
  },
  {
    Header: labels.get('columns.balance'),
    accessor: 'balance',
    Cell: ({ row: { original } }) => (
      parseFloat(Number(parseFloat(original?.balance || 0))).toFixed(2)
    ),
    Footer: ({ lineItems = [] }) => {
      const total = useMemo(
        () => (
          lineItems.reduce((sum, { balance = 0 }) => sum + parseFloat(balance), 0) || 0
        ).toFixed(2), [lineItems],
      );
      if (!lineItems?.length) {
        return null;
      }
      return (
        <div style={{ fontWeight: 'bolder', color: '#2a4a79', textAlign: 'end' }}>
          {total}
        </div>
      );
    },
    fixWidth: '100',
    className: 'text-align-right',
  },
  {
    Header: labels.get('columns.applied'),
    accessor: 'applied',
    Cell: ({
      row: {
        index,
        original,
      },
      getLineItemSetter,
      lineItems = [], financialId, isValueUptoTwoDecimalPlaces,
    }) => {
      const [localData, setLocalData] = useState(parseFloat(lineItems[index]?.applied).toFixed(2));

      const handleTextBoxChange = useCallback(({ target: { value } }) => {
        if (isValueUptoTwoDecimalPlaces(value)) {
          setLocalData(value);
        }
      }, [isValueUptoTwoDecimalPlaces]);

      const isCreditPayment = original?.description === 'Credit Payment';

      const isDisabled = (isCreditPayment && billingState !== 'BillingStateOutBox') ? false
        : ((!financialId && original?.balance <= 0) || billingState === 'BillingStateOutBox');

      return (
        <Input
          isFormItem={false}
          numberOnly
          labelSpan={0}
          inputSpan={24}
          value={localData}
          onChange={handleTextBoxChange}
          onBlur={getLineItemSetter(index, 'applied')}
          disabled={isDisabled}
          maxValueLength={11}
        />
      );
    },
    Footer: ({ lineItems = [] }) => {
      const total = useMemo(
        () => (
          lineItems.reduce((sum, { applied = 0 }) => sum + parseFloat(applied), 0) || 0
        ).toFixed(2), [lineItems],
      );
      if (!lineItems?.length) {
        return null;
      }
      return (
        <div style={{ fontWeight: 'bolder', color: '#2a4a79', textAlign: 'end' }}>
          {total}
        </div>
      );
    },
    fixWidth: '150',
  },
];
export default PatientPayment;
