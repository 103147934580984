import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';
import Icon from '../../../../../../components/Icon';
import iconNames from '../../../../../../lib/iconNames';

const waystarRejectedColumns = (labels) => [
  {
    Header: labels.get('tableColumns.claimNo'),
    accessor: 'claimNo',
    fixWidth: '150',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.patientName'),
    accessor: 'patientName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'stageMasterName',
    Cell: ({ row: { original: { stageMasterName } } }) => {
      if (stageMasterName === 'Waystar Rejection') {
        return <span>Rejected by waystar</span>;
      }
      return <span />;
    },
  },
  {
    Header: labels.get('tableColumns.waystarSubmissionDate'),
    accessor: 'claimSubmissionDate',
    fixWidth: '200',
    Cell: (e) => (
      <TimeCell
        value={e?.row.original?.claimSubmissionDate?.dateString}
        format="MM-DD-YYYY"
      />
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.payer'),
    accessor: 'insurancePayerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.policyType'),
    accessor: 'insurancePolicyTypeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'profileTypeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.dos'),
    accessor: 'billingservicelineitemcreateddate.timestemp',
    Cell: (e) => <TimeCell value={e?.row.original?.billingServiceLineItemCreatedDate?.dateString} format="MM-DD-YYYY" />,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.balance'),
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    sort: true,
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.waystarRejectionError'),
    accessor: 'waystarRejectionError',
    fixWidth: '60',
    Cell: ({
      row,
      row: { original: { waystarRejectionError } },
      isEditPermitted,
    }) => {
      const expanderProps = row.getToggleRowExpandedProps();
      if (expanderProps.onClick) {
        const { onClick } = expanderProps;
        expanderProps.onClick = (e) => {
          e.stopPropagation();
          onClick(e);
        };
      }
      const dataArray = waystarRejectionError?.split(',,');

      return (
        <div onClick={() => (waystarRejectionError)} role="presentation">
          <span {...expanderProps}>
            <span style={{ marginLeft: '3px', marginRight: '5px' }}>
              {isEditPermitted && row.isExpanded ? (
                <span style={{ color: 'orange' }} role="presentation">
                  -
                </span>
              ) : (
                <span style={{ color: 'orange' }}>
                  {dataArray?.length}
                </span>
              )}
            </span>
            <span>
              <Icon
                style={{ color: 'orange' }}
                name={row?.isExpanded ? iconNames.minus : iconNames.plusIcon}
              />
            </span>
          </span>

        </div>
      );
    },
  },
];

export default waystarRejectedColumns;
