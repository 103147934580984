import { Map } from 'immutable';

import {
  GET_LIST_DATA_ADVANCED,
  SET_LIST_DATA_ADVANCED,
  SET_LIST_LOADING_ADVANCED,
  SET_LIST_ERROR_ADVANCED,
  SET_SORT_ORDER_ADVANCED,
  SET_FILTERS_ADVANCED,
  SET_PAGE_ADVANCED,
  CLEAR_PAGE_CACHE_ADVANCED,
} from '../actions/queryApiAdvanced';

const initialState = Map({
});

const actionsMap = {

  [GET_LIST_DATA_ADVANCED]: (state, { listId, variables }) => {
    const list = state.get(listId, {});
    return state.set(listId, {
      ...list,
      error: null,
      lastQueryParams: {
        filters: variables.filters,
        sortBy: variables.sortBy,
      },
    });
  },

  [SET_LIST_DATA_ADVANCED]: (state, {
    data = [], listId, reset, hasMore, totalCount, totalPages, otherTableData,
  }) => {
    const list = state.get(listId, {});
    const existingPageData = list.pageData || {};
    const pageIndex = otherTableData?.pageIndex || 0;

    return state.set(listId, {
      ...list,
      data,
      pageData: {
        ...existingPageData,
        [pageIndex]: data,
      },
      hasMore,
      totalCount,
      totalPages,
      otherTableData: otherTableData || {},
      lastQueryParams: {
        filters: list.lastQueryParams?.filters,
        sortBy: list.lastQueryParams?.sortBy,
      },
    });
  },

  [CLEAR_PAGE_CACHE_ADVANCED]: (state, { listId }) => {
    const list = state.get(listId, {});
    return state.set(listId, {
      ...list,
      pageData: {},
    });
  },

  [CLEAR_PAGE_CACHE_ADVANCED]: (state, { listId }) => {
    const list = state.get(listId, {});
    return state.set(listId, {
      ...list,
      pageData: {},
      data: [],
    });
  },

  [SET_SORT_ORDER_ADVANCED]: (state, { sortBy, listId }) => {
    const list = state.get(listId);
    return state.set(listId, {
      ...list,
      sortBy,
      pageData: {},
    });
  },

  [SET_FILTERS_ADVANCED]: (state, { filters, listId }) => {
    const list = state.get(listId);
    return state.set(listId, {
      ...list,
      filters,
      pageData: {},
    });
  },

  [SET_PAGE_ADVANCED]: (state, { page, listId }) => {
    const list = state.get(listId);
    return state.set(listId, { ...list, page });
  },

  [SET_LIST_LOADING_ADVANCED]: (state, { loading, listId }) => {
    const list = state.get(listId);
    return state.set(listId, { ...list, loading });
  },

  [SET_LIST_ERROR_ADVANCED]: (state, { error, listId }) => {
    const list = state.get(listId);
    return state.set(listId, { ...list, error });
  },
};

export default function queryApiAdvanced(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
