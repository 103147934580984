import React from 'react';
import TimeCell from '../../../../../../components/Cells/TimeCell';

const clearingHouseColumns = (labels) => [
  {
    Header: labels.get('tableColumns.claimNo'),
    accessor: 'claimNo',
    fixWidth: '150',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.patientName'),
    accessor: 'patientName',
    sort: true,
    fixWidth: '250',
    Footer: ({ totalCount }) => <span>{`${totalCount} Claims`}</span>,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'stageMasterName',
    Cell: ({ row: { original: { deliveryMethodCode, profileTypeName } } }) => {
      if (profileTypeName?.toLowerCase() === 'secondary'
      || profileTypeName?.toLowerCase() === 'tertiary'
      || profileTypeName?.toLowerCase() === 'quaternary') {
        return (
          <span>
            Forwarded to
            {' '}
            {profileTypeName?.toLowerCase()}
          </span>
        );
      }
      if (deliveryMethodCode === 'Paper') {
        return <span>Marked for submission</span>;
      }
      if (deliveryMethodCode === 'Electronic') {
        return <span>Ready</span>;
      }
      return <span />;
    },
  },
  {
    Header: labels.get('tableColumns.payer'),
    accessor: 'insurancePayerName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.policyType'),
    accessor: 'insurancePolicyTypeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.type'),
    accessor: 'profileTypeName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.format'),
    accessor: 'format',
    Cell: ({ row: { original: { deliveryMethodName } } }) => <span>{deliveryMethodName?.split('/')?.[0]}</span>,
  },
  {
    Header: labels.get('tableColumns.deliveryMethod'),
    accessor: 'deliveryMethodName',
    Cell: ({ row: { original: { deliveryMethodName } } }) => <span>{deliveryMethodName?.split('/')?.[1]}</span>,
  },
  {
    Header: labels.get('tableColumns.dos'),
    accessor: 'billingservicelineitemcreateddate.timestemp',
    Cell: (e) => <TimeCell value={e?.row.original?.billingServiceLineItemCreatedDate?.dateString} format="MM-DD-YYYY" />,
    sort: true,
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.balance'),
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    sort: true,
    className: 'text-align-right',
  },
/*   {
    Header: labels.get('tableColumns.valueBalance'),
    accessor: 'valueBalance',
  }, */
];

export default clearingHouseColumns;
