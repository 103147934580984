import React, { useCallback, useEffect, useState } from 'react';

import Input from '../../../../../../components/Form/Input';
import DatePicker from '../../../../../../components/Form/DatePicker';
import Form from '../../../../../../components/Form';

import EnumSelect from '../../../../../../wiredComponents/Enum/Select';
import WiredSelect from '../../../../../../wiredComponents/Select/selectBoxV2';
import WidgetLoader from '../../../../../../components/WidgetLoader';
import useCRUD from '../../../../../../hooks/useCRUD';

import { enums } from '../../../../../../lib/constants';
import SelectBox from '../../../../../../components/SelectBox';
import { apiUrls } from '../../../../../../api/constants';
import Events from '../../../../../../lib/events';

const CreditCardForm = ({ labels, financialId }) => (
  <Form.Section noOfColumns="2">
    <Form.Column>
      <EnumSelect
        label={labels.get('labels.cardType')}
        name="cardTypeId"
        enumName={enums.PATIENT_PAYMENT_CARD_TYPE}
        required
        disabled={financialId}
      />
      <Input
        label={labels.get('labels.cardName')}
        name="nameOnCard"
        disabled={financialId}
        required
      />
    </Form.Column>
    <Form.Column>
      <Input
        label={labels.get('labels.LastFourDigitsCardNumber')}
        name="cardNumber"
        maxValueLength={4}
        minValueLength={4}
        numberOnly
        disabled={financialId}
        required
      />
    </Form.Column>
  </Form.Section>
);

const CheckForm = ({ labels, financialId }) => (
  <Form.Section noOfColumns="2">
    <Form.Column>
      <DatePicker
        label={labels.get('labels.checkDate')}
        name="checkDate"
        disabled={financialId}
      />
      <Input
        label={labels.get('labels.checkNumber')}
        name="checkNumber"
        disabled={financialId}
        required
      />
    </Form.Column>
    <Form.Column>
      <Input
        label={labels.get('labels.accountNumber')}
        name="accountNumber"
        disabled={financialId}
      />
      <Input
        label={labels.get('labels.checkAmount')}
        name="checkAmount" // not-found
        numberOnly
        decimalPlaces={3}
        disabled={financialId}
      />
    </Form.Column>
  </Form.Section>
);

const GiftCardForm = ({ labels, financialId }) => (
  <Form.Section noOfColumns="2">
    <Form.Column>
      <Input
        label={labels.get('labels.certificateName')}
        name="nameOnCertificate"
        disabled={financialId}
      />
    </Form.Column>
    <Form.Column>
      <Input
        label={labels.get('labels.certificateNumber')}
        name="certificateNumber"
        disabled={financialId}
      />
    </Form.Column>
  </Form.Section>
);

const ECheckForm = ({ labels, financialId }) => (
  <Form.Section noOfColumns="2">
    <Form.Column>
      <Input
        label={labels.get('labels.cardNumber')}
        name="cardNumber"
        maxValueLength={4}
        minValueLength={4}
        numberOnly
        disabled={financialId}
        required
      />
    </Form.Column>
    <Form.Column>
      <Input
        label={labels.get('labels.cardName')}
        name="nameOnCard"
        disabled={financialId}
        required
      />
    </Form.Column>
  </Form.Section>
);

const DebitCardForm = ({ labels, financialId }) => (
  <Form.Section noOfColumns="2">
    <Form.Column>
      <EnumSelect
        label={labels.get('labels.cardType')}
        name="cardTypeId"
        enumName={enums.PATIENT_PAYMENT_CARD_TYPE}
        disabled={financialId}
        required
      />
      <Input
        label={labels.get('labels.cardNumber')}
        name="cardNumber"
        maxValueLength={4}
        minValueLength={4}
        numberOnly
        disabled={financialId}
        required
      />
    </Form.Column>
    <Form.Column>
      <DatePicker
        label={labels.get('labels.expirationDate')}
        name="expirationDate"
        picker="month"
        dateFormat="MM/YYYY"
        disabled={financialId}
      />
      <Input
        label={labels.get('labels.cardName')}
        name="nameOnCard"
        disabled={financialId}
        required
      />
    </Form.Column>
  </Form.Section>
);

export const detailsFormComponent = (type, props) => {
  const forms = {
    947: CreditCardForm,
    949: CheckForm,
    954: GiftCardForm,
    955: ECheckForm, // Care Credit
    956: ECheckForm, // E-Check
    958: ECheckForm, // Money Order
    959: DebitCardForm,
  };
  if (forms[type]) {
    const DetailsForm = forms[type];
    return <DetailsForm {...props} />;
  }
  return null;
};

const PatientPaymentForm = ({
  labels, financialId, formData, form, patientId,
}) => {
  const [transactionType, setTransactionType] = useState(0);
  const [pasadenaLocation, setPasadenaLocation] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [stripeGatewayData, setStripeGatewayData] = useState({ show: false, collectable: 0, product: 'patientPayment' });

  const [
    stripeUrlResponse,,
    stripeUrlLoading,
    stripeUrl,
    clearStripeUrl,
  ] = useCRUD({
    id: apiUrls.STRIPE_PAYMENT_GATEWAY,
    url: apiUrls.STRIPE_PAYMENT_GATEWAY,
    type: 'create',
  });

  const onSelectProvider = useCallback((providerId) => setSelectedProvider(providerId), []);
  const onSelectLocation = useCallback((locationId, _allItem) => {
    const { item } = _allItem || {};
    if (item?.locationName === 'BILLING PASADENA') {
      setPasadenaLocation(true);
    } else if (pasadenaLocation) {
      setPasadenaLocation(false);
    }
    setSelectedLocation(locationId);
  }, [pasadenaLocation]);

  useEffect(() => {
    if (formData && financialId) {
      onSelectLocation(formData?.locationId, { item: { locationName: formData?.locationName } });
      onSelectProvider(formData?.providerId);
      setTransactionType(formData?.paymentTypeId ?? 0);
    }
  }, [financialId, formData]);

  useEffect(() => {
    if (stripeGatewayData?.show) {
      stripeUrl({
        data: {
          patientId,
          amount: stripeGatewayData?.collectable,
          currency: 'usd',
          product: stripeGatewayData?.product,
          providerId: stripeGatewayData?.providerId,
          locationId: stripeGatewayData?.locationId,
          effectiveDate: stripeGatewayData?.effectiveDate,
        },
      });
    }
  }, [stripeGatewayData]);

  useEffect(() => {
    if (stripeUrlResponse) {
      // eslint-disable-next-line no-restricted-globals
      const popWidth = (screen.width - 650) / 2;
      const popupOpened = window.open(
        stripeUrlResponse?.stripeUrl,
        'Popup',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${650}, height=${650}, top=${100}, left=${popWidth}`,
      );
      window.addEventListener('message', (event) => {
        if (event.origin === process.env.REACT_APP_STRIPE_URL) {
          if (event.data === 'ok') {
            popupOpened.close();
            Events.trigger('reFetchCheckOutFinancial');
          }
        }
      });
    }
    clearStripeUrl();
  }, [stripeUrlResponse]);

  const handleTransactionType = useCallback((id, _all = {}) => {
    const { item } = _all;
    if (form && patientId
      && (item?.masterCode?.toLowerCase() === 'stripe')) {
      setStripeGatewayData({
        show: false,
        collectable: form?.getFieldValue('amount') || 0,
        product: 'patientPayment',
        providerId: form?.getFieldValue('providerId'),
        locationId: form?.getFieldValue('locationId'),
        effectiveDate: form?.getFieldValue('effectiveDate'),
      });
      setTransactionType(0);
    } else {
      setTransactionType(id);
    }
  }, [form, patientId]);

  return (
    <>
      {stripeUrlLoading && <WidgetLoader />}
      <Form.Section noOfColumns="2">
        <Form.Column>
          <DatePicker
            label={labels.get('labels.effectiveDate')}
            name="effectiveDate"
            required
            disabled={financialId}
            datePickerProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
            dataTestId="patient-payment-date"
          />
          <EnumSelect
            label={labels.get('labels.transactionType')}
            name="paymentTypeId"
            enumName={enums.PATIENT_PAYMENT_TYPE}
            excludeOptionNames={['PPA']}
            onChange={handleTransactionType}
            required
            disabled={financialId}
            selectProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
            dataTestId="patient-payment-transaction-type"
          />
          <Input
            label={labels.get('labels.amount')}
            name="amount"
            numberWithDecimal
            maxValueLength={11}
            required
            disabled={financialId}
            dataTestId="patient-payment-amount"
            placeholder="Enter Amount"
          />
        </Form.Column>
        <Form.Column>
          <WiredSelect
            id="provider"
            name="providerId"
            label={labels.get('labels.provider')}
            placeholder="Select Provider"
            url={selectedLocation && selectedLocation > 0 && apiUrls.SCHEDULAR_PROVIDER_DROPDOWN}
            nameAccessor="providerName"
            valueAccessor="providerId"
            Component={SelectBox}
            startCaseOptions
            selectProps={{
              style: { width: '100%', paddingLeft: '17.5%' },
              showSearch: true,
              getPopupContainer: (trigger) => trigger?.parentNode,
            }}
            disabled={financialId}
            initialValue={financialId && formData?.providerId && formData?.providerName && {
              name: formData?.providerName,
              value: formData?.providerId,
            }}
            defaultSorting={false}
            onChange={onSelectProvider}
            params={{
              LocationId: selectedLocation,
            }}
            dataTestId="patient-payment-provider"
          />
          <div className="provider-error-fix">
            <WiredSelect
              id="location"
              name="locationId"
              label={labels.get('labels.location')}
              url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
              placeholder="Select Location"
              nameAccessor="locationName"
              valueAccessor="locationId"
              Component={SelectBox}
              startCaseOptions
              selectProps={{
                style: { width: '100%', paddingLeft: '17.5%' },
                showSearch: true,
                getPopupContainer: (trigger) => trigger?.parentNode,
              }}
              disabled={financialId}
              initialValue={financialId && formData?.locationId && formData?.locationName && {
                name: formData?.locationName,
                value: formData?.locationId,
              }}
              params={{
                ProviderId: selectedProvider || null,
              }}
              onChange={onSelectLocation}
              dataTestId="patient-payment-location"
            />
          </div>
          {pasadenaLocation && (
            <EnumSelect
              name="billingPasadenaTypeId"
              label={labels.get('labels.billingPasadenaType')}
              enumName={enums.BILLING_PASADENA_TYPE}
              required
              disabled={financialId}
              selectProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
              dataTestId="patient-payment-pasadena"
            />
          )}
          <Input
            label={labels.get('labels.comments')}
            name="comments"
            required
            disabled={financialId}
            dataTestId="patient-payment-comments"
            placeholder="Enter Comments"
          />
        </Form.Column>
      </Form.Section>
      {detailsFormComponent(transactionType || 0, { labels, financialId })}
    </>
  );
};

export default PatientPaymentForm;
