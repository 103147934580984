import React, { useEffect, useRef, useState } from 'react';
import { Button as AntdButton } from 'antd-old';
import {
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useTable, useSortBy, usePagination } from 'react-table';
import './table.scss';
import { Dropdown, Select } from 'antd';
import Button from '../Button';
import SkeletonLoader from './TableLoader';
import { makeTableResizable } from './resizable';
import Tooltip from '../Tooltip';
import CheckBox from '../SmCheckbox';

const PaginationButtons = ({
  currentPage,
  totalPages,
  handlePageChange,
  paginationLoading,
  loadingButton,
}) => (
  <div className="pagination-controls">
    <AntdButton
      icon={<DoubleLeftOutlined />}
      onClick={() => handlePageChange(0, 'first')}
      disabled={currentPage === 0 || paginationLoading}
      loading={loadingButton === 'first'}
      className="nav-button"
      title="First Page"
    >
      First
    </AntdButton>
    <AntdButton
      icon={<LeftOutlined />}
      onClick={() => handlePageChange(currentPage - 1, 'prev')}
      disabled={currentPage === 0 || paginationLoading}
      loading={loadingButton === 'prev'}
      className="nav-button"
      title="Previous Page"
    >
      Previous
    </AntdButton>
    <AntdButton
      icon={<RightOutlined />}
      onClick={() => handlePageChange(currentPage + 1, 'next')}
      disabled={currentPage === totalPages - 1 || paginationLoading}
      loading={loadingButton === 'next'}
      className="nav-button"
      title="Next Page"
    >
      Next
    </AntdButton>
    <AntdButton
      icon={<DoubleRightOutlined />}
      onClick={() => handlePageChange(totalPages - 1, 'last')}
      disabled={currentPage === totalPages - 1 || paginationLoading}
      loading={loadingButton === 'last'}
      className="nav-button"
      title="Last Page"
    >
      Last
    </AntdButton>
  </div>
);

const AdvancedTable = ({
  columns: tableColumns,
  data,
  loading,
  onSort,
  initialSort,
  totalCount,
  totalPages,
  otherTableData,
  onRowClick,
  onFetchMore,
  noDataText = 'No data found',
  isPagination = false,
  rightClickMenu,
  pageSize,
  setPageSize,
  onRefresh,
  showRowSelection = false,
  setSelectedRows,
  selectedRows,
  showPageSizeSelector = false,
  ...otherProps
}) => {
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [menuOverlay, setMenuOverlay] = useState(null);
  const tableRef = useRef(null);
  const [columns, setColumns] = useState(tableColumns || []);

  const selectionColumn = {
    accessor: 'checkBox',
    Header: () => (
      <CheckBox
        indeterminate={selectedRows?.length > 0 && selectedRows?.length < data?.length}
        checked={data?.length > 0 && selectedRows?.length === data?.length}
        onChange={(e) => {
          if (e.target.checked) {
            const allRows = data?.filter((row) => !row.disabled) || [];
            setSelectedRows(allRows);
          } else {
            setSelectedRows([]);
          }
        }}
        disabled={loading || !data?.length}
      />
    ),
    Cell: ({ row }) => (
      <CheckBox
        checked={selectedRows?.includes(row.original)}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedRows([...selectedRows, row.original]);
          } else {
            setSelectedRows(selectedRows.filter((original) => original !== row.original));
          }
        }}
        onClick={(e) => e.stopPropagation()}
      />
    ),
    width: 10,
  };

  useEffect(() => {
    if (showRowSelection) {
      setColumns([selectionColumn, ...tableColumns]);
    }
  }, [showRowSelection, selectedRows, data]);

  useEffect(() => {
    // Update the document title using the browser API
    if (tableRef.current) {
      makeTableResizable(tableRef.current);
    }
  }, [columns, tableRef.current]);

  const tableInstance = useTable(
    {
      columns,
      data: data || [],
      manualSortBy: true,
      manualPagination: true,
      pageCount: totalPages || -1,
      initialState: {
        sortBy: initialSort || [],
        pageSize: otherTableData?.pageSize || 30,
        pageIndex: otherTableData?.pageIndex || 0,
      },
      ...otherProps,
    },
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { sortBy },
  } = tableInstance;

  useEffect(() => {
    if (sortBy?.length) {
      onSort(sortBy);
    }
  }, [sortBy]);

  useEffect(() => {
    if (!loading) {
      setPaginationLoading(false);
      setLoadingButton(null);
    }
    if (showRowSelection && setSelectedRows) {
      setSelectedRows([]);
    }
  }, [data, loading]);

  const handleContextMenu = (event, row) => {
    event.preventDefault(); // Prevent default browser right-click menu

    if (rightClickMenu) {
      const menuFunction = rightClickMenu(row);
      const overlay = menuFunction();

      if (overlay !== false) {
        setSelectedRow(row);
        setMenuOverlay(overlay);
      } else {
        setSelectedRow(null);
        setMenuOverlay(null);
      }
    }
  };

  const currentPage = otherTableData?.pageIndex || 0;

  const handlePageChange = (pageNumber, buttonType) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setPaginationLoading(true);
      setLoadingButton(buttonType);
      onFetchMore(pageNumber);
    }
  };

  const getTitleText = (column) => {
    if (!(column.sort || column.reverseSort)) return '';
    if (!column.isSorted) return 'Sort Ascending';
    return column.isSortedDesc ? 'Sort Ascending' : 'Sort Descending';
  };

  const columnSort = (column) => {
    let columnSorted = '';
    if (column.isSorted) {
      columnSorted = column.isSortedDesc ? ' ▼' : ' ▲';
    }
    return columnSorted;
  };

  return (
    <div className="table-container">
      <div className="refresh-button">
        <Tooltip title="Refresh data to see latest changes">
          <Button
            type="button"
            onClick={onRefresh}
            className="btn btn-primary sm-btn refresh-btn"
            data-testid="refresh-encounters-button"
            disabled={loading}
          >
            <SyncOutlined />
            {' '}
            Refresh
          </Button>
        </Tooltip>
      </div>
      <div className="app-table">
        <table {...getTableProps()} ref={tableRef} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(
                      (column.sort || column.reverseSort)
                        && column.getSortByToggleProps(),
                    )}
                    key={column.id}
                    // className={classNames('table-data', { divider: true })}
                    style={{
                      cursor: (column.sort || column.reverseSort) && 'pointer',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => {
                      if (column.sort || column.reverseSort) {
                        if (column.isSorted) {
                          column.toggleSortBy(!column.isSortedDesc, false);
                        } else {
                          column.toggleSortBy(false, false);
                        }
                      }
                    }}
                    title={getTitleText(column)}
                  >
                    <div className="table-header">
                      <span>{column.render('Header')}</span>
                      <span className="up-sort-arrow">
                        {(column.sort || column.reverseSort)
                          && columnSort(column)}
                      </span>
                      {!column.isSorted
                        && (column.sort || column.reverseSort) && (
                          <span className="both-sort-arrow">
                            <span>▲</span>
                            <span>▼</span>
                          </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading ? (
              <SkeletonLoader headerGroups={headerGroups} />
            ) : (
              page.map((row) => {
                prepareRow(row);

                if (row.original.type === 'section') {
                  return (
                    <tr {...row.getRowProps()} key={`section-${row.id}`}>
                      <td colSpan={columns.length} className="section-divider">
                        {row.original.letter}
                      </td>
                    </tr>
                  );
                }

                return (
                  <Dropdown
                    key={`row-${row.id}`}
                    overlay={selectedRow === row ? menuOverlay : <div />}
                    placement="bottomLeft"
                    trigger={['contextMenu']}
                  >
                    <tr
                      {...row.getRowProps()}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (typeof onRowClick === 'function') {
                          onRowClick(row);
                        }
                      }}
                      onContextMenu={(event) => handleContextMenu(event, row)}
                    >
                      {row.cells.map((cell, cellIndex) => (
                        <td
                          key={`cell-${cellIndex}`}
                          {...cell.getCellProps()}
                          className="table-data"
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  </Dropdown>
                );
              })
            )}
          </tbody>
        </table>
        {!data?.length && !loading ? (
          <div className="no-data-text">{noDataText}</div>
        ) : (
          isPagination && (
            <div className="pagination">
              <div className="pagination-info">
                <span>
                  Total Records:
                  {totalCount || 0}
                </span>
                {showPageSizeSelector && (
                <span>
                  <label>Records Per page: </label>
                  <Select
                    defaultValue={pageSize}
                    onChange={(value) => {
                      setPageSize(Number(value));
                    }}
                    options={[
                      { value: 30, label: '30' },
                      { value: 50, label: '50' },
                      { value: 100, label: '100' },
                      { value: 150, label: '150' },
                      { value: 200, label: '200' },
                      { value: 250, label: '250' },
                      { value: 300, label: '300' },
                      { value: 1000, label: '1000' },
                    ]}
                    size="small"
                  />
                </span>
                )}
                <span>
                  Page
                  {' '}
                  {currentPage + 1}
                  {' '}
                  of
                  {' '}
                  {totalPages}
                </span>
              </div>
              <PaginationButtons
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                paginationLoading={paginationLoading}
                loadingButton={loadingButton}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AdvancedTable;
