/* eslint-disable no-unused-expressions */
import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import moment from 'moment';
import find from 'lodash/find';

import { apiUrls } from '../../../../api/constants';

import SelectBox from '../../../../components/SelectBox';
import DatePicker from '../../../../components/Form/DatePicker';

import WiredSelect from '../../../../wiredComponents/Select/selectBoxV2';
import EnumSelect from '../../../../wiredComponents/Enum/Select';

import '../../billing.scss';
import dateFormatter from '../../../../lib/dateFormatter';
import { dateFormatStartsWithYearSeconds, enums } from '../../../../lib/constants';
import Checkbox from '../../../../components/FilterComponents/Checkbox';
import useCRUDWithoutTab from '../../../../hooks/useCRUDWithoutTab';

const tabsFilter = {
  All: 'All',
  UnbilledEncounters: 'Unbilled',
  Saved: 'Saved',
  OnHold: 'On Hold',
};

const BillingEncounterFilter = ({
  onFilterChange: onChangeFilter,
  filters,
  totalCount,
  labels,
  activeTab,
  memoizedSettings,
  reFetch,
}) => {
  const [filterString, setFilterString] = useState([]);
  const [allProviders] = useCRUDWithoutTab({ id: 'doctor', type: 'read' });
  const [allLocations] = useCRUDWithoutTab({ id: 'location', type: 'read' });

  const [filterData, setFiltersData] = useState({
    providerName: '',
    locationName: '',
    examName: '',
    InsurancePayerIds: [],
    InsurancePolicyTypeIds: [],
  });

  useEffect(() => {
    if (filters) {
      const filterStr = [];
      filterStr.push((<span className="text-bold">{filters?.providerId ? ` ${filterData.providerName}` : ' All Providers'}</span>));
      filterStr.push(<span>,</span>);
      filterStr.push((<span className="text-bold">{filters?.locationId ? ` ${filterData.locationName}` : ' All Locations'}</span>));
      filterStr.push(<span>,</span>);
      filterStr.push((<span className="text-bold">{ filters?.examTypeId ? ` ${filterData.examName}` : ' All Visit Types'}</span>));
      if (filters?.dateOfServiceFrom && filters?.dateOfServiceTo) {
        filterStr.push(<span>,</span>);
        filterStr.push((
          <span>
            {' '}
            From
            {' '}
            <span className="text-bold">{filters.dateOfServiceFrom}</span>
            {' '}
            To
            {' '}
            <span className="text-bold">{filters.dateOfServiceTo}</span>
          </span>));
      } else if (filters?.dateOfServiceFrom) {
        filterStr.push(<span>,</span>);
        filterStr.push((
          <span>
            From
            {' '}
            <span className="text-bold">
              {' '}
              {filters.dateOfServiceFrom}
            </span>
          </span>));
      } else if (filters.dateOfServiceTo) {
        filterStr.push(<span>,</span>);
        filterStr.push((
          <span>
            Till $
            <span className="text-bold">
              {' '}
              {filters.dateOfServiceTo}
            </span>
          </span>));
      }
      setFilterString(filterStr);
    }
  }, [filters]);

  const providerFilterHandler = useCallback((
    providerName, providerId, onFilterChange,
  ) => {
    const localStorageData = JSON.parse(localStorage.getDecryptedData('userSettingsData')) || {};
    const { appliedFilters = {} } = localStorageData;
    appliedFilters.doctor = providerId;
    localStorageData.appliedFilters = appliedFilters;
    localStorage.setEncryptedData('userSettingsData', JSON.stringify(localStorageData));
    onFilterChange({ target: { name: 'providerId', value: providerId } });
    setFiltersData({
      ...filterData,
      providerName,
    });
  }, [filterData]);

  const locationFilterHandler = useCallback((
    locationName, locationId, onFilterChange,
  ) => {
    const localStorageData = JSON.parse(localStorage.getDecryptedData('userSettingsData')) || {};
    const { appliedFilters = {} } = localStorageData;
    appliedFilters.location = locationId;
    localStorageData.appliedFilters = appliedFilters;
    localStorage.setEncryptedData('userSettingsData', JSON.stringify(localStorageData));
    onFilterChange({ target: { name: 'locationId', value: locationId } });
    setFiltersData({
      ...filterData,
      locationName,
    });
  }, [filterData]);

  const examFilterHandler = useCallback((
    examName, examTypeId, onFilterChange,
  ) => {
    onFilterChange({ target: { name: 'examTypeId', value: examTypeId } });
    setFiltersData({
      ...filterData,
      examName,
    });
  }, [filterData]);

  const payerFilterHandler = useCallback((payerName, payerId, onFilterChange) => {
    onFilterChange({ InsurancePayerIds: payerId?.join(',') });
    setFiltersData({
      ...filterData,
      payerName: payerId,
    });
  }, [filterData]);

  const policyFilterHandler = useCallback((policyName, policyId, onFilterChange) => {
    onFilterChange({ InsurancePolicyTypeIds: policyId?.join(',') });
    setFiltersData({
      ...filterData,
      policyName: policyId,
    });
  }, [filterData]);

  const setProvider = useCallback((doctorId) => {
    const doctorDetail = find(allProviders, ({ providerId }) => providerId === doctorId);
    if (doctorDetail) {
      providerFilterHandler(doctorDetail?.providerName,
        doctorDetail?.providerId, onChangeFilter);
    }
  }, [allProviders, onChangeFilter, providerFilterHandler]);

  const setLocation = useCallback((id) => {
    const locationDetail = find(allLocations,
      ({ locationId }) => locationId === id);
    locationFilterHandler(locationDetail?.locationName,
      locationDetail?.locationId, onChangeFilter);
  }, [allLocations, locationFilterHandler, onChangeFilter]);

  const fromDateHandler = useCallback((
    dateOfServiceFrom, onFilterChange,
  ) => {
    if (dateOfServiceFrom) {
      onFilterChange({
        target: {
          name: 'dateOfServiceFrom',
          value: dateFormatter(dateOfServiceFrom, dateFormatStartsWithYearSeconds),
        },
      });
    } else {
      onFilterChange({ target: { name: 'dateOfServiceFrom', value: null } });
    }
  }, [filterData]);

  const toDateHandler = useCallback((
    dateOfServiceTo, onFilterChange,
  ) => {
    if (dateOfServiceTo) {
      onFilterChange({
        target: {
          name: 'dateOfServiceTo',
          value: dateFormatter(dateOfServiceTo, dateFormatStartsWithYearSeconds),
        },
      });
    } else {
      onFilterChange({ target: { name: 'dateOfServiceTo', value: null } });
    }
  }, [filterData]);

  const currentTab = useMemo(() => {
    if (activeTab === 'All') return '';
    return activeTab;
  }, [activeTab]);

  const handleCheckboxClick = useCallback(({ target: { checked } }) => {
    onChangeFilter({
      target: {
        name: 'IsCheckout',
        value: checked,
      },
    });
  }, [onChangeFilter]);

  useEffect(() => {
    if (!allLocations || !allProviders) return;

    const localStorageData = JSON.parse(localStorage.getDecryptedData('userSettingsData')) || {};
    let { doctor, location } = localStorageData?.current || {};
    const { appliedFilters } = localStorageData;

    if (appliedFilters) {
      doctor = appliedFilters?.doctor;
      location = appliedFilters?.location;
    }

    if (!doctor && !location) {
      reFetch();
      return;
    }

    if (localStorageData) {
      const shouldProcessFilters = (!appliedFilters && doctor && location) || appliedFilters;

      if (shouldProcessFilters) {
        if (Number.isInteger(doctor) && Number.isInteger(location)) {
          const doctorDetail = find(allProviders,
            ({ providerId: id }) => id === doctor) || {};
          const locationDetail = find(allLocations,
            ({ locationId: id }) => id === location) || {};

          const { providerName, providerId } = doctorDetail;
          const { locationName, locationId } = locationDetail;

          if (providerId) {
            const appliedFiltersFromLocalStorage = {
              ...localStorageData.appliedFilters,
              doctor: providerId,
              location: locationId,
            };

            localStorageData.appliedFilters = appliedFiltersFromLocalStorage;
            localStorage.setEncryptedData('userSettingsData', JSON.stringify(localStorageData));

            onChangeFilter({ providerId, locationId });
            setFiltersData({
              ...filterData,
              providerName,
              locationName,
            });
          }
        } else {
          if (doctor && Number.isInteger(doctor)) {
            setProvider(doctor);
          }
          if (location && Number.isInteger(location)) {
            setLocation(location);
          }
        }
      }
    }
  }, [memoizedSettings, allProviders, allLocations]);

  return (
    <>
      <div className="table-filters filters-billing-encounter mr-top-8">
        <div className="flex flex-filter">
          <span className="m-w-100">
            <WiredSelect
              id="doctor"
              name={null}
              url={apiUrls.GET_PROVIDER_WITH_SUGGESTIVE_SEARCH}
              placeholder="Search Provider"
              nameAccessor="providerName"
              valueAccessor="providerId"
              Component={SelectBox}
              selectProps={{
                style: { width: 150 },
                showSearch: true,
              }}
              onChange={(value, item) => (
                providerFilterHandler(item?.children, value, onChangeFilter)
              )}
              labelSpan="0"
              inputSpan="16"
              value={filters?.providerId}
              dataTestId="provider-name"
            />
          </span>
          <span className="mr-lt-10">
            <WiredSelect
              id="location"
              name={null}
              url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
              placeholder="Search Location"
              nameAccessor="locationName"
              valueAccessor="locationId"
              Component={SelectBox}
              selectProps={{
                style: { width: 150 },
                showSearch: true,
              }}
              onChange={(value, item) => (
                locationFilterHandler(item?.children, value, onChangeFilter)
              )}
              labelSpan="0"
              inputSpan="16"
              value={filters?.locationId}
              dataTestId="location-name"
            />
          </span>
          <span className="mr-lt-10">
            <WiredSelect
              id="examType"
              name={null}
              url={apiUrls.GET_VISIT_TYPES}
              nameAccessor="visitName"
              valueAccessor="visitTypeId"
              labelSpan="0"
              inputSpan="16"
              selectProps={{
                style: { minWidth: 150 },
                showArrow: true,
              }}
              params={{
                SortBy: 'visitName',
                SortOrder: 'asc',
              }}
              onChange={(value, item) => (
                examFilterHandler(item?.children, value, onChangeFilter)
              )}
              placeholder="Select Visit Type"
              Component={SelectBox}
              value={filters?.examTypeId}
              dataTestId="exam-type"
              isStatCaseOptions={false}
            />
          </span>
          <span className="mr-lt-10">
            <EnumSelect
              id="InsurancePolicyTypeIds"
              name={null}
              inputSpan="16"
              labelSpan="0"
              placeholder="Select Policy Type"
              enumName={enums.POLICY_TYPE}
              mode="multiple"
              isStatCaseOptions={false}
              selectProps={{
                style: { minWidth: 150 },
                showArrow: true,
                maxTagCount: 'responsive',
              }}
              onChange={(value) => (
                policyFilterHandler('InsurancePolicyTypeIds', value, onChangeFilter)
              )}
              value={filters?.InsurancePolicyTypeIds ? filters?.InsurancePolicyTypeIds?.split(',')?.map((id) => parseInt(id, 10)) : undefined}
            />
          </span>
          <span className="mr-lt-10">
            <WiredSelect
              id="insurancePayerIds"
              name={null}
              url={apiUrls.CONTRACT_PAYER_DROPDOWN}
              nameAccessor="payerName"
              valueAccessor="payerId"
              labelSpan="0"
              inputSpan="16"
              selectProps={{
                style: { minWidth: 150 },
                showSearch: true,
                mode: 'multiple',
                maxTagCount: 'responsive',
              }}
              onChange={(value) => (
                payerFilterHandler('InsurancePayerIds', value, onChangeFilter)
              )}
              placeholder="Select Payer"
              Component={SelectBox}
              value={filters?.InsurancePayerIds ? filters?.InsurancePayerIds?.split(',') : undefined}
              dataTestId="payer"
              isStatCaseOptions={false}
              valueInString
            />
          </span>
          <span className="mr-lt-10">
            <DatePicker
              labelSpan="0"
              inputSpan="24"
              name={null}
              placeholder="From DOS"
              onChange={(fromDate) => fromDateHandler(fromDate, onChangeFilter)}
              value={filters?.dateOfServiceFrom && moment(filters?.dateOfServiceFrom)}
              dataTestId="From DOS"
            />
          </span>
          <span className="mr-lt-10">
            <DatePicker
              labelSpan="0"
              inputSpan="24"
              name={null}
              placeholder="To DOS"
              onChange={(toDate) => toDateHandler(toDate, onChangeFilter)}
              value={filters?.dateOfServiceTo && moment(filters?.dateOfServiceTo)}
              dataTestId="To DOS"
            />
          </span>
          <span className="mr-lt-10 margin mr-bottom-8 flex align-center">
            {' '}
            <Checkbox
              name={null}
              label={labels.get('labels.onlyCheckedOutAppointments')}
              labelSpan={10}
              inputSpan={12}
              onChange={handleCheckboxClick}
              value={filters?.IsCheckout}
            />
          </span>
          <span className="mr-lt-10 mr-bottom-8 flex align-center">
            <div
              role="presentation"
              onClick={() => onChangeFilter({
                EncounterStatus: filters?.EncounterStatus,
                SearchText: filters?.SearchText,
              }, true)}
              className="clr-btn"
            >
              {labels.get('showAll')}
            </div>
          </span>
        </div>
      </div>
      <div className="notify-test-container">
        <div className="notify-text">
          <span>
            Showing
            {' '}
            <span className="text-bold">{totalCount}</span>
            {' '}
            {tabsFilter[currentTab]}
            {' '}
            Encounter
            {totalCount > 1 ? 's' : ''}
            {' '}
            for Acuity Eye Group:
            <span>{filterString}</span>
          </span>
        </div>
      </div>
    </>
  );
};

export default BillingEncounterFilter;
