/* eslint-disable max-len */
import React from 'react';

import iconNames from '../../../../../../lib/iconNames';
import { formatAmount } from '../../../../../../lib/util';
import Icon from '../../../../../../components/Icon';

import ErrorPopOver from '../../Component/ErrorPopOver';

const columns = (labels, tabName) => [
  {
    Header: '',
    accessor: 'icon',
    Footer: ({ totalData }) => {
      if (!totalData?.length) return null;
      return (
        <span style={{ fontWeight: 'bolder', color: '#2a4a79' }}>
          Total:
          {totalData?.length}
          {' '}
          Items
        </span>
      );
    },
    Cell: ({ row: { original: { amount, errors, description } } }) => {
      if (description === 'Remitted Patient Payment ') {
        return null;
      }
      if (tabName?.toLowerCase() === 'errors') {
        let skippedDuplicate = null;
        let skippedAdjustment = null;
        let skipped = false;
        let hasOtherErrors = false;
        let showRedDollar = false;
        if (tabName?.toLowerCase() === 'errors' && errors) {
          errors.forEach((error) => {
            if (error.status === 'SD' && error.status === 'SA') {
              skippedAdjustment = <p style={{ color: 'green', marginBottom: '0%', marginTop: '-0.65%' }}>A</p>;
              skippedDuplicate = <p style={{ color: 'green', marginBottom: '0%', marginTop: '-0.65%' }}>D</p>;
              skipped = true;
            }
            if (error.status === 'SA') {
              skippedAdjustment = <p style={{ color: 'green', marginBottom: '0%', marginTop: '-0.65%' }}>A</p>;
              skipped = true;
            }
            if (error.status === 'SD') {
              skippedDuplicate = <p style={{ color: 'green', marginBottom: '0%', marginTop: '-0.65%' }}>D</p>;
              skipped = true;
            }
            if (error.errorCode !== 'Error8516' && error.errorCode !== 'Error8035') {
              hasOtherErrors = true;
            }
            if (!error.isSkipped && error.status === 'UD') {
              skipped = false;
              showRedDollar = true;
            }
            if (!error.isSkipped && error.status === 'UA') {
              skipped = false;
              showRedDollar = true;
            }
            if (error.status === 'E') {
              showRedDollar = true;
            }
          });
        }
        return (

          <div style={{ display: 'flex', gap: '8%' }}>
            {hasOtherErrors && (
            <ErrorPopOver errorList={errors} iconName={iconNames.dollar} trigger={['hover']} color="red" />
            )}
            {!hasOtherErrors && skipped && !showRedDollar && (
            <ErrorPopOver errorList={errors} iconName={iconNames.dollar} trigger={['hover']} color="green" />
            )}
            {!hasOtherErrors && !skipped && (
            <ErrorPopOver errorList={errors} iconName={iconNames.dollar} trigger={['hover']} color="red" />
            )}
            {!hasOtherErrors && skipped && showRedDollar && (
            <ErrorPopOver errorList={errors} iconName={iconNames.dollar} trigger={['hover']} color="red" />
            )}
            {skippedAdjustment}
            {skippedDuplicate}
          </div>
        );
      }
      const parsedAmount = parseInt(amount, 10);
      let color = 'green';
      if (parsedAmount === 0) {
        color = 'grey';
      }
      if (parsedAmount <= 0) {
        color = 'red';
      }
      return <Icon name={iconNames.dollar} color={color} />;
    },
    fixWidth: '80',
  },
  {
    Header: labels.get('columns.patient'),
    accessor: 'patientName',
    Cell: ({ row: { original: { patientName, errors } } }) => {
      if (tabName?.toLowerCase() === 'errors') {
        const isUnexpectedError = errors?.some(({ errorCode } = {}) => errorCode === 'UnexpectedError_RemittanceBatch' || errorCode === 'UnexpectedError_DupChargesMapped' || errorCode === 'UnexpectedError_ChargesUnmapped');
        return <span>{`${isUnexpectedError ? 'Unexpected - ' : ''}${patientName}`}</span>;
      }
      return <span>{patientName}</span>;
    },
    fixWidth: '120',
  },
  {
    Header: labels.get('columns.page'),
    accessor: 'pageNumber',
    fixWidth: '80',
    className: 'text-align-right',
  },
  {
    Header: labels.get('columns.claimNumber'),
    accessor: 'claimNo',
    Cell: ({ row: { original: { claimNo } } }) => (
      claimNo && (claimNo > 0 || typeof claimNo === 'string') && claimNo
    ),
    fixWidth: '80',
    className: 'text-align-right',
  },
  {
    Header: labels.get('columns.amount'),
    accessor: 'amount',
    className: 'text-align-right',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    fixWidth: 80,
    Footer: ({ totalData = [] }) => {
      let finalAmount = 0;
      totalData.forEach(({ amount, worklistId }) => {
        if (amount && !worklistId) finalAmount += parseFloat(amount || 0);
      });
      if (!finalAmount) return null;
      return (
        <div style={{ fontWeight: 'bolder', color: '#2a4a79', textAlign: 'end' }}>
          {formatAmount(finalAmount)}
        </div>
      );
    },
  },
  {
    Header: labels.get('columns.paid'),
    accessor: 'applied',
    Cell: ({ row: { original: { applied, balance, worklistId } } }) => (
      worklistId > 0 ? (balance || 0).toFixed(2) : (applied || 0).toFixed(2)
    ),
    fixWidth: 80,
    Footer: ({ data = [] }) => {
      const finalAmount = data?.[0]?.totalPosted;
      if (!finalAmount) return null;
      return (
        <div style={{ fontWeight: 'bolder', color: '#2a4a79', textAlign: 'end' }}>
          {formatAmount(finalAmount)}
        </div>
      );
    },
    className: 'text-align-right',
  },
  {
    Header: labels.get('columns.adjusted'),
    accessor: 'adjustment',
    Cell: ({ row: { original: { adjustment } } }) => (
      (adjustment || 0).toFixed(2)
    ),
    fixWidth: 80,
    Footer: ({ totalData = [] }) => {
      let finalAmount = 0;
      totalData.forEach(({ adjustment }) => {
        if (adjustment) finalAmount += parseFloat(adjustment || 0);
      });
      if (!finalAmount) return null;
      return (
        <div style={{ fontWeight: 'bolder', color: '#2a4a79', textAlign: 'end' }}>
          {formatAmount(finalAmount)}
        </div>
      );
    },
    className: 'text-align-right',
  },
  {
    Header: labels.get('columns.interest'),
    accessor: 'interest',
    Cell: ({ row: { original: { interest } } }) => (
      (interest || 0).toFixed(2)
    ),
    fixWidth: 80,
    Footer: ({ totalData = [] }) => {
      let finalAmount = 0;
      totalData.forEach(({ interest }) => {
        if (interest) finalAmount += parseFloat(interest || 0);
      });
      if (!finalAmount) return null;
      return (
        <div style={{ fontWeight: 'bolder', color: '#2a4a79', textAlign: 'end' }}>
          {formatAmount(finalAmount)}
        </div>
      );
    },
    className: 'text-align-right',
  },
];

export default columns;
