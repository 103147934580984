
import { Skeleton } from 'antd';
import React from 'react';
import './table.scss';

const SkeletonLoader = ({ headerGroups }) => (
  Array.from({ length: 20 }).map((_, rowIndexx) => (
    <tr key={rowIndexx} className="custom-s-loader">
      {Array.from({ length: headerGroups[0].headers.length }).map((__, rowIndex) => (
        <td key={rowIndex}>
          <Skeleton
            loading
            active
            paragraph={{ rows: 0 }}
            title={{ width: '80%' }}
            className="mr-left-5"
            size="small"
          />
        </td>

      ))}
    </tr>
  ))
);

export default SkeletonLoader;
