/* eslint-disable max-len */
import React, {
  forwardRef,
  useCallback, useEffect, useImperativeHandle, useMemo, useState,
} from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import classNames from 'classnames';

import { isEmpty } from 'lodash';
import { getEnumMaster, getEnumOptions } from '../../../../../../store/selectors';

import { apiUrls } from '../../../../../../api/constants';
import { enums, listIds } from '../../../../../../lib/constants';

import useCRUD from '../../../../../../hooks/useCRUD';
import useRedirect from '../../../../../../hooks/useRedirect';

import Input from '../../../../../../components/Form/Input';
import WidgetLoader from '../../../../../../components/WidgetLoader';
import Notification from '../../../../../../components/Notification';
import Button from '../../../../../../components/Button';

import ActionIcons from '../../../../../../wiredComponents/ActionIcons';

import DenialReasonComponent from './DenialReasonComponent';
import useReduxState from '../../../../../../hooks/useReduxState';
import dateFormatter from '../../../../../../lib/dateFormatter';
import Tooltip from '../../../../../../components/Tooltip';
import useCRUDWithoutTab from '../../../../../../hooks/useCRUDWithoutTab';
import './lineItemsTable.scss';
import CheckBox from '../../../../../../components/Form/CheckBox';

const isNegativeValueUptoTwoDecimalPlaces = (value, pattern = /^-?\d*\.?\d{0,2}$/) => {
  if (!value || value === '-') return true;
  return pattern.test(value);
};

const getAdjustAmount = (data) => parseFloat(Number(
  ((parseFloat(data?.amount || 0) || 0)
   - (parseFloat(data?.value || 0) || 0)),
)).toFixed(2);

const getPaidAmount = (data) => parseFloat(Number(
  (parseFloat(data?.approved || 0) || 0) - (parseFloat(data?.coIns || data?.coInsurance || 0) || 0)
  - (parseFloat(data?.coPay || 0) || 0)
   - (parseFloat(data?.deduct || data?.deductible || 0) || 0)
    - (parseFloat(data?.withHold || 0) || 0),
)).toFixed(2);

const getNewBalanceAmount = (data) => parseFloat(Number(
  (parseFloat(data?.value || 0) || 0) - (parseFloat(data?.approved || 0) || 0),
)).toFixed(2);

const getPatientBalance = (data) => parseFloat(Number(
  (parseFloat(data?.coIns || data?.coInsurance || 0) || 0)
+ (parseFloat(data?.coPay || 0) || 0)
 + (parseFloat(data?.deduct || data?.deductible || 0) || 0),
)).toFixed(2);

const getInsuranceBalance = (data) => parseFloat(Number(
  (parseFloat((data?.financialId ? data?.chargeReversedBalance : data?.balance) || 0) || 0)
  - ((parseFloat(data?.coIns || data?.coInsurance || 0) || 0)
+ (parseFloat(data?.coPay || 0) || 0)
 + (parseFloat(data?.deduct || data?.deductible || 0) || 0)
 + (parseFloat(data?.withHold || 0) || 0)
 + (parseFloat(data?.adjust || data?.adjustment || 0) || 0)
 + (parseFloat(data?.paid || 0)) || 0),
)).toFixed(2);

const getRemittanceInsuranceBalance = (data) => parseFloat(
  Number((parseFloat(data?.trStatus?.toLowerCase() === 'd' ? data?.balance : data?.chargeReversedBalance || 0) || 0)
 - ((parseFloat(data?.coIns || data?.coInsurance || 0) || 0)
 + (parseFloat(data?.coPay || 0) || 0)
  + (parseFloat(data?.deduct || data?.deductible || 0) || 0)
  + (parseFloat(data?.withHold || 0) || 0)
  + (parseFloat(data?.adjust || data?.adjustment || 0) || 0)
  + (parseFloat(data?.paid || 0)) || 0)),
).toFixed(2);

const secondaryPayerInsuranceBalance = (data) => {
  let balance = 0;
  if (data?.isRemittancePayment && data?.trStatus?.toLowerCase() !== 'd') {
    balance = data?.chargeReversedBalance;
  } else if (data?.financialId) {
    balance = data?.chargeReversedBalance;
  } else {
    balance = data?.balance;
  }
  return (parseFloat(balance || 0)
   - (parseFloat(
     (parseFloat(data?.withHold || 0) || 0) + (parseFloat(data?.paid || 0) || 0)
   + (parseFloat(data?.adjust || data?.adjustment || 0) || 0),
   )) || 0).toFixed(2);
};

const getProcedureWithDescription = (cptCode, description) => {
  const cptCodeWithDescription = [];
  if (cptCode) cptCodeWithDescription.push(cptCode);
  if (description) cptCodeWithDescription.push(description);
  return cptCodeWithDescription.join(' ');
};

const getChangedAdjustedAmount = (name, val, currentLineItemData) => {
  if (name === 'adjust') {
    return val;
  }
  return currentLineItemData?.adjust;
};

const getChangedPaidAmount = (name, val, currentLineItemData) => {
  let paidAmount = 0;
  if (name === 'paid') {
    paidAmount = val;
  } else {
    paidAmount = getPaidAmount(currentLineItemData);
  }
  return paidAmount;
};

const getApprovedAmount = (data, isLineItemEdiited, isRemittancePayment) => {
  if (isRemittancePayment && !data?.isAlreadyApplied && data?.denialReasonId) {
    return 0;
  }
  const isAmountNotApplied = parseFloat((data?.approved), 10) === 0 && !data?.denialReasonId;
  return (!isLineItemEdiited || isAmountNotApplied)
    ? undefined : (parseFloat(data?.approved || 0) || 0).toFixed(2);
};

const getChargeReversedBalance = (secondaryPayer, data) => {
  if (!secondaryPayer) {
    return parseFloat(
      Number((parseFloat(data?.balance || 0) || 0)
      + ((parseFloat(data?.coIns || data?.coInsurance || 0) || 0)
      + (parseFloat(data?.coPay || 0) || 0)
      + (parseFloat(data?.deduct || data?.deductible || 0) || 0)
      + (parseFloat(data?.withHold || 0) || 0)
      + (parseFloat(data?.adjust || data?.adjustment || 0) || 0)
      + (parseFloat(data?.paid || 0)) || 0)),
    ).toFixed(2);
  }
  return parseFloat(
    Number((parseFloat(data?.balance || 0) || 0)
    + ((parseFloat(data?.withHold || 0) || 0)
    + (parseFloat(data?.adjust || data?.adjustment || 0) || 0)
    + (parseFloat(data?.paid || 0)) || 0)),
  ).toFixed(2);
};

const getChargeInsurancePatientBalance = (
  secondaryPayer, item, isRemittancePayment, paidAmount, adjustedAmount, financialId,
) => {
  let insuranceBalance = 0;
  let patientBalance = 0;
  const trStatus = item?.trStatus?.toLowerCase();
  const chargeReversedBalance = getChargeReversedBalance(secondaryPayer, item);

  if (item?.denialReasonId > 0 && parseFloat((item?.approved) || 0, 10) === 0) {
    insuranceBalance = 0;
    patientBalance = 0;
  } else if (financialId) {
    insuranceBalance = item?.insuranceBalance || item?.balance || 0;
    patientBalance = !secondaryPayer ? getPatientBalance(item) : parseFloat(0).toFixed(2);
  } else if (!secondaryPayer) {
    if (trStatus === 'd') {
      insuranceBalance = parseFloat(
        Number((parseFloat(item?.balance || 0))
       - ((parseFloat(item?.coIns || item?.coInsurance || 0))
       + (parseFloat(item?.coPay || 0))
        + (parseFloat(item?.deduct || item?.deductible || 0))
        + (parseFloat(item?.withHold || 0))
        + (parseFloat(adjustedAmount || 0))
        + (parseFloat(paidAmount || 0)))),
      ).toFixed(2);
    } else {
      insuranceBalance = item?.insuranceBalance || item?.balance || 0;
    }
    patientBalance = getPatientBalance(item);
  } else {
    insuranceBalance = (parseFloat(isRemittancePayment && trStatus !== 'd'
      ? chargeReversedBalance : item?.balance) || 0) - (
      ((parseFloat(paidAmount) || 0) + (parseFloat(item?.withHold || 0) || 0)
      + (parseFloat(adjustedAmount) || 0)));
    patientBalance = parseFloat(0).toFixed(2);
  }
  return { insuranceBalance, patientBalance, chargeReversedBalance };
};

const getUpdatedLineItemAmountDetails = (
  name, val, currentLineItemData, isRemittancePayment,
  financialId, secondaryPayer,
) => {
  if (parseFloat((val), 10) === 0 && name === 'approved') {
    return {
      approved: val,
      coIns: parseFloat(0).toFixed(2),
      coPay: parseFloat(0).toFixed(2),
      deduct: parseFloat(0).toFixed(2),
      withHold: parseFloat(0).toFixed(2),
      adjust: parseFloat(0).toFixed(2),
      paid: parseFloat(0).toFixed(2),
      insuranceBalance: parseFloat(0).toFixed(2),
      patientBalance: parseFloat(0).toFixed(2),
      interest: parseFloat(0).toFixed(2),
    };
  }
  const adjustedAmount = getChangedAdjustedAmount(
    name, val, currentLineItemData,
  );
  const paidAmount = getChangedPaidAmount(name, val, currentLineItemData);
  const updatedLineItemData = {
    ...currentLineItemData, adjust: adjustedAmount, paid: paidAmount,
  };
  const insuranceBalanceCalculation = isRemittancePayment
    ? getRemittanceInsuranceBalance({ ...updatedLineItemData })
    : getInsuranceBalance({ ...updatedLineItemData, financialId });

  return {
    paid: paidAmount,
    adjust: adjustedAmount,
    newBalance: getNewBalanceAmount(currentLineItemData),
    patientBalance: !secondaryPayer
      ? getPatientBalance(currentLineItemData) : parseFloat(0).toFixed(2),
    insuranceBalance: !secondaryPayer ? insuranceBalanceCalculation
      : secondaryPayerInsuranceBalance({
        ...updatedLineItemData, isRemittancePayment, financialId,
      }),
  };
};

const LineItemsTable = forwardRef(({
  labels,
  onCancel,
  onPost,
  onDataChange,
  claimId: propsClaimId,
  form,
  enumMaster,
  enumOptions,
  container,
  isRemittancePayment,
  remittanceId,
  showActionIcons,
  autoSaveForm,
  isEditWorkListLineItems,
  isEditWorkList,
  worklistId,
  billingEncounterId,
  selectedPatientId,
  nextPayerClaimId,
  setNextPayerResponsibility,
  remittancePaymentIdentifierForWorklist,
  setPayerData,
  notFilterServiceLineItems,
  patientIdForEditTransaction,
  doNotFetchLineItemsData,
}, ref) => {
  const {
    params: {
      claimId, claimFilter, id: patientId, financialId, remittancePaymentId, editTransactionPatientId,
      encounterId = 'encounterId', tabId, identifier, isRemittanceAddPayment, selectedClaimData,
    }, path,
  } = useRedirect();
  const formId = path.indexOf('new') > -1 ? tabId : remittancePaymentId;
  const [lineItemData, setLineItemData] = useReduxState(`insurance-payment-lineItemData-${claimId || propsClaimId}-${patientId}-${financialId}-${formId}-${encounterId}-${tabId}`, []);
  const [isAllClaimsPosted, setIsAllClaimsPosted] = useReduxState(`insurance-payment-isAllClaimsPosted-${claimId}-${patientId}-${financialId}-${formId}-${encounterId}`, false);
  const [updatedLineItemsList, setUpdatedLineItemsList] = useReduxState(`insurance-payment-updatedLineItemsList-${claimId}-${patientId}-${financialId}-${formId}-${encounterId}-${tabId}`, []);
  const [previousAdjustment, setPreviousAdjustment] = useReduxState(`insurance-payment-previous-adjustment-${claimId}-${patientId}-${financialId}-${formId}-${encounterId}-${tabId}`, {});
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  useImperativeHandle(ref, () => ({
    clearLineItemData() {
      setLineItemData([]);
      setUpdatedLineItemsList([]);
    },
  }), [setLineItemData, setUpdatedLineItemsList]);

  const [
    claimDetails,,,
    getClaimDetails,
  ] = useCRUD({
    id: `${listIds.CLAIM_DETAILS}-${claimId}`,
    url: apiUrls.GET_CLAIM_DETAILS,
    type: 'read',
  });

  const [patientInfo, , patientInfoLoading, getPatientInfo] = useCRUD({ id: 'basic-info-demographic', url: apiUrls.GET_PATIENT, type: 'read' });

  const [
    serviceLineItemsData,,
    lineItemsLoading,
    getServiceLineItems,
    clearServiceLineItems,
  ] = useCRUDWithoutTab({
    id: `${listIds.CLAIM_DETAILS}-insurance-payment-${financialId ?? ''}`,
    url: isRemittancePayment
      ? apiUrls.GET_REMITTANCE_INSURANCE_CLAIMS_CHARGES : apiUrls.FINANCIAL_LINE_ITEMS,
    type: 'read',
  });

  /*
    * serviceLineItemsData response format varies on whether its an remittance payment or not.
  */

  const serviceLineItemsResponse = useMemo(() => {
    if (serviceLineItemsData && isRemittancePayment) {
      return [...serviceLineItemsData];
    }
    if (serviceLineItemsData && !isRemittancePayment) { return serviceLineItemsData?.result || []; }
    return [];
  }, [isRemittancePayment, serviceLineItemsData]);

  const [
    patientInsuranceResponse,,
    patientInsuranceLoading,
    getPatientInsurance,
  ] = useCRUD({
    id: `Line-Item-Patient-Insurance-${patientId}`,
    url: apiUrls.GET_PATIENT_INSURANCE,
    type: 'read',
  });

  const { secondaryPayer, isInsuranceOtherThanPrimary } = useMemo(() => {
    /*
    * In remittance we do not hit api call for claimDetails so check is changed to this
    * Backend's response for serviceLineItems was changed. Data is now coming
    in array format instead of object.
    */
    if (patientInsuranceResponse && (serviceLineItemsResponse || claimDetails)) {
      const claimInsuranceProfileId = claimDetails?.billingEncounterData?.serviceLineItems?.[0]
     || serviceLineItemsResponse?.[0];
      const selectedInsurance = patientInsuranceResponse?.find(
        (item) => claimInsuranceProfileId?.insuranceProfileId === item?.insuranceProfileId);
      return {
        secondaryPayer: !!selectedInsurance?.profileDetails?.filter(
           (item) => item?.profileTypeId > claimInsuranceProfileId?.insuranceProfileTypeId).length,
        isInsuranceOtherThanPrimary: claimInsuranceProfileId?.insuranceProfileTypeName?.toLowerCase() !== 'primary',
      };
    }
    return { secondaryPayer: false, isInsuranceOtherThanPrimary: false };
  }, [claimDetails, patientInsuranceResponse, serviceLineItemsResponse]);

  const denialCodes = useMemo(() => {
    const enumId = get(enumMaster, `${enums.DENIAL_CODES}.enumId`);
    const denialData = {};
    if (enumId && enumOptions?.[enumId]?.data?.length) {
      enumOptions[enumId].data.forEach((data) => {
        denialData[data?.masterId] = data?.masterDescription;
      });
    }
    return { ...denialData };
  }, [enumMaster, enumOptions]);

  useEffect(() => {
    if (!doNotFetchLineItemsData) {
      setUpdatedLineItemsList([]);
    }
  }, []);

  useEffect(() => {
    if ((patientId || selectedPatientId) || ((claimId || propsClaimId) && !isRemittancePayment)) {
      getPatientInsurance({
        PatientId: patientId || selectedPatientId,
        IncludeInActive: false,
        claimId: claimId || propsClaimId,
      });
    }
    if (patientId) {
      getPatientInfo({}, `/${patientId}`);
    }
  }, [patientId]);

  useEffect(() => () => {
    clearServiceLineItems(true);
  }, []);

  useEffect(() => {
    if (patientId && !isRemittancePayment && !(doNotFetchLineItemsData && lineItemData?.length)) {
      getServiceLineItems({
        patientId,
        IsPatientResponsibility: false,
        IsCharge: true,
        claimId: claimId || nextPayerClaimId,
        ShowFinanceDetailsFromTransaction: !!financialId,
        FinancePaymentId: financialId,
        remittancePaymentIdentifier: identifier || remittancePaymentIdentifierForWorklist,
        isRemittanceAddPayment,
      });
    }
  }, [patientId, isRemittancePayment]);

  useEffect(() => {
    if (doNotFetchLineItemsData && lineItemData?.length && onDataChange) {
      const financeParsedItems = financialId
        ? lineItemData?.map((item) => ({ ...item, isEdited: true }))
        : lineItemData;
      onDataChange(financeParsedItems);
    }
  }, []);

  useEffect(() => {
    if ((claimId || propsClaimId) && isRemittancePayment && !(doNotFetchLineItemsData && lineItemData?.length)) {
      getServiceLineItems({
        claimId: claimId || propsClaimId,
        paymentId: remittancePaymentId,
        worklistId,
        patientId: patientId || selectedPatientId,
        billingEncounterId: billingEncounterId || encounterId,
        remittancePaymentIdentifier: identifier || remittancePaymentIdentifierForWorklist,
        isRemittanceAddPayment,
        remittanceBatchId: remittanceId,
      });
    }
  }, [isRemittancePayment, claimId, propsClaimId]);

  useEffect(() => {
    if ((claimId || propsClaimId) && !isRemittancePayment) {
      getClaimDetails({}, `/${claimId || propsClaimId}`);
    }
  }, [claimId, propsClaimId, isRemittancePayment]);

  const isEditLineItem = useCallback((item) => (
    financialId || (isRemittancePayment && item?.isAlreadyApplied)
  ), [financialId, isRemittancePayment]);

  const newAdjustedAmount = useCallback((isEdit, item, hasInsuranceOtherThanPrimary) => {
    const isAdjustmentOtherThanPrimaryInFinancial = (
      !financialId && !isRemittancePayment && hasInsuranceOtherThanPrimary
    );
    const isAdjustmentOtherThanPrimaryInRemittance = (
      isRemittancePayment && hasInsuranceOtherThanPrimary
    );
    const isPrimaryClaimHasPartialPaymentInFinancial = (
      !financialId && !isRemittancePayment
      && !hasInsuranceOtherThanPrimary && item?.isChargeHasPartialPayments
    );
    const isPrimaryClaimHasPartialPaymentInRemittance = (
      isRemittancePayment && !hasInsuranceOtherThanPrimary && item?.isChargeHasPartialPayments
    );
    if (isAdjustmentOtherThanPrimaryInFinancial || isPrimaryClaimHasPartialPaymentInFinancial) {
      return parseFloat(0).toFixed(2);
    }
    if (isAdjustmentOtherThanPrimaryInRemittance || isPrimaryClaimHasPartialPaymentInRemittance) {
      return item?.adjustment;
    }
    return !isEdit ? getAdjustAmount(item) : item?.adjustment;
  }, [financialId, isRemittancePayment]);

  const isDenialItemEdited = useCallback((item) => (
    financialId || (isRemittancePayment && (item?.isAlreadyApplied || item?.denialReasonId > 0))
  ), [financialId, isRemittancePayment]);

  const isWorkListEditLineItem = useCallback((item) => (
    (isRemittancePayment && (item?.workListId === worklistId || item?.denialReasonId > 0))
  ), [isRemittancePayment, worklistId]);

  useEffect(() => {
    if (serviceLineItemsResponse?.length) {
      let filteredData = serviceLineItemsResponse || [];
      if (!isRemittancePayment && !financialId && !notFilterServiceLineItems) {
        filteredData = serviceLineItemsResponse?.filter(
          (parsedItem) => !(parsedItem?.denialReasonId || parsedItem?.isDenied));
      }
      const parsedData = filteredData?.map((item) => {
        const isEdit = isEditWorkList ? isWorkListEditLineItem(item) : isEditLineItem(item);
        const isDenialEdited = isDenialItemEdited(item);
        const adjustedAmount = newAdjustedAmount(isEdit, item, isInsuranceOtherThanPrimary);
        const paidAmount = !isEdit ? undefined : getPaidAmount(item);
        const parsedBalance = getChargeInsurancePatientBalance(
          secondaryPayer, item, isRemittancePayment, paidAmount, adjustedAmount, financialId,
        );
        const InsuranceBalance = parsedBalance?.insuranceBalance || 0;
        const PatientBalance = parsedBalance?.patientBalance || 0;
        return {
          billingEncounterServiceLineId: item?.billingEncounterServiceLineId,
          denialReasonId: !isDenialEdited ? undefined : item?.denialReasonId || 0,
          denialReason: !isDenialEdited ? undefined : item?.denialReason,
          denialReasonCode: !isDenialEdited ? undefined : item?.denialReasonCode,
          denialCategory: !isDenialEdited ? undefined : item?.denialCategory,
          procedure: isRemittancePayment ? item?.description
            : getProcedureWithDescription(item?.cptCode, item?.cptCodeDescription),
          amount: (parseFloat(item?.amount || 0) || 0).toFixed(2),
          balance: (parseFloat(item?.balance || 0) || 0).toFixed(2),
          approved: getApprovedAmount(item, isEdit, isRemittancePayment),
          coIns: !isEdit ? undefined : (parseFloat(item?.coInsurance || 0) || 0).toFixed(2),
          coPay: !isEdit ? undefined : (parseFloat(item?.coPay || 0) || 0).toFixed(2),
          deduct: !isEdit ? undefined : (parseFloat(item?.deductible || 0) || 0).toFixed(2),
          withHold: !isEdit ? undefined : (parseFloat(item?.withHold || 0) || 0).toFixed(2),
          adjust: (parseFloat(adjustedAmount) || 0)?.toFixed(2),
          paid: paidAmount,
          insuranceBalance: !isEdit ? undefined : (parseFloat(InsuranceBalance) || 0).toFixed(2),
          patientBalance: !isEdit ? undefined : (parseFloat(PatientBalance) || 0).toFixed(2),
          interest: !isEdit ? undefined : (parseFloat(item?.interest || 0) || 0).toFixed(2),
          dos: item?.effectiveDateModel ? moment(item?.effectiveDateModel?.dateString).format('MM-DD-YYYY') : moment(item?.effectiveDate).format('MM-DD-YYYY'),
          financeId: item?.financeId,
          value: (parseFloat(item?.value || 0) || 0).toFixed(2),
          isPosted: false,
          chargeBalance: (parseFloat(item?.chargeBalance || 0) || 0).toFixed(2),
          chargeReversedBalance: (parseFloat(parsedBalance?.chargeReversedBalance || 0)
           || 0).toFixed(2),
          isChargeHasPartialPayments: item?.isChargeHasPartialPayments,
          workListId: item?.workListId,
          trStatus: item?.trStatus,
          claimPaymentPlanCodeFirst: item?.claimPaymentPlanCodeFirst,
          claimPaymentPlanCodeSecond: item?.claimPaymentPlanCodeSecond,
          modifiers: isRemittancePayment ? item?.modifiers : item?.modifiersList,
          isCrossOver: item.isCrossOver,
        };
      });
      setLineItemData(parsedData);
      setIsAllClaimsPosted(
        (parsedData?.every((item) => item?.isPosted) || !updatedLineItemsList?.length),
      );
      if (onDataChange) {
        const financeParsedItems = financialId
          ? parsedData?.map((item) => ({ ...item, isEdited: true }))
          : parsedData;
        onDataChange(financeParsedItems);
      }
      if (setNextPayerResponsibility) {
        setNextPayerResponsibility(secondaryPayer);
      }
    }
  }, [serviceLineItemsResponse, secondaryPayer, isInsuranceOtherThanPrimary]);

  useEffect(() => {
    if (isRemittancePayment
      && !isEditWorkListLineItems
      && serviceLineItemsResponse?.length) {
      if (setPayerData) {
        setPayerData({
          payerId: serviceLineItemsResponse?.[0]?.insurancePayerId,
          payerName: serviceLineItemsResponse?.[0]?.insurancePayerName,
        });
      }
      form.setFieldsValue({
        payerId: serviceLineItemsResponse?.[0]?.insurancePayerId,
      });
      autoSaveForm();
    }
  }, [serviceLineItemsResponse]);

  useEffect(() => {
    if (claimDetails?.insurancePayer?.insurancePayerId) {
      form.setFieldsValue({ payerId: claimDetails?.insurancePayer?.insurancePayerId });
    }
  }, [claimDetails]);

  const handleLineItemData = useCallback(
    (index) => ({ target: { value: val, name } }) => {
      const valueCheck = isNegativeValueUptoTwoDecimalPlaces(val, /^-?[0-9]+(?:\.\d{0,2})?$/);
      if (name === 'denialReasonId' || valueCheck || !val) {
        const clonedLineItemData = [...lineItemData];
        const currentLineItemData = { ...lineItemData[index], [name]: parseFloat(val) };
        const updatedAmountCalculation = getUpdatedLineItemAmountDetails(
          name,
          val,
          currentLineItemData,
          isRemittancePayment,
          financialId,
          secondaryPayer,
        );
        let clonedPreviousAdjustment = { ...previousAdjustment };
        if (name === 'approved') {
          if (parseFloat(val, 10) === 0) {
            clonedPreviousAdjustment = {
              ...clonedPreviousAdjustment,
              [currentLineItemData.billingEncounterServiceLineId]: currentLineItemData.adjust,
            };
          } else if (
            clonedPreviousAdjustment[currentLineItemData.billingEncounterServiceLineId]
          ) {
            updatedAmountCalculation.adjust = clonedPreviousAdjustment[currentLineItemData.billingEncounterServiceLineId];
            delete clonedPreviousAdjustment[currentLineItemData.billingEncounterServiceLineId];
          }
        }
        clonedLineItemData[index] = {
          ...currentLineItemData,
          [name]: val,
          denialReasonId:
              parseFloat(currentLineItemData?.approved || 0 || 0, 10) === 0
                ? currentLineItemData?.denialReasonId
                : 0,
          isEdited: true,
          ...updatedAmountCalculation,
        };
        const updatedLineItems = clonedLineItemData?.filter((item) => item.isEdited);
        setLineItemData(clonedLineItemData);
        setPreviousAdjustment(clonedPreviousAdjustment);
        setIsAllClaimsPosted(
            lineItemData?.every((item) => item?.isPosted) || !updatedLineItems?.length,
        );
        setUpdatedLineItemsList(updatedLineItems);
        if (onDataChange) {
          const financeLineItems = financialId
            ? clonedLineItemData?.map((item) => ({ ...item, isEdited: true }))
            : clonedLineItemData;
          onDataChange(financeLineItems);
        }
        if (setNextPayerResponsibility) {
          setNextPayerResponsibility(secondaryPayer);
        }
      }
    },
    [
      lineItemData,
      isRemittancePayment,
      financialId,
      secondaryPayer,
      previousAdjustment,
      setLineItemData,
      setPreviousAdjustment,
      setIsAllClaimsPosted,
      setUpdatedLineItemsList,
      onDataChange,
      setNextPayerResponsibility,
    ],
  );

  const handlePost = useCallback(() => {
    const isDenialReasonNotSelected = lineItemData?.some((item) => (
      parseFloat((item?.approved), 10) === 0) && !item?.denialReasonId
      );
    if (isDenialReasonNotSelected) {
      Notification({ message: 'Please select denial reason' });
      return;
    }
    onPost(updatedLineItemsList, secondaryPayer, claimId,
      identifier, !!isRemittanceAddPayment, checkboxChecked);
  }, [lineItemData, onPost, updatedLineItemsList, secondaryPayer, claimId,
    identifier, isRemittanceAddPayment, checkboxChecked]);

  const handleCancel = useCallback(() => {
    setLineItemData([]);
    setUpdatedLineItemsList([]);
    onCancel();
  }, [onCancel, setLineItemData, setUpdatedLineItemsList]);

  const {
    amount,
    balance,
    approved,
    coIns,
    coPay,
    deduct,
    withHold,
    paid,
    adjust,
    value,
    insuranceBalance,
    patientBalance,
  } = useMemo(() => {
    let amountCalculated = 0;
    let balanceCalculated = 0;
    let approvedCalculated = 0;
    let coInsCalculated = 0;
    let coPayCalculated = 0;
    let deductCalculated = 0;
    let withHoldCalculated = 0;
    let paidCalculated = 0;
    let adjustCalculated = 0;
    let newBalanceCalculated = 0;
    let valueCalculated = 0;
    let insuranceBalanceCalculated = 0;
    let patientBalanceCalculated = 0;
    lineItemData.forEach((item) => {
      amountCalculated += parseFloat(item?.amount || 0) || 0;
      balanceCalculated += parseFloat(item?.balance || 0) || 0;
      approvedCalculated += parseFloat(item?.approved || 0) || 0;
      coInsCalculated += parseFloat((item?.coIns || item?.coInsurance) || 0) || 0;
      coPayCalculated += parseFloat(item?.coPay || 0) || 0;
      deductCalculated += parseFloat((item?.deduct || item?.deductible) || 0) || 0;
      withHoldCalculated += parseFloat(item?.withHold || 0) || 0;
      paidCalculated += parseFloat(item?.paid || 0) || 0;
      adjustCalculated += parseFloat(item?.adjustment || item?.adjust || 0) || 0;
      newBalanceCalculated += parseFloat(item?.newBalance || 0) || 0;
      valueCalculated += parseFloat(item?.value || 0) || 0;
      insuranceBalanceCalculated += parseFloat(item?.insuranceBalance || 0) || 0;
      patientBalanceCalculated += parseFloat(item?.patientBalance || 0) || 0;
    });
    return {
      amount: amountCalculated.toFixed(2),
      balance: balanceCalculated.toFixed(2),
      approved: approvedCalculated.toFixed(2),
      coIns: coInsCalculated.toFixed(2),
      coPay: coPayCalculated.toFixed(2),
      deduct: deductCalculated.toFixed(2),
      withHold: withHoldCalculated.toFixed(2),
      paid: paidCalculated.toFixed(2),
      adjust: adjustCalculated.toFixed(2),
      newBalance: newBalanceCalculated.toFixed(2),
      value: valueCalculated.toFixed(2),
      insuranceBalance: insuranceBalanceCalculated.toFixed(2),
      patientBalance: patientBalanceCalculated.toFixed(2),
    };
  }, [lineItemData]);

  useEffect(() => {
    if (Array.isArray(serviceLineItemsData) && serviceLineItemsData && serviceLineItemsData.some((x) => x.isCrossOver)) {
      setCheckboxChecked(true);
    } else if (Array.isArray(lineItemData) && lineItemData && lineItemData.some((x) => x.isCrossOver)) {
      setCheckboxChecked(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceLineItemsData]);

  return (
    <>
      {(lineItemsLoading || patientInsuranceLoading) && <WidgetLoader />}
      {(isRemittancePayment && showActionIcons) && (
      <div className="demographicInfo">
        <div className="insurance-field">
          <Tooltip content={!isEmpty(serviceLineItemsResponse) && serviceLineItemsResponse[0].healthPlan}>
            <Input
              label="Health Plan"
              labelSpan="5"
              maxValueLength={50}
              inputSpan="7"
              // className="w-100"
              className="insuranceInput"
              disabled
              placeholder={!isEmpty(serviceLineItemsResponse) && serviceLineItemsResponse[0].healthPlan}
            />
          </Tooltip>
          <Input
            label="Profile Type"
            labelSpan="5"
            inputSpan="7"
            name="pageRange"
            className="insuranceInput"
            disabled
            placeholder={!isEmpty(serviceLineItemsResponse) && serviceLineItemsResponse[0]?.insuranceProfileTypeName}
          />
          <Input
            label="Policy Type"
            labelSpan="5"
            inputSpan="7"
            name="pageRange"
            className="insuranceInput"
            disabled
            placeholder={!isEmpty(serviceLineItemsResponse) && serviceLineItemsResponse[0].insurancePolicyTypeName}
          />
          <CheckBox
            label="Cross-Over to secondary"
            name="isCrossOverToSecondary"
            onChange={() => setCheckboxChecked(!checkboxChecked)}
            id="isCrossOverToSecondary"
            labelSpan="5"
            inputSpan="9"
            value={checkboxChecked}
            checked={checkboxChecked}
          />
        </div>
        <div
          className="flex justify-content-sp-bt align-center mr-top-10 mr-bt-8"
        >
          <div>
            <span className="mr-bt-6">Patient Demographic</span>
            <div className="flex align-center patient-name-title">
              <label
                title="Patient Name"
                htmlFor="patient-name"
              >
                {labels.get('patientName')}
                :
              </label>
              {!patientInfoLoading && <span>{`${patientInfo?.firstName || ''} ${patientInfo?.middleName ? patientInfo.middleName : ''} ${patientInfo?.lastName || ''}`}</span>}
            </div>
            <div className="flex align-center patient-name-title">
              <label
                title="Patient MRN"
                htmlFor="patient-dob"
              >
                DOB
                :
              </label>
              {!patientInfoLoading && <span>{dateFormatter(patientInfo?.dateOfBirth?.split('T')?.[0])}</span>}
              {' '}
            </div>
            <div className="flex align-center patient-name-title">
              <label
                title="Patient MRN"
                htmlFor="patient-dob"
              >
                Chart No.
                :
              </label>
              {!patientInfoLoading && <span>{`${patientInfo?.chartNumber && patientInfo.chartNumber}`}</span>}
            </div>
          </div>
        </div>
      </div>
      )}
      <div style={{ marginBottom: '15px', marginTop: '15px' }}>
        <ActionIcons
          userNotes
          edit
          demoGraphic
          transaction
          insurance
          notesProps={{
            page: 'patientpaymentremittance',
          }}
          patientId={patientId || patientIdForEditTransaction || selectedPatientId}
        />
      </div>
      <div className="line-item-table table-max-height-350 posi-relative table-scroll-wrap">
        <div className="app-table">
          <table>
            <thead>
              <tr>
                <th>{labels.get('columns.dosShort')}</th>
                <th>{labels.get('columns.procedure')}</th>
                <th>{labels.get('columns.amount')}</th>
                <th>{labels.get('columns.value')}</th>
                <th>{labels.get('columns.balance')}</th>
                <th>{labels.get('columns.approved')}</th>
                <th>{labels.get('columns.coIns')}</th>
                <th>{labels.get('columns.coPay')}</th>
                <th>{labels.get('columns.deduct')}</th>
                <th>{labels.get('columns.withHold')}</th>
                <th>{labels.get('columns.paid')}</th>
                <th>{labels.get('columns.adjust')}</th>
                {isRemittancePayment && <th>{labels.get('columns.interest')}</th>}
                <th>{labels.get('columns.insuranceBalanceShort')}</th>
                <th>{labels.get('columns.patientBalanceShort')}</th>
                {/* <th>{labels.get('columns.newBalance')}</th> */}
              </tr>
            </thead>
            <tbody>
              {lineItemData?.map((item, index) => (
                <tr key={item?.id} data-testid={`row-${index}`}>
                  <td className="text-align-left lineItem-dos">{item?.dos ?? ''}</td>
                  <td className="text-align-left">{item?.procedure ?? ''}</td>
                  <td className="text-align-right">{item?.amount ?? ''}</td>
                  <td className="text-align-right">{item?.value ?? ''}</td>
                  <td className="text-align-right">{item?.balance ?? ''}</td>
                  <td>
                    <Input
                      isFormItem={false}
                      labelSpan={0}
                      inputSpan={24}
                      value={item?.approved || ''}
                      onChange={handleLineItemData(index)}
                      onBlur={(e) => {
                        if (e.target.value === '-') {
                          handleLineItemData(index)({ target: { value: '', name: 'approved' } });
                        }
                      }}
                      name="approved"
                      disabled={claimFilter === 'outbox' && !isEditWorkList}
                      maxValueLength={11}
                      dataTestId={`approved-${index}`}
                    />
                  </td>
                  {(parseFloat((lineItemData[index]?.approved), 10) === 0)
                    ? (
                      <>
                        <td colSpan={3} key={index}>
                          <DenialReasonComponent
                            item={item}
                            index={index}
                            labels={labels}
                            handleLineItemData={handleLineItemData}
                            name={`denialReasonId-${index}`}
                            reasonIdName="denialReasonId"
                            getPopupContainer={container?.current && (() => container.current)}
                            dataTestId={`denialReasonId-${index}`}
                            id={`denialReasonId-${index}-${claimId}-${patientId}-${financialId}-${formId}-${encounterId}-${editTransactionPatientId}-${selectedClaimData}-${tabId}`}
                          />
                        </td>
                        <td colSpan={10} className="denial-code-align-left">
                          {denialCodes[item?.denialReasonId]}
                        </td>
                      </>
                    )
                    : (
                      <>
                        <td>
                          <Input
                            isFormItem={false}
                            labelSpan={0}
                            inputSpan={24}
                            value={item?.coIns || ''}
                            onChange={handleLineItemData(index)}
                            name="coIns"
                            disabled={claimFilter === 'outbox' && !isEditWorkList}
                            maxValueLength={11}
                            dataTestId={`coIns-${index}`}
                            onBlur={(e) => {
                              if (e.target.value === '-') {
                                handleLineItemData(index)({ target: { value: '0.00', name: 'coIns' } });
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            isFormItem={false}
                            labelSpan={0}
                            inputSpan={24}
                            value={item?.coPay || ''}
                            onChange={handleLineItemData(index)}
                            name="coPay"
                            dataTestId={`coPay-${index}`}
                            disabled={claimFilter === 'outbox' && !isEditWorkList}
                            maxValueLength={11}
                            onBlur={(e) => {
                              if (e.target.value === '-') {
                                handleLineItemData(index)({ target: { value: '0.00', name: 'coPay' } });
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            isFormItem={false}
                            labelSpan={0}
                            inputSpan={24}
                            value={item?.deduct || ''}
                            onChange={handleLineItemData(index)}
                            name="deduct"
                            dataTestId={`deduct-${index}`}
                            disabled={item?.isPosted || (claimFilter === 'outbox' && !isEditWorkList)}
                            maxValueLength={11}
                            onBlur={(e) => {
                              if (e.target.value === '-') {
                                handleLineItemData(index)({ target: { value: '0.00', name: 'deduct' } });
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            isFormItem={false}
                            labelSpan={0}
                            inputSpan={24}
                            value={item?.withHold || ''}
                            onChange={handleLineItemData(index)}
                            name="withHold"
                            dataTestId={`withHold-${index}`}
                            disabled={claimFilter === 'outbox' && !isEditWorkList}
                            maxValueLength={11}
                            onBlur={(e) => {
                              if (e.target.value === '-') {
                                handleLineItemData(index)({ target: { value: '0.00', name: 'withHold' } });
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            isFormItem={false}
                            labelSpan={0}
                            inputSpan={24}
                            value={item?.paid || ''}
                            onChange={handleLineItemData(index)}
                            name="paid"
                            dataTestId={`paid-${index}`}
                            disabled={claimFilter === 'outbox' && !isEditWorkList}
                            maxValueLength={11}
                            onBlur={(e) => {
                              if (e.target.value === '-') {
                                handleLineItemData(index)({ target: { value: '0.00', name: 'paid' } });
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            isFormItem={false}
                            labelSpan={0}
                            inputSpan={24}
                            value={item?.adjust || ''}
                            onChange={handleLineItemData(index)}
                            name="adjust"
                            dataTestId={`adjust-${index}`}
                            disabled={claimFilter === 'outbox' && !isEditWorkList}
                            maxValueLength={11}
                            onBlur={(e) => {
                              if (e.target.value === '-') {
                                handleLineItemData(index)({ target: { value: '0.00', name: 'adjust' } });
                              }
                            }}
                          />
                        </td>
                        {isRemittancePayment && (
                        <td>
                          <Input
                            isFormItem={false}
                            numberOnly
                            labelSpan={0}
                            inputSpan={24}
                            value={item?.interest || ''}
                            onChange={handleLineItemData(index)}
                            name="interest"
                            disabled={claimFilter === 'outbox' && !isEditWorkList}
                            maxValueLength={11}
                            onBlur={(e) => {
                              if (e.target.value === '-') {
                                handleLineItemData(index)({ target: { value: '0.00', name: 'interest' } });
                              }
                            }}
                          />
                        </td>
                        )}
                        <td className="text-align-right">{item?.insuranceBalance}</td>
                        <td className="text-align-right">{item?.patientBalance}</td>
                      </>
                    )}
                </tr>
              ))}
              <tr>
                <td />
                <td />
                <td className="text-align-right">{amount}</td>
                <td className="text-align-right">{value}</td>
                <td className="text-align-right">{balance}</td>
                <td className="text-align-right">{approved}</td>
                <td className="text-align-right">{coIns}</td>
                <td className="text-align-right">{coPay}</td>
                <td className="text-align-right">{deduct}</td>
                <td className="text-align-right">{withHold}</td>
                <td className="text-align-right">{paid}</td>
                <td className="text-align-right">{adjust}</td>
                {isRemittancePayment && <td />}
                <td className="text-align-right">{insuranceBalance}</td>
                <td className="text-align-right">{patientBalance}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      { !onDataChange
      && (
      <div className="flex mr-top-16 flex-end">
        <Button
          type="button"
          className={classNames('btn mr-rt-16 sm-btn', {
            'btn-success': !isAllClaimsPosted,
            'cursor-not-allowed': isAllClaimsPosted,
          })}
          onClick={handlePost}
          disabled={isAllClaimsPosted}
        >
          {labels.get('post')}
        </Button>
        <Button
          type="button"
          className="btn btn-primary sm-btn"
          onClick={handleCancel}
        >
          {labels.get('cancel')}
        </Button>
      </div>
      )}
    </>
  );
});

export default connect((state) => ({
  enumOptions: getEnumOptions(state),
  enumMaster: getEnumMaster(state),
}), null, null, { forwardRef: true })(LineItemsTable);
