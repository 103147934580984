import React, { useMemo, useState } from 'react';

import { dateFormat } from '../../../../../../../lib/constants';

import TimeCell from '../../../../../../../components/Cells/TimeCell';
import InputCell from '../../../../../../../components/Cells/Input';

const PatientPayment = (labels) => [
  {
    Header: labels.get('tableColumns.dateOfService'),
    accessor: 'dateOfService',
    Cell: ({ row: { original: { effectiveDateModel, effectiveDate, description } } }) => {
      if (description === 'Credit Payment') {
        return '-';
      }

      return (
        <TimeCell
          value={effectiveDateModel ? effectiveDateModel?.dateString : effectiveDate}
          format={dateFormat}
        />
      );
    },
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.provider'),
    accessor: 'providerName',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.description'),
    accessor: 'description',
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.responsibility'),
    accessor: 'responsibility',
    Cell: ({ row: { original } }) => (original?.isPatientResponsibility ? 'Patient' : 'Insurance'),
    className: 'text-align-left',
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'amount',
    Cell: ({ row: { original: { amount } } }) => (
      (amount || 0).toFixed(2)
    ),
    Footer: ({ lineItems = [] }) => {
      const total = useMemo(
        () => (
          lineItems.reduce((sum, { amount = 0 }) => sum + parseFloat(amount), 0) || 0
        ).toFixed(2), [lineItems],
      );
      if (!lineItems?.length) {
        return null;
      }
      return (
        <div style={{ fontWeight: 'bolder', color: '#2a4a79', textAlign: 'end' }}>
          {total}
        </div>
      );
    },
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.balance'),
    accessor: 'balance',
    Cell: ({ row: { original: { balance } } }) => (
      (balance || 0).toFixed(2)
    ),
    Footer: ({ lineItems = [] }) => {
      const total = useMemo(
        () => (
          lineItems.reduce((sum, { balance = 0 }) => sum + parseFloat(balance), 0) || 0
        ).toFixed(2), [lineItems],
      );
      if (!lineItems?.length) {
        return null;
      }
      return (
        <div style={{ fontWeight: 'bolder', color: '#2a4a79', textAlign: 'end' }}>
          {total}
        </div>
      );
    },
    fixWidth: '150',
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.applied'),
    accessor: 'applied',
    className: 'text-align-right',
    Cell: ({
      row: { index, original }, getLineItemSetter,
      lineItems = [], financialId, isValueUptoTwoDecimalPlaces, shouldEditPayment, isCreditPayment,
    }) => {
      const isCredit = original?.description === 'Credit Payment';

      const [localData, setLocalData] = useState(parseFloat(lineItems[index]?.applied).toFixed(2));

      const handleTextBoxChange = ({ target: { value } }) => {
        if (isCreditPayment) {
          // When isCreditPayment is true, only allow positive numbers
          if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
            setLocalData(value);
          }
        } else if (isCredit) {
          // Original logic for Credit Payment description
          if (value === '' || value === '-' || /^-?\d*\.?\d{0,2}$/.test(value)) {
            setLocalData(value);
          }
        } else if (isValueUptoTwoDecimalPlaces(value)) {
          // Original logic for other cases
          setLocalData(value);
        }
      };

      return (
        <InputCell
          initialValue={parseFloat(lineItems[index]?.applied).toFixed(2)}
          handleTextBox={getLineItemSetter(index, 'applied')}
          maxValueLength={11}
          numberOnly={isCreditPayment || !isCredit}
          value={localData}
          onChange={handleTextBoxChange}
          disabled={isCredit ? false
            : (shouldEditPayment || (!financialId && lineItems[index]?.balance <= 0))}
          dataTestId={`patient-payment-applied-${index}`}
        />
      );
    },
    Footer: ({ lineItems = [] }) => {
      const total = useMemo(
        () => (
          lineItems.reduce((sum, { applied = 0 }) => sum + parseFloat(applied), 0) || 0
        ).toFixed(2), [lineItems],
      );
      if (!lineItems?.length) {
        return null;
      }
      return (
        <div style={{ fontWeight: 'bolder', color: '#2a4a79', textAlign: 'end' }}>
          {total}
        </div>
      );
    },
    fixWidth: '150',
  },
];
export default PatientPayment;
